/* eslint-disable */
import { Field } from '@modules/factory/dynamic-form-generator/dynamic-form-generator.component';
import { ChartConfiguration } from 'chart.js';
import { SharedData, SharingType } from '../_enums';
import { ColumnTypes } from '../_utilities/table-data';
import { BasePermission } from './menu';
import { ModuleNames } from './permissions';
import { DateRange } from './range';
import { PremiumFeatures, UserType } from './user';

export class Pagination {
  total: number;
  count: number;
  per_page: number;
  current_page: number;
  total_pages: number;
  links?: {
    next?: string;
    previous?: string;
  };
}

export class MetaData {
  pagination?: Pagination;
}
export class ApiListResponse {
  data: any[] | null;
  meta?: MetaData;
}

export interface WorkOptions {
  key: string;
  value: any;
}

export interface ChartDataCollection {
  collectionName: string;
  collectionData: any;
  uid?: string;
}

export interface CollectionWithUid {
  uid: string;
  collection: ChartDataCollection;
}

export interface InfoTextData {
  message: string;
  link: string;
}

export interface ReportFilter {
  dateRange: DateRange;
  report: string;
  action: string;
  group_by?: string;
}
export interface SdkFilter {
  dateRange: DateRange;
}

export interface ResetPasswordBody {
  token: string;
  password: string;
  account_id?: number;
}

export interface ForgetPasswordBody {
  account_id: number;
  email: string;
}

export interface CreateAlertData {
  name: string;
  app_id: string;
  dimension: string;
  filter?: string;
  kpi: string;
  rule: string;
  threshold: number;
  comparison_period: string;
  min_installs: number;
  receivers: string[];
  priority?: string;
}

export interface LiveAlertData {
  active: boolean;
  app: string;
  channel: string;
  id: number;
  last_trigger: string;
  name: string;
  priority: number;
}

export interface AlertsListResponseData {
  data: LiveAlertData[];
  meta: any;
}

export interface AppSettings {
  session: string | number;
  appStoreCountry?: string;
  attribution: number; // in days
  retargeting: boolean;
  engagementInterval: number; // in days
  serverKey: string;
  timezone?: string;
  currency?: string;
  masking: boolean;
}

export interface MultiplyOptions {
  uid: string;
  value: string;
  label: string;
}

export interface RuleData {
  ruleCode: string;
  options: MultiplyOptions[];
}

export interface HeaderFormData {
  app: string;
  engagementType: string;
  timeFrame: string;
  timeFrameValue: number;
  frequency: string;
  frequencyValue: number;
}

export interface HeaderOutput {
  formData: HeaderFormData;
  selectedUUID: string;
  isValid: boolean;
}

export interface FilterValuesOutput {
  action: string;
  timeFrame: string;
  timeFrameValue: string;
  frequency: string;
  frequencyValue: string;
}

export interface ChildRuleObject {
  uid: string;
  filterValues: FilterValuesOutput;
}

export interface AudienceTable {
  audience_name: string;
  size: string;
  app_name: string;
  upload_to: any[];
  upload_status: any;
  actions: string;
}

export interface EmailBody {
  email: string;
  account_id?: number;
}

export interface EmailVerify {
  account_id?: number;
  token: string;
}

export interface MemberVerify {
  email: string;
  token: string;
  password: string;
}

export interface ChangePassword {
  password: string;
}

export interface PremiumAccess {
  audience: boolean;
  fraud: boolean;
  retargeting: boolean;
}

export interface PackageModel {
  name: string;
  price: number | string;
  info: string;
  features: string[];
}

export interface QueryForm {
  title: string;
  subject?: string;
  email?: string;
  description?: string;
}

export interface ParamOptions {
  name: string;
  value: string;
  placeholder?: string;
}

export interface FieldData {
  name: string;
  value: string;
  key?: string;
  options: ParamOptions[];
}

export interface BannerData {
  name: string;
  key: string;
  template: string;
  action?: string;
}

export interface BannerBehaviour {
  frequency_capping: number;
  frequency: number;
  dismissal_period: number;
  rating_enabled: boolean;
}

export interface BannerDesign {
  title_color: string;
  description_color: string;
  call_to_action_text_color: string;
  call_to_action_background_color: string;
  call_to_action_border_color: string;
  rating_color: string;
  reviews_color: string;
  background_image_color: string;
  dismissal_icon_color: string;
}

export interface BannerContent {
  title: string;
  description: string;
  app_icon?: string;
  logo?: string;
  call_to_action: string;
  rating?: number;
  reviews_count?: number;
}

export interface BannerConfig {
  style: {
    position: string;
    type: string;
    mainBackgroundImage: string;
    mainBackgroundColor: string;
    titleColor: string;
    subTitleColor: string;
    buttonBackgroundColor: string;
    buttonTextColor: string;
    closeButtonBackground: string;
    imageConfig: {
      size: string;
      position: string;
      repeat: string;
    };
  };
  content: {
    title: string;
    subtitle: string;
    logo: string;
    call_to_action: string;
    url: string;
    rating: number;
  };
  show_only_mobile: boolean;
}

export interface LinkTemplateFormValue {
  linkTemplateName: string;
  subdomain: string;
  iosRedirectionWithoutLogin: string;
  androidRedirectionWithoutLogin: string;
  fallbackUrlIos: string;
  fallbackUrlAndroid: string;
  androidAppId?: string;
  iosAppId?: string;
}

export interface SmartBannerPayload {
  position: string;
  type: string;
  name: string;
  template_id: string;
  attributes?: IAttributionParameters;
  content: BannerContent;
  settings: BannerBehaviour;
  design: BannerDesign;
}

export interface BannerRawData {
  settings: {
    name: string;
    template: string;
    dismissal: number;
  };
  creativeData?: {
    type: string;
    position: string;
    title: string;
    titleColor: string;
    subtitle: string;
    subTitleColor: string;
    call_to_action: string;
    buttonTextColor: string;
    buttonBackgroundColor: string;
    logo: string;
    closeButtonBackground: string;
    mainBackgroundImage: string;
    mainBackgroundColor: string;
  };
  linkParams: any;
}

export class AttributionParameters implements IAttributionParameters {
  media_source?: string;
}

export interface IAttributionParameters {
  media_source?: string;
  campaign?: string;
  channel?: string;
  ad_set?: string;
  ad_name?: string;
  custom?: any;
  id?: number;
}

export interface BannerResponse {
  attributes: {
    data: IAttributionParameters;
  };
  name: string;
  banner_key: string;
  behaviour: BannerBehaviour;
  content: BannerContent;
  template: {
    data: {
      android: {
        data: {
          api_key: string;
          attribution_window_months: number;
          country_code: string;
          country_name: string;
          currency: string;
          enable_ip_masking: boolean;
          enable_re_targeting_attribution: number;
          firebase_gcm_key: string;
          id: string;
          logo: string;
          loyal_user_event: string | number;
          name: string;
          re_engagement_time_frame: number;
          store: string;
          store_url: string;
          timezone: string;
        };
      };
      android_landing_page_url: string;
      deep_link: string;
      id: number;
      ios_landing_page_url: string;
      name: string;
      subdomain: string;
    };
  };
}

export interface FilterOptions {
  agencies?: any[];
  channels?: any[];
  countries: any[];
  media_sources: string[];
}

export interface CreateDashboard {
  name: string;
}

export interface FilterOptionsValue {
  countries: string[];
  media_sources: string[];
  excludeOrganic?: boolean;
}

export interface WidgetFormData {
  type: string;
  title: string;
  description: string;
  apps: string[];
  kpi: string;
  groupBy: string;
  showTop: string | number;
  filters?: FilterOptionsValue;
  dashboardId: string;
}

export interface WidgetDetails {
  app_id: string;
  description: string;
  filters: {
    exclude_organic: boolean;
    countries: string[];
    media_sources: string[];
    channels: string[];
  };
  campaigns: string[];
  channels: string[];
  countries: string[];
  exclude_organic: true;
  media_sources: string[];
  group_by: string;
  id: number;
  kpi: string[];
  title: string;
  top_results_count: number;
  type: string;
}

export interface PresetDateRange {
  presetLabel: any;
  range: DateRange;
}

export interface PrimaryButtonConfig {
  icon?: string;
  label: string;
}

export interface MultilineMessage {
  line1: string;
  line2?: string;
}

export interface BrandedLinkData {
  branded_link: string;
  id: string | number;
  subdomain: string;
  verified: boolean | number;
}

export interface VerifyBrandLink {
  brand_link: string;
}

export interface CreateBrandLink {
  brand_link: string;
  subdomain: string;
}

export interface RetentionFilter {
  mediaSource: string[];
  geo: string[];
  type: TimeFrame;
  groupBy: string[];
  dateRange: DateRange;
  minSize: number;
  filters: any;
}

export enum TimeFrame {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly'
}

export interface DynamicObject {
  [key: string]: {
    type: string;
    label?: string;
    options?: any;
    labelPosition?: string;
    width?: string;
  };
}

export interface EventsData {
  id?: string;
  event: string;
  partner_identifier: string;
  type: SharingType;
  data: SharedData;
}

export class SaveIntegration {
  activate: boolean;
  dynamicForm: DynamicObject;
  postback: {
    default: {
      install: SharingType;
      're-install': SharingType;
    };
    inAppEvents?: {
      window: number;
      events: EventsData[];
    };
    in_app_event?: {
      window: number;
      events: EventsData[];
    };
  };
}

export interface PlanRequest {
  description: string;
  email: string;
  subject: string;
}

export interface PivotElement {
  pickList: number;
  name: string;
}

export interface PivotData {
  dimention: Metrics;
  metric: Metrics;
  events?: Metrics;
}

export interface PivotRequestBody {
  apps: string[];
  dimensions: string[];
  metrics: string[];
  dateRange: DateRange;
}

export interface SavePivotBody {
  apps: string[];
  dimenions: string[];
  metrics: string[];
  name: string;
}

export interface SavedPivotData {
  id: number;
  name: string;
  dimensions: string[];
  metrics: string[];
  apps: string[];
}

export interface PivotListResponse {
  data: SavedPivotData[];
  meta?: MetaData;
}

export interface AlertFilterData {
  app_id: string;
  dimension: string;
}

export interface PivotTableDataSource {
  dimension: string;
  data?: PivotTableDataSource[];
  metrics?: MetricsValue;
}

export interface MetricsValue {
  [key: string]: number | string;
}

// Adtrack interfaces strats here

export interface ApiResponse<T> {
  success: boolean;
  message: string;
  error_code?: number;
  data: T;
}

export interface Links {
  first: string;
  last: string;
  prev: null;
  next: null;
}

export interface Link {
  url: null | string;
  label: string;
  active: boolean;
}

export interface Meta {
  current_page?: number;
  from?: number;
  last_page?: number;
  links?: Link[];
  path?: string;
  per_page: number;
  to?: number;
  total: number;
}

export interface PaginatedResponse<T> {
  data: T;
  links?: Links | null;
  meta?: Meta | null;
}

export interface LTVResponse {
  metrics: MetricData;
  stats: StatsData;
}

export interface Metrics {
  [key: string]: number;
}

export interface StatsData {
  [key: string]: Stats;
}

export interface Stats {
  active: number;
  inactive: number;
  pending: number;
}

export interface MetricData {
  today: Metrics[];
  yesterday: Metrics[];
  MTD: Metrics[];
}

export interface TopEntityResponse {
  today: TopMetrics[];
  yesterday: TopMetrics[];
  mtd: TopMetrics[];
}

export interface TopMetrics {
  // name: string;
  // id: number;
  // conversion: number;
  // clicks: number;
  // epc: number;
  // payout: number;
  // revenue: number;
  // profit: number;
  [key: string]: number;
}

export interface ReportData {
  clicks: number;
  conversion: number;
  impression: number;
  payout: number;
  profit: number;
  revenue: number;
  report_date?: string;
}

export interface CampaignMenu {
  title: string;
  subtitle: string;
  url: string;
}

export interface InternalNavigationMenu extends Partial<BasePermission> {
  index?: number;
  title: string;
  subtitle?: string;
  url: string | any[];
  routeData?: any;
  icon?: string;
  separator?: boolean;
  paginate?: boolean;
}

export interface AllCampaignsResponse {
  count: StatusCount;
  active: ActiveCountry;
}

export interface ActiveCountry {
  [key: string]: number;
}

export interface AddMemberRequest {
  first_name: string;
  last_name: string;
  email: string;
  country_code: string;
  password: string;
}

export interface StatusCount {
  active: number;
  paused: number;
  pending: number;
  disabled: number;
}

export interface ActionButton extends Partial<BasePermission> {
  label: string;
  emitValue: string | number;
  icon?: string;
  color?: 'primary' | 'accent' | 'warn' | '';
  fontSet?: 'mso' | 'fas' | 'fab' | 'fad';
  menu?: Array<ActionDropdownMenu> | ((data: any) => Array<ActionDropdownMenu>);
  tooltip?: string;
  accessKey?: string;
  disableFn?: (data: any) => boolean;
}

export interface ActionDropdownMenu extends Partial<BasePermission> {
  label: string;
  emitValue: any;
}

export interface ActionMenuConfig extends Partial<BasePermission> {
  columnName: string;
  columnWidth?: number;
  buttons: Array<ActionButton>;
}

export interface ColumnCalculations {
  sumAllExcept?: Array<string>;
  sumColumns?: Array<string>;
  averageColumns?: Array<string>;
  ignoreColumns?: Array<string>;
  rateColumns?: Array<string>;
  rateCalculationParamsMap?: {
    [key: string]: {
      total: string;
      divisor: string;
    };
  };
}

export interface DataSourceConfig {
  dataSource: PaginatedResponse<any>;
  hiddenColumns?: Array<string>;
  visibleColumns?: Array<string>;
  stickyColumns?: Array<string>;
  stickyColumnsEnd?: Array<string>;
  enableSelection?: boolean;
  actionMenus?: Array<ActionMenuConfig>;
  cellStatus?: CellStatus;
  columnCalculation?: ColumnCalculations;
  selected?: Array<string | number>;
  isCustomizable?: boolean;
}

export interface CellStatus {
  statusKey: string | string[];
  inverse?: boolean;
}

export interface Postback {
  _id: number;
  id: number;
  publisher: Publisher;
  event: string;
  postback_url: string;
  privacy_postback_url: string;
  is_active: boolean;
  options: Options;
  campaign?: Campaign;
  goal?: Goal;
  is_global_postback: boolean;
}
export interface Campaign {
  id: number;
  title: string;
}

export interface Publisher {
  company: string;
  id: string;
}

export interface Options {
  id: number;
  fire_if_zero: number;
  skip_params_encoding: string[];
  http_method: string;
  body_payload: BodyPayload;
  http_headers: HTTPHeader[];
}

export interface BodyPayload {
  name: string;
}

export interface HTTPHeader {
  key: string;
  value: string;
}

export interface AddPostbackRequest {
  event: any[];
  publisher_id: string;
  postback_method: any[];
  campaign_id: string;
  postback_url: string;
  privacy_postback_url: string;
  advance_options: AdvancePostbackOptions;
}

export interface AdvancePostbackOptions {
  fire_if_zero: string;
  skip_params_encoding: string[];
  http_method: any[];
  body_payload: string;
  http_headers: {
    [key: string]: string;
  };
}

export enum WidgetTypes {
  CHART = 'chart',
  STATUS_CHART = 'status_chart',
  TABLE = 'table',
  CHART_TABLE = 'chart_table',
  CHART_REPORT = 'chart_report',
  WORLDMAP = 'worldmap'
}
export interface ColumnTypeMap {
  columnName: string;
  type: ColumnTypes;
  format?: string;
  limit?: number;
}

export interface ActionEvent<T> {
  emitValue: string;
  row: T;
}

export interface ChartWidget<T> extends Partial<BasePermission> {
  id: string;
  title: string;
  info: string;
  gridType: WidgetTypes;
  cssClass?: string;
  config: T;
  height?: number;
  width?: number;
  css?: string;
}

export interface StatusWidgetData {
  statuses: Array<Status>;
  chart: ChartConfiguration;
  map?: ActiveCountry;
}
export interface ChartTableWidgetData {
  stats: TopEntityResponse;
  chart: ChartConfiguration;
}

export interface TrafficChannel {
  id: number;
  name: string;
}

export interface Status {
  key: string;
  val: any;
}

export interface CreatePartnerRequest {
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  company_name: string;
  company_website: string;
  user_type: PartnerType;
  password: string;
  country_code: string;
}

export interface UserResponse {
  _id: number;
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  user_type?: UserType;
  phone: string;
  status: string;
  _permissions?: {
    [key: string]: Array<'create' | 'read' | 'update' | 'delete'>;
  };
  premium_features?: PremiumFeatures[];
  country_code?: string[];
  roles?: RoleItem[];
  teams?: Team[];
}

export interface TrackingLinkRequest {
  tracking_domain: string;
  campaign: number;
  url: string;
  impression_pixel: string;
  click_pixel: string;
  deeplink: string;
  source: string;
}

export interface PartnerResponse extends UserResponse {
  _id: any;
  company_name: string;
  website: string;
  status: string;
}

export interface TeamMember extends UserResponse {
  permissions: any[];
}

export interface WidgetMetadata {
  title?: string;
  info?: string;
  id?: string;
}

export interface InitialTableData {
  dataSource: any[];
  dataSourceMap: any[];
}

export interface CohortDataSource extends InitialTableData {
  dimensionLabels: string[];
}

export interface DimensionData {
  dimension: any;
  data?: DimensionData[];
  metrics?: Metrics;
}

export interface CreateCouponRequest {
  code: string;
  campaign_id: number;
  type: CouponType;
  publisher_id: number;
  description: string;
  start_date: Date;
  end_date: Date;
  status: string;
}

export enum CouponType {
  Generic = 'generic',
  Exclusive = 'exclusive',
  OneTime = 'one_time'
}
export enum CouponStatus {
  Active = 'active',
  Pending = 'pending',
  Expired = 'expired',
  Paused = 'paused',
  Disabled = 'disabled'
}

export enum FraudRuleStatus {
  Active = 'active',
  Inactive = 'inactive'
}

export enum CampaignStatus {
  Active = 'active',
  Paused = 'paused',
  Pending = 'pending',
  Disabled = 'disabled'
}

export enum PartnerStatus {
  Active = 'active',
  Pending = 'pending',
  Disabled = 'disabled',
  Rejected = 'rejected'
}

export enum TeamMemberStatus {
  Active = 'active',
  Pending = 'pending',
  Disabled = 'disabled'
  // Rejected = 'rejected',
}

export enum ReportType {
  Campaign = 'campaign',
  Publisher = 'publisher',
  Advertiser = 'advertiser',
  Goal = 'goal',
  Click = 'click',
  Conversion = 'conversion',
  ConversionFunnel = 'conversionFunnel',
  Impression = 'impression',
  CAP = 'CAP',
  Sampling = 'sampling',
  Comparison = 'comparison',
  Cohort = 'cohort',
  Performance = 'performance',
  Daily = 'daily',
  SubID = 'subid',
  GeoWise = 'geo_wise',
  Event = 'event',
  Offline = 'offline',
  FailedConversion = 'failed_conversions',
  Custom = 'custom'
}

export enum TemplateType {
  PayOut = 'payout',
  App = 'app',
  Email = 'email',
  Targeting = 'targeting'
}

export enum PartnerType {
  Publisher = 'publisher',
  Advertiser = 'advertiser'
}

export enum TextColors {
  primary = '#272D37',
  secondary = '#646F79',
  disabled = '#CBCFD3',
  link = '#0078D4',
  error = '#D81E22',
  success = '#0CB560',
  warning = '#F89B02',
  info = '#3E8DEC',
  white = '#ffffff',
  theme = '#6160e3'
}

export enum ChartColors {
  red = '#D81E22',
  info = '#3E8DEC',
  green = '#40C057',
  theme = '#6160e3',
  white = '#ffffff',
  orange = '#FD7E14',
  secondary = '#646F79',
  system = '#324358',
  border = '#D9E3EF',
  hover = '#f2f5f8',
  input = '#fafbfc'
}

export interface CouponCodeDetails {
  _id: number;
  id: number;
  code: string;
  campaign: Campaign;
  publisher: Publisher;
  start_date: Date;
  end_date: Date;
  coupon_type: string;
  is_active: number | boolean;
  status: CouponStatus;
  description: string;
}

export interface SettingsToggle {
  title?: string;
  subtitle?: string;
  state?: boolean;
  id?: string | number;
}

export interface CompanyDetails {
  name: string;
  email: string;
  address: string;
  city?: string;
  state?: string;
  country_code: string;
  zip_code?: string;
  tax_id?: string;
  digital_signature?: any;
  id?: number;
}
export interface BankDetails {
  account_holder_name: string;
  account_number: string;
  bank_name: string;
  ifsc_code: string;
  swift_code?: string;
  id?: number;
}

export interface BrandingCustomizationRequest {
  brand_logo: any;
  brand_color: string;
  favicon: any;
  background: string;
  support_email: string;
  support_link: string;
  terms_conditions_url: string;
  privacy_policy_url: string;
}

export interface InvoiceDetails {
  id: number;
  billing_start_date: string;
  billing_end_date: string;
  generation_date: string;
  due_date: string;
  amount: number;
  status: string;
  discount: number;
  payment_date: string;
}

export interface InvoiceInfo {
  company_name: string;
  billing_email: string;
  account_manager: string;
  billing_address: string;
  tax_id: string;
  due_invoice: DueInvoice;
  subscription: null;
}

export interface DueInvoice {
  amount: string;
  due_date: string;
  id: string;
}

export interface LogResponse {
  id: number;
  description: object
}

export interface BrandingRequest {
  domain: string;
}

export interface BrandingResponse {
  account_id: number;
  company_name: string;
  company_type: 'network' | 'brand';
  domain: string;
  website: string;
  branding: BrandDetails;
  modules: ModuleAccess;
  security_token: string;
  white_label_domain: string;
}

export interface ModuleAccess {
  disabled: string[];
  enabled: string[];
  hidden: string[];
}

export interface BrandDetails {
  brand_logo: string;
  brand_color: string;
  favicon: string;
  background: string;
  terms_condition: null;
  privacy_policy: null;
  support_email: string;
}

export interface Macro {
  macro: string;
  value: string;
  data?: any;
}

export enum ImportType {
  Campaign = 'campaign',
  Publisher = 'publisher',
  Advertiser = 'advertiser',
  'Offline report' = 'offline_report'
}
export enum ImportFileFormatType {
  Appcarry = 'appcarry',
  Trackier = 'trackier',
  Offer18 = 'offer18',
  Affise = 'affise'
}

export interface ImportRequest {
  import_file: File;
  import_type: ImportType;
}

export interface ColumnIconsMap {
  columnName: string;
  prefixIcon?: string;
  suffixIcon?: string;
}

export interface MenuGroup {
  name: string;
  links: ReportNavigationMenu[];
  type?: 'general' | 'additional' | 'tracking' | 'custom';
}

export type ReportViewType = 'general' | 'advanced';
export interface ReportNavigationMenu extends InternalNavigationMenu {
  reportViewType: ReportViewType;
  id?: number;
  isConfigured?: boolean;
}

export interface DebugResponse {
  postback_url: string;
  trace_path: { [key: string]: number };
}

export interface IntegrationPartners {
  id?: number;
  name: string;
  logo: string;
  website?: string;
  is_active?: boolean;
  form_fields?: Field[];
}

export interface CampaignSettings {
  display_cap: boolean;
  cp_override: boolean;
  enforce_gst: boolean;
  enforce_ost: boolean;
  ccoc: boolean;
  ccpg: boolean;
  asltpc: boolean;
  aucc: boolean;
  ccpar: boolean;
  mcscls: boolean;
  cac: boolean;
  aicc: boolean;
  dcc: boolean;
  ntc_status: 'rejected' | 'cancelled';
}

export enum StatusType {
  Campaign = 'campaign',
  Partner = 'partner',
  Coupon = 'coupon',
  TeamMember = 'team_member',
  SmartLink = 'smart-link',
  AccessApproval = 'access',
  OptimizationRule = 'campaign/optimization',
  FraudRules = 'fraud/rules',
}

export enum FilterType {
  Campaign = 'campaign',
  Partner = 'partner',
  Report = 'report'
}

export enum Fragments {
  NavigateBack = 'navigateBack'
}

export interface TemplateCreateResponse<T> {
  type: TemplateType;
  data: T;
}

export interface PartnerFilter {
  name: string;
  email: string;
  status: string;
  company_name: string;
  id: string;
}

export interface CampaignFilter {
  title?: string;
  categories?: string[];
  traffic_channels?: string[];
  advertiser?: Array<string | number>;
  objective?: string;
  status?: string[];
  id?: string;
}

export interface ReportsFilter {
  campaigns: string[];
  publishers: string[];
  advertisers: string[];
  payout: number;
  revenue: number;
  clicks: number;
  conversions: number;
  impressions: number;
  browsers: string[];
  countries: string[];
  cities: string[];
  os: string[];
  goal: string[];
  ip: string;
}

export interface IAdvancedFilter {
  partner: PartnerFilter;
  campaign: CampaignFilter;
  report: ReportsFilter;
}

export class AdvancedFilter implements IAdvancedFilter {
  partner: {
    name: null;
    email: null;
    status: null;
    company_name: null;
    id: null;
  };

  campaign: {
    title: null;
    categories: null;
    traffic_channels: null;
    advertiser: null;
    objective: null;
    status: null;
    id: null;
  };

  report: {
    campaigns: null;
    publishers: null;
    advertisers: null;
    payout: null;
    revenue: null;
    clicks: null;
    conversions: null;
    impressions: null;
    browsers: null;
    countries: null;
    cities: null;
    os: null;
    goal: null;
    ip: null;
  };
}

export interface SMTPDetails {
  server: string;
  username: string;
  from_name: string;
  email: string;
  port: number;
  security_type: string;
  password: string;
  threshold: number;
}

export interface TrackingDomain {
  domain: string;
  has_ssl: boolean;
  is_default: boolean;
}

export interface TrackingDomainResponse extends TrackingDomain {
  id: number;
  is_verified: boolean
  is_removable: boolean
}

export interface OfferCheckRequest {
  url: string;
  os_name: string;
  os_version: string | undefined;
  country_code: string;
}
export interface Creatives {
  tag: string;
  resource: File;
  resource_url: string;
}

export interface CreativeSaveRequest {
  campaign_id: string;
  creatives: Creatives[];
}

export interface CreateRoleRequest {
  role_name: string;
  permissions: ModulePermission[];
}

export interface AssignRoleRequest {
  role_permissions_id: string;
  user_id: number;
}

export interface ModulePermission {
  module: ModuleNames;
  permissions: Array<'create' | 'read' | 'update' | 'delete'>;
}

export interface RoleItem {
  id: number;
  name: string;
}

export interface Roles {
  id: number;
  role_name: string;
  permissions: ModulePermission[];
  role_permissions_id?: number[];
}

export interface Team {
  id: number;
  account_id?: number;
  name: string;
  countries: string[];
  data_shared?: string[];
}

export interface OfferCheckResponse {
  postback_url: string;
  trace_path: { [key: string]: number };
  conversion_path: { [key: string]: number };
}

export interface VisibleTag {
  label: string;
  description: string;
  data?: any;
}

export interface CampaignCreatives {
  id: number;
  campaign_id: string;
  url: string;
  tags: string;
}

export interface CampaignAssignmentRequest {
  status: 'approved' | 'rejected';
  campaign_id: string;
  publisher_ids: string[];
}

export interface CreateGoalRequest {
  goal_name: string;
  advertiser_goal_token: string;
  payout: number;
  revenue: number;
  send_postback_to_all: boolean;
  _id?: any;
  payout_type: PayoutTypes;
}

export enum PayoutTypes {
  Fixed = 'fixed',
  Percent = 'percent'
}

export interface Goal {
  _id: number;
  goal_name: string;
  advertiser_goal_token: string;
  payout: number;
  revenue: number;
  send_postback_to_all: boolean;
  publishers: PublisherElement[];
}

export interface PublisherElement {
  id: number;
  publisher: PublisherPublisher;
  revenue_model: string;
  payout: number;
}

export interface PublisherPublisher {
  id: string;
  name: string;
}

export interface AssignGoalRequest {
  campaignId: string;
  goalId: string | number;
  publisher_id: string;
  revenue_model: string;
  payout: number;
  payout_type: PayoutTypes;
}

export interface CreateCapRequest {
  type: CapTypes;
  publishers?: string[] | null;
  excluded_publishers?: string[] | null;
  daily: number;
  monthly: number;
  lifetime: number;
  redirect?: string | null;
  custom_redirect_url?: string;
  cap_apply: ApplyCapType;
  _id?: number;
}

export enum CapTypes {
  GrossConversions = 'gross-conversions',
  Payout = 'payout',
  Revenue = 'revenue',
  GrossClicks = 'gross-clicks',
  Impressions = 'impressions'
}

export enum ApplyCapType {
  Individual = 'individual',
  Group = 'group'
}

export interface Cap {
  _id: number;
  type: string;
  daily: number;
  monthly: number;
  lifetime: number;
  redirect: null;
  cap_apply: string;
  publishers: PartnerResponse[];
  excluded_publishers?: PartnerResponse[];
}

export interface Targeting {
  geo: Geo;
  network: string[];
  time: Time;
  device: TargetDevice;
  geo_fallback_url?: string;
  network_fallback_url?: string;
  device_fallback_url?: string;
  time_fallback_url?: string;
}

export interface TargetDevice {
  os: string[];
  device_type: string[];
}

export interface Geo {
  label: string;
  id: number;
  options?: Geo[];
}

export interface Time {
  timezone: string;
  start_hour: number;
  end_hour: number;
  day: string[];
}

export interface CreateSamplingRequest {
  minimum?: number;
  maximum?: number;
  publishers: string[];
  status: 'active' | 'paused';
}

export interface Sampling {
  _id: number;
  publishers: Array<PublisherDetails>;
  minimum: number;
  maximum: number;
  goal?: Goal;
  status?: string;
}

export interface PublisherDetails {
  _id: string;
  id: number;
  first_name: string;
  last_name: string;
  status: string;
  email: string;
  phone: string;
  company_name: string;
  website: string;
  country_code: string;
}


export const AllowedColumnsMap = {
  campaign: ['title', 'id', 'advertiser'],
  publisher: ['name', 'id', 'company_name'],
  advertiser: ['name', ' id', 'company_name'],
  coupons: ['code', 'id', 'publisher'],
  reports: ['report_name']
};

export interface AssignDataRequest {
  action: 'assign' | 'reject';
  type: 'string';
  ids: string[];
}

export interface ScheduleReports {
  name: string;
  status: string;
  frequency: string;
  report_type: string;
  notify_users: (NotifyUser | string)[];
  additional_users: string[];
  _id?: number;
}

export interface NotifyUser {
  _id: number;
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: null;
  status: string;
  user_type: string;
}


export enum Browsers {
  Chrome = 'chrome',
  Firefox = 'firefox',
  Microsoft_Edge = 'edge',
  Safari = 'safari',
  Oera = 'opera',
  Brave = 'brave'
}

export interface TrackingType {
  label: string;
  value: string;
}

export interface ColumnsConfig {
  hidden: string[];
  displayed: string[];
}

export interface LogDetail {
  id: number;
  description: string;
  performed_by: string;
  performed_at: string;
  log_name: string;
  iP_address: string;
  subject: any;
}

export interface CampaignAccessDataResponse {
  _id: string
  id: number
  title: string
  objective: string
  status: string
  campaign_url: string
  advertiser: Advertiser
  visibility: string
  preview_url: string
  categories: string[]
  description: string
  kpi: string
  external_offer_id: number | string
  country_code?: string
  currency: string
  promotion_methods?: string[]
  fallback_url: string
  impression_url: string
}

export interface Advertiser {
  id?: string
  name?: string
}
