import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import {
  ApiResponse,
  PaginatedResponse,
} from '@interfaces/global';
import ApiBase from '@utilities/api-base';
import { Observable } from 'rxjs';
import { CreateAdvancedReportRequest } from '../services/report.service';

export interface CustomReportRequest {
  _id?: number;
  name: string;
  description: string;
  configuration?: CreateAdvancedReportRequest;
}

@Injectable({
  providedIn: 'root'
})
export class CustomReportService extends ApiBase {
  constructor(
    private http: HttpClient,
  ) {
    super();
  }

  getCustomReports(): Observable<ApiResponse<CustomReportRequest[]>> {
    return this.http.get<ApiResponse<CustomReportRequest[]>>(
      `${this.baseUrl}report/custom`
    );
  }

  getCustomReportDetails(reportId: number): Observable<ApiResponse<CustomReportRequest>> {
    return this.http.get<ApiResponse<CustomReportRequest>>(
      `${this.baseUrl}report/custom/${reportId}`
    );
  }

  createCustomReport(body: CustomReportRequest, reportId?: number): Observable<ApiResponse<null>> {
    return this.http.post<ApiResponse<null>>(
      `${this.baseUrl}report/custom/${reportId || ''}`,
      body
    );
  }

  deleteCustomReport(reportId: number): Observable<ApiResponse<null>> {
    return this.http.delete<ApiResponse<null>>(
      `${this.baseUrl}report/custom/${reportId}`
    );
  }
}
