import { PostbackTrackingParameters, TrackingParameters } from '@enums/macros';
import {
  CampaignStatus,
  CouponStatus,
  PartnerStatus,
  TeamMemberStatus,
  TemplateType
} from '@interfaces/global';
import { BasePermission } from '@interfaces/menu';
import { AdvancedFilterFields } from '@modules/reports/advanced-reports/condition-block/conditions';

export interface DropdownOption<T> extends Partial<BasePermission> {
  label: string;
  value: string;
  info?: string;
  data?: T;
  disabled?: boolean;
}

export interface AdvancedFilterOption<T> extends DropdownOption<T> {
  value: AdvancedFilterFields;
  isFilterField?: boolean;
  isGroupByField?: boolean;
  disabled?: boolean;
}

export const EnumToMacro = (enumToConvert: any) => {
  return [
    ...Object?.keys(enumToConvert)?.map((key) => ({
      label: `{${enumToConvert[key]}}`,
      value: `{${enumToConvert[key]}}`
    }))
  ];
};

export const CampaignUrlTokens: DropdownOption<any>[] = EnumToMacro(TrackingParameters);

export const PostbackUrlTokens: DropdownOption<any>[] = EnumToMacro(
  PostbackTrackingParameters
);

export const isMacro = (inputString: string): boolean => {
  let valid: boolean = true;
  const openBraceIndex = inputString.indexOf('{');
  const closeBraceIndex = inputString.indexOf('}');

  inputString = inputString.trim();
  valid = inputString?.indexOf(' ') === -1;
  valid = openBraceIndex > -1 && closeBraceIndex > -1;
  valid = closeBraceIndex > openBraceIndex;

  return valid;
};

export const isValidParam = (
  param: string,
  tokens: DropdownOption<any>[]
): boolean => {
  if (param.includes('{') || param.includes('}')) {
    if (isMacro(param)) {
      return tokens.findIndex((p) => p.value === param) > -1;
    }

    return false;
  }

  return !!param;
};

export const isValidCampaignParam = (param: string): boolean => {
  return isValidParam(param, CampaignUrlTokens);
};

export const isValidPostbackParam = (param: string): boolean => {
  return isValidParam(param, PostbackUrlTokens);
};

export const HttpMethods = ['GET', 'POST', 'PUT', 'PATCH', 'DELETE'];

export const CampaignObjectives = [
  'conversion',
  'sale',
  'app_install',
  'lead',
  'impression',
  'click'
];

export const ParamsEncoding = () => [
  ...new Array(10).fill(null).map((_, i) => `P${i + 1}`),
  ...new Array(10).fill(null).map((_, i) => `sub${i + 1}`)
];

export const TrackingMethods = [
  'server_postback',
  'https_iframe_pixel',
  'image_pixel',
  'appcarry_app_sdk'
];

export const VisibilityOptions = ['public', 'private', 'permission'];
export const PopularUrlTokens = [
  'click_id',
  'campaign_id',
  'publisher_id',
  'source',
  'gaid',
  'idfa',
  'app_name',
  'p1',
  'p2'
];

export const ConversionStatusOptions = [
  'approved',
  'extended' /* 'cancelled', 'rejected' */
];

export const RedirectionTypeOptions = [
  {
    label: '302 REFERRER',
    value: 'referrer_302'
  },
  {
    label: '302 NO REFERRER',
    value: 'no_referrer_302'
  },
  {
    label: '200 OK',
    value: 'ok_200'
  },
  {
    label: '200 NO REFERRER',
    value: 'no_referrer_200'
  }
];

export const ScheduleStatus = [
  'active',
  'paused',
  'pending',
  'disabled',
  'deleted'
];

export const TemplateTypesOptions = Object.values(TemplateType);

export const OperatingSystems: Array<DropdownOption<any>> = [
  {
    label: 'Android',
    value: 'android'
  },
  {
    label: 'iOS',
    value: 'ios'
  },
  {
    label: 'Windows',
    value: 'windows'
  },
  {
    label: 'Mac OS',
    value: 'macos'
  }
];

export const DeviceTypes: Array<DropdownOption<any>> = [
  {
    label: 'Smartphone(Android)',
    value: 'android'
  },
  {
    label: 'Tablet(Android)',
    value: 'android'
  },
  {
    label: 'iPhone(iOS)',
    value: 'ios'
  },
  {
    label: 'iPad(iOS)',
    value: 'ios'
  },
  {
    label: 'Desktop',
    value: 'windows'
  }
];

export enum CreativeTypes {
  Banner = 'banner',
  Email = 'email',
  Video = 'video',
  SMS = 'sms',
  HTMLAd = 'html_ad',
  NativeAd = 'native_ad',
  Other = 'other'
}

export const EnumToDropdownOptions = (enumToConvert: unknown) => {
  return [
    ...Object.keys(enumToConvert).map((key) => ({
      label: key,
      value: enumToConvert[key]
    }))
  ];
};

export const CreativeTypeOptions = [
  ...Object.keys(CreativeTypes).map((key) => ({
    label: key,
    value: CreativeTypes[key]
  }))
];

export const generateOptions = (items: string[]): DropdownOption<any>[] => {
  return items.map((value) => ({ label: value.replace(/_/g, ' '), value }));
};

export const FrequentlyUsedTokensValues: string[] = [
  TrackingParameters.ClickID,
  TrackingParameters.CampaignID,
  TrackingParameters.PublisherID,
  TrackingParameters.SubPublisherID,
  TrackingParameters.Gaid,
  TrackingParameters.IDFA,
  TrackingParameters.AndroidID,
  TrackingParameters.DeviceID,
  TrackingParameters.CustomParam1,
  TrackingParameters.CustomParam2,
  TrackingParameters.Sub1,
  TrackingParameters.Sub2
];

export const FrequentlyUsedPostbackTokens: string[] = [
  PostbackTrackingParameters.CampaignId,
  PostbackTrackingParameters.ConversionId,
  PostbackTrackingParameters.ClickId,
  PostbackTrackingParameters.Goal,
  PostbackTrackingParameters.Payout,
  PostbackTrackingParameters.Revenue,
  PostbackTrackingParameters.PurchaseAmount,
  PostbackTrackingParameters.CustomParam1
];

export const FrequentlyUsedTokens = (tokens: string[], tokenList: any[]) => {
  return tokens?.map((token) =>
    tokenList?.find((tokenObj) => tokenObj?.value === `{${token}}`)
  );
};

// export const FrequentlyUsedTokens = FrequentlyUsedTokensValues?.map((token) =>
//   AllUrlTokens?.find((tokenObj) => tokenObj?.value === `{${token}}`),
// );

export const Statuses = {
  campaign: EnumToDropdownOptions(CampaignStatus),
  partner: EnumToDropdownOptions(PartnerStatus),
  coupon: EnumToDropdownOptions(CouponStatus),
  team_member: EnumToDropdownOptions(TeamMemberStatus)
};

export const RevenueModelOptions = [
  { label: 'Pay Per Click (PPC)', value: 'PPC' },
  { label: 'Pay Per Install (PPI)', value: 'PPI' },
  // { label: 'Pay Per Impression (PPI)', value: 'PPI' },
  { label: 'Pay Per Lead (PPL)', value: 'PPL' },
  { label: 'Pay Per Sale (PPS)', value: 'PPS' }
];

export const WeekDays = [
  'sunday',
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday'
];
