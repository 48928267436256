import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApiResponse,
  CreateRoleRequest,
  PaginatedResponse,
  Roles,
  Team
} from '@interfaces/global';
import ApiBase from '@utilities/api-base';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class MemberPermissionService extends ApiBase {
  constructor(
    private auth: AuthService,
    private http: HttpClient
  ) {
    super();
  }

  createRole(body: CreateRoleRequest, id: any): Observable<ApiResponse<null>> {
    return this.http.post<ApiResponse<null>>(
      `${environment.base_url}roles/${id || ''}`,
      body
    );
  }

  getRoles(): Observable<ApiResponse<PaginatedResponse<Roles[]>>> {
    return this.http.get<ApiResponse<PaginatedResponse<Roles[]>>>(
      `${environment.base_url}roles`
    );
  }

  getRoleDetails(roleId: number): Observable<ApiResponse<Roles>> {
    return this.http.get<ApiResponse<Roles>>(
      `${environment.base_url}roles/${roleId}`
    );
  }

  deleteRole(roleId: number): Observable<ApiResponse<null>> {
    return this.http.delete<ApiResponse<null>>(
      `${environment.base_url}roles/${roleId}`
    );
  }

  createOrUpdateTeam(body: any, id?: number): Observable<ApiResponse<null>> {
    return this.http.post<ApiResponse<null>>(
      `${environment.base_url}office-team/${id || ''}`,
      body
    );
  }

  deleteTeam(teamId: number): Observable<ApiResponse<null>> {
    return this.http.delete<ApiResponse<null>>(
      `${environment.base_url}office-team/${teamId}`
    );
  }

  getTeamDetails(teamId: number): Observable<ApiResponse<Team>> {
    return this.http.get<ApiResponse<Team>>(
      `${environment.base_url}office-team/${teamId}`
    );
  }

  getTeams(): Observable<ApiResponse<PaginatedResponse<Team[]>>> {
    return this.http.get<ApiResponse<PaginatedResponse<Team[]>>>(
      `${environment.base_url}office-team`
    );
  }
}
