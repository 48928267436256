import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-avatar-cell',
  templateUrl: './avatar-cell.component.html',
  styleUrls: ['./avatar-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarCellComponent {
  @Input() name: string;
  @Input() email: string;
  @Input() image: string;
}
