import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { BillingStatus } from '@enums/globals';
import { AuthService } from '@services/auth.service';
import { PaymentNotificationConfig } from '@services/global-dialog.service';

@Component({
  selector: 'app-payment-dialog',
  templateUrl: './payment-dialog.component.html',
  styleUrl: './payment-dialog.component.scss'
})
export class PaymentDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<PaymentDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: PaymentNotificationConfig,
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService
  ) {}

  get notifyText() {
    return NotifyText[this.data.billing_status];
  }

  logout() {
    if (!this.data?.dismissible) {
      this.dialogRef.close();
      this.auth.logout().subscribe();
    }
  }

  paymentRoute() {
    location.href = '/billing/billing-info';
  }
}

export const NotifyText = {
  [BillingStatus.Due]: `📆 Friendly reminder: Your payment is due! Keep enjoying uninterrupted access to our awesome features by settling your bill today.`,
  [BillingStatus.Overdue]: `🚨 Your account is overdue! Please settle your bill to avoid any interruptions to your services.`
};
