import {
  Component,
  Input,
  ChangeDetectionStrategy,
  EventEmitter,
  Output
} from '@angular/core';

@Component({
  selector: 'app-toggle-field',
  templateUrl: './toggle-field.component.html',
  styleUrls: ['./toggle-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToggleFieldComponent {
  @Input() value: boolean;
  @Input() editable: boolean;

  @Output() toggleChange: EventEmitter<unknown> = new EventEmitter();
  constructor() {}
}
