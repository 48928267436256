import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, inject } from '@angular/core';
import { VisibleTag } from '@interfaces/global';

@Component({
  selector: 'app-permission-view-cell',
  templateUrl: './permission-view-cell.component.html',
  styleUrls: ['./permission-view-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PermissionViewCellComponent {
  cdr = inject(ChangeDetectorRef);

  @Input() set value({ value, limit }) {
    this.generateTags(value, limit);
    setTimeout(() => { this.cdr.detectChanges() }, 50);
  }

  tags: VisibleTag[] = [];

  generateTags(val: VisibleTag[], limit: number) {
    if (val) {
      this.tags = this.visibleTags(limit, val);
    }
  }

  visibleTags(limit: number, tags: VisibleTag[]) {
    let visibleTags = [...tags];

    if (tags?.length > limit && limit > 0) {
      const showTags = new Array(limit).fill(null).map((v, i) => tags[i]);

      visibleTags = [
        ...showTags,
        {
          label: `+${tags.length - limit}`,
          description: ''
        }
      ];
    }

    return visibleTags;
  }
}
