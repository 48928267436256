import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import ApiBase from '../../../_utilities/api-base';
import { Observable, BehaviorSubject, of } from 'rxjs';
import {
  AddMemberRequest,
  ApiResponse,
  AssignDataRequest,
  AssignRoleRequest,
  CampaignAccessDataResponse,
  MenuGroup,
  PaginatedResponse,
  TeamMember,
} from '@interfaces/global';
import { environment } from 'src/environments/environment';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { StorageService } from '@services/storage.service';
import {
  AdditionalReportsMenu,
  AdvertiserReports,
  GeneralReportsMenu,
  NOLIMIT,
  PublisherReports,
  STORAGE_KEY,
  TrackingReportsMenu
} from '@enums/globals';
import { PageEvent } from '@angular/material/paginator';
import { UserType } from '@interfaces/user';

@Injectable({
  providedIn: 'root'
})
export class TeamService extends ApiBase {
  private teamMembers$: BehaviorSubject<TeamMember[]> = new BehaviorSubject(
    []
  );
  private addTeamMemberTrigger: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  public triggerState = this.addTeamMemberTrigger.asObservable();
  public set triggerAddMember(trigger: boolean) {
    this.addTeamMemberTrigger.next(trigger);
  }

  constructor(private http: HttpClient) {
    super();
    const teamMembers = StorageService.getLocalItem(STORAGE_KEY.TEAM_MEMBERS);

    if (teamMembers?.length) {
      this.teamMembers = teamMembers;
    }
  }

  public get teamMembers() {
    return this.teamMembers$.getValue();
  }

  public set teamMembers(teamMembers: TeamMember[]) {
    StorageService.setLocalItem(STORAGE_KEY.TEAM_MEMBERS, teamMembers);
    this.teamMembers$.next(teamMembers);
  }

  getStoredTeamMember(id: number): TeamMember {
    return this.teamMembers?.find((member) => member._id === Number(id));
  }

  getTeamMembers(): Observable<
    ApiResponse<PaginatedResponse<Array<TeamMember>>>
  > {
    return this.http
      .get<
        ApiResponse<PaginatedResponse<Array<TeamMember>>>
      >(`${environment.base_url}account/team`)
      .pipe(
        tap((res) => {
          this.teamMembers = res?.data?.data;
        })
      );
  }

  assignRole(body: AssignRoleRequest): Observable<ApiResponse<null>> {
    return this.http.post<ApiResponse<null>>(
      `${environment.base_url}account/team/assign-role `,
      body
    );
  }

  manageDataAssignment(
    body: AssignDataRequest,
    memberId: number
  ): Observable<ApiResponse<null>> {
    return this.http.post<ApiResponse<null>>(
      `${environment.base_url}account/team/${memberId}/data-access `,
      body
    );
  }

  getAssignedData(
    memberId: number,
    type: string,
    pageEvent: PageEvent = this.pageEvent
  ) {
    return this.http.get<ApiResponse<PaginatedResponse<CampaignAccessDataResponse[]>>>(
      `${environment.base_url}account/team/${memberId}/data-access/${type}?page=${pageEvent?.pageIndex}&per_page=${pageEvent?.pageSize}`
    );
  }

  addTeamMember(body: AddMemberRequest, id: string|number): Observable<ApiResponse<TeamMember>> {
    return this.http.post<ApiResponse<TeamMember>>(
      `${environment.base_url}account/team/${id || ''}`,
      body
    );
  }

  deleteTeamMember(id: number): Observable<ApiResponse<null>> {
    return this.http.delete<ApiResponse<null>>(
      `${environment.base_url}account/team/${id}`
    );
  }

  changeStatus(
    id: number | string,
    status: any
  ): Observable<ApiResponse<null>> {
    return this.http.patch<ApiResponse<null>>(
      `${environment.base_url}account/team/status/${id}/${status}`,
      null
    );
  }

  getUserDetails(userId: number): Observable<ApiResponse<TeamMember>> {
    return this.http.get<ApiResponse<TeamMember>>(
      `${this.baseUrl}account/team/${userId}`
    );
  }
}
