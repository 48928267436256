import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { STORAGE_KEY } from '@enums/globals';
import {
  ApiResponse,
  BrandingRequest,
  BrandingResponse
} from '@interfaces/global';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class BrandingService {
  public httpOptions;
  private baseUrl = environment.base_url;

  constructor(private http: HttpClient) { }

  getBrandingDetails(
    body: BrandingRequest,
    forceUpdate: boolean = false
  ): Observable<BrandingResponse> {
    const savedDomainObj: BrandingResponse = StorageService.getLocalItem(
      body.domain
    );

    if (!!savedDomainObj && !forceUpdate) {
      return of(savedDomainObj);
    } else {
      return this.http.post(`${this.baseUrl}account/branding`, body).pipe(
        map((response: ApiResponse<BrandingResponse>) => {
          StorageService.setLocalItem(body.domain, response?.data);
          StorageService.setLocalItem(
            STORAGE_KEY.BRANDCOLOR,
            response?.data?.branding?.brand_color || '#6160e3',
            environment.storageExpiry
          );

          return response?.data;
        })
      );
    }
  }
}
