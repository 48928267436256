import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-email-cell',
  templateUrl: './email-cell.component.html',
  styleUrls: ['./email-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailCellComponent {
  @Input() email: string;
}
