<button type="button" class="cf-filter-pill {{ tags && 'tags' }}" [ngClass]="getType()" [disabled]="disabled">
  <span class="filter-icon" *ngIf="icon && iconPosition === 'before'">
    <mat-icon [fontSet]="fontSet">{{ icon }}</mat-icon>
  </span>
  <span class="filter-text" *ngIf="label">
    {{ label }}
  </span>
  <span class="filter-value" [class.required]="required">
    <ng-content></ng-content>
  </span>

  <span class="filter-icon" *ngIf="icon && iconPosition === 'after'">
    <mat-icon [fontSet]="fontSet">{{ icon }}</mat-icon>
  </span>
  <mat-icon *ngIf="removable" fontSet="mso" libClickStopPropagation (click)="remove()">close</mat-icon>
</button>