import { NavigationMenuItem } from "@interfaces/menu";
import { AccountTypeModules, CRUD, ModuleNames } from "@interfaces/permissions";
import { AccountType, UserType } from "@interfaces/user";
import cloneDeep from "lodash/cloneDeep";

export interface ModulePermissionMap<T> {
    module: T;
    permissions: CRUD[];
}

export type UserTypeModulePermissions = {
    [key in UserType]: Array<ModulePermissionMap<ModuleNames>>;
}

export type AccountTypeModulePermissions = {
    [key in AccountType]: Array<AccountTypeModules>;
}

export const AccountLevelModules = [
    AccountTypeModules.AdvertiserAccess
];

export const UserBasedPremiumFeatureRestrictions = {
    [UserType.Owner]: [],
    [UserType.Advertiser]: [],
    [UserType.Publisher]: [],
    [UserType.TeamMember]: []
}

// Other than the back-end modules
export const LocalModules = [
    ModuleNames.Dashboard,
    ModuleNames.CouponCodes,
    ModuleNames.OfferCheck,
    ModuleNames.SmartLink,
    ModuleNames.Logs,
    ModuleNames.Partner,
    ModuleNames.Message,
    ModuleNames.Profile,
    ModuleNames.OptimizationRule,
    ModuleNames.ApiKey,
    ModuleNames.Support,
    ModuleNames.SmartAlerts,
    ModuleNames.Invoices,
    ModuleNames.FraudDetection,
];

export const SpecialModules = [
    ModuleNames.Offer_Campaign,
];

export const AllModules = [
    ModuleNames.Dashboard,
    ModuleNames.Campaign,
    ModuleNames.Advertiser,
    ModuleNames.Publisher,
    ModuleNames.RoleManagement,
    ModuleNames.Billing,
    ModuleNames.Team,
    ModuleNames.Category,
    ModuleNames.PromotionMethods,
    ModuleNames.Customization,
    ModuleNames.Reports,
    ModuleNames.Automations,
    ModuleNames.Sampling,
    ModuleNames.Settings,
    ModuleNames.Tasks,
    ModuleNames.Messages,
    ...LocalModules
];

export const BasePermissionsByUserType: UserTypeModulePermissions = {
    owner: AllModules.map((module) => ({ module, permissions: [CRUD.Create, CRUD.Read, CRUD.Update, CRUD.Delete] })),
    advertiser: [
        { module: ModuleNames.Dashboard, permissions: [CRUD.Read] },
        { module: ModuleNames.Campaign, permissions: [CRUD.Read] },
        { module: ModuleNames.Reports, permissions: [CRUD.Read] },
        { module: ModuleNames.Profile, permissions: [CRUD.Read] },
    ],
    publisher: [
        { module: ModuleNames.Dashboard, permissions: [CRUD.Read] },
        { module: ModuleNames.Profile, permissions: [CRUD.Read] },
        { module: ModuleNames.Offer_Campaign, permissions: [CRUD.Read] },
        { module: ModuleNames.CouponCodes, permissions: [CRUD.Read] },
        { module: ModuleNames.Reports, permissions: [CRUD.Read] },
        { module: ModuleNames.Publisher, permissions: [CRUD.Read] },
    ],
    team_member: [
        { module: ModuleNames.ApiKey, permissions: [CRUD.Read] },
        { module: ModuleNames.Support, permissions: [CRUD.Read] },
        { module: ModuleNames.Dashboard, permissions: [CRUD.Read] },
        { module: ModuleNames.Profile, permissions: [CRUD.Read] },
        { module: ModuleNames.Partner, permissions: [CRUD.Read] },
        { module: ModuleNames.FraudDetection, permissions: [CRUD.Read] },
    ],
};

export const AccountTypePermissions: AccountTypeModulePermissions = {
    brand: [],
    network: [
        AccountTypeModules.AdvertiserAccess
    ],
};

/**
 * Sanitize the menu list based on the user's permissions
 * @param menuList List of NavigationMenuItem
 * @param modulePermissionMap Array of ModulePermissionMap
 * @returns Array of NavigationMenuItem
 */
export const menuListSanitizer = (menuList: Array<NavigationMenuItem>, modulePermissionMap: Array<ModulePermissionMap<ModuleNames>>, childMenuKey: string = 'children') => {
    if (!menuList?.length) { return menuList; }
    const filteredMenuList = [];

    cloneDeep(menuList).forEach((menu) => {
        if (menu?.[childMenuKey]?.length) {
            menu[childMenuKey] = menu[childMenuKey].filter((subMenu) => {
                return hasRequiredPermission(subMenu?.module || menu?.module, [subMenu.permission || menu?.permission], modulePermissionMap);
            });
        }

        if (hasRequiredPermission(menu?.module, [menu.permission], modulePermissionMap)) {
            if (menu?.expandable) {
                if (menu?.[childMenuKey]?.length > 0) {
                    filteredMenuList.push(menu);
                }
            } else {
                filteredMenuList.push(menu);
            }
        }
    });

    return filteredMenuList;
}

/**
 * Check if the user has the required permission
 * @param moduleName Name of the module
 * @param permissions CRUD permissions
 * @param modulePermissionMap Array of ModulePermissionMap
 * @returns boolean
 */
export const hasRequiredPermission = (moduleName: ModuleNames, permissions: CRUD[], modulePermissionMap: Array<ModulePermissionMap<ModuleNames>>) => {
    if (!moduleName || !modulePermissionMap) { return true; }

    const module = modulePermissionMap.find((m) => m.module === moduleName);
    if (!module) {
        return false;
    }

    return permissions.every((permission) => module.permissions.includes(permission));
}

export const premiumFeatureSanitizer = (menuList: Array<NavigationMenuItem>, userType: UserType, restrictionsByUserType = UserBasedPremiumFeatureRestrictions) => {
    return menuList?.filter((menu) => {
        if (menu?.premium_feature_key) {
            return restrictionsByUserType[userType].indexOf(menu?.premium_feature_key) === -1;
        }

        return true;
    })
}