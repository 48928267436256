import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BrandingResponse } from '@interfaces/global';
import { AuthService } from '@services/auth.service';
import { BrandingService } from '@services/branding.service';
import { SharedService } from '@services/shared.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BrandingResolver {
  private favIcon: HTMLLinkElement = document.querySelector('#favicon');
  constructor(
    private branding: BrandingService,
    private shared: SharedService,
    private router: Router,
    private auth: AuthService
  ) { }

  load(): Promise<boolean> {
    return this.getBrandDetails();
  }

  getBrandDetails(forceUpdate: boolean = false): Promise<boolean> {
    return new Promise((resolve, reject) => {
      try {
        const { origin } = new URL(window.location.origin);

        this.branding
          .getBrandingDetails(
            {
              domain: environment.production
                ? origin
                : environment.networkOrBrandDomain
            },
            forceUpdate
          )
          .subscribe(
            (res: BrandingResponse) => {
              this.shared.brand = res?.branding;

              // Dynamic favicon
              if (res?.branding?.favicon) {
                this.favIcon.href = res.branding.favicon;
              }

              // Dynamic color setting
              if (res?.branding?.brand_color) {
                this.shared.brandColor = res.branding.brand_color;
              }

              this.shared.setSubdomain(res?.company_name);
              resolve(true);
            },
            (err) => {
              this.auth.removeLocalData();
              this.router.navigate(['/find-my-account']);
              resolve(true);
            }
          );
      } catch (error) {
        console.log(error);
        reject(false);
      }
    });
  }
}
