import { Location } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { Fragments } from '@interfaces/global';

@Component({
  selector: 'app-sidenav-wrapper',
  templateUrl: './sidenav-wrapper.component.html',
  styleUrls: ['./sidenav-wrapper.component.scss']
})
export class SidenavWrapperComponent {
  fragment: Fragments;
  @Input() routerLinkUrl: any = ['.'];
  @Input() minWidth: string = '400px';
  @Input() isRelative: boolean = true;
  @Input() activeSidesheet: 'side' | 'outer' = 'side';
  @Input() fullHeight: boolean;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location
  ) {
    this.route.fragment.subscribe((fragment: Fragments) => {
      this.fragment = fragment;
    });
  }

  navigateBack() {
    this.location.back();
    window.location.hash = '';
  }

  closeSidesheet() {
    const urlTree: UrlTree = this.router.parseUrl(this.router.url);

    if (this.activeSidesheet === 'side') {
      delete urlTree.root.children?.side;
      delete urlTree.root.children?.primary?.children?.side;
    }

    if (this.activeSidesheet === 'outer') {
      delete urlTree.root.children?.outer;
      delete urlTree.root.children?.side?.children?.outer;
      delete urlTree.root.children?.primary?.children?.outer;
    }

    this.router.navigateByUrl(urlTree);
  }
}
