import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  inject
} from '@angular/core';

export interface ProgressBarConfig {
  label: string;
  value: number;
  color?: string;
  suffix?: string;
  prefix?: string;
}

@Component({
  selector: 'app-progress-cell',
  templateUrl: './progress-cell.component.html',
  styleUrls: ['./progress-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProgressCellComponent {
  cdr = inject(ChangeDetectorRef);
  config: ProgressBarConfig;
  label: string;

  @Input() set progress(config: ProgressBarConfig) {
    this.config = this.getProgressConfig(config);
    this.label = `${config?.prefix || ''}${config.label}${config?.suffix || ''}`;
    this.cdr.detectChanges();
  }
  constructor() { }

  getProgressConfig({ label, value }: ProgressBarConfig): ProgressBarConfig {
    let color = '';

    if (value > 0 && value <= 20) {
      color = '#6895f7';
    } else if (value > 20 && value <= 40) {
      color = '#77d8c4';
    } else if (value > 40 && value <= 60) {
      color = '#99c65f';
    } else if (value > 60 && value <= 80) {
      color = '#f7c563';
    } else if (value > 80 && value <= 100) {
      color = '#dd5953';
    }

    return {
      label: `${label}`,
      value,
      color
    };
  }
}
