import { Component, Input, forwardRef, OnInit } from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import FormField from 'src/app/_utilities/form-field';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { TeamService } from '@modules/team/services/team.service';
import { UserResponse } from '@interfaces/global';

@Component({
  selector: 'app-user-selector',
  templateUrl: './user-selector.component.html',
  styleUrls: ['./user-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => UserSelectorComponent)
    }
  ]
})
export class UserSelectorComponent extends FormField implements OnInit {
  @Input() classes: string;
  @Input() cssClass: string;
  @Input() limit: number = 0;
  @Input() multi: boolean;
  @Input() valueKey = '_id';
  @Input() required: boolean;

  formControl: UntypedFormControl = new UntypedFormControl();
  campaignList$: Observable<UserResponse[]> = this.teamService
    .getTeamMembers()
    .pipe(
      switchMap((res) =>
        of(
          res.data.data?.map((user) => ({
            ...user,
            title: `${user?.first_name} ${user?.last_name}`
          }))
        )
      )
    );

  constructor(private teamService: TeamService) {
    super();
    this.formControl.valueChanges.subscribe((res) => this.onChange(res));
  }

  override writeValue(val: any): void {
    this.formControl.setValue(val);
  }

  ngOnInit(): void {}
}
