import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

export interface LinkObject {
  label: string;
  url?: string;
  routerLink?: any[];
  queryParams?: any;
}

@Component({
  selector: 'app-link-cell',
  templateUrl: './link-cell.component.html',
  styleUrls: ['./link-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkCellComponent {
  _value: LinkObject;

  @Input() set value(linkObject: LinkObject | any) {
    if (!linkObject?.hasOwnProperty('label')) {
      if (linkObject?.hasOwnProperty('name')) {
        this._value = {
          label: linkObject?.name,
          url: linkObject?.url
        };
      } else if (typeof linkObject === 'string') {
        this._value = {
          label: linkObject,
          url: linkObject
        };
      }
    } else {
      this._value = linkObject;
    }
  }
  @Input() external: boolean;
  constructor() {}
}
