import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { FactoryModule } from '@modules/factory/factory.module';
import { AuthService } from '@services/auth.service';
import { QRCodeModule } from 'angularx-qrcode';

@Component({
  selector: 'app-two-factor-auth-dialog',
  standalone: true,
  imports: [QRCodeModule, FactoryModule],
  templateUrl: './two-factor-auth-dialog.component.html',
  styleUrl: './two-factor-auth-dialog.component.scss'
})
export class TwoFactorAuthDialogComponent implements OnInit {
  qrInputcontrol = this.fb.control('', [Validators.required, Validators.minLength(6), Validators.pattern(/^[0-9]*$/)]);
  qrData: string;
  loader = false;

  constructor(private fb: FormBuilder, private auth: AuthService, private dialogRef: MatDialogRef<TwoFactorAuthDialogComponent>) {
    this.qrInputcontrol.valueChanges.subscribe((value) => {
      console.log(value);
    })
  }

  validateAuthCode() {
    // Validate the auth code here
    this.auth.enableTwoFactorAuth(this.qrInputcontrol.value)
      .subscribe((res) => {
        this.dialogRef.close(res);
      });
  }

  ngOnInit() {
    this.loader = true;
    this.auth.getTwoFactorAuthQrCode().subscribe((res) => {
      this.loader = false;
      this.qrData = res.data.qr_code;
    }, () => {
      this.loader = false;
    })
  }
}
