import { HttpHeaders } from '@angular/common/http';
import { PageEvent } from '@angular/material/paginator';
import { ApiResponse, InternalNavigationMenu, PaginatedResponse } from '@interfaces/global';
import { environment } from '../../environments/environment';

export default abstract class ApiBase {
  httpOptions: { headers: HttpHeaders };
  httpFormDataOptions: { headers: HttpHeaders };
  public baseUrl = environment.base_url;
  navigationMenu: InternalNavigationMenu[] = [];
  httpLoader: boolean;
  pageEvent: PageEvent = {
    length: 0,
    pageIndex: 1,
    pageSize: 25,
    previousPageIndex: 0
  };
  constructor() {
    this.setHeader();
    this.setFormDataHeader();
  }

  public getNextStepUrl(currentStepUrl: string): string | any[] {
    const menus = this.navigationMenu.filter((menu) => !menu.separator);
    const stepIndex = menus.findIndex((step) => step.url === currentStepUrl);

    return menus?.length >= stepIndex + 1 ? menus[stepIndex + 1]?.url : '';
  }

  setHeader(contentType = 'application/json') {
    let $headers = new HttpHeaders();

    $headers = $headers.append('Content-Type', contentType);
    this.httpOptions = { headers: $headers };
  }

  setFormDataHeader(contentType = 'multipart/form-data') {
    let $headers = new HttpHeaders();

    $headers = $headers.append('Accept', '*/*');
    $headers = $headers.append('Content-Type', contentType);
    this.httpOptions = { headers: $headers };
  }

  removeNullValues<T>(obj: T) {
    for (const key of Object.keys(obj)) {
      if (
        obj[key] === null || obj[key] === '' ||
        (Array.isArray(obj[key]) && obj[key].length === 0)
      ) {
        delete obj[key];
      }
    }
    return obj;
  }

  jsonToFormData(data: any): FormData {
    const form_data = new FormData();

    for (const key in data) {
      form_data.append(key, data[key]);
    }

    return form_data;
  }
}

export const convertToPaginatedResponse = <T>(res: ApiResponse<Array<T>>): ApiResponse<PaginatedResponse<Array<T>>> => {
  return {
    data: {
      data: res.data,
      links: null,
      meta: {
        per_page: res.data.length,
        total: res.data.length,
        current_page: 1,
        last_page: 1,
        from: 1,
        to: res.data.length,
      }
    },
    message: res.message,
    success: res.success,
    error_code: res.error_code,
  }
}
