<span
  [ngClass]="classList"
  [ngStyle]="{ 'min-width.px': minWidth, 'text-align': position }">
  <mat-icon *ngIf="prefixIcon" [fontSet]="fontSet" class="label-icon">
    {{ prefixIcon }}
  </mat-icon>
  <ng-container *ngIf="text; else dynamicContent">
    {{ text || '' }}
  </ng-container>
  <ng-template #dynamicContent>
    <ng-content></ng-content>
  </ng-template>
  <mat-icon *ngIf="suffixIcon" [fontSet]="fontSet" class="label-icon">
    {{ suffixIcon }}
  </mat-icon>
</span>
