import {
  AbstractControl,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn
} from '@angular/forms';
import { CLICK_ID_VALIDATOR, IP_ADDRRESS_VALIDATOR } from './regex';
import {
  convertToTokens,
  extractUniqueTokensFromUrl
} from '@enums/tracking-generator';
import {
  isValidCampaignParam,
  isValidPostbackParam
} from '@modules/campaign/campaign-constants';
import { REGEXPATTERNS } from '@enums/globals';
import { isValidArray } from '@utilities/functions';

/**
 * Checks if the given value is a valid ip address
 * @returns ValidatorFn
 */
export const ipAddressValidator = (): ValidatorFn => {
  return (control: UntypedFormControl): { [key: string]: any } | null => {
    const ipAddress = control.value;

    if (!ipAddress) {
      // If the control is empty, return null (valid).
      return null;
    }

    // Regular expression for a valid IPv4 address.
    const ipRegex = IP_ADDRRESS_VALIDATOR;

    if (!ipRegex.test(ipAddress)) {
      // If the IP address is not valid, return an error.
      return { invalidIpAddress: true, message: 'Invalid IP address' };
    }

    // If the IP address is valid, return null (valid).
    return null;
  };
};

/**
 * Checks if the given url has click_id
 * @returns ValidatorFn
 */
export const clickIdValidator = (): ValidatorFn => {
  return (control: UntypedFormControl): { [key: string]: any } | null => {
    const url = control.value;

    if (!url) {
      // If the control is empty, return null (valid).
      return null;
    }

    // Regular expression for a valid IPv4 address.
    const clickIdRegex = CLICK_ID_VALIDATOR;

    if (!clickIdRegex.test(url)) {
      // If the IP address is not valid, return an error.
      return { missingClickId: true, message: 'click_id is required' };
    }

    // If the IP address is valid, return null (valid).
    return null;
  };
};

/**
 * Checks if the given url has valid macros
 * @param value string
 * @returns boolean
 */
export const urlHasValidMacros = (
  value: string,
  checkFn: (param: string) => boolean
): string[] => {
  if (!value) return [];

  return convertToTokens(extractUniqueTokensFromUrl(value))?.filter(
    (token) => !checkFn(token)
  );
};

/**
 * Checks if the given url has valid macros for campaign
 * @returns ValidatorFn
 */
export const defaultCampaignUrlTokenValidtor = () => {
  return (control: AbstractControl): ValidationErrors | null => {
    const invalidTokens = urlHasValidMacros(
      control.value,
      isValidCampaignParam
    );

    return invalidTokens?.length === 0
      ? null
      : {
        defaultCampaignUrl_invalid: true,
        message: `Url contains invalid tokens ${invalidTokens
          ?.toString()
          ?.split(',')
          ?.join(', ')}`
      };
  };
};

/**
 * Checks if the given url has valid macros for postback
 * @returns ValidatorFn
 */
export const postbackUrlValidator = () => {
  return (control: AbstractControl): ValidationErrors | null => {
    const invalidTokens = urlHasValidMacros(
      control.value,
      isValidPostbackParam
    );

    return invalidTokens?.length === 0
      ? null
      : {
        invalid_url_tokens: true,
        message: `Url contains invalid tokens ${invalidTokens
          ?.toString()
          ?.split(',')
          ?.join(', ')}`
      };
  };
};

export const decimalValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;

    if (value && value.toString().includes('.')) {
      return { decimal: true, message: 'Decimal value is not allowed' };
    }
    return null;
  };
};

// Validator to check if array of emails are valid
export const emailArrayValidator = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const emails = control.value;

    if (!emails?.length) {
      return null;
    }

    const invalidEmails = emails.filter(
      (email: string) =>
        !/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(email)
    );

    if (invalidEmails.length) {
      return {
        invalidEmails: true,
        message: 'Email list contains invalid email address'
      };
    }

    return null;
  };
};

export const timeDataValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null; // Return null if the value is empty
    }

    //Check for regex
    if (
      !REGEXPATTERNS.TIME.test(value.start_hour) ||
      !REGEXPATTERNS.TIME.test(value.end_hour)
    ) {
      return { invalidTimeData: true }; // Return error if start_hour or end_hour does not match the regex pattern
    }

    //Check for required value is present
    const requiredProperties = ['timezone', 'start_hour', 'end_hour', 'day'];
    if (!requiredProperties.every((prop) => value[prop])) {
      return { invalidTimeData: true }; // Return error if any required property is missing or invalid
    }

    // Check if day is an array containing at least one valid day
    if (
      !isValidArray(value.day) ||
      value.day.some((day: string) => !REGEXPATTERNS.DAY.test(day))
    ) {
      return { invalidTimeData: true }; // Return error if day is not a non-empty array of valid days
    }

    return null; // No validation errors found, return null
  };
};

export const deviceDataValidator = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;

    if (!value) {
      return null; // Return null if the value is empty
    }

    // Check if 'os' and 'device_type' arrays are valid
    if (!isValidArray(value.os) || !isValidArray(value.device_type)) {
      return { invalidTimeData: true }; // Return error if any array is not valid
    }

    return null; // No validation errors found, return null
  };
};

export const validateMacros = (validMacros: string[]): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control.value) {
      return null;
    }

    const bodyValue = control.value as string;
    const macroRegex = /{([^}]+)}/g;
    let match;
    const foundMacros = new Set<string>();

    while ((match = macroRegex.exec(bodyValue)) !== null) {
      foundMacros.add(`{${match[1]}}`);
    }

    const invalidMacros = Array.from(foundMacros).filter(macro => !validMacros.includes(macro));

    return invalidMacros.length > 0 ? { invalidMacros: `Invalid macros: ${invalidMacros.toString().split(',').join(', ')}` } : null;
  };
}

export const trackingDomainValidator = () => {
  return (control: AbstractControl): ValidationErrors | null => {
    const domain = control.value;

    if (!domain) {
      return { required: true, message: `Tracking domain is required` }; // Return null if the value is empty
    }

    if (!REGEXPATTERNS.URL.test(domain) || !REGEXPATTERNS.INVALID_DOMAINS.test(domain)) {
      const message = !REGEXPATTERNS.INVALID_DOMAINS.test(domain) ? `This domain is forbidden, can't use *.appcarry.com or *.appcarry.live` : 'Invalid domain';

      return { invalidDomain: true, message }; // Return error if any array is not valid
    }

    return null; // No validation errors found, return null
  };
}