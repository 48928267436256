<div class="cf-card h-100 cf-vertical">
  <div class="cf-section cf-card">
    <div>
      <app-text-line type="leading">
        <ng-content select="[title]"></ng-content>
      </app-text-line>
    </div>
    <div class="cf-atom">
      <ng-content select="[action]"></ng-content>
    </div>
  </div>

  <div class="pt-1">
    <ng-content *ngIf="!isEmpty" select="[content]"></ng-content>

    <app-null-state *ngIf="isEmpty" icon="bar_chart" size="20" [message]="{
        line1: 'There\'s no activity to report yet',
        line2: 'We\'ll update as soon as there\'s data available'
      }">
    </app-null-state>
  </div>
</div>