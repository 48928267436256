<app-sidenav-wrapper activeSidesheet="side" [routerLinkUrl]="closeOuter" [isRelative]="false">
  <ng-container sidenavToolbarLeft>
    <app-text-line type="leading">Notifications</app-text-line>
  </ng-container>

  <ng-container sidenavContent>
    <app-sidesheet-content-wrapper size="small" [fullHeight]="true">
      <ng-container sidesheetContent>
        <div class="load-container" *ngIf="totalCount === 0; else showNotificationsList">
          <app-null-state [message]="{
              line1: 'All caught up',
              line2: 'We\'ll let you know when we get news for you'
            }" icon="done_all">
          </app-null-state>
        </div>

        <ng-template #showNotificationsList>
          <div class="notification-header" fxLayout="row" fxLayoutAlign="space-between center">
            <mat-chip-listbox aria-label="Search and filter">
              <app-search [formControl]="searchControl" placeholder="Search" class="ml-2"></app-search>
              <mat-chip-option color="primary" [selected]="displayUnreadOnly"
                (click)="showUnreadToggle()">Unread</mat-chip-option>
            </mat-chip-listbox>

            <button mat-button color="primary" (click)="markRead()">
              Mark all read
            </button>
          </div>
          <div class="notifications-table-container">
            <table width="100%" class="notifications-table">
              @defer (when !loader) {
              <tr *ngFor="
                    let notification of filteredNotificationStack$ | async
                  ">
                <td class="fixed p-2">
                  <app-avatar [matTooltip]="notification?.message?.initiator?.name" size="small" [name]="
                        notification?.message?.initiator?.name
                      "></app-avatar>
                </td>
                <td class="fluid p-2">
                  <app-text-line [enableWrap]="true" type="base" weight="strong" [textColor]="
                        !notification?.is_read ? 'primary' : 'secondary'
                      ">
                    {{ notification?.message?.title }}
                  </app-text-line>

                  <app-text-line [enableWrap]="true" type="small" weight="mb-1" [textColor]="
                        !notification?.is_read ? 'primary' : 'secondary'
                      ">
                    {{ notification?.message?.description }}
                  </app-text-line>

                  <app-text-line [enableWrap]="true" textColor="secondary" type="xsmall">
                    {{ notification?.created_at | date: 'medium' }}
                  </app-text-line>
                </td>
              </tr>
              } @placeholder {
              <tr class="mb-2">
                <td class="fluid loader">
                  <app-skeleton type="line"></app-skeleton>
                  <app-skeleton type="line-half"></app-skeleton>
                </td>
              </tr>
              <tr class="mb-2">
                <td class="fluid loader">
                  <app-skeleton type="line"></app-skeleton>
                  <app-skeleton type="line-half"></app-skeleton>
                </td>
              </tr>
              <tr class="mb-2">
                <td class="fluid loader">
                  <app-skeleton type="line"></app-skeleton>
                  <app-skeleton type="line-half"></app-skeleton>
                </td>
              </tr>
              <tr class="mb-2">
                <td class="fluid loader">
                  <app-skeleton type="line"></app-skeleton>
                  <app-skeleton type="line-half"></app-skeleton>
                </td>
              </tr>
              }
            </table>
          </div>
          <div class="notifications-table-pagination">
            <mat-paginator [hidden]="!(filteredNotificationStack$ | async)?.length" [length]="totalCount"
              [pageSize]="pageSize" [pageSizeOptions]="[25, 50, 100]" color="primary" (page)="paginatorAction($event)">
            </mat-paginator>
          </div>
        </ng-template>
      </ng-container>
    </app-sidesheet-content-wrapper>
  </ng-container>
</app-sidenav-wrapper>