import { Injectable } from '@angular/core';
import { MediaObserver } from '@ngbracket/ngx-layout';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class BreakpointService {
  public isMobile$: Observable<boolean>;
  constructor(private media: MediaObserver) {
    this.isMobile$ = this.media
      .asObservable()
      .pipe(
        switchMap((data) =>
          of(!!(data[0]?.mqAlias === 'sm' || data[0]?.mqAlias === 'xs'))
        )
      );
  }
}
