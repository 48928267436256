import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from '@interfaces/global';
import ApiBase from '@utilities/api-base';
import { environment } from 'src/environments/environment';

export interface SendMessageBody {
  to_user_id: string;
  message: string;
  title: string;
}

export interface TaskDetails {
  id: number
  publisher: {
    _id: string
    name: string
  }
  campaign: {
    _id: string
    name: string
  }
  requested_at: string
}

@Injectable({
  providedIn: 'root'
})
export class UpdateService extends ApiBase {
  constructor(private http: HttpClient) {
    super();
  }

  public sendMessageToUser(body: SendMessageBody) {
    return this.http.post<ApiResponse<any>>(
      `${environment.base_url}account/message`,
      body
    );
  }
  public getAllmessages(pageEvent = this.pageEvent) {
    return this.http.get<ApiResponse<any>>(
      `${environment.base_url}account/message?page=${pageEvent?.pageIndex}&per_page=${pageEvent?.pageSize}`
    );
  }
  public getAllTasks(pageEvent = this.pageEvent) {
    return this.http.get<ApiResponse<TaskDetails[]>>(
      `${environment.base_url}account/task?page=${pageEvent?.pageIndex}&per_page=${pageEvent?.pageSize}`
    );
  }
}
