import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { PublisherStatus } from '@enums/publishers';
import {
  ApiResponse,
  CampaignAssignmentRequest,
  CreatePartnerRequest,
  InternalNavigationMenu,
  PaginatedResponse,
  PartnerFilter,
  PartnerResponse,
  TrackingLinkRequest
} from '@interfaces/global';
import { UserType } from '@interfaces/user';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import ApiBase from '../../../_utilities/api-base';
import { HelperService } from '@services/helper.service';
import { PremiumFeatureKeys } from '@interfaces/permissions';

export interface IPWhitelistRequest {
  advertiser_id: string;
  ip_address: string;
  is_active: boolean;
}

export interface WhiteListIp extends IPWhitelistRequest {
  id: number;
}

export type PartnerType = 'publisher' | 'advertiser';

@Injectable({
  providedIn: 'root'
})
export class PartnerService extends ApiBase {
  private addPartnerTrigger: BehaviorSubject<boolean> = new BehaviorSubject(
    null
  );
  private currentEntityType: BehaviorSubject<UserType> = new BehaviorSubject(
    null
  );

  public entityType = this.currentEntityType.asObservable();
  public partnerTriggerObs = this.addPartnerTrigger.asObservable();

  constructor(
    private http: HttpClient,
    private helper: HelperService
  ) {
    super();
    this.navigationMenu = NavigationMenu;
  }

  public set entityTypeValue(type: UserType) {
    this.currentEntityType.next(type);
  }

  public set partnerTriggerValue(val: boolean) {
    this.addPartnerTrigger.next(val);
  }

  getPartners(
    parnerType: PartnerType,
    pageEvent: PageEvent = this.pageEvent,
    filters: PartnerFilter = null
  ): Observable<ApiResponse<PaginatedResponse<PartnerResponse[]>>> {
    return this.http.post<ApiResponse<PaginatedResponse<PartnerResponse[]>>>(
      `${this.baseUrl}partner/${parnerType}?page=${pageEvent?.pageIndex}&per_page=${pageEvent?.pageSize}`,
      { filters: this.removeNullValues(filters || {}) }
    );
  }

  // PARTNER
  createPartner(
    body: CreatePartnerRequest,
    id?: any
  ): Observable<ApiResponse<PartnerResponse>> {
    return this.http.post<ApiResponse<null>>(
      `${this.baseUrl}partner/create/${id || ''}`,
      this.removeNullValues(body)
    );
  }

  saveTrackingLink(body: TrackingLinkRequest): Observable<ApiResponse<null>> {
    return this.http.post<ApiResponse<null>>(
      `${this.baseUrl}tracking-link`,
      body
    );
  }

  getPublishisersByStatus(
    status: PublisherStatus,
    campaignId: string,
    pageEvent = this.pageEvent
  ) {
    return this.http.get<ApiResponse<PaginatedResponse<PartnerResponse[]>>>(
      `${environment.base_url}campaign/access/request/${status}/${campaignId}?page=${pageEvent?.pageIndex}&per_page=${pageEvent?.pageSize}`
    );
  }

  assignCampaignToPartner(
    body: CampaignAssignmentRequest
  ): Observable<ApiResponse<null>> {
    return this.http.post<ApiResponse<null>>(
      `${this.baseUrl}campaign/access/assign`,
      body
    );
  }
  getPartnerDetails(
    parnerType: PartnerType,
    partnerId: number
  ): Observable<ApiResponse<PartnerResponse>> {
    return this.http.get<ApiResponse<PartnerResponse>>(
      `${this.baseUrl}partner/${parnerType}/${partnerId}`
    );
  }

  deletePartner(
    parnerType: PartnerType,
    partnerId: number
  ): Observable<ApiResponse<PaginatedResponse<PartnerResponse>>> {
    return this.http.delete<ApiResponse<PaginatedResponse<PartnerResponse>>>(
      `${this.baseUrl}partner/${parnerType}/${partnerId}`
    );
  }

  createOrUpdateWhitelistIp(
    whitelistIpData: IPWhitelistRequest,
    ipId: number = null
  ): Observable<ApiResponse<null>> {
    return this.http.post<ApiResponse<null>>(
      `${this.baseUrl}partner/whitelist/ip/${ipId || ''}`,
      whitelistIpData
    );
  }

  getAllWhitelistIps(
    partnerId: number
  ): Observable<ApiResponse<WhiteListIp[]>> {
    return this.http.get<ApiResponse<WhiteListIp[]>>(
      `${this.baseUrl}partner/whitelist/ip/${partnerId}`
    );
  }

  getSingleWhitelistIp(
    partnerId: number,
    whitelistIpId: number
  ): Observable<ApiResponse<WhiteListIp>> {
    return this.http.get<ApiResponse<WhiteListIp>>(
      `${this.baseUrl}partner/whitelist/ip/${partnerId}/${whitelistIpId}`
    );
  }

  deleteWhitelistIp(whitelistIpId: number): Observable<ApiResponse<null>> {
    return this.http.delete<ApiResponse<null>>(
      `${this.baseUrl}partner/whitelist/ip/${whitelistIpId}`
    );
  }
}

export const NavigationMenu: InternalNavigationMenu[] = [
  {
    title: 'Profile',
    subtitle: 'Add profile details',
    url: 'profile',
  },
  {
    title: 'Settings & Api key',
    subtitle: 'Configure your settings and manage API key',
    url: 'settings',
  },
  // {
  //   title: 'Tracking link',
  //   subtitle: 'Setup and manage tracking links',
  //   url: 'tracking-link',
  //   key: 'tracking_link',
  // },
  // {
  //   title: 'Postback & Global pixels',
  //   subtitle: 'Setup manage postbacks and global pixels',
  //   url: 'postback-pixel',
  //   key: 'postback_pixel',
  // },
  {
    title: 'IP Whitelisting',
    subtitle: 'Add and manage IP Whitelisting',
    url: 'ip-whitelisting',
    premium_feature_key: PremiumFeatureKeys.IPWhitelisting,
  }
];
