<div class="f-col custom-cell-wrapper">
  <ng-container [ngSwitch]="type">
    <!-- Date fields -->
    <ng-container *ngSwitchCase="'date'">
      {{ value | date: format || 'mediumDate' }}
    </ng-container>

    <!-- Avatar fields -->
    <ng-container *ngSwitchCase="'category'">
      <app-category-cell [label]="value?.label" [color]="value?.colorCode" [colorClass]="value?.colorClass">
      </app-category-cell>
    </ng-container>

    <!-- Avatar fields -->
    <ng-container *ngSwitchCase="'avatar'">
      <app-avatar-cell [name]="value?.name || value" [image]="value?.image" [email]="value?.email"></app-avatar-cell>
    </ng-container>

    <!-- Status field -->
    <ng-container *ngSwitchCase="'status'">
      <app-status-cell [status]="value"></app-status-cell>
    </ng-container>

    <!-- User type field -->
    <ng-container *ngSwitchCase="'user_type'">
      <app-user-type-cell [type]="value"></app-user-type-cell>
    </ng-container>

    <!-- avatar link field(internal) -->
    <ng-container *ngSwitchCase="'avatar_link'">
      <app-avatar-link-cell [value]="value"></app-avatar-link-cell>
    </ng-container>

    <!-- email -->
    <ng-container *ngSwitchCase="'email'">
      <app-email-cell [email]="value"></app-email-cell>
    </ng-container>
    <!-- email -->
    <ng-container *ngSwitchCase="'phone' || 'mobile'">
      <app-phone-cell [phone]="value"></app-phone-cell>
    </ng-container>

    <!-- link field(internal) -->
    <ng-container *ngSwitchCase="'link'">
      <app-link-cell [value]="value"></app-link-cell>
    </ng-container>

    <!-- hyperlink field(external) -->
    <ng-container *ngSwitchCase="'hyperlink'">
      <app-link-cell [value]="value" [external]="true"></app-link-cell>
    </ng-container>

    <!-- Html field -->
    <ng-container *ngSwitchCase="'html'">
      <app-html-cell [value]="value" [extendedView]="extendedView"></app-html-cell>
    </ng-container>

    <!-- External link field -->
    <ng-container *ngSwitchCase="'external-link'">
      <app-external-link [value]="value"></app-external-link>
    </ng-container>

    <!-- Toggle field -->
    <ng-container *ngSwitchCase="'toggle'">
      <app-toggle-field [value]="value"></app-toggle-field>
    </ng-container>

    <!-- Editable Toggle field -->
    <ng-container *ngSwitchCase="'editable-toggle'">
      <app-toggle-field [value]="value" [editable]="true"
        (toggleChange)="optionClicked.emit($event)"></app-toggle-field>
    </ng-container>

    <!-- Table field -->
    <ng-container *ngSwitchCase="'table'">
      <app-table-field [value]="value"></app-table-field>
    </ng-container>

    <!-- Table field -->
    <ng-container *ngSwitchCase="'nested'">
      <app-nested-field [value]="value"></app-nested-field>
    </ng-container>

    <!-- Table field -->
    <ng-container *ngSwitchCase="'tags'">
      <app-tag-cell [value]="{ value: value, limit: limit }"></app-tag-cell>
    </ng-container>

    <!-- Table field -->
    <ng-container *ngSwitchCase="'permissions'">
      <app-permission-view-cell [value]="{ value: value, limit: limit }"></app-permission-view-cell>
    </ng-container>

    <!-- Table field -->
    <ng-container *ngSwitchCase="'selectable_pills'">
      <app-selectable-pills-cell (optionClicked)="optionSelected($event)" [value]="value"></app-selectable-pills-cell>
    </ng-container>

    <!-- Title field -->
    <ng-container *ngSwitchCase="'title'">
      <app-text-line type="base" [enableWrap]="false" [title]="value">{{
        value | titlecase
        }}</app-text-line>
    </ng-container>

    <!-- Country field -->
    <ng-container *ngSwitchCase="'country'">
      <app-tag-cell [value]="{
          value: resolveCountries(value),
          limit: limit
        }"></app-tag-cell>
    </ng-container>

    <!-- Progress bar field -->
    <ng-container *ngSwitchCase="'progress'">
      <app-progress-cell [progress]="value"></app-progress-cell>
    </ng-container>

    <!-- Default -->
    <ng-container *ngSwitchDefault>
      <ng-container *ngIf="!!prefixIcon || !!suffixIcon; else normalText">
        <div class="f-row">
          <mat-icon class="icon-size-6 mr-2" *ngIf="!!prefixIcon" fontSet="mso">{{ prefixIcon }}</mat-icon>
          <app-text-line type="base" [enableWrap]="true">{{
            value
            }}</app-text-line>
          <mat-icon class="icon-size-6 ml-2" *ngIf="!!suffixIcon" fontSet="mso">{{ suffixIcon }}</mat-icon>
        </div>
      </ng-container>

      <ng-template #normalText>
        <app-text-line type="base" [enableWrap]="true" [title]="value">{{
          getLocalizedNumber(value)
          }}</app-text-line>
      </ng-template>
    </ng-container>
  </ng-container>
</div>