<app-searchable-single-select
  *ngIf="!multi; else multiselect"
  class="w-100 {{ classes }}"
  [cssClass]="cssClass"
  [formControl]="formControl"
  [disabled]="formControl?.disabled"
  [options]="campaignList$ | async"
  nameKey="title"
  [valueKey]="valueKey"
  placeholder="Select user"
  [required]="required">
</app-searchable-single-select>

<ng-template #multiselect>
  <app-multiselect-search
    [formControl]="formControl"
    [cssClass]="cssClass"
    [disabled]="formControl?.disabled"
    [receivedValues]="campaignList$ | async"
    nameKey="title"
    [valueKey]="valueKey"
    label="Select users"
    [required]="required"></app-multiselect-search>
</ng-template>
