import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { NotifyService } from '@services/notify.service';
import { SharedService } from '@services/shared.service';
import FormDataComponent from '@utilities/form-data.component';
import { UpdateService } from '../update.service';

@Component({
  selector: 'app-send-message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.scss']
})
export class SendMessageComponent
  extends FormDataComponent<any>
  implements OnInit
{
  form: UntypedFormGroup;
  override method = this.updateService.sendMessageToUser.bind(
    this.updateService
  );

  constructor(
    shared: SharedService,
    private router: Router,
    route: ActivatedRoute,
    notify: NotifyService,
    private updateService: UpdateService
  ) {
    super(shared, router, route, notify);

    this.autoclose = true;
    this.outlet = 'side';
    this.form = new UntypedFormGroup({
      to_user_id: new UntypedFormControl('', [Validators.required]),
      title: new UntypedFormControl('', [Validators.required]),
      message: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(255)
      ])
    });
  }

  ngOnInit(): void {
    const urlTree: UrlTree = this.router.parseUrl(this.router.url);

    delete urlTree.root.children.side;
    this.outlet = urlTree;
  }
}
