<app-text-line
  [title]="_value?.label || '-'"
  *ngIf="!external; else externalLink"
  [ngClass]="{ link: !!_value?.routerLink }"
  [routerLink]="_value?.routerLink || null"
  [queryParams]="_value?.queryParams || {}"
  queryParamsHandling="merge"
  [textColor]="!!_value?.routerLink ? 'link' : 'primary'">
  {{ _value?.label || '-' }}
</app-text-line>

<ng-template #externalLink>
  <ng-container *ngIf="!!_value?.url; else disabledLink">
    <a href="{{ _value?.url }}" class="nodecor" target="_blank">
      <app-text-line class="link" textColor="link">
        {{ _value?.label }}
      </app-text-line>
    </a>
  </ng-container>

  <ng-template #disabledLink>
    <app-text-line textColor="primary" [title]="_value?.label || '-'">
      {{ _value?.label || '-' }}
    </app-text-line>
  </ng-template>
</ng-template>
