import { TemplateType } from './global';

export interface CampaignResponse {
  _id: string;
  id: number;
  objective: string;
  advertiser: PartnerRef;
  categories: string[];
  currency: string;
  title: string;
  description: string;
  kpi: string;
  preview_url: string;
  tracking_method: string;
  campaign_url: string;
  external_offer_id: string;
  status: string;
  visibility: string;
  promotion_methods: [];
  fallback_url?: string;
  impression_url?: string;
  redirect_type?: CampaignRedirectOptions;
}

export enum CampaignRedirectOptions {
  AllowReferral302 = "302_AR",
  HideReferral302 = "302_HR",
  AllowReferral200 = "200_AR",
  HideReferral200 = "200_HR"
}

export const RedirectOptionsDisplay = [
  { label: "302 Allow Referral", value: CampaignRedirectOptions.AllowReferral302 },
  { label: "302 Hide Referral", value: CampaignRedirectOptions.HideReferral302 },
  { label: "200 Allow Referral", value: CampaignRedirectOptions.AllowReferral200 },
  { label: "200 Hide Referral", value: CampaignRedirectOptions.HideReferral200 }
];

export interface PartnerRef {
  id: string;
  name: string;
}

export interface CreateCampaignRequest {
  objective: string;
  advertiser_id: number;
  promotion_methods: string[];
  title: string;
  preview_url: string;
  campaign_url: string;
  categories: string[];
  description: string;
  kpi: string;
  currency: string;
  visibility: string;
  fallback_url?: string;
  impression_url?: string;
  redirect_type?: CampaignRedirectOptions;
}

export interface Category {
  name: string;
  id?: number;
}

export interface CampaignDetailsResponse extends CampaignResponse { }

export interface CampaignConfiguration {
  goal_name: string;
  landing_page_name: string;
  conversion_flow: string[];
  redirect_type: string;
  conversion_hold_period: number;
  conversion_status_after_hold: string;
  app_template_id: number;
  payout_template_id: number;
  targeting_template_id: null;
  email_template_id: null;
  cap_template_id: null;
}

export interface CreateCampaignConfigurationRequest {
  goal_name: string;
  landing_page_name: string;
  conversion_flow: string[];
  redirect_type: string;
  conversion_hold_period: number;
  conversion_status_after_hold: 'approved' | 'extended';
  payout_template_id: number;
  app_template_id: number;
  targeting_template_id: number;
  email_template_id: number;
}

export interface CampaignTemplate {
  _id: number;
  id: number;
  name: string;
  type: TemplateType;
  active: boolean;
  configuration?:
  | PayoutTemplateConfigurationRequest[]
  | AppTemplateConfigurationResponse
  | any;
}

export interface AppTemplateConfigurationResponse {
  id?: number;
  name: string;
  deeplink: string;
  apps: App[];
}

export interface App {
  platform: string;
  app_id: string;
  app_url: string;
}

export interface CreateTemplateRequest {
  name: string;
  type: string;
}

export interface PayoutTemplateConfigurationRequest {
  countries: string[] | string | any;
  revenue: number;
  payout: number;
  currency: string;
}

export interface ConfigureTemplateRequest {
  template_id: number;
  configuration:
  | PayoutTemplateConfigurationRequest[]
  | AppTemplateConfigurationResponse;
}
