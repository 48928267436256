import { NgModule } from '@angular/core';
import { ChipsMultiDropdownComponent } from '@modules/factory/chips-multi-dropdown/chips-multi-dropdown.component';
import { ConfirmationDialogComponent } from '@modules/factory/confirmation-dialog/confirmation-dialog.component';
import { ExpansionMenuComponent } from '@modules/factory/expansion-menu/expansion-menu.component';
import { NodeLayoutComponent } from '@modules/factory/node-layout/node-layout.component';
import { NotFoundSectionComponent } from '@modules/factory/not-found-section/not-found-section.component';
import { SharedModule } from '@modules/shared/shared.module';
// import { PaymentModule } from 'src/app/_payment/payment.module';

import { AdvancedFilterComponent } from './advanced-filter/advanced-filter.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { AvatarComponent } from './avatar/avatar.component';
import { ButtonToggleGroupComponent } from './button-toggle-group/button-toggle-group.component';
import { ChartRendererComponent } from './chart-renderer/chart-renderer.component';
import { ChartTableRendererComponent } from './chart-table-renderer/chart-table-renderer.component';
import { ChipComponent } from './chip/chip.component';
import { CustomParametersFormComponent } from './custom-parameters-form/custom-parameters-form.component';
import { DashboardWidgetComponent } from './dashboard-widget/dashboard-widget.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { FilterOptionComponent } from './filter-option/filter-option.component';
import { GlobalLoaderComponent } from './global-loader/global-loader.component';
import { LabelComponent } from './label/label.component';
import { AvatarCellComponent } from './list-data-table/avatar-cell/avatar-cell.component';
import { CustomCellComponent } from './list-data-table/custom-cell/custom-cell.component';
import { ExternalLinkComponent } from './list-data-table/external-link/external-link.component';
import { HtmlCellComponent } from './list-data-table/html-cell/html-cell.component';
import { LinkCellComponent } from './list-data-table/link-cell/link-cell.component';
import { ListDataTableComponent } from './list-data-table/list-data-table.component';
import { NestedFieldComponent } from './list-data-table/nested-field/nested-field.component';
import { StatusCellComponent } from './list-data-table/status-cell/status-cell.component';
import { TableFieldComponent } from './list-data-table/table-field/table-field.component';
import { TableToolbarComponent } from './list-data-table/table-toolbar/table-toolbar.component';
import { ToggleFieldComponent } from './list-data-table/toggle-field/toggle-field.component';
import { TwoColumnTableComponent } from './list-data-table/two-column-table/two-column-table.component';
import { MetricsCardsComponent } from './metrics-cards/metrics-cards.component';
import { MultiselectSearchComponent } from './multiselect-search/multiselect-search.component';
// import { PackagesComponent } from './plan/packages/packages.component';
// import { PlanComponent } from './plan/plan.component';
import { QuillEditorComponent } from './quill-editor/quill-editor.component';
import { ScalableSvgComponent } from './scalable-svg/scalable-svg.component';
import { SearchComponent } from './search/search.component';
import { SearchableSingleSelectComponent } from './searchable-single-select/searchable-single-select.component';
import { SectionLoaderComponent } from './section-loader/section-loader.component';
import { SegmentComponent } from './segment/segment.component';
import { SidenavLinkItemComponent } from './sidenav-link-item/sidenav-link-item.component';
import { SidenavWrapperComponent } from './sidenav-wrapper/sidenav-wrapper.component';
import { SkeletonComponent } from './skeleton/skeleton.component';
import { SnackbarComponent } from './snackbar/snackbar.component';
import { TextLineComponent } from './text-line/text-line.component';
import { TopNavigationComponent } from './top-navigation/top-navigation.component';
import { ButtonGroupComponent } from './button-group/button-group.component';
import { CustomKeyValGeneratorComponent } from './custom-key-val-generator/custom-key-val-generator.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { DimensionDataTableComponent } from './dimension-data-table/dimension-data-table.component';
import { TagEditorComponent } from './tag-editor/tag-editor.component';
import {
  SettingsToggleComponent,
  SettingsToggleWrapperComponent
} from './settings-toggle/settings-toggle.component';
import { SidesheetFormWrapperComponent } from './sidesheet-form-wrapper/sidesheet-form-wrapper.component';
import { CountrySelectorComponent } from './country-selector/country-selector.component';
import { SidesheetContentWrapperComponent } from './sidesheet-content-wrapper/sidesheet-content-wrapper.component';
import { FileItemComponent } from './file-item/file-item.component';
import { FileDropComponent } from './file-drop/file-drop.component';
import { ExpandedRowComponent } from './list-data-table/expanded-row/expanded-row.component';
import { CategoryCellComponent } from './list-data-table/category-cell/category-cell.component';
import { AvatarLinkCellComponent } from './list-data-table/avatar-link-cell/avatar-link-cell.component';
import { EmailCellComponent } from './list-data-table/email-cell/email-cell.component';
import { PhoneCellComponent } from './list-data-table/phone-cell/phone-cell.component';
import { SkeletonPreviewsComponent } from './skeleton-previews/skeleton-previews.component';
import { NullStateComponent } from '@layouts/null-state/null-state.component';
import { CampaignSelectorComponent } from './campaign-selector/campaign-selector.component';
import { CurrencySelectorComponent } from './currency-selector/currency-selector.component';
import { StatusChangeDialogComponent } from './status-change-dialog/status-change-dialog.component';
import { TrackingLinkComponent } from './tracking-link/tracking-link.component';
import { PartnerSelectorComponent } from './partner-selector/partner-selector.component';
import { GlobalProfileComponent } from './global-profile/global-profile.component';
import { TagCellComponent } from './list-data-table/tag-cell/tag-cell.component';
import {
  CfStepComponent,
  CfStepperWrapperComponent
} from './cf-stepper/cf-stepper.component';
import { SelectablePillsCellComponent } from './list-data-table/selectable-pills-cell/selectable-pills-cell.component';
import { RolesSelectorComponent } from './roles-selector/roles-selector.component';
import { TeamsSelectorComponent } from './teams-selector/teams-selector.component';
import { PermissionViewCellComponent } from './list-data-table/permission-view-cell/permission-view-cell.component';
import { AssignRolesDialogComponent } from './assign-roles-dialog/assign-roles-dialog.component';
import { TimezoneSelectorComponent } from './timezone-selector/timezone-selector.component';
import { DynamicFormGeneratorComponent } from './dynamic-form-generator/dynamic-form-generator.component';
import { EmailInputComponent } from './email-input/email-input.component';
import { UserSelectorComponent } from './user-selector/user-selector.component';
import { SimpleDataTableComponent } from './simple-data-table/simple-data-table.component';
import { UserTypeCellComponent } from './list-data-table/user-type-cell/user-type-cell.component';
import { CustomNotificationComponent } from './custom-notification/custom-notification.component';
import { GoalSelectorComponent } from './goal-selector/goal-selector.component';
import { FieldPropertiesComponent } from './field-properties/field-properties.component';
import { CustomizeColumnComponent } from './list-data-table/customize-column/customize-column.component';
import { ProgressCellComponent } from './list-data-table/progress-cell/progress-cell.component';
import { PremiumInfoCardComponent } from './premium-info-card/premium-info-card.component';
import { ContentWithDrawerComponent } from './content-with-drawer/content-with-drawer.component';
import { ExpandedMultiselectSearchComponent } from './expanded-multiselect-search/expanded-multiselect-search.component';
import { WorldmapGraphComponent } from './worldmap-graph/worldmap-graph.component';
import { SmartLinkSelectorComponent } from './smart-link-selector/smart-link-selector.component';
import { PaymentDialogComponent } from './payment-dialog/payment-dialog.component';
import { ToolboxMenuComponent } from './toolbox-menu/toolbox-menu.component';
import { CustomEmailEditorComponent } from './custom-email-editor/custom-email-editor.component';
import { ScheduleTimelineComponent } from './schedule-timeline/schedule-timeline.component';

@NgModule({
  declarations: [
    DimensionDataTableComponent,
    ChipsMultiDropdownComponent,
    NotFoundSectionComponent,
    ExpansionMenuComponent,
    ConfirmationDialogComponent,
    NodeLayoutComponent,
    GlobalLoaderComponent,
    TextLineComponent,
    AvatarComponent,
    MultiselectSearchComponent,
    ConfirmationDialogComponent,
    MetricsCardsComponent,
    NotFoundSectionComponent,
    // PlanComponent,
    // PackagesComponent,
    ScalableSvgComponent,
    SearchableSingleSelectComponent,
    SnackbarComponent,
    NullStateComponent,
    SectionLoaderComponent,
    CustomParametersFormComponent,
    ListDataTableComponent,
    AvatarCellComponent,
    CustomCellComponent,
    StatusCellComponent,
    TableToolbarComponent,
    SidenavWrapperComponent,
    AdvancedFilterComponent,
    SkeletonComponent,
    LabelComponent,
    FilterOptionComponent,
    QuillEditorComponent,
    AutocompleteComponent,
    LinkCellComponent,
    ExternalLinkComponent,
    HtmlCellComponent,
    FileDropComponent,
    FileItemComponent,
    SearchComponent,
    ChipComponent,
    SidenavLinkItemComponent,
    ButtonToggleGroupComponent,
    ToggleFieldComponent,
    TopNavigationComponent,
    ChartRendererComponent,
    DashboardWidgetComponent,
    ChartTableRendererComponent,
    DatePickerComponent,
    TwoColumnTableComponent,
    TableFieldComponent,
    NestedFieldComponent,
    SegmentComponent,
    ButtonGroupComponent,
    CustomKeyValGeneratorComponent,
    TagEditorComponent,
    SettingsToggleComponent,
    SettingsToggleWrapperComponent,
    SidesheetFormWrapperComponent,
    SidesheetContentWrapperComponent,
    CountrySelectorComponent,
    ExpandedRowComponent,
    CategoryCellComponent,
    AvatarLinkCellComponent,
    PhoneCellComponent,
    EmailCellComponent,
    SkeletonPreviewsComponent,
    CampaignSelectorComponent,
    CurrencySelectorComponent,
    StatusChangeDialogComponent,
    TrackingLinkComponent,
    PartnerSelectorComponent,
    GlobalProfileComponent,
    TagCellComponent,
    CfStepperWrapperComponent,
    CfStepComponent,
    SelectablePillsCellComponent,
    RolesSelectorComponent,
    TeamsSelectorComponent,
    PermissionViewCellComponent,
    AssignRolesDialogComponent,
    TimezoneSelectorComponent,
    DynamicFormGeneratorComponent,
    EmailInputComponent,
    UserSelectorComponent,
    SimpleDataTableComponent,
    UserTypeCellComponent,
    CustomNotificationComponent,
    GoalSelectorComponent,
    FieldPropertiesComponent,
    CustomizeColumnComponent,
    ProgressCellComponent,
    PremiumInfoCardComponent,
    ContentWithDrawerComponent,
    ExpandedMultiselectSearchComponent,
    WorldmapGraphComponent,
    SmartLinkSelectorComponent,
    PaymentDialogComponent,
    ToolboxMenuComponent,
    CustomEmailEditorComponent,
    ScheduleTimelineComponent
  ],
  exports: [
    CfStepperWrapperComponent,
    CfStepComponent,
    TrackingLinkComponent,
    SkeletonPreviewsComponent,
    AvatarLinkCellComponent,
    PhoneCellComponent,
    EmailCellComponent,
    CategoryCellComponent,
    ExpandedRowComponent,
    CountrySelectorComponent,
    SidesheetFormWrapperComponent,
    SidesheetContentWrapperComponent,
    DimensionDataTableComponent,
    SegmentComponent,
    DatePickerComponent,
    ChartTableRendererComponent,
    DashboardWidgetComponent,
    ChartRendererComponent,
    ToggleFieldComponent,
    TopNavigationComponent,
    ButtonToggleGroupComponent,
    SidenavLinkItemComponent,
    SearchComponent,
    ChipComponent,
    FileDropComponent,
    FileItemComponent,
    LinkCellComponent,
    HtmlCellComponent,
    ExternalLinkComponent,
    AutocompleteComponent,
    QuillEditorComponent,
    FilterOptionComponent,
    LabelComponent,
    SkeletonComponent,
    AdvancedFilterComponent,
    SidenavWrapperComponent,
    CustomCellComponent,
    StatusCellComponent,
    TableToolbarComponent,
    ListDataTableComponent,
    AvatarCellComponent,
    CustomParametersFormComponent,
    NullStateComponent,
    SectionLoaderComponent,
    ChipsMultiDropdownComponent,
    NotFoundSectionComponent,
    ExpansionMenuComponent,
    ConfirmationDialogComponent,
    NodeLayoutComponent,
    GlobalLoaderComponent,
    TextLineComponent,
    AvatarComponent,
    MultiselectSearchComponent,
    ConfirmationDialogComponent,
    MetricsCardsComponent,
    NotFoundSectionComponent,
    // PlanComponent,
    // PackagesComponent,
    ScalableSvgComponent,
    SearchableSingleSelectComponent,
    SnackbarComponent,
    SharedModule,
    TwoColumnTableComponent,
    NestedFieldComponent,
    ButtonGroupComponent,
    CustomKeyValGeneratorComponent,
    TagEditorComponent,
    SettingsToggleComponent,
    SettingsToggleWrapperComponent,
    CampaignSelectorComponent,
    CurrencySelectorComponent,
    StatusChangeDialogComponent,
    PartnerSelectorComponent,
    GlobalProfileComponent,
    TagCellComponent,
    SelectablePillsCellComponent,
    RolesSelectorComponent,
    TeamsSelectorComponent,
    PermissionViewCellComponent,
    AssignRolesDialogComponent,
    TimezoneSelectorComponent,
    DynamicFormGeneratorComponent,
    EmailInputComponent,
    UserSelectorComponent,
    SimpleDataTableComponent,
    UserTypeCellComponent,
    CustomNotificationComponent,
    GoalSelectorComponent,
    FieldPropertiesComponent,
    CustomizeColumnComponent,
    ProgressCellComponent,
    PremiumInfoCardComponent,
    ContentWithDrawerComponent,
    ExpandedMultiselectSearchComponent,
    WorldmapGraphComponent,
    SmartLinkSelectorComponent,
    PaymentDialogComponent,
    ToolboxMenuComponent,
    CustomEmailEditorComponent,
    ScheduleTimelineComponent
  ],
  imports: [SharedModule],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'en-GB' }]
})
export class FactoryModule { }
