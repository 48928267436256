import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { LoaderService } from '@services/loader.service';
import { NotifyService } from '@services/notify.service';
import { StorageService } from '@services/storage.service';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
  private isRefreshing: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private readonly sessionExpiredMessage =
    'Oops! looks like your session has expired, please login again';
  masterToken: string;
  sessioExpired: boolean;
  constructor(
    private notify: NotifyService,
    private gload: LoaderService,
    private authService: AuthService,
    private router: Router
  ) {}

  private get tokenRefreshState() {
    return this.isRefreshing.getValue();
  }

  private set tokenRefreshState(state: boolean) {
    this.isRefreshing.next(state);
  }

  public get tokenRefreshState$(): Observable<boolean> {
    return this.isRefreshing.asObservable();
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this.doLogout();
          return of(null);
        }

        const errorMessage = this.handleError(err);

        return throwError(errorMessage);
      })
    );
  }

  handleTokenRefresh(error: any = null) {
    return new Observable((observer) => {
      this.authService.refreshToken().subscribe(
        (res: any) => {
          res ? observer.next(res) : observer.error(error);
        },
        (err: any) => {
          observer.error(err);
        }
      );
    });
  }

  doLogout() {
    // auto logout if 401 response returned from api
    this.authService.removeLocalData();
    this.router.navigate(['login']);
    this.authService.setUserSubject(null);
    this.gload.isLoading.next(false);
  }

  // private addTokenHeader(request: HttpRequest<any>, token: string) {
  //   return request.clone({
  //     setHeaders: {
  //       Authorization: `Bearer ${token}`,
  //     },
  //   });
  // }

  private handleError(error: HttpErrorResponse) {
    let errorMessage = 'Oops! Something went wrong.';

    if (error.error instanceof ErrorEvent) {
      // Client side error
      errorMessage = `${error.error.message}`;
    } else {
      errorMessage = new HttpErrorResponse(error)?.error?.message;
      this.handleBranding404(error)
        .then((navigationUrl: string) => {
          this.router.navigate([navigationUrl]);
        })
        .catch(() => {
          // Server side error
          if (error.status !== 0 || !error) {
            errorMessage = error?.url.includes('/token/refresh')
              ? this.sessionExpiredMessage
              : error?.error?.message || errorMessage || null;
          }
        });
    }

    if (errorMessage) {
      this.notify.openSnackBar(errorMessage, 'Dismiss');
    }

    return errorMessage;
  }

  handleBranding404(error: HttpErrorResponse): Promise<any> {
    // const { hostname } = new URL(window.location.origin);

    return new Promise((resolve, reject) => {
      if (error.status === 404 && error.url.indexOf('account/branding') > -1) {
        // StorageService.removeLocalItem(hostname);
        resolve('/find-my-account');
      } else {
        reject(error);
      }
    });
  }
}
