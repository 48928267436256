<div class="notification-container">
  <div class="icon-container">
    <mat-icon fontSet="mso" class="icon-size-8">{{
      snackBarData?.icon || 'info-circle'
      }}</mat-icon>
  </div>
  <div class="content">
    <h6 *ngIf="!!snackBarData?.title">{{ snackBarData?.title }}</h6>
    <p class="mb-0 __secondary">{{ snackBarData?.message }}</p>
  </div>
  <div class="action-button">
    <button matSnackBarAction mat-button color="primary" class="close-btn" (click)="snackBarRef.dismissWithAction()">
      <mat-icon fontSet="mso" class="icon-size-5 __secondary">close</mat-icon>
    </button>
  </div>
</div>