<mat-drawer-container class="w-100 h-100" autosize [hasBackdrop]="false">
  <mat-drawer mode="over" position="end" [opened]="openSidebar" disableClose>
    <app-sidenav-wrapper>
      <ng-container sidenavToolbarLeft>
        <app-text-line type="leading">{{
          (titleFromRoute | async)?.side
          }}</app-text-line>
      </ng-container>

      <ng-container sidenavContent>
        <router-outlet name="side" (activate)="openSidebar = true" (deactivate)="openSidebar = false">
        </router-outlet>
      </ng-container>
    </app-sidenav-wrapper>
  </mat-drawer>

  <app-list-data-table (paginateEvent)="handlePagination($event)" [dataSourceConfig]="dataSourceConfig"
    [columnTypeMap]="columnTypeMap" [isLoading]="loader" (actionClicked)="actionMenuClicked($event)">
    <ng-container tableToolbarLeft>
      <div class="f-row">
        <app-text-line type="leading">Messages</app-text-line>
        <app-text-line type="base" textColor="secondary" weight="ml-1"
          *ngIf="+dataSourceConfig?.dataSource?.meta?.total">({{ dataSourceConfig?.dataSource?.meta?.total
          }})</app-text-line>
      </div>
    </ng-container>

    <ng-container tableToolbarRight>
      <!-- <button class="ml-2" color="primary" mat-raised-button
                [routerLink]="['.', { outlets: { side: ['add-task'] } }]">
                Add message
            </button> -->
    </ng-container>

    <ng-container emptyStateSection>
      <app-null-state [loader]="loader" loaderType="table" icon="chat" [size]="20" [message]="{
          line1: 'No messages found',
          line2: 'Try again in a while'
        }">
      </app-null-state>
    </ng-container>
  </app-list-data-table>
</mat-drawer-container>