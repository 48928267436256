import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@services/auth.guard';
import { NotificationsComponent } from './notifications/notifications.component';
import { MessagesComponent } from './messages/messages.component';
import { SendMessageComponent } from './send-message/send-message.component';
import { GlobalProfileComponent } from '@modules/factory/global-profile/global-profile.component';

const routes: Routes = [
  {
    path: 'messages',
    component: MessagesComponent,
    canActivate: [AuthGuard],
    data: { primary: 'Messages' },
    children: [
      {
        path: 'member-profile/:type/:memberId',
        data: { side: 'Member profile' },
        component: GlobalProfileComponent,
        outlet: 'side'
      }
    ]
  },
  {
    path: 'notifications',
    component: NotificationsComponent,
    data: { side: 'Notifications' },
    canActivate: [AuthGuard],
    outlet: 'side'
  },
  {
    path: 'write-message',
    component: SendMessageComponent,
    data: { side: 'Send message' },
    canActivate: [AuthGuard],
    outlet: 'side'
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UpdatesRoutingModule {}
