import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { STORAGE_KEY } from '@enums/globals';
import { BrandingResponse } from '@interfaces/global';
import { AuthService } from '@services/auth.service';
import { NotifyService } from '@services/notify.service';
import { StorageService } from '@services/storage.service';
import ApiBase from '@utilities/api-base';
import Pusher, { Channel } from 'pusher-js';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Message } from './push-notification.service';
//Include as needed: import * as PusherTypes from 'pusher-js';

export const TOPIC = 'notification';

export interface PusherConfig {
  APP_ID: string;
  APP_KEY: string;
  APP_SECRET: string;
  APP_CLUSTER: string;
}

@Injectable({
  providedIn: 'root'
})
export class PusherService extends ApiBase {
  private _pusher: Pusher;
  private feedSub$: BehaviorSubject<Message> = new BehaviorSubject<Message>(
    null
  );

  get feedItems() {
    return this.feedSub$.asObservable();
  }

  constructor(
    private auth: AuthService,
    private http: HttpClient,
    private notify: NotifyService
  ) {
    super();
  }

  initiateSubscription() {
    this.initializePusher(environment?.pusherConfig).then((pusher) => {
      this._pusher = pusher;

      if (!this.auth.isImpersonationActive) {
        this.subscribeChannel(TOPIC);
      }
    });
  }

  initializePusher({ APP_KEY, APP_CLUSTER }: PusherConfig): Promise<Pusher> {
    const pusher = new Pusher(APP_KEY, {
      cluster: APP_CLUSTER
    });

    return Promise.resolve(pusher);
  }

  subscribeChannel(topic: string, channelName: string = this.getAutoChannel()) {
    if (!channelName) return;

    const channel: Channel = this._pusher.subscribe(channelName);

    channel.bind(topic, (event: Message) => {
      // this.createBrowserNotification(message);
      this.notify.alert({
        message: event.description,
        title: event.title,
        icon: 'info',
        action: null
      });
      this.feedSub$.next(event);
    });
  }

  unsubscribeChannel(
    channelName: string = StorageService.getLocalItem(
      STORAGE_KEY.PUSHER_CHANNEL
    )
  ) {
    if (!this.auth.isImpersonationActive) {
      this._pusher?.unsubscribe(channelName);
      StorageService.removeLocalItem(STORAGE_KEY.PUSHER_CHANNEL);
    }
  }

  getAutoChannel() {
    const { hostname, origin } = new URL(window.location.origin);
    const brandDetails: BrandingResponse = StorageService.getLocalItem(
      hostname === 'localhost' ? environment.networkOrBrandDomain : origin
    );

    if (!this.auth.currentUserValue || !brandDetails) return null;

    const channel = `account-${brandDetails?.account_id}-user-${this.auth.currentUserValue?._id}`;

    StorageService.setLocalItem(STORAGE_KEY.PUSHER_CHANNEL, channel);
    return channel;
  }

  createMessage() {
    this.http.get(`${environment.base_url}send-message`).subscribe();
  }

  createBrowserNotification(message: string) {
    if (!('Notification' in window)) {
      // Check if the browser supports notifications
      console.log('This browser does not support desktop notification');
    } else if (Notification.permission === 'granted') {
      // Check whether notification permissions have already been granted;
      // if so, create a notification
      const notification = new Notification(message);
      // …
    } else if (Notification.permission !== 'denied') {
      // We need to ask the user for permission
      Notification.requestPermission().then((permission) => {
        // If the user accepts, let's create a notification
        if (permission === 'granted') {
          const notification = new Notification(message);
          // …
        }
      });
    }
  }
}
