import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { STORAGE_KEY } from '@enums/index';
import { CampaignDetailsResponse } from '@interfaces/campaign';
import { BrandDetails } from '@interfaces/global';
import { BehaviorSubject, distinctUntilChanged } from 'rxjs';

import { calculateContrastRatio } from '@utilities/functions';
import { StorageService } from './storage.service';

export type SidenavState = 'open' | 'closed';
export enum PanelTheme {
  Dark = 'Dark',
  Light = 'Light',
  System = 'System'
}

export interface OutletTitle {
  primary: string;
  side: string;
  outer: string;
}

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  private subDomainTrigger$: BehaviorSubject<string> = new BehaviorSubject(
    '...'
  );
  private panelTheme$: BehaviorSubject<PanelTheme> = new BehaviorSubject(
    StorageService.getLocalItem(STORAGE_KEY.APP_THEME) || PanelTheme.Light
  );
  private triggerLogin$: BehaviorSubject<any> = new BehaviorSubject(null);
  private titleSubject$: BehaviorSubject<OutletTitle> = new BehaviorSubject(
    StorageService.getLocalItem(STORAGE_KEY.TITLE_STATE) || null
  );
  private campaignDetailsSubject$: BehaviorSubject<CampaignDetailsResponse> =
    new BehaviorSubject(null);
  private formDataSaveSub$: BehaviorSubject<boolean> = new BehaviorSubject(
    null
  );
  private branding$: BehaviorSubject<BrandDetails> = new BehaviorSubject(null);
  private brandingTriggerSub$: BehaviorSubject<boolean> = new BehaviorSubject(
    null
  );
  private templateConfiguredTrigger$: BehaviorSubject<boolean> =
    new BehaviorSubject(null);

  private sideNavState$: BehaviorSubject<SidenavState> = new BehaviorSubject(
    'closed'
  );

  public panelThemeSub$ = this.panelTheme$.asObservable();
  currentPanelTheme = null;

  public set panelTheme(val: PanelTheme) {
    StorageService.setLocalItem(STORAGE_KEY.APP_THEME, val);
    this.panelTheme$.next(val);
  }

  public configurationTrigger = this.templateConfiguredTrigger$.asObservable();
  public brandingTrigger = this.brandingTriggerSub$.asObservable();
  public formDataSaved = this.formDataSaveSub$.asObservable();
  public subdomain = this.subDomainTrigger$.asObservable();
  public triggerLogin = this.triggerLogin$.asObservable();
  public title = this.titleSubject$.asObservable();
  public branding = this.branding$.asObservable();
  public campaignDetails = this.campaignDetailsSubject$.asObservable();
  public sidenavStateSub = this.sideNavState$.asObservable();

  public set _brandingTrigger(value: boolean) {
    this.brandingTriggerSub$.next(value);
  }

  public set _templateConfigured(val: boolean) {
    this.templateConfiguredTrigger$.next(val);
  }

  public set componentTitle(routeData: any) {
    const existing = this.titleSubject$.getValue();
    const titleObj: OutletTitle = {
      ...existing,
      ...(routeData?.primary
        ? { primary: routeData?.primary }
        : { primary: existing?.primary }),
      ...(routeData?.side
        ? { side: routeData?.side }
        : { side: existing?.side }),
      ...(routeData?.outer
        ? { outer: routeData?.outer }
        : { outer: existing?.outer })
    };

    this.titleSubject$.next(titleObj);
    StorageService.setLocalItem(STORAGE_KEY.TITLE_STATE, titleObj);
  }

  public set formDataSavedState(value: boolean) {
    this.formDataSaveSub$.next(value);
  }

  public set brand(value: BrandDetails) {
    this.branding$.next(value);
  }
  public get brandDetails(): BrandDetails {
    return this.branding$.getValue();
  }

  public set sidenavState(value: SidenavState) {
    this.sideNavState$.next(value);
  }

  private brandColorObs = new BehaviorSubject('');
  public currentBrandColor = this.brandColorObs.asObservable();
  public set brandColor(color: string) {
    this.brandColorObs.next(color);
    this.setBrandTheme(color);
  }

  get brandColor() {
    return this.brandColorObs.getValue();
  }

  constructor(@Inject(DOCUMENT) private document) {
    this.brandColorObs.subscribe((color) => {
      this.setBrandTheme(
        color || StorageService.getLocalItem(STORAGE_KEY.BRANDCOLOR)
      );
    });

    this.brandColorObs.next(
      StorageService.getLocalItem(STORAGE_KEY.BRANDCOLOR) ||
      'rgba(97, 96, 227, 1)'
    );

    this.panelThemeSub$
      .pipe(distinctUntilChanged())
      .subscribe((theme) => {
        this.currentPanelTheme = theme;
      });
  }

  public retriggerBrandColor() {
    this.brandColor = this.brandColorObs.getValue();
  }

  public toggleTheme() {
    const currentTheme = this.currentPanelTheme;

    this.panelTheme =
      currentTheme === PanelTheme.Light ? PanelTheme.Dark : PanelTheme.Light;
    this.retriggerBrandColor();
  }

  public toggleSidenavState() {
    const currentState = this.sideNavState$.getValue();

    this.sideNavState$.next(currentState === 'open' ? 'closed' : 'open');
  }

  public setBrandTheme(color: string) {
    document.getElementById('brand-theme')?.remove();
    if (this.currentPanelTheme !== PanelTheme.Dark) {
      const style = `
          :root {
            --cf-theme: ${color};
            --cf-text-theme: ${color};
          }

          .mat-mdc-raised-button.mat-primary,
          .mat-mdc-unelevated-button.mat-primary {
            --mdc-filled-button-container-color: var(--cf-theme);
            --mdc-protected-button-container-color: var(--cf-theme);
          }

          .mat-mdc-button.mat-primary {
            --mdc-text-button-label-text-color: ${color};
          }

          html .mat-mdc-icon-button.mat-primary {
              --mdc-icon-button-icon-color:  ${color};
              --mat-icon-button-state-layer-color:  ${color};
          }

          .mat-icon.mat-primary {
              --mat-icon-color: ${color};
          }

          .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary,
          .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
            --mdc-chip-elevated-container-color: ${color};
            --mdc-chip-elevated-disabled-container-color: ${color};
          }
        `;

      const tag = this.document.createElement('style');

      tag.innerText = style;
      tag.id = 'brand-theme';
      const head: HTMLHeadElement = this.document.getElementsByTagName('head')[0];

      head.appendChild(tag);
    }
  }

  public getVisibleTextColor(color: string): string {
    const ratioWithWhite = calculateContrastRatio(color, '#ffffff');
    const ratioWithBlack = calculateContrastRatio(color, '#000000');

    return ratioWithWhite > ratioWithBlack ? '#ffffff' : '#000000';
  }

  public getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  public setSubdomain(subDomainName: string) {
    this.subDomainTrigger$.next(subDomainName);
  }

  public get currentSubdomain() {
    return this.subDomainTrigger$.getValue();
  }

  public setcampaignDetails(details: CampaignDetailsResponse) {
    this.campaignDetailsSubject$.next(details);
  }

  public getCampaignDetails() {
    return this.campaignDetailsSubject$.getValue();
  }

  public get campaignUrl(): string {
    return this.campaignDetailsSubject$.getValue()?.campaign_url || '';
  }

  downloadFileByUrl(url: string) {
    // Create an anchor element
    const anchor = document.createElement('a');

    anchor.target = '_blank';
    anchor.href = url;
    anchor.download = 'downloaded.pdf'; // Set the desired file name

    // Programmatically trigger a click on the anchor element
    anchor.click();

    // Clean up by removing the anchor element
    window.URL.revokeObjectURL(anchor.href);
  }

  extractCurlyBraceParams(url: string): Record<string, string> {
    const queryParams = new URLSearchParams(url.split('?')[1]);
    const params: Record<string, string> = {};

    queryParams.forEach((value, key) => {
      if (value?.includes('{') && value?.includes('}')) {
        params[key] = value;
      }
    });

    return params;
  }
}
