import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Router, UrlTree } from '@angular/router';
import {
  PushNotification,
  PushNotificationService
} from '@services/_pusher/push-notification.service';
import { PusherService } from '@services/_pusher/pusher.service';
import { SharedService } from '@services/shared.service';
import AbstractComponent from '@utilities/abstract-component';
import { map, of, startWith } from 'rxjs';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent
  extends AbstractComponent
  implements OnInit {
  closeOuter: unknown;
  searchControl = new FormControl('');
  filteredNotificationStack$ = of([]);
  allNotifications: PushNotification[] = [];
  displayUnreadOnly = false;

  constructor(
    shared: SharedService,
    private router: Router,
    private pusher: PusherService,
    private pushNotificationService: PushNotificationService
  ) {
    super(shared);
  }

  ngOnInit(): void {
    this.getAllNotifications();
    this.routeAfterSidesheetClosed();
  }

  getAllNotifications() {
    this.loader = true;
    this.subs.sink = this.pushNotificationService.notificationStack$.subscribe(
      (notifications) => {
        this.allNotifications = this.displayUnreadOnly
          ? notifications?.filter((notification) => !notification.is_read)
          : notifications;
        this.initSearchControl();
        this.loader = false;
      }
    );
  }

  get totalCount() {
    return this.pushNotificationService.totalCount;
  }

  get pageSize() {
    return this.pushNotificationService.pageSize;
  }

  paginatorAction(event: PageEvent) {
    this.pushNotificationService.updateNotificationStack({
      ...event,
      pageIndex: event?.pageIndex + 1
    });
  }

  filterNotifications(searchText: string) {
    searchText = `${searchText}`.trim()?.toLowerCase();

    return searchText
      ? this.allNotifications.filter((notification) => {
        return (
          notification.message.title.toLowerCase().includes(searchText) ||
          notification.message.description
            .toLowerCase()
            .includes(searchText) ||
          notification.message.initiator.name
            .toLowerCase()
            .includes(searchText)
        );
      })
      : this.allNotifications?.slice();
  }

  initSearchControl() {
    this.filteredNotificationStack$ = this.searchControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterNotifications(value))
    );
  }

  showUnreadToggle() {
    this.displayUnreadOnly = !this.displayUnreadOnly;
    this.getAllNotifications();
  }

  markRead() {
    this.pushNotificationService.markAllRead().subscribe(() => {
      this.pushNotificationService.updateNotificationStack();
    });
  }

  /**
   * Update the url tree for the current activated route
   */
  routeAfterSidesheetClosed() {
    const urlTree: UrlTree = this.router.parseUrl(this.router.url);

    delete urlTree.root.children.side;
    this.closeOuter = urlTree;
  }
}
