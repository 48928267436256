import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BrandingResponse } from '@interfaces/global';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

import { AuthService } from './auth.service';
import { BrandingService } from './branding.service';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard {
  constructor(
    private router: Router,
    private authService: AuthService,
    private branding: BrandingService
  ) {}

  /**
   * This method will behave opposite of auth guard
   */
  canActivate(): any {
    if (
      this.authService.currentUserValue !== null &&
      this.authService.currentUserValue !== undefined
    ) {
      this.router.navigate(['/network/dashboard']);
      return false;
    } else {
      return this.branding
        .getBrandingDetails({
          domain: environment.production
            ? origin
            : environment.networkOrBrandDomain
        })
        .pipe(switchMap((res: BrandingResponse) => of(!!res?.account_id)));
    }
  }
}
