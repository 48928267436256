<div class="f-row start">
  <app-avatar
    [name]="value?.label"
    [srcPicture]="value?.image"
    class="mr-2"></app-avatar>

  <div class="f-col">
    <app-text-line
      *ngIf="!external; else externalLink"
      [ngClass]="{ link: !!value?.routerLink }"
      [routerLink]="value?.routerLink || null"
      [queryParams]="value?.queryParams || {}"
      textColor="link">
      {{ value?.label || value | capitalize }}
    </app-text-line>
    <app-text-line type="small" textColor="secondary" *ngIf="value?.email">{{
      value?.email
    }}</app-text-line>
  </div>

  <ng-template #externalLink>
    <a href="{{ value?.url || value }}" class="nodecor" target="_blank">
      <app-text-line class="link" textColor="link">
        {{ value?.label || value | capitalize }}
      </app-text-line>
    </a>
  </ng-template>
</div>
