import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { RegisterRequest } from '@interfaces/auth';
import { ApiService } from '@services/api.service';
import { AuthService } from '@services/auth.service';
import { HelperService } from '@services/helper.service';
import { NotifyService } from '@services/notify.service';
import { SharedService } from '@services/shared.service';

import * as countries from '../../../../assets/data/countries.json';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  searchCtrl: UntypedFormControl = new UntypedFormControl();
  registerForm: UntypedFormGroup;
  countryList: any[] = (countries as any).default;
  filteredCountryList: any[] = (countries as any).default;
  selectedCountry: any;
  errorText: string = null;
  subdomain: string;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private notify: NotifyService,
    private api: ApiService,
    private auth: AuthService,
    private route: Router,
    private helper: HelperService,
    private shared: SharedService
  ) {
    this.shared.subdomain.subscribe((data) => {
      this.subdomain = data;
    });
  }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', Validators.required],
      country: ['', Validators.required],
      company: ['', Validators.required],
      partner_type: [this.isNetwork ? null : 'publisher', Validators.required],
      subdomain: [this.subdomain]
    });

    this.registerForm.controls.country.valueChanges.subscribe((data) => {
      data = typeof data === 'object' ? (data = data.name) : data;
      if (data.trim()) {
        this.filteredCountryList = this.countryList.filter((val) =>
          val.name.toLowerCase().includes(data.toLowerCase())
        );
      } else {
        this.filteredCountryList = this.countryList;
      }
    });
  }

  get isNetwork(): boolean {
    return this.auth.isNetwork;
  }

  displayFn(value) {
    return value && value.name ? value.name : '';
  }

  onSubmit() {
    const {
      firstname,
      lastname,
      email,
      password,
      confirmPassword,
      country,
      company,
      partner_type
    } = this.registerForm.value;

    if (typeof country === 'string') {
      this.registerForm.controls.country.setErrors({
        serverError: 'You must select a country from the list'
      });
      return;
    }
    if (password !== confirmPassword) {
      this.notify.openSnackBar(
        'Password is not matching, please check and try again',
        'Dismiss'
      );
      return;
    }
    const body: RegisterRequest = {
      first_name: firstname,
      last_name: lastname,
      email,
      password,
      company_name: company,
      country_code: country.code,
      account_id: this.auth.accountId,
      mobile: null,
      partner_type
    };

    this.registerForm.disable();
    this.auth.register(body).subscribe(
      (response) => {
        this.helper.handleSuccessText(response);
        setTimeout(() => {
          this.route.navigateByUrl('/registration_success');
        }, 300);
      },
      () => {
        this.registerForm.enable();
      }
    );
  }

  get environment() {
    return environment;
  }
}
