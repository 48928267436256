import { SPACE } from '@angular/cdk/keycodes';
import {
  Component,
  Input,
  Output,
  EventEmitter,
  AfterContentInit,
  OnChanges,
  SimpleChanges,
  forwardRef,
  ViewChild,
  OnInit
} from '@angular/core';
import { UntypedFormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import isEqual from 'lodash/isEqual';
import FormField from 'src/app/_utilities/form-field';

@Component({
  selector: 'app-searchable-single-select',
  templateUrl: './searchable-single-select.component.html',
  styleUrls: ['./searchable-single-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SearchableSingleSelectComponent)
    }
  ]
})
export class SearchableSingleSelectComponent
  extends FormField
  implements OnInit, AfterContentInit, OnChanges
{
  private _disabledOptions: any[] = [];
  searchCtrl: UntypedFormControl = new UntypedFormControl();
  clonedValues: any[] = [];
  // output
  @Output() selectionChange = new EventEmitter();
  @Output() addNew = new EventEmitter();

  availableOptions: any[] = [];
  @Input() options: any[] = [];

  @Input() selectLabel = '';
  @Input() nameKey = '';
  @Input() valueKey = '';
  @Input() selectedValue: any = null;
  @Input() capitalizeLabel: boolean = null;
  @Input() addOption: boolean = null;
  @Input() limit: number;
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() loader: boolean;
  @Input() hint: string;
  @Input() cssClass: string;
  @Input() set disabledOptions(options: any[]) {
    this._disabledOptions = options;
  }

  get disabledOptions() {
    return this._disabledOptions;
  }

  @Input() displayFn: (value: any) => string;

  @Input() set placeholder(value: string) {
    this.selectLabel = value;
  }

  @ViewChild('select', { static: true }) select: any;

  constructor() {
    super();
  }

  override writeValue(value: any): void {
    this.selectedValue = value;
  }

  ngAfterContentInit(): void {
    if (this.availableOptions) {
      this.clonedValues = this.availableOptions;
    }
    if (this.selectedValue) {
      this.selectionChange.emit(this.selectedValue);
    }
    this.searchCtrl.valueChanges.subscribe((data) => {
      if (data) {
        this.clonedValues = this.availableOptions.filter((val) =>
          val[this.nameKey].toLowerCase().includes(data.toLowerCase())
        );
      } else {
        this.clonedValues = this.availableOptions;
      }
    });
  }

  isOptionDisabled(option: any) {
    return this.disabledOptions.some((d) => d === option[this.valueKey]);
  }

  ngOnInit(): void {
    this.select._handleKeydown = (event: KeyboardEvent) => {
      if (event.keyCode == SPACE) return;
      if (!this.select.disabled) {
        this.select.panelOpen
          ? this.select._handleOpenKeydown(event)
          : this.select._handleClosedKeydown(event);
      }
    };

    if (this.limit > 0 && this.availableOptions?.length > this.limit) {
      this.availableOptions = this.options.slice(0, this.limit - 1);
    } else {
      this.availableOptions = this.options;
    }
  }

  emitAddNewEvent() {
    this.addNew.emit();
  }

  onValueChange(event) {
    this.selectedValue = event.value;
    this.selectionChange.emit(this.selectedValue);
    this.onChange(this.selectedValue);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.options &&
      !isEqual(changes.options.currentValue, changes.options.previousValue)
    ) {
      this.availableOptions = changes.options.currentValue;
      this.clonedValues = this.availableOptions;
    }
    if (changes?.selectedValue?.currentValue) {
      this.selectedValue = changes.selectedValue.currentValue;
    }
  }
}
