import {
  Component,
  Input,
  AfterViewInit,
  NgZone,
  ViewChild,
  ElementRef,
  HostListener
} from '@angular/core';
import { v4 as uuidv4 } from 'uuid';
import {
  ArcElement,
  BarController,
  BarElement,
  CategoryScale,
  Chart,
  ChartConfiguration,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PieController,
  PointElement,
  Tooltip,
  Filler
} from 'chart.js';

Chart.register(
  PieController,
  LineController,
  BarController,
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement,
  LineElement,
  Tooltip,
  Legend,
  Filler
);

@Component({
  selector: 'app-chart-renderer',
  templateUrl: './chart-renderer.component.html',
  styleUrls: ['./chart-renderer.component.scss']
})
export class ChartRendererComponent implements AfterViewInit {
  @ViewChild('canvasRef') canvasRef: ElementRef<HTMLCanvasElement>;

  @HostListener('window:resize', ['$event'])
  onResize() {
    // this.chartInstance?.reset();
  }

  @Input() chartId: string = uuidv4();
  @Input() set config(chartConfiguration: ChartConfiguration) {
    if (chartConfiguration) {
      this.chartConfiguration = chartConfiguration;
      this.initializeChart(this.chartConfiguration);
    } else {
      this.chartConfiguration = null;
    }
  }

  chartConfiguration: ChartConfiguration;
  private chartInstance: any;
  constructor(private zone: NgZone) { }

  ngAfterViewInit(): void {
    this.initializeChart(this.chartConfiguration);
  }

  initializeChart(chartConfiguration: ChartConfiguration) {
    if (
      !chartConfiguration ||
      !this.chartId ||
      !this.canvasRef?.nativeElement
    ) {
      return;
    }

    if (this.chartInstance) {
      this.chartInstance?.destroy();
    }

    this.zone.runOutsideAngular(() => {
      this.chartInstance = new Chart(this.canvasRef.nativeElement, {
        ...chartConfiguration,
        options: {
          ...chartConfiguration?.options,
          responsive: true,
          plugins: {
            ...chartConfiguration?.options?.plugins,
            legend: {
              ...chartConfiguration?.options?.plugins?.legend,
              ...(chartConfiguration.type === 'scatter'
                ? {
                  onClick: (event, legendItem, legend) => {
                    if ('datasetIndex' in legendItem) {
                      const index = legendItem.datasetIndex;
                      const dataSetMeta = legend.chart.getDatasetMeta(index);
                      const hidden = !dataSetMeta.hidden;
                      const dataSetMetaForNextIndex =
                        legend.chart.getDatasetMeta(index + 1);

                      dataSetMeta.hidden = hidden;

                      if (dataSetMetaForNextIndex) {
                        dataSetMetaForNextIndex.hidden = hidden;
                      }
                    } else {
                      // will find a way to handle pie toggle later
                    }

                    legend.chart.update();
                  }
                }
                : {})
            }
          }
        }
      });
    });
  }
}
