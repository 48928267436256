import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ColumnTypes } from 'src/app/_utilities/table-data';

@Component({
  selector: 'app-nested-field',
  templateUrl: './nested-field.component.html',
  styleUrls: ['./nested-field.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NestedFieldComponent {
  dataSource: unknown[] = [];
  displayedColumns: string[] = ['key', 'value'];
  @Input() set value(val: unknown) {
    this.createDataSource(val);
    setTimeout(() => { this.cdr?.detectChanges() }, 50)
  }

  constructor(private cdr: ChangeDetectorRef) { }

  createDataSource(val: unknown) {
    if (Array.isArray(val)) {
      this.dataSource = val.map(
        (data: { key: string; value: unknown; type: unknown }) => {
          return {
            ...data,
            type: this.getType(data?.value)
          };
        }
      );
    } else {
      if (val && typeof val === 'object') {
        this.dataSource = Object.keys(val).map((column) => {
          return {
            key: column,
            value: val[column],
            type: this.getType(val[column])
          };
        });
      }
    }
  }

  getType(data: unknown) {
    if (Array.isArray(data) && typeof data[0] === 'object') {
      return ColumnTypes.TABLE;
    }
    if (!Array.isArray(data) && typeof data === 'object') {
      return ColumnTypes.NESTED;
    }

    return ColumnTypes.TEXT;
  }
}
