import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';

@Injectable({ providedIn: 'root' })
export class PwaService {
  constructor(swUpdate: SwUpdate, snackBar: MatSnackBar) {
    swUpdate.versionUpdates.subscribe((event) => {
      console.log('version update', event);
      if (event?.type === 'VERSION_DETECTED') {
        snackBar
          .open(
            'App update Available, reload to get the latest version',
            'Reload'
          )
          .onAction()
          .subscribe(() => {
            window.location.reload();
          });
      }
    });
  }
}
