<div class="expanded-view bg-white" *ngIf="element?.report">
  <div class="f-col py-2 pl-3" *ngIf="element?.report">
    <app-two-column-table
      [dataSource]="detailsDataSource"
      layout="profile"></app-two-column-table>

    <ng-container *ngFor="let section of sectionDetails">
      <app-text-line type="base" weight="strong">{{
        section?.label | capitalize
      }}</app-text-line>

      <ng-container *ngIf="section?.label === 'movement'">
        <div class="row">
          <div class="col-4">
            <app-two-column-table
              [dataSource]="getTwoColDS(section?.data)"
              layout="table"></app-two-column-table>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="section?.label === 'images'">
        <ul class="images-list f-row wrap">
          <li class="bordered hoverable" *ngFor="let item of section?.data">
            <a [href]="item" target="_blank">
              <img [src]="item" alt="PhysioX appointment image" />
            </a>
          </li>
        </ul>
      </ng-container>
    </ng-container>
  </div>
</div>
