import { Component } from '@angular/core';
import { STORAGE_KEY } from '../../../_enums';
import { StorageService } from '@services/storage.service';
import { AuthService } from '@services/auth.service';
import { EmailBody } from 'src/app/_interfaces';

@Component({
  selector: 'app-registration-success',
  templateUrl: './registration-success.component.html',
  styleUrls: ['./registration-success.component.css']
})
export class RegistrationSuccessComponent {
  loader = false;
  constructor(private auth: AuthService) {}

  resendEmail() {
    const body: EmailBody = {
      email: StorageService.getLocalItem(STORAGE_KEY.RESENDEMAIL)
    };

    this.loader = true;
    this.auth.resendVerificationLink(body).subscribe((res) => {
      this.loader = false;
      if (res) {
        localStorage.removeItem(STORAGE_KEY.RESENDEMAIL);
      }
    });
  }
}
