import { PipeTransform, Pipe } from '@angular/core';
import translations from '../../assets/data/infoMessage.json';

@Pipe({
  name: 'translate',
  pure: false
})
export class TranslatePipe implements PipeTransform {
  private translations: any = translations;
  transform(code: string, type: string): string {
    if (this.translations[code]) {
      return this.translations[code][type];
    }
    return null;
  }
}
