<div fxFill class="position-relative center" fxLayout="column" fxLayoutAlign="start center">
  <div class="load-container" *ngIf="loader">
    <mat-spinner></mat-spinner>
  </div>
  <div class="ht4 center mt-4" fxFlex="60%">
    <section class="p-3 pt-5" fxLayout="column" fxLayoutAlign="center center" *ngIf="!loader && !success">
      <span class="d-flex justify-content-center w-100">
        <mat-icon class="icon-xl mb-2" fontSet="mso" color="warn">warning</mat-icon>
      </span>
      <h2 class="text-center mat-h2 text-muted mb-2">
        The link you're trying to access is either invalid or expired
      </h2>
      <!-- <span class="text-center mat-h3 ml-auto mr-auto"></span> -->
      <div class="d-flex justify-content-center w-100">
        <button mat-raised-button color="primary" routerLink="/login">
          <mat-icon fontSet="mso" class="mr-2">login</mat-icon>
          <span>Login</span>
        </button>
      </div>
    </section>
    <section class="p-3 pt-5" fxLayout="column" fxLayoutAlign="center center" *ngIf="!loader && success">
      <app-null-state icon="person_check" fontset="mso" size="30" [message]="{
          line1: 'Congrats! Your account is now verified',
          line2: 'Set your password now to login'
        }">
        <button mat-raised-button color="primary" routerLink="/reset/password" [queryParams]="{ token: token }">
          Set password
        </button>
      </app-null-state>
    </section>
  </div>
</div>