export const environment = {
  terms_of_use: 'https://appcarry.com/company/terms-of-services',
  privacy_policy: 'https://appcarry.com/company/privacy-policy',
  production: true,
  domain: 'appcarry.com',
  base_url: 'https://performanceapi.appcarry.com/v1/',
  paymentProvider: 'razorpay',
  storageExpiry: 86400000,
  networkOrBrandDomain: 'https://xeniumnetwork.appcarry.com',
  pusherConfig: {
    APP_ID: '1559727',
    APP_KEY: '72e02779fc820a7f105d',
    APP_SECRET: 'b30cc916a20828acfd14',
    APP_CLUSTER: 'ap2'
  },
  locale: 'en-IN',
  premiumFeatures: [
    'advanced-report',
    'smart-link',
    'smart-alerts',
    'offer-check',
    'sampling',
    'landing-pages',
    'ip-whitelisting'
  ],
  currency: 'USD',
  currencySymbol: '$'
};
