import { NgModule } from '@angular/core';

import { FactoryModule } from '@modules/factory/factory.module';
import { EmailVerifiedComponent } from './email-verified/email-verified.component';
import { LoginRegisterRoutingModule } from './login-register-routing.module';
import { LoginComponent } from './login/login.component';
import { MemberVerifiedComponent } from './member-verified/member-verified.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { PasswordResetComponent } from './password-reset/password-reset.component';
import { RegisterComponent } from './register/register.component';
import { RegistrationSuccessComponent } from './registration-success/registration-success.component';
import { OfficialLinksComponent } from './official-links/official-links.component';
import { ProfileComponent } from './profile/profile.component';

@NgModule({
  declarations: [
    LoginComponent,
    NotFoundComponent,
    RegisterComponent,
    PasswordResetComponent,
    RegistrationSuccessComponent,
    EmailVerifiedComponent,
    MemberVerifiedComponent,
    OfficialLinksComponent,
    ProfileComponent
  ],
  exports: [
    LoginComponent,
    NotFoundComponent,
    RegisterComponent,
    PasswordResetComponent,
    RegistrationSuccessComponent,
    EmailVerifiedComponent,
    MemberVerifiedComponent,
    ProfileComponent
  ],
  imports: [LoginRegisterRoutingModule, FactoryModule]
})
export class LoginRegisterModule {}
