import { Component, Inject } from '@angular/core';
import {
  UntypedFormBuilder,
  FormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Roles, StatusType, UserResponse } from '@interfaces/global';
import { DropdownOption } from '@modules/campaign/campaign-constants';
import { TeamService } from '@modules/team/services/team.service';
import { MemberPermissionService } from '@services/member-permission.service';
import { NotifyService } from '@services/notify.service';
import { SharedService } from '@services/shared.service';
import FormDataComponent from '@utilities/form-data.component';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-assign-roles-dialog',
  templateUrl: './assign-roles-dialog.component.html',
  styleUrls: ['./assign-roles-dialog.component.scss']
})
export class AssignRolesDialogComponent extends FormDataComponent<any> {
  form: UntypedFormGroup;
  users$: Observable<UserResponse[]> = this.teamService
    .getTeamMembers()
    .pipe(switchMap((res) => of(res.data.data)));
  roles$: Observable<Roles[]> = this.memberPermissionService
    .getRoles()
    .pipe(switchMap((res) => of(res.data.data)));

  override method = this.teamService.assignRole.bind(this.teamService);

  constructor(
    shared: SharedService,
    router: Router,
    route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private dialogRef: MatDialogRef<AssignRolesDialogComponent>,
    private teamService: TeamService,
    private memberPermissionService: MemberPermissionService,
    private notify: NotifyService,
    @Inject(MAT_DIALOG_DATA)
    public data: { role_id: number; user_id: number }
  ) {
    super(shared, router, route, notify);
    this.autoclose = false;
    this.notifyUser = true;
    const { role_id, user_id } = data || {};

    this.form = this.fb.group({
      user_id: [user_id, Validators.required],
      role_permissions_id: [role_id, Validators.required]
    });

    this.formDataSaved.subscribe(() => {
      this.close(true);
    });
  }

  close(success = false) {
    this.dialogRef.close(success);
  }
}
