import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@modules/factory/confirmation-dialog/confirmation-dialog.component';
import { ConfirmDialogOptions } from '../_interfaces';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationService {
  default: ConfirmDialogOptions = {
    message: 'Are you sure you wish to proceed?',
    successLabel: 'Yes',
    cancelLabel: 'No',
    preventOuterClick: false,
    data: null
  };

  constructor(private dialog: MatDialog) { }

  openDialog(dialogOptions: ConfirmDialogOptions = null) {
    const { cancelLabel, message, successLabel, data, preventOuterClick, inputConfig } = dialogOptions || {};

    return this.dialog.open(ConfirmationDialogComponent, {
      width: '450px',
      data: {
        message: message || this.default.message,
        successLabel: successLabel || this.default.successLabel,
        cancelLabel: cancelLabel || this.default.cancelLabel,
        preventOuterClick: preventOuterClick || this.default.preventOuterClick,
        data: data || this.default.data,
        inputConfig
      }
    });
  }
}
