<ng-container *ngIf="loader || !hasData">
  <div class="w-100 h-100" [ngClass]="classes">
    <div *ngIf="loader">
      <app-skeleton-previews [type]="loaderType" width="100%">
      </app-skeleton-previews>
    </div>

    <section *ngIf="!loader" class="p-2">
      <span class="d-flex justify-content-center w-100 mb-3">
        <mat-icon [ngClass]="[iconSize, iconColor]" [fontSet]="fontSet">{{ icon }}</mat-icon>
      </span>

      <app-text-line weight="mb-3" class="text-center" type="leading" [enableWrap]="true" *ngIf="message?.line1">{{
        message.line1 }}
      </app-text-line>

      <app-text-line class="text-center" weight="mb-3" textColor="secondary" type="base" [enableWrap]="true"
        *ngIf="message?.line2">{{ message.line2 || '' }}</app-text-line>

      <div class="d-flex justify-content-center w-100">
        <ng-container *ngIf="buttonData; else customContent">
          <button mat-raised-button color="primary" (click)="addNewLink()">
            <mat-icon fontSet="mso">{{ buttonData.icon }}</mat-icon>
            <span>{{ buttonData.label }}</span>
          </button>
        </ng-container>
        <ng-template #customContent>
          <ng-content></ng-content>
        </ng-template>
      </div>
    </section>
  </div>
</ng-container>