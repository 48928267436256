<table mat-table [dataSource]="dataSource" class="table-vertical mb-2">
  <ng-container matColumnDef="key" sticky>
    <td mat-cell *matCellDef="let element" class="cp">
      <app-text-line weight="strong">{{
        element.key | replaceUnderscore
      }}</app-text-line>
    </td>
  </ng-container>

  <ng-container matColumnDef="value" sticky>
    <td mat-cell *matCellDef="let element">
      <app-custom-cell
        [type]="element?.type"
        [value]="element?.value"></app-custom-cell>
    </td>
  </ng-container>

  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
