<h2 mat-dialog-title>Change status</h2>
<mat-dialog-content>
  <mat-form-field appearance="outline" class="w-100">
    <mat-label>Set status</mat-label>
    <mat-select [formControl]="changeStatusControl">
      <mat-option *ngFor="let type of statuses | async" [value]="type.value">{{ type.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button (click)="close()">Cancel</button>
  <button [class.spinner]="loader" cdkFocusInitial mat-raised-button color="primary"
    [disabled]="!changeStatusControl.valid || loader" (click)="changeStatus()">
    Save
  </button>
</mat-dialog-actions>