import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PartnerResponse } from '@interfaces/global';
import { UserType } from '@interfaces/user';
import { PartnerService } from '@modules/partners/services/partner.service';
import { TeamService } from '@modules/team/services/team.service';
import { SharedService } from '@services/shared.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-global-profile',
  templateUrl: './global-profile.component.html',
  styleUrls: ['./global-profile.component.scss']
})
export class GlobalProfileComponent implements OnInit {
  loader: boolean;
  profileData: PartnerResponse;
  memberId: number;
  type: UserType;
  constructor(
    private route: ActivatedRoute,
    private team: TeamService,
    private partner: PartnerService,
    private shared: SharedService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(({ memberId, type }) => {
      this.memberId = memberId;
      this.type = type;
      if (memberId && type) {
        if (type === UserType.TeamMember) {
          this.getProfileDetails(memberId);
        } else {
          this.getPartnerProfile(memberId, type);
        }
      }
    });

    this.shared.componentTitle = this.route.snapshot.data?.title;
  }

  getPartnerProfile(memberId: number, type: any) {
    this.loader = true;
    this.partner
      .getPartnerDetails(type, memberId)
      .pipe(
        finalize(() => {
          this.loader = false;
        })
      )
      .subscribe((data) => {
        this.profileData = data?.data;
      });
  }

  getProfileDetails(memberId: number) {
    this.loader = true;
    this.team
      .getUserDetails(memberId)
      .pipe(
        finalize(() => {
          this.loader = false;
        })
      )
      .subscribe((data) => {
        const { _id, id, status, email, first_name, last_name, phone } =
          data?.data || {};

        this.profileData = {
          _id,
          company_name: '',
          status,
          website: '',
          id,
          email,
          first_name,
          last_name,
          phone,
          user_type: UserType.TeamMember
        };
      });
  }

  get fullName() {
    const { first_name, last_name } = this.profileData || {};

    return first_name ? `${first_name} ${last_name}` : '';
  }
}
