import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  Renderer2,
  SimpleChanges
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, throttleTime } from 'rxjs/operators';

export interface HeightConfig {
  value: number;
  type: 'max' | 'min' | '';
  viewportHeight?: boolean;
}

@Directive({
  selector: '[fluidHeight]'
})
export class FluidHeightDirective implements AfterViewInit, OnChanges, OnDestroy {
  @Input('fluidHeight') heightConfig: HeightConfig;
  // @HostBinding('style.overflow-y') overflowY = 'auto';

  private domElement: HTMLElement;
  private resizeSub: Subscription;

  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef
  ) {
    // get ref HTML element
    this.domElement = this.elementRef.nativeElement as HTMLElement;

    // register on window resize event
    this.resizeSub = fromEvent(window, 'resize')
      .pipe(throttleTime(500), debounceTime(500))
      .subscribe(() => this.setHeight());
  }

  ngAfterViewInit() {
    this.setHeight();
  }

  ngOnDestroy() {
    this.resizeSub.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.heightConfig && !changes.heightConfig.firstChange) {
      this.setHeight();
    }
  }

  private setHeight() {
    this.renderer.setStyle(
      this.domElement,
      `${this.heightConfig.type ? this.heightConfig.type + '-' : ''}height`,
      `calc(100${this.heightConfig?.viewportHeight ? 'vh' : '%'} - ${this.heightConfig?.value
      }px)`
    );
  }
}
