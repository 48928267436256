import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifyService } from '@services/notify.service';
import { SharedService } from '@services/shared.service';
import FormDataComponent from '@utilities/form-data.component';
import { CustomReportRequest, CustomReportService } from '../custom-report.service';

@Component({
  selector: 'app-custom-report-dialog',
  templateUrl: './custom-report-dialog.component.html',
  styleUrl: './custom-report-dialog.component.scss'
})
export class CustomReportDialogComponent extends FormDataComponent<CustomReportRequest> implements OnInit {
  override form: UntypedFormGroup;
  override method = this.saveOrUpdateCustomReport.bind(this);

  constructor(
    private shared: SharedService,
    router: Router,
    route: ActivatedRoute,
    notify: NotifyService,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: CustomReportRequest,
    private customReportService: CustomReportService,
    private dialogRef: MatDialogRef<CustomReportDialogComponent>
  ) {
    super(shared, router, route, notify);
    this.shared.formDataSavedState = false;
  }

  initForm() {
    this.form = this.fb.group({
      name: [this.data?.name || '', [Validators.required]],
      description: [this.data?.description || ''],
      configuration: [this.data?.configuration || null]
    });
  }

  saveOrUpdateCustomReport(data: CustomReportRequest) {
    return this.customReportService.createCustomReport(data, this.data?._id);
  }

  ngOnInit(): void {
    this.initForm();
    this.formDataSaved.subscribe((data) => {
      this.dialogRef.close(data);
    })
  }

}
