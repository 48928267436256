import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundSectionComponent } from '@modules/factory/not-found-section/not-found-section.component';
import { EmailVerifiedComponent } from '@modules/login-register/email-verified/email-verified.component';
import { LoginComponent } from '@modules/login-register/login/login.component';
import { MemberVerifiedComponent } from '@modules/login-register/member-verified/member-verified.component';
import { PasswordResetComponent } from '@modules/login-register/password-reset/password-reset.component';
import { ProfileComponent } from '@modules/login-register/profile/profile.component';
import { RegisterComponent } from '@modules/login-register/register/register.component';
import { RegistrationSuccessComponent } from '@modules/login-register/registration-success/registration-success.component';
import { AuthGuard } from '@services/auth.guard';
import { LoginGuard } from '@services/login.guard';
import { CustomPreloadingStrategyService } from '@utilities/custom-preloading-strategy.service';
import { FindMyAccountComponent } from './_components/find-my-account/find-my-account.component';
import { FindAccountGuard } from './_guards/find-account.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard],
    data: { primary: 'Login', id: 'login' }
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [LoginGuard],
    data: { primary: 'Registration', id: 'register' }
  },
  {
    path: 'registration_success',
    canActivate: [LoginGuard],
    component: RegistrationSuccessComponent,
    data: { primary: 'Registration success' }
  },
  {
    path: 'email/confirm',
    canActivate: [LoginGuard],
    component: EmailVerifiedComponent,
    data: { primary: 'Confirm email' }
  },
  {
    path: 'member/confirm',
    canActivate: [LoginGuard],
    component: MemberVerifiedComponent,
    data: { primary: 'Confirm member' }
  },
  {
    path: 'reset/password',
    canActivate: [LoginGuard],
    component: PasswordResetComponent,
    data: { primary: 'Reset password' }
  },
  {
    path: 'profile',
    component: ProfileComponent,
    data: { primary: 'Reset password' },
    canActivate: [AuthGuard]
  },
  {
    path: 'network',
    loadChildren: () =>
      import('@modules/network/network.module').then((m) => m.NetworkModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'mock',
    loadChildren: () =>
      import('@modules/mock-layout/mock-layout.module').then(
        (m) => m.MockLayoutModule
      ),
    canActivate: [AuthGuard]
  },
  {
    path: 'campaigns',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@modules/campaign/campaign.module').then((m) => m.CampaignModule),
    data: { primary: 'Campaigns' }
  },
  {
    path: 'reports',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@modules/reports/reports.module').then((m) => m.ReportsModule),
    data: { primary: 'Reports' }
  },
  {
    path: 'partners',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@modules/partners/partners.module').then((m) => m.PartnersModule),
    data: { primary: 'Partners' }
  },
  {
    path: 'billing',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@modules/billing/billing.module').then((m) => m.BillingModule),
    data: { primary: 'Billing details' }
  },
  {
    path: 'customization',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@modules/customization/customization.module').then(
        (m) => m.CustomizationModule
      ),
    data: { primary: 'Customization' }
  },
  {
    path: 'postback',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@modules/postback/postback.module').then((m) => m.PostbackModule),
    data: { primary: 'Postback' }
  },
  {
    path: 'fraud',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@modules/fraud-detection/fraud-detection.module').then((m) => m.FraudDetectionModule),
    data: { primary: 'Fraud detection' }
  },
  {
    path: 'automation',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@modules/automation/automation.module').then(
        (m) => m.AutomationModule
      ),
    data: { primary: 'Automation' }
  },
  {
    path: 'logs',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@modules/app-logs/logs.module').then((m) => m.LogsModule),
    data: { primary: 'Logs' }
  },
  {
    path: 'partner-billing',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('@modules/payments/payments.module').then((m) => m.PaymentsModule),
    data: { primary: 'Partner billing' }
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    outlet: 'side',
    loadChildren: () =>
      import('@modules/settings/settings.module').then((m) => m.SettingsModule),
    data: { primary: 'Settings' }
  },
  {
    path: 'toolbox',
    canActivate: [AuthGuard],
    outlet: 'side',
    loadChildren: () =>
      import('@modules/toolbox/toolbox.module').then((m) => m.ToolboxModule),
    data: { primary: 'Toolbox' }
  },
  {
    path: 'find-my-account',
    component: FindMyAccountComponent,
    canActivate: [FindAccountGuard]
  },
  { path: 'unauthorized', component: NotFoundSectionComponent },
  { path: 'page-not-found', component: NotFoundSectionComponent },
  { path: '**', redirectTo: 'page-not-found', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // enableTracing: !environment.production,
      preloadingStrategy: CustomPreloadingStrategyService
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
