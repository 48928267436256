import { DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ViewChildren,
  ElementRef
} from '@angular/core';
import { MatProgressBar } from '@angular/material/progress-bar';
import { LoaderService } from '@services/loader.service';
import { SharedService } from '@services/shared.service';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'app-global-loader',
  templateUrl: './global-loader.component.html',
  styleUrls: ['./global-loader.component.css']
})
export class GlobalLoaderComponent implements AfterViewInit {
  loading: Observable<boolean>;
  color = '';
  mode = 'indeterminate';
  value = 0;
  bufferValue = 40;
  brandColor: Observable<string>;

  @ViewChildren('progress') progress: ElementRef<MatProgressBar>;

  constructor(
    private loaderService: LoaderService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    this.loaderService.isLoading.subscribe((val) => {
      this.loading = of(val);
      this.cdRef.detectChanges();
    });
  }
}
