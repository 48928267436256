import {
  HttpClient,
  HttpEvent,
  HttpHeaders,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import get from 'lodash/get';

import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { PageEvent } from '@angular/material/paginator';
import { TwoFactorAuthDialogComponent } from '@modules/login-register/profile/two-factor-auth-dialog/two-factor-auth-dialog.component';
import { environment } from '../../environments/environment';
import {
  AdditionalReportsMenu,
  AdvertiserReports,
  BillingStatus,
  GeneralReportsMenu,
  MenuList,
  NOLIMIT,
  PublisherReports,
  STORAGE_KEY,
  TrackingReportsMenu
} from '../_enums';
import {
  AccountType,
  AccountTypeModules,
  ApiResponse,
  BasePermission,
  BrandingResponse,
  CRUD,
  ChangePassword,
  EmailBody,
  EmailVerify,
  FindAccountRequest,
  ForgetPasswordBody,
  LoginRequest,
  LoginResponse,
  MemberVerify,
  MenuGroup,
  ModuleAccess,
  ModuleNames,
  ModulePermission,
  NavigationMenuItem,
  PaginatedResponse,
  PremiumFeatureKeys,
  PremiumFeatures,
  RegisterRequest,
  ResetPasswordBody,
  TwoFactorLoginRequest,
  TwoFactorResponse,
  UserJWT,
  UserType
} from '../_interfaces';
import { ModuleStoreClass } from './_permissions/module-store.class';
import { AccountTypePermissions, ModulePermissionMap, hasRequiredPermission, menuListSanitizer, premiumFeatureSanitizer } from './_permissions/static-permission-definition';
import { HelperService } from './helper.service';
import { StorageService } from './storage.service';

export interface AccessObject {
  userType?: UserType;
  accountType?: AccountType;
  restricted: string[];
  allowed: string[];
}

export enum AccessState {
  Grant = 'grant',
  Revoke = 'revoke',
  Check = 'check'
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private modulePermissionStateValue: ModulePermissionMap<ModuleNames>[] = [];
  private modulePermissionState$ = new BehaviorSubject<ModulePermissionMap<ModuleNames>[]>([]);
  private readonly navigationMenus = MenuList;
  private currentUser: BehaviorSubject<LoginResponse> = new BehaviorSubject(
    null
  );
  public currentUserObs = this.currentUser.asObservable();
  private current2FAToken$: BehaviorSubject<string> = new BehaviorSubject(null);
  public current2FAToken = this.current2FAToken$.asObservable();

  private reportMenuItems$ = new BehaviorSubject<MenuGroup[]>([]);
  public reportMenuItems = this.reportMenuItems$.asObservable();
  private set reportMenuItemsState(value: MenuGroup[]) {
    this.reportMenuItems$.next(value);
  }

  private moduleAccessData: BehaviorSubject<ModuleAccess> = new BehaviorSubject(
    {
      hidden: [],
      disabled: [],
      enabled: [
        ModuleNames.Campaign,
        ModuleNames.Advertiser,
        ModuleNames.Publisher,
        ModuleNames.RoleManagement,
        ModuleNames.Billing,
        ModuleNames.Team,
        ModuleNames.Category,
        ModuleNames.PromotionMethods,
        ModuleNames.Customization,
        ModuleNames.Reports,
        ModuleNames.Automations,
        ModuleNames.Sampling
      ]
    }
  );

  private contentPermission$: BehaviorSubject<ModulePermission[]> =
    new BehaviorSubject([]);
  private menus$: BehaviorSubject<NavigationMenuItem[]> = new BehaviorSubject([]);
  public menuListSub = this.menus$.asObservable();

  public httpOptions;
  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private dialog: MatDialog,
    private helper: HelperService,
    private router: Router,
  ) {
    // this.setHeader();
    this.setCurrentUserFromStorage();
    this.modulePermissionState$.subscribe((permissions) => {
      this.modulePermissionStateValue = permissions;
    });
  }

  setCurrentUserFromStorage() {
    const userData: LoginResponse = this.storageService.getUserData();

    if (userData) {
      this.setUserSubject(userData);
    }
  }

  get isPaymentOverdue(): boolean {
    return Boolean(
      this.currentUserValue?.billing_status === BillingStatus.Overdue
    );
  }

  public hasFeatureAccess(feature: PremiumFeatureKeys) {
    const foundFeature: PremiumFeatures =
      this.currentUserValue?.premium_features?.find((f) => f.url === feature);

    return foundFeature && foundFeature?.available_limit > 0;
  }

  public isPremiumFeature(feature: PremiumFeatureKeys) {
    const foundFeature: PremiumFeatureKeys = environment.premiumFeatures?.find(
      (f) => f === feature
    ) as PremiumFeatureKeys;

    return !!foundFeature;
  }

  public get ModuleAccessData() {
    return this.moduleAccessData.getValue();
  }

  public get userType(): UserType {
    const parsed: UserJWT = this.parseJwt(
      this.currentUserValue?.authorization?.token
    );

    return parsed?.user_type as UserType;
  }

  public get partnerId() {
    return this.currentUserValue?.company?._id || null;
  }

  public get relativePartnerId() {
    return this.currentUserValue?.id || null;
  }

  public get accountType(): AccountType {
    const parsed: UserJWT = this.parseJwt(this.currentUserToken);

    return parsed?.account_type as AccountType;
  }

  public get currentUserValue(): LoginResponse {
    return this.currentUser.getValue();
  }

  public get currentUserToken(): string {
    const response: LoginResponse = this.currentUserValue;

    return get(response?.authorization, 'token', '');
  }

  public get contentPermissions(): ModulePermission[] {
    return this.contentPermission$.getValue();
  }

  public set contentPermissions(permission: ModulePermission[]) {
    this.contentPermission$.next(permission);
  }

  /**
   * Updating token in the locally stpred user data
   */
  public set currentUserToken(token: string) {
    const userData: LoginResponse = this.storageService.getUserData();

    if (token && userData) {
      userData.authorization.token = token;
      this.storageService.setUserData(userData);
    }
  }

  public get isNetwork(): boolean {
    const { origin } = new URL(window.location.origin);
    const branding = StorageService.getLocalItem(
      environment.production ? origin : environment.networkOrBrandDomain
    ) as BrandingResponse;

    return branding?.company_type === 'network';
  }

  public get accountId(): number {
    const { origin } = new URL(window.location.origin);
    const branding = StorageService.getLocalItem(
      environment.production ? origin : environment.networkOrBrandDomain
    ) as BrandingResponse;

    return branding?.account_id;
  }

  /**
   * Setting header before initiating an http request
   * @param contentType desired content type
   */
  private setHeader(contentType = 'application/json') {
    let $headers = new HttpHeaders();

    $headers = $headers.append('Accept', contentType);
    this.httpOptions = { headers: $headers };
  }

  public hasAccountTypeModuleAccess(module: AccountTypeModules) {
    return AccountTypePermissions[this.accountType]?.indexOf(module) > -1;
  }

  register(body: RegisterRequest): Observable<HttpEvent<ApiResponse<null>>> {
    return this.http.post<ApiResponse<null>>(
      `${environment.base_url}partner/register`,
      { ...body },
      this.httpOptions
    );
  }

  login(body: LoginRequest): Observable<ApiResponse<(LoginResponse | TwoFactorResponse)>> {
    this.removeLocalData();
    return this.http
      .post<ApiResponse<(LoginResponse | TwoFactorResponse)>>
      (`${environment.base_url}auth/login`, { ...body })
      .pipe(
        tap((response: ApiResponse<(LoginResponse | TwoFactorResponse)>) => {
          if ((response?.data as TwoFactorResponse).two_f_token !== undefined) {
            this.current2FAToken$.next((response?.data as TwoFactorResponse).two_f_token);
          } else {
            this.handleLoginResponse(response as ApiResponse<LoginResponse>);
          }
        })
      );
  }

  handleLoginResponse(response: ApiResponse<LoginResponse>) {
    this.menuList = [];
    StorageService.removeLocalItem(STORAGE_KEY.PAYMENT_NOTIFICATION);
    this.storageService.setUserData(response?.data);
    this.setUserSubject(response.data);
    this.helper.handleSuccess(response);
    this.router.navigateByUrl('/network/dashboard');
    this.updateReportsMenu();
  }

  public updateReportsMenu() {
    this.getReportsMenuByUserType(this.userType, this.currentUserValue?._id)
      .pipe(tap((menu) => (this.reportMenuItemsState = menu)))
      .subscribe();
  }

  checkModuleRestriction(module: string) {
    return (
      this.ModuleAccessData?.disabled.findIndex(
        (moduleName) => moduleName?.toLowerCase() === module?.toLowerCase()
      ) > -1
    );
  }

  isMenuHidden(restrictedUserType: string[]) {
    return restrictedUserType.indexOf(this.userType) > -1;
  }

  public filterByPermission<T>(options: Array<Partial<BasePermission>>): T {
    let hasPermission = false;
    return (options.filter((option) => {
      if (option?.permission?.includes('.')) {
        const [module, permission]: [ModuleNames, CRUD] = option?.permission?.split('.') as [ModuleNames, CRUD];
        hasPermission = hasRequiredPermission(module, [permission], this.modulePermissionStateValue);
      } else if (option?.permission) {
        console.error('Malformed permission value: ', option?.permission);
        hasPermission = false;
      } else {
        hasPermission = true;
      }

      return option?.permission
        ? hasPermission
        : true;
    }) || []) as T;
  }

  public filterByUserTypes(options: Array<Partial<BasePermission>>) {
    return (
      options.filter((option) => {
        const isRestricted =
          option?.restrictedUserType?.indexOf(this.userType) > -1;
        const noRestriction = !option?.restrictedUserType?.length;

        return !isRestricted || noRestriction;
      }) || []
    );
  }

  public filterByUserTypesAndPermission<T>(
    options: Array<Partial<BasePermission>>
  ): T {
    return this.filterByPermission(this.filterByUserTypes(options));
  }

  public sanitizeMenuList<T>(
    options: Array<Partial<BasePermission>>,
  ): T {
    return premiumFeatureSanitizer(menuListSanitizer(options, this.modulePermissionStateValue), this.userType) as T;
  }

  public set menuList(menus: NavigationMenuItem[]) {
    this.menus$.next(menus);
  }

  public get menuList(): NavigationMenuItem[] {
    return this.menus$.getValue();
  }

  public get apiKey() {
    return this.currentUserValue?.api_key;
  }

  public hasPermissionInline(appContentPermission: string): boolean {
    if (!appContentPermission) {
      return true;
    }

    const [module, permission]: [ModuleNames, CRUD] = appContentPermission?.split('.') as [ModuleNames, CRUD];
    const hasPermission = hasRequiredPermission(module, [permission], this.modulePermissionStateValue);

    return hasPermission;
  }

  public hasPermission(
    module: string,
    permission: CRUD,
    modulePermissions: ModulePermission[] = this.contentPermissions
  ): boolean {
    if (this.userType === UserType.Owner) {
      return true;
    }
    if (
      this.userType === UserType.TeamMember &&
      (module === ModuleNames.Tasks || module === ModuleNames.Messages)
    ) {
      return true;
    }

    const foundModule = modulePermissions.find((p) => p.module === module);

    if (!foundModule) {
      return false;
    }

    return foundModule.permissions.indexOf(permission) > -1;
  }

  isRestricted(
    moduleName: string,
    modulePermissions: ModulePermission[] = this.contentPermissions,
    checkSpecificPermission: CRUD
  ) {
    if (!checkSpecificPermission) {
      checkSpecificPermission = CRUD.Read;
    }
    return !this.hasPermission(
      moduleName?.toLowerCase(),
      checkSpecificPermission,
      modulePermissions
    );
  }

  handleToken(masterToken: string) {
    if (!StorageService.getLocalItem(STORAGE_KEY.MASTER_TOKEN)) {
      StorageService.setLocalItem(STORAGE_KEY.MASTER_TOKEN, masterToken);
    } else {
      this.destroyServerSession().subscribe();
    }
  }

  findAccount(body: FindAccountRequest) {
    return this.http.post(`${environment.base_url}account/search-brand`, body);
  }

  verifyEmail(body: EmailVerify): Observable<ApiResponse<null>> {
    body = { ...body, account_id: this.accountId };
    return this.http
      .post<ApiResponse<null>>(`${environment.base_url}auth/email/verify`, body)
      .pipe(
        tap((response: ApiResponse<null>) =>
          this.helper.handleSuccess(response)
        ),
        map((response: ApiResponse<null>) => {
          return response;
        })
      );
  }

  verifyMember(body: MemberVerify): Observable<ApiResponse<null>> {
    return this.http
      .post<
        ApiResponse<null>
      >(`${environment.base_url}auth/invitation/accept`, body)
      .pipe(
        tap((response: ApiResponse<null>) =>
          this.helper.handleSuccess(response)
        ),
        map((response: ApiResponse<null>) => {
          return response;
        })
      );
  }

  forgotPassword(body: ForgetPasswordBody): Observable<ApiResponse<null>> {
    body = { ...body, account_id: this.accountId };
    return this.http
      .post<
        ApiResponse<null>
      >(`${environment.base_url}auth/forgot/password`, body)
      .pipe(
        tap((response: ApiResponse<null>) =>
          this.helper.handleSuccess(response)
        ),
        map((response: ApiResponse<null>) => {
          return response;
        })
      );
  }

  resetPassword(body: ResetPasswordBody): Observable<ApiResponse<null>> {
    body = { ...body, account_id: this.accountId };
    return this.http
      .post<ApiResponse<null>>(`${environment.base_url}auth/set/password`, body)
      .pipe(
        tap((response: ApiResponse<null>) =>
          this.helper.handleSuccess(response)
        )
      );
  }

  changePassword(body: ChangePassword) {
    return this.http
      .post(`${environment.base_url}auth/set/password`, body)
      .pipe(
        map((response: ApiResponse<null> | any) => {
          return response;
        })
      );
  }

  public manageAccess(action: AccessState) {
    if (!hasRequiredPermission(ModuleNames.Support, [CRUD.Read], this.modulePermissionStateValue)) {
      return of(null);
    }
    return this.http
      .get(`${environment.base_url}account/manager/${action}/access`)
      .pipe(
        tap(
          (
            response: ApiResponse<{
              has_access: boolean;
              password?: string;
            } | null>
          ) =>
            action !== AccessState.Check
              ? this.helper.handleSuccess(response)
              : null
        )
      );
  }

  public getAccountManager(): Observable<ApiResponse<null>> {
    return this.http.get<ApiResponse<null>>(
      `${environment.base_url}account/manager`
    );
  }

  logout() {
    if (this.isImpersonationActive) {
      return this.leaveImpersonation();
    } else {
      this.dialog.closeAll();
      return this.destroyServerSession();
    }
  }

  public get isImpersonationActive(): boolean {
    if (!this.currentUserValue) return false;

    const { authorization } = this.currentUserValue || {};
    const { token } = authorization;
    const parsed = this.parseJwt(token);

    return (
      !!parsed?.impersonated_by &&
      parsed?.impersonated_by !== Number(parsed?.sub)
    );
  }

  leaveImpersonation(): Observable<ApiResponse<LoginResponse>> {
    return this.http
      .get<
        ApiResponse<LoginResponse>
      >(`${environment.base_url}account/impersonate/leave`)
      .pipe(
        tap((response) => {
          this.handleLoginResponse(response);
        })
      );
  }

  destroyServerSession(): Observable<ApiResponse<null>> {
    return this.http.get(`${environment.base_url}auth/logout`).pipe(
      tap((response: ApiResponse<null>) => {
        this.helper.handleSuccess(response);
        this.removeLocalData();
        this.router.navigate(['login']);
      })
    );
  }

  resendVerificationLink(data: EmailBody): Observable<ApiResponse<null>> {
    data = { ...data, account_id: this.accountId };
    return this.http
      .post<
        ApiResponse<null>
      >(`${environment.base_url}account/resend/email`, data)
      .pipe(
        tap((response: ApiResponse<null>) => {
          this.helper.handleSuccess(response);
        }),
        map((response) => response)
      );
  }

  refreshToken() {
    return this.http.get(`${environment.base_url}/token/refresh`).pipe(
      map((response: HttpResponse<any>) => {
        return response;
      })
    );
  }

  setUserSubject(loginResponseData: LoginResponse) {
    this.currentUser.next(loginResponseData);

    const moduleStore = new ModuleStoreClass(loginResponseData);

    this.menuList = menuListSanitizer(this.navigationMenus, moduleStore.modulePermissions);
    this.modulePermissionState$.next(moduleStore.modulePermissions);
  }

  getAssignedData(memberId: any, type: string, pageEvent: PageEvent = NOLIMIT) {
    return this.http.get<ApiResponse<PaginatedResponse<any[]>>>(
      `${environment.base_url}account/team/${memberId}/data-access/${type}?page=${pageEvent?.pageIndex}&per_page=${pageEvent?.pageSize}`
    );
  }

  private getReportsMenuByUserType(
    userType: UserType,
    memberId?: any
  ): Observable<MenuGroup[]> {
    let reports: MenuGroup[] = [];
    if (userType === UserType.TeamMember) {
      return this.getAssignedData(memberId, 'reports', NOLIMIT).pipe(
        catchError(() => of([])),
        switchMap((res: any) => {
          reports = this.allReports.map((reportCategory) => {
            reportCategory.links = reportCategory.links.filter(
              (link) => res?.data?.data?.find(({ _id }) => _id === link.url)
            );
            return reportCategory;
          });
          return of(reports);
        })
      );
    } else {
      return new Observable((observer) => {
        if (userType === UserType.Advertiser) {
          reports = [
            {
              name: 'General reports',
              links: [...AdvertiserReports],
              type: 'general'
            },
            {
              name: 'Tracking reports',
              links: [...TrackingReportsMenu],
              type: 'tracking'
            }
          ];
        }
        if (userType === UserType.Publisher) {
          reports = [
            {
              name: 'General reports',
              links: [...PublisherReports],
              type: 'general'
            },
            {
              name: 'Tracking reports',
              links: [...TrackingReportsMenu],
              type: 'tracking'
            }
          ];
        }
        if (userType === UserType.Owner) {
          reports = this.allReports;
        }

        observer.next(reports);
        observer.complete();
      });
    }
  }

  public get allReports(): MenuGroup[] {
    return [
      {
        name: 'General reports',
        links: [...GeneralReportsMenu],
        type: 'general'
      },
      {
        name: 'Tracking reports',
        links: [...TrackingReportsMenu],
        type: 'tracking'
      },
      {
        name: 'Additional reports',
        links: [...AdditionalReportsMenu],
        type: 'additional'
      }
    ];
  }

  getPermissions(response: LoginResponse) {
    return response?._permissions;
  }

  getToken(user: LoginResponse) {
    return user?.authorization?.token || null;
  }

  getExpirationTime(user: LoginResponse) {
    const data = this.parseJwt(this.getToken(user));
    const exp = data.exp;
    const d = new Date(0);

    d.setUTCSeconds(exp);
    return d;
  }

  private parseJwt(token: string): UserJWT {
    return JSON.parse(atob(token?.toString().split('.')[1]));
  }

  openTwoFactorAuthDialog(data: unknown): MatDialogRef<TwoFactorAuthDialogComponent> {
    return this.dialog.open(TwoFactorAuthDialogComponent, {
      width: '450px',
      backdropClass: 'ac-theme-background',
      data,
      disableClose: true
    });
  }

  /**
   * Set up 2FA
   */
  getTwoFactorAuthQrCode(): Observable<ApiResponse<{ qr_code: string }>> {
    return this.http.get<ApiResponse<{ qr_code: string }>>(
      `${environment.base_url}auth/2factor/setup`
    );
  }

  /**
   * login with 2FA
   */
  loginWithTwoFactorAuth(body: TwoFactorLoginRequest): Observable<ApiResponse<LoginResponse>> {
    return this.http.post<ApiResponse<LoginResponse>>(`${environment.base_url}auth/2factor`, body)
      .pipe(tap((response) => {
        this.handleLoginResponse(response);
        this.current2FAToken$.next(null);
      }));
  }

  /**
   * Disable 2FA
   */
  disableTwoFactorAuth(): Observable<ApiResponse<null>> {
    return this.http.patch<ApiResponse<null>>(`${environment.base_url}auth/2factor/disable`, null)
      .pipe(tap((res) => {
        console.log(res);
        if (res.success) {
          this.set2FAState('disable');
        }
      }));
  }

  /**
   * Enable 2FA
   * @param otp 
   */
  enableTwoFactorAuth(otp: string): Observable<ApiResponse<null>> {
    return this.http.patch<ApiResponse<null>>(`${environment.base_url}auth/2factor/enable/${otp}`, null)
      .pipe(tap((res) => {
        if (res.success) {
          this.set2FAState('enable');
        }
      }));
  }

  private set2FAState(state: 'disable' | 'enable') {
    const currentUser = StorageService.getLocalItem(STORAGE_KEY.CURRENTUSER) as LoginResponse;

    if (state === 'disable') {
      StorageService.setLocalItem(STORAGE_KEY.CURRENTUSER, {
        ...currentUser,
        is_2f_enabled: false
      });
      this.current2FAToken$.next(null);
    } else {
      StorageService.setLocalItem(STORAGE_KEY.CURRENTUSER, {
        ...currentUser,
        is_2f_enabled: true
      });
    }
  }

  removeLocalData() {
    StorageService.removeLocalItem(STORAGE_KEY.PAYMENT_NOTIFICATION);
    StorageService.removeLocalItem(STORAGE_KEY.CURRENTUSER);
    StorageService.removeLocalItem(STORAGE_KEY.BRANDCOLOR);
    StorageService.removeLocalItem(STORAGE_KEY.ADVANCED_REPORT_CONFIG);
    StorageService.removeLocalItem(STORAGE_KEY.TITLE_STATE);
    this.storageService.removeUserData();
    this.currentUser.next(null);
    this.dialog?.closeAll();
  }
}
