export enum TrackingParameters {
  CreativeID = 'creative_id',
  CreativeName = 'creative_name',
  CampaignID = 'campaign_id',
  CampaignName = 'campaign_name',
  PublisherID = 'publisher_id',
  AdvertiserID = 'advertiser_id',
  SubPublisherID = 'sub_publisher_id',
  ClickID = 'click_id',
  ClickRegion = 'click_region',
  ClickCity = 'click_city',
  ClickCountry = 'click_country',
  ClickIP = 'click_ip',
  ClickISP = 'click_isp',
  ClickOSName = 'click_os_name',
  ClickOSVersion = 'click_os_version',
  Gaid = 'gaid',
  IDFA = 'idfa',
  AndroidID = 'android_id',
  DeviceID = 'device_id',
  DeviceType = 'device_type',
  DeviceLang = 'device_lang',
  Fbclid = 'fbclid',
  Gclid = 'gclid',
  CustomParam1 = 'custom_param_1',
  CustomParam2 = 'custom_param_2',
  CustomParam3 = 'custom_param_3',
  CustomParam4 = 'custom_param_4',
  CustomParam5 = 'custom_param_5',
  Sub1 = 'sub1',
  Sub2 = 'sub2',
  Sub3 = 'sub3',
  Sub4 = 'sub4',
  Sub5 = 'sub5',
  UnixTimeStamp = 'unix_time_stamp',
  Url = 'url',
  GDPR = 'gdpr',
  GDPR_consent = 'gdpr_consent',
  US_privacy = 'us_privacy',
  Privacy = 'privacy',
  FallbackURL = 'fallback_url',
  Category = 'category',
}

export enum PostbackTrackingParameters {
  ConversionId = 'conversion_id',
  CampaignId = 'campaign_id',
  ConversionTimestamp = 'conversion_timestamp',
  ClickId = 'click_id',
  ClickIp = 'click_ip',
  SubPublisherID = 'sub_publisher_id',
  Goal = 'goal',
  Currency = 'currency',
  Payout = 'payout',
  Revenue = 'revenue',
  PurchaseAmount = 'purchase_amount',
  TransactionId = 'transaction_id',
  CustomParam1 = 'custom_param_1',
  CustomParam2 = 'custom_param_2',
  CustomParam3 = 'custom_param_3',
  CustomParam4 = 'custom_param_4',
  CustomParam5 = 'custom_param_5',
  Sub1 = 'sub1',
  Sub2 = 'sub2',
  Sub3 = 'sub3',
  Sub4 = 'sub4',
  Sub5 = 'sub5',
}
