import { Component, OnInit, signal, WritableSignal } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { AuthService } from '@services/auth.service';
import { HelperService } from '@services/helper.service';
import { SharedService } from '@services/shared.service';

import { environment } from '../../../../environments/environment';
import { BrandDetails, ForgetPasswordBody } from '../../../_interfaces';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: UntypedFormGroup;
  twoFactorAuthForm: UntypedFormGroup;
  loading = false;
  submitted = false;
  errorText: string = null;
  forgetPassword = false;
  passwordVisible = false;
  backgroundImage: string = '/assets/extras/login_infographic.png';
  current2FAtoken: WritableSignal<string> = signal('');
  constructor(
    private authService: AuthService,
    private shared: SharedService,
    private helper: HelperService
  ) {
    this.shared.branding.subscribe((data: BrandDetails) => {
      if (data) {
        this.backgroundImage =
          data?.brand_logo || '/assets/extras/login_infographic.png';
      }
    });
  }

  ngOnInit(): void {
    this.initLoginForm();
    this.initTwoFactorAuthForm();
    this.authService.current2FAToken.subscribe((token) => {
      this.current2FAtoken.set(token);
      this.twoFactorAuthForm.patchValue({ two_f_token: token });
    })
  }

  public get is2FAEnabled() {
    return !!this.current2FAtoken();
  }

  initTwoFactorAuthForm() {
    this.twoFactorAuthForm = new UntypedFormGroup({
      otp: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(6),
        Validators.pattern(/^[0-9]*$/)
      ]),
      two_f_token: new UntypedFormControl('', [Validators.required])
    });
  }

  initLoginForm() {
    if (this.forgetPassword) {
      this.loginForm = new UntypedFormGroup({
        email: new UntypedFormControl('', [
          Validators.required,
          Validators.email
        ])
      });
    } else {
      this.loginForm = new UntypedFormGroup({
        email: new UntypedFormControl('', [
          Validators.required,
          Validators.email
        ]),
        password: new UntypedFormControl('', [
          Validators.required,
          Validators.minLength(6)
        ]),
        rememberMe: new UntypedFormControl('')
      });
    }
  }

  get f() {
    return this.loginForm.controls;
  }

  onLogin() {
    if (this.is2FAEnabled) {
      this.submitted = true;
      this.loading = true;
      this.authService.loginWithTwoFactorAuth(this.twoFactorAuthForm.value)
        .subscribe(() => {
          this.loading = false;
        },
          (error) => {
            this.errorText = error && error.message ? error.message : '';
            if (error && error?.invalid_fields) {
              this.helper.displayFormErrorText(
                error?.invalid_fields,
                this.twoFactorAuthForm
              );
            }
            this.loading = false;
          }
        );
    } else {
      // stop here if form is invalid
      if (this.loginForm.valid && !this.forgetPassword) {
        this.submitted = true;
        this.loading = true;
        this.authService
          .login({
            email: this.f.email.value,
            password: this.f.password.value,
            account_id: this.authService.accountId
          })
          .subscribe(
            () => {
              this.loading = false;
            },
            (error) => {
              this.errorText = error && error.message ? error.message : '';
              if (error && error?.invalid_fields) {
                this.helper.displayFormErrorText(
                  error?.invalid_fields,
                  this.loginForm
                );
              }
              this.loading = false;
            }
          );
      }
      if (this.loginForm.valid && this.forgetPassword) {
        this.resetPassword(this.loginForm.value);
      }
    }
  }

  resetPassword(formData: ForgetPasswordBody) {
    this.submitted = true;
    this.loading = true;
    this.authService.forgotPassword(formData).subscribe(
      () => {
        this.submitted = false;
        this.loading = false;
      },
      (error) => {
        this.errorText = error && error.message ? error.message : '';
        if (error && error?.invalid_fields) {
          this.helper.displayFormErrorText(
            error?.invalid_fields,
            this.loginForm
          );
        }
        this.loading = false;
      }
    );
  }

  get environment() {
    return environment;
  }

  togglePasswordForm() {
    this.forgetPassword = !this.forgetPassword;
    this.initLoginForm();
  }
}
