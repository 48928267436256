<div fxFill class="position-relative center" fxLayout="column" fxLayoutAlign="start center">
  <div class="load-container" *ngIf="loader">
    <mat-spinner></mat-spinner>
  </div>
  <div class="ht4 center mt-4" fxFlex="60%">
    <section class="p-3 pt-5" fxLayout="column" fxLayoutAlign="center center" *ngIf="!loader && !success">
      <span class="d-flex justify-content-center w-100">
        <mat-icon class="icon-xl" fontSet="mso" color="warn">error</mat-icon>
      </span>
      <h2 class="text-center mat-h2 text-muted mb-2">
        The link you're trying to access is either expired or not valid
      </h2>
      <!-- <span class="text-center mat-h3 ml-auto mr-auto"></span> -->
      <div class="d-flex justify-content-center w-100"></div>
    </section>
    <section class="p-3 pt-5" fxLayout="column" fxLayoutAlign="center center" *ngIf="!loader && success">
      <mat-card class="main-modal">
        <mat-card-title>
          <h2 class="txt-light">Accept Invite</h2>
          <span class="txt-light fnt-sm">Confirm your password to accept the member invitation</span>
        </mat-card-title>
        <div class="w-100 h-100">
          <form [formGroup]="passwordForm" class="h-100" fxLayout="column">
            <div fxLayout="column" fxLayoutAlign="start center">
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Enter password</mat-label>
                <input type="password" matInput placeholder="Placeholder" formControlName="password" required />
              </mat-form-field>
              <mat-form-field appearance="outline" class="w-100">
                <mat-label>Confirm password</mat-label>
                <input matInput type="password" placeholder="Confirm password" formControlName="confirmPassword" />
              </mat-form-field>
            </div>
          </form>
        </div>
        <div class="w-100 center fixed-b">
          <div class="w-100">
            <button mat-raised-button type="button" [disabled]="
                passwordForm.invalid ||
                passwordForm.controls.password.value !==
                  passwordForm.controls.confirmPassword.value
              " color="primary" fxFlex="92%" (click)="acceptInvite()">
              Submit
            </button>
          </div>
        </div>
      </mat-card>
    </section>
  </div>
</div>