<mat-toolbar class="top-toolbar cf-card" [color]="toolbarColor" [ngStyle]="{ background: toolbarCustomColor }">
  <div class="menu-control" *ngIf="(isMobile | async) && isLoggedIn">
    <button class="close-btn" type="button" mat-button (click)="toggleSideNavState()">
      <mat-icon fontSet="mso" class="icon-size-6">menu</mat-icon>
    </button>
  </div>
  <div class="cf-atom-brand">
    <span class="cf-text h-100">
      <div class="f-row h-100 brand-appearance">
        <ng-container *ngIf="(branding | async)?.brand_logo">
          <img class="brand-image" [src]="(branding | async)?.brand_logo" [alt]="subdomain" routerLink="/" />
          <mat-divider [vertical]="true" *ngIf="(isMobile | async) === false" class="h-100 mx-2"></mat-divider>
        </ng-container>
        <app-text-line *ngIf="(isMobile | async) === false || !(branding | async)?.brand_logo"
          [enableWrap]="(isMobile | async) === false" routerLink="/" class="link nodecor" [textColor]="textColor"
          [type]="(isMobile | async) === false ? 'base' : 'small'">{{ subdomain | async }}
        </app-text-line>
      </div>
    </span>
  </div>

  <div>
    <ul class="cf-tabs end">
      @if((isMobile | async) === false) {
      <ng-template [ngIf]="userInfo$ | async">
        <!-- <li class="flex px-1" *ngIf="!isPartner">
            <button
              mat-button
              class="close-btn"
              matTooltip="Write a message"
              (click)="menuClicked(writeMessageMenu)">
              <mat-icon fontSet="mso" class="icon-size-6">{{
                writeMessageMenu?.menuIcon
              }}</mat-icon>
            </button>
          </li> -->
        <li class="flex px-1">
          <a mat-button class="close-btn nodecor" href="https://help.appcarry.com" target="_blank">
            <mat-icon fontSet="mso" class="icon-size-6">help</mat-icon>
          </a>
        </li>

        <li class="flex px-1">
          <button mat-button class="close-btn" [matTooltip]="
                (notificationCounter$ | async) === 0
                  ? 'No new notifications'
                  : 'view notifications'
              " (click)="menuClicked(notificationsMenu)">
            <mat-icon fontSet="mso" class="icon-size-6" aria-hidden="false" [matBadge]="notificationCounter$ | async"
              matBadgeColor="warn" matBadgeSize="small" [matBadgeHidden]="(notificationCounter$ | async) === 0"
              [matBadgeOverlap]="true">{{
              notificationsMenu?.icon }}</mat-icon>
          </button>
        </li>

        <li class="flex px-1">
          <button mat-button class="close-btn" matTooltip="Toolbox" [matMenuTriggerFor]="toolboxMenu">
            <mat-icon class="icon-size-6" fontSet="mso">widgets</mat-icon>
          </button>

          <mat-menu #toolboxMenu="matMenu" class="toolbox-menu">
            <app-toolbox-menu></app-toolbox-menu>
          </mat-menu>
        </li>
      </ng-template>

      <li class="flex px-1">
        <button mat-button class="close-btn" [matTooltip]="currentThemeIcon !== 'dark_mode'? 'Light mode': 'Dark mode'"
          (click)="toggleTheme()">
          <mat-icon class="icon-size-6" fontSet="mso">{{
            currentThemeIcon
            }}</mat-icon>
        </button>
      </li>

      <!-- <li class="flex px-1">
                <button mat-button [matMenuTriggerFor]="languagesMenu">
                  <div class="f-row">
                    <mat-icon class="icon-size-6 mr-2" fontSet="mso">language</mat-icon>
                    <app-text-line [textColor]="textColor">Language</app-text-line>
                  </div>
                </button>
        
                <mat-menu #languagesMenu="matMenu">
                  <app-filter-option>
                    <app-text-line>English</app-text-line>
                  </app-filter-option>
                </mat-menu>
              </li> -->
      }

      <ng-template [ngIf]="userInfo$ | async" [ngIfElse]="loggedOutMenu" let-userInfo>
        <li class="flex px-1">
          <div class="f-row" [matMenuTriggerFor]="userMenu">
            <app-avatar class="mr-1" [name]="
                userInfo?.first_name + ' ' + userInfo?.last_name
              "></app-avatar>

            @if((isMobile | async) === false) {
            <app-text-line [textColor]="textColor">{{
              userInfo?.first_name + ' ' + userInfo?.last_name | capitalize
              }}
            </app-text-line>
            }
          </div>

          <mat-menu #userMenu="matMenu" xPosition="before">
            <div class="f-col user-menu">

              @if((isMobile | async) === true) {
              <div fxLayout="column" class="px-2 mb-1 pb-2 brb">
                <app-text-line [textColor]="textColor" weight="strong" type="small">{{
                  userInfo?.first_name + ' ' + userInfo?.last_name | capitalize
                  }}
                </app-text-line>
              </div>
              <div class="small-device-controls brb">
                <div class="control">
                  <a mat-button matTooltip="Get support" class="close-btn nodecor" href="https://help.appcarry.com"
                    target="_blank">
                    <mat-icon fontSet="mso" class="icon-size-6">help</mat-icon>
                  </a>
                </div>
                <div class="control">
                  <button mat-button class="close-btn" [matTooltip]="
                                                                              (notificationCounter$ | async) === 0
                                                                                ? 'No new notifications'
                                                                                : 'view notifications'
                                                                            " (click)="menuClicked(notificationsMenu)">
                    <mat-icon fontSet="mso" class="icon-size-6" aria-hidden="false"
                      [matBadge]="notificationCounter$ | async" matBadgeColor="warn" matBadgeSize="small"
                      [matBadgeHidden]="(notificationCounter$ | async) === 0" [matBadgeOverlap]="true">{{
                      notificationsMenu?.icon }}</mat-icon>
                  </button>
                </div>
                <div class="control">
                  <button mat-button appClickStopPropagation class="close-btn" matTooltip="Toolbox"
                    [matMenuTriggerFor]="toolboxMenu">
                    <mat-icon class="icon-size-6" fontSet="mso">widgets</mat-icon>
                  </button>

                  <mat-menu #toolboxMenu="matMenu" class="toolbox-menu">
                    <app-toolbox-menu></app-toolbox-menu>
                  </mat-menu>

                </div>

                <div class="control">
                  <button mat-button class="close-btn"
                    [matTooltip]="currentThemeIcon !== 'dark_mode'? 'Light mode': 'Dark mode'" (click)="toggleTheme()">
                    <mat-icon class="icon-size-6" fontSet="mso">{{
                      currentThemeIcon
                      }}</mat-icon>
                  </button>
                </div>

              </div>
              }

              <ng-container *ngFor="let option of userMenuItems$ | async">
                <ng-template appContentPermission="{{option.module}}.{{option.permission}}">
                  <app-filter-option *ngIf="!option?.separator; else separator" (click)="menuClicked(option)">
                    <div class="f-row">
                      <mat-icon class="icon-size-6 mr-2" [color]="option?.color?.icon" fontSet="mso">
                        {{ option.icon }}</mat-icon>
                      <app-text-line [textColor]="option?.color?.text || textColor">
                        {{ option.label }}
                      </app-text-line>
                    </div>
                  </app-filter-option>
                  <ng-template #separator>
                    <mat-divider class="w-100"></mat-divider>
                  </ng-template>
                </ng-template>
              </ng-container>
            </div>
          </mat-menu>
        </li>
      </ng-template>
      <ng-template #loggedOutMenu>
        <li class="flex">
          <div class="f-row" *ngIf="menuId === 'login'">
            <app-text-line routerLink="/register" [textColor]="textColor">
              Register
            </app-text-line>
          </div>
          <div class="f-row" *ngIf="menuId === 'register'">
            <app-text-line routerLink="/login" [textColor]="textColor">
              Login
            </app-text-line>
          </div>
        </li>
      </ng-template>
    </ul>
  </div>
</mat-toolbar>