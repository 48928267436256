import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-text-line',
  templateUrl: './text-line.component.html',
  styleUrls: ['./text-line.component.scss']
})
export class TextLineComponent implements OnChanges {
  constructor() { }

  /**
   * Input to set the text value
   */
  @Input() value = '';

  /**
   * Set the type e.g. metric | display | headline | subheading | leading | base | small | xsmall
   * Default type is base
   */
  @Input() type = 'base';

  /**
   * Set the weight type e.g. default | strong
   */
  @Input() weight = '';

  /**
   * set whether the text should wrap to the next line
   */
  @Input() enableWrap = false;

  /**
   * whether to break all string
   */
  @Input() breakAll = false;

  /**
   * set text color styles
   */
  @Input() textColor: string = 'primary';

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.textColor &&
      changes.textColor.previousValue !== changes.textColor.currentValue
    ) {
      this.textColor = changes.textColor.currentValue;
    }
  }
}
