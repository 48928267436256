<div fxFill class="mini-form-wrapper ac-theme-background" fxLayout="row" fxLayoutAlign="center center">
  <mat-card class="mini-form-container mat-elevation-z2">
    <mat-card-header fxLayout="column" fxLayoutAlign="start center">
      @if (!loader && !success) {
      <mat-icon mat-card-avatar class="icon-size-15 mb-3" fontSet="mso" color="warn">error</mat-icon>
      } @else {
      <mat-icon mat-card-avatar class="icon-size-15 mb-3" fontSet="mso" color="primary">lock</mat-icon>
      }
    </mat-card-header>
    <mat-card-header class="mb-2" fxLayout="row" fxLayoutAlign="start center">
      <mat-card-title>Reset password</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="resetForm" *ngIf="success; else showErrorText">
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Enter new password</mat-label>
          <input type="password" matInput placeholder="New Password" formControlName="password" required />
        </mat-form-field>
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Repeat new password</mat-label>
          <input type="password" matInput placeholder="Repeat new password" formControlName="confirmPassword"
            required />
        </mat-form-field>
      </form>

      <ng-template #showErrorText>
        <p>{{ errorText }}</p>
      </ng-template>
    </mat-card-content>
    <mat-card-actions align="end">
      <button mat-button mat-raised-button type="button" (click)="onSubmit()" [class.spinner]="loader"
        [disabled]="resetForm.invalid || loader" color="primary">
        Reset
      </button>
    </mat-card-actions>
  </mat-card>
</div>