import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sidesheet-content-wrapper',
  templateUrl: './sidesheet-content-wrapper.component.html',
  styleUrls: ['./sidesheet-content-wrapper.component.scss']
})
export class SidesheetContentWrapperComponent {
  @Input() fullHeight: boolean = false;
}
