import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-tag-cell',
  templateUrl: './tag-cell.component.html',
  styleUrls: ['./tag-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TagCellComponent {
  @Input() set value({ value, limit }) {
    this.generateTags(value, limit);
  }

  tags: string[] = [];
  constructor() { }

  generateTags(val: string, limit: number) {
    if (val) {
      const tags = Array.isArray(val) ? val : val?.split(',');

      this.tags = this.visibleTags(limit, tags);
    }
  }

  visibleTags(limit: number, tags: string[]) {
    let visibleTags = [...tags];

    if (tags?.length > limit && limit > 0) {
      const showTags = new Array(limit).fill(null).map((v, i) => tags[i]);

      visibleTags = [...showTags, `+${tags.length - limit}`];
    }

    return visibleTags;
  }
}
