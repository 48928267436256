import { Component } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { addIndex, deleteProperties } from '@enums/globals';
import { InternalNavigationMenu } from '@interfaces/global';

@Component({
  selector: 'app-toolbox-menu',
  templateUrl: './toolbox-menu.component.html',
  styleUrl: './toolbox-menu.component.scss'
})
export class ToolboxMenuComponent {
  readonly toolboxMenu: InternalNavigationMenu[] = addIndex([
    {
      title: 'Link generator',
      icon: 'add_link',
      url: 'tracking-link-generator'
    },
    {
      title: 'Postback generator',
      icon: 'swap_horiz',
      url: 'postback-link-generator'
    },
    {
      title: 'Smart link generator',
      icon: 'call_merge',
      url: 'smart-link-generator'
    },
  ]);

  constructor(private router: Router, private route: ActivatedRoute) { }

  menuClicked(option: InternalNavigationMenu) {
    const existingQueryParams = deleteProperties(this.route.snapshot.queryParams, ['relativeId']);

    this.router.navigate([
      '.',
      { outlets: { side: ['toolbox', option.url] } } as NavigationExtras
    ], {
      queryParams: existingQueryParams,
    });
  }
}
