<!-- <app-common-toolbar class="w-100 h55p fixed-top" [loginActive]="true"></app-common-toolbar>
<br><br><br> -->
<div
  fxLayout="column"
  fxLayoutAlign="start center"
  class="w-100 center mat-app-background">
  <div
    fxLayout="column"
    fxLayoutAlign="center center"
    class="h-100 w-100"
    id="registerCont">
    <div fxLayout="row" fxLayoutAlign="start start" class="h-100 w-100">
      <div class="w-100 h-100 desktop">
        <div
          class="w-100 h-100 desktop"
          fxLayout="row"
          fxLayoutAlign="center center">
          <span class="appCarryLogin desktop"> </span>
        </div>
      </div>
      <div class="h-100 desktop">
        <div class="h-100 pt-2 pb-2">
          <div class="hr"></div>
        </div>
      </div>
      <div
        class="col-lg-5 col-md-12 col-xs-12 h-100 col-sm-12 p-3 mw450 position-relative"
        fxLayout="column"
        fxLayoutAlign="center center">
        <div class="w-100 h-100 p-3 dblock">
          <div class="top-blue-hr"></div>
          <h2 class="text-normal">Register</h2>
          <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-12 form-field-wrapper">
                <mat-button-toggle-group
                  class="cf-toggle-group col-12"
                  formControlName="partner_type"
                  color="primary">
                  <mat-button-toggle
                    class="col-6"
                    [disabled]="!isNetwork"
                    value="advertiser"
                    >Advertiser
                  </mat-button-toggle>
                  <mat-button-toggle class="col-6" value="publisher"
                    >Publisher</mat-button-toggle
                  >
                </mat-button-toggle-group>
              </div>

              <mat-form-field class="col-md-6 mb-1" appearance="outline">
                <mat-label>First name</mat-label>
                <input
                  type="text"
                  matInput
                  placeholder="First name"
                  formControlName="firstname" />
                <mat-error
                  *ngIf="registerForm.get('firstname').errors?.['serverError']">
                  {{ registerForm.get('firstname').errors?.['serverError'] }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-md-6 mb-1" appearance="outline">
                <mat-label>Last name</mat-label>
                <input
                  type="text"
                  matInput
                  placeholder="Last name"
                  formControlName="lastname" />
                <mat-error
                  *ngIf="registerForm.get('lastname').errors?.['serverError']">
                  {{ registerForm.get('lastname').errors?.['serverError'] }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-md-12 mb-1" appearance="outline">
                <mat-label>Email Id</mat-label>
                <input
                  type="email"
                  matInput
                  placeholder="Email"
                  formControlName="email" />
                <mat-error
                  *ngIf="registerForm.get('email').errors?.['serverError']">
                  {{ registerForm.get('email').errors?.['serverError'] }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-md-6 mb-1" appearance="outline">
                <mat-label>Password</mat-label>
                <input
                  type="password"
                  matInput
                  placeholder="Password"
                  formControlName="password" />
                <mat-error
                  *ngIf="registerForm.get('password').errors?.['serverError']">
                  {{ registerForm.get('password').errors?.['serverError'] }}
                </mat-error>
              </mat-form-field>
              <mat-form-field class="col-md-6 mb-1" appearance="outline">
                <mat-label>Confirm Password</mat-label>
                <input
                  type="password"
                  matInput
                  placeholder="Confirm Password"
                  formControlName="confirmPassword" />
              </mat-form-field>
              <mat-form-field class="col-md-6 mb-1" appearance="outline">
                <mat-label>Select Country</mat-label>
                <input
                  type="text"
                  placeholder="Type to filter"
                  matInput
                  formControlName="country"
                  [matAutocomplete]="auto" />
                <mat-autocomplete
                  autoActiveFirstOption
                  #auto="matAutocomplete"
                  [displayWith]="displayFn">
                  <mat-option
                    *ngFor="let country of filteredCountryList"
                    [value]="country">
                    {{ country.name }}
                  </mat-option>
                </mat-autocomplete>
                <mat-error
                  *ngIf="registerForm.get('country').errors?.['serverError']">
                  {{ registerForm.get('country').errors?.['serverError'] }}
                </mat-error>
              </mat-form-field>

              <mat-form-field class="col-md-6 mb-1" appearance="outline">
                <mat-label>Company name</mat-label>
                <input
                  type="text"
                  matInput
                  placeholder="Company name"
                  formControlName="company" />
                <mat-error
                  *ngIf="registerForm.get('company').errors?.['serverError']">
                  {{ registerForm.get('company').errors?.['serverError'] }}
                </mat-error>
              </mat-form-field>

              <div
                class="w-100 d-flex justify-content-center align-items-center">
                <span class="p-2 mb-2">
                  By Registering with us you agree to our terms & conditions
                </span>
              </div>
              <span *ngIf="errorText" class="danger">{{ errorText }}</span>
              <div
                class="w-100 d-flex justify-content-center align-items-center">
                <button
                  mat-raised-button
                  type="submit"
                  color="primary"
                  [disabled]="registerForm.invalid">
                  Register
                </button>
              </div>
            </div>
          </form>
        </div>
        <div>
          <div
            fxLayout="row"
            fxLayoutAlign="center center"
            class="center action-links mt-4">
            <app-official-links class="bottom"></app-official-links>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
