<div class="h-100 p-0 m-0 ac-theme-background">
  <div class="w-100 p-3 cal-h" fxLayoutAlign="center center">
    <mat-card class="pt-3 position-relative">
      <div class="load-container" *ngIf="loader">
        <mat-spinner></mat-spinner>
      </div>
      <div class="w-100 h-80 p-3 position-relative" fxLayout="column" fxLayoutAlign="center center">
        <h1 class="mat-h1 text-center">Thank you for Registering!</h1>
        <span class="text-center mat-h3 mb-3">You still have remaining steps to complete your account
          verification</span>
        <div class="row w-100 p-3">
          <div class="col-md-4">
            <div class="p-2" fxLayout="column" fxLayoutAlign="center center">
              <mat-icon fontSet="mso" class="icon-l ic-active disabled">person</mat-icon>
              <span class="ic-active disabled">Create an account</span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="p-2" fxLayout="column" fxLayoutAlign="center center">
              <mat-icon fontSet="mso" class="icon-l ic-active">alternate_email</mat-icon>
              <span class="ic-active">Email confirmation</span>
            </div>
          </div>
          <div class="col-md-4">
            <div class="p-2" fxLayout="column" fxLayoutAlign="center center">
              <mat-icon fontSet="mso" class="icon-l ic-deactive">person_check</mat-icon>
              <span class="ic-deactive">Verify Account</span>
            </div>
          </div>
        </div>
        <div class="p-2 mt-2 w-100" fxLayout="column" fxLayoutAlign="center center">
          <h3 class="mat-h3 mb-1 text-muted">
            Didn't receive a confirmation email?
          </h3>
          <button mat-button color="primary" [disabled]="loader" (click)="resendEmail()">
            Resend Email
          </button>
        </div>
      </div>
    </mat-card>
  </div>
</div>