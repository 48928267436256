import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-external-link',
  templateUrl: './external-link.component.html',
  styleUrls: ['./external-link.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExternalLinkComponent {
  @Input() value: { name: string; website: string };
  constructor() {}
}
