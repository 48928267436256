import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import FormField from './form-field';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-multiselect-search-base',
  template: ''
})
export class MultiselectSearchBaseComponent
  extends FormField
  implements OnChanges, OnInit
{
  private _selected: any[] = [];
  selection = new SelectionModel<any>(true, []);
  filteredSelection: Observable<string[]>;
  searchControl = new UntypedFormControl();
  filteredValues: Observable<string[]>;

  @Input() valueKey = 'value';
  @Input() nameKey = 'name';
  @Input() hint: string;
  @Input() set selected(values: any[]) {
    this._selected = values || [];
    this.selection.select(...this._selected);
  }

  get selected() {
    return this._selected;
  }
  @Input() receivedValues: Array<object>;
  @Input() loader = false;
  /**
   * Show the display value for a selected option
   * @param value value to display
   * @returns string
   */
  @Input() displayFn = (value: object): string => {
    if (value) {
      if (typeof value === 'object') {
        return value[this.nameKey];
      } else {
        const found = this.receivedValues?.find(
          (val) => val[this.valueKey] === value
        );

        return get(found, this.nameKey, '');
      }
    }

    return '';
  };

  @Output() selectionChange: EventEmitter<string[]> = new EventEmitter();

  constructor() {
    super();
    this.selection.changed.subscribe(() => {
      this.dispatchValues();
    });
  }

  public get isAllSelected(): boolean {
    return (
      this.receivedValues?.length > 0 &&
      this.selection.selected.length === this.receivedValues?.length
    );
  }

  ngOnInit(): void {
    if (this.receivedValues?.length) {
      this.initFilter();
    }
  }

  toggleSelectAll(event: MatCheckboxChange) {
    if (event.checked) {
      const values = this.receivedValues?.map((val) => val[this.valueKey]);

      this.selection.select(...values);
    } else {
      this.selection.clear();
    }
  }

  dispatchValues() {
    this.onChange([...this.selection.selected]);
    this.selectionChange.emit([...this.selection.selected]);
    this.searchControl.setValue('');
  }

  override writeValue(values: any[]): void {
    this.selected = values || [];
  }

  /**
   * Initialize the search filter
   */
  initFilter() {
    this.filteredValues = this.searchControl.valueChanges.pipe(
      startWith(''),
      map((searchTerm: string | null) => {
        searchTerm = searchTerm?.toLowerCase() || '';
        return this.filterResults(searchTerm, this.receivedValues || []);
      })
    );

    this.filteredSelection = this.searchControl.valueChanges.pipe(
      startWith(''),
      map((searchTerm: string | null) => {
        searchTerm = searchTerm?.toLowerCase() || '';
        return this.selection.selected?.filter((val) =>
          `${val}`.includes(searchTerm)
        );
      })
    );
  }

  /**
   * Clear the input after selecting a value
   * @param value The value to select
   */
  clearInput(value: string) {
    if (!value) {
      return;
    }

    this.searchControl.setValue(null);
  }

  /**
   * remove a value from the selected values
   * @param value The value to remove
   */
  remove(value: string): void {
    const index = this.selection.selected.indexOf(value);

    if (index >= 0) {
      this.selection.selected.splice(index, 1);
    }
  }

  /**
   * Angular On changes hook
   * @param changes changes object for input properties
   */
  ngOnChanges(changes: SimpleChanges) {
    if (
      !isEqual(
        changes.receivedValues?.currentValue,
        changes.receivedValues?.previousValue
      )
    ) {
      this.receivedValues = changes.receivedValues.currentValue;
      if (this.receivedValues?.length) {
        this.initFilter();
        const validSelectedValues =
          this.receivedValues
            ?.filter(
              (val) =>
                this.selection?.selected?.indexOf(val[this.valueKey]) > -1
            )
            ?.map((val) => val[this.valueKey]) || [];

        this.selection?.clear();
        this.selection?.select(...validSelectedValues);
      }
    }
  }

  /**
   * check whether a dropdown value is selected
   * @param value The value to check
   * @returns boolean
   */
  isSelected(value: string): boolean {
    return !!this.selection.selected.find((option) => option === value);
  }

  /**
   * Filter the results
   * @param searchTerm The search term
   * @param values The values to filter
   * @returns The filtered values
   */
  filterResults(searchTerm: string | null, values: any[]): any[] {
    return values.filter((value: any) => {
      const searchValue = (value[this.nameKey] || '').toLowerCase();

      return searchValue.includes(searchTerm);
    });
  }

  get displayValue() {
    return this.selection?.selected?.length
      ? this.getSelectedValue(this.selection?.selected[0])
      : '';
  }

  /**
   * Get the display value for the selected option
   * @param value the selected value
   * @returns string
   */
  getSelectedValue(value: string | number) {
    return get(
      this.receivedValues?.find(
        (option) => get(option, this.valueKey, null) === value
      ),
      this.nameKey,
      ''
    );
  }
}
