<div class="cf-search-pill f-row {{ classes }}" [ngClass]="{ disabled: control.disabled, active: isActive }">
  <div class="search-icon-container">
    <mat-icon [ngClass]="{ active: isActive && control.value }" class="icon-size-5" fontSet="mso">search</mat-icon>
  </div>
  <input class="mt-0" matInput [id]="id" type="text" autocomplete="off" tabindex="0" [placeholder]="placeholder"
    (blur)="registerTouchEvents('blur')" (focus)="registerTouchEvents('focus')" (input)="afterChange(control.value)"
    [formControl]="control" />
  <div class="search-icon-container">
    <mat-icon *ngIf="control.value && !control.disabled" [ngClass]="{ active: isActive && control.value }"
      (click)="clearSearch()" class="icon-size-6" fontSet="mso">close</mat-icon>
    <mat-icon fontSet="mso" *ngIf="suffixIcon && !control.value && !control.disabled" [fontSet]="fontSet">{{ suffixIcon
      }}
    </mat-icon>
  </div>
</div>