import { BillingStatus } from '@enums/globals';
import { Team } from './global';
import { ModulePermissions, PremiumFeatureKeys } from './permissions';

export class UserAccount {
  company: string;
  website: string;
  expiresAt: string;
}

export interface PremiumFeatures {
  name: string;
  consumed_limit: number;
  available_limit: number;
  url: PremiumFeatureKeys;
}

export interface LoginResponse extends User {
  api_key: string;
  authorization: Authorization;
  billing_status: BillingStatus;
  company?: CompanyResponse;
  _permissions?: ModulePermissions;
  premium_features: PremiumFeatures[];
  teams: Team[];
  user_type: UserType;
  is_2f_enabled: boolean;
}

export interface TwoFactorResponse {
  two_f_token: string;
}

export interface TwoFactorLoginRequest extends TwoFactorResponse {
  otp: string;
}

export interface CompanyResponse {
  _id: string;
  company_name: string;
  website: string;
  status: null;
  user: User;
  _name: string;
}

export interface User {
  _id: number;
  id: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: null;
  status: string;
}

export interface Authorization {
  token: string;
  type: string;
}

export enum UserType {
  Owner = 'owner',
  TeamMember = 'team_member',
  Publisher = 'publisher',
  Advertiser = 'advertiser'
}

export enum AccountType {
  Network = 'network',
  Brand = 'brand'
}
export interface UserJWT {
  iss: string;
  iat: number;
  exp: number;
  nbf: number;
  jti: string;
  sub: string;
  prv: string;
  account_type: string;
  user_type: string;
  impersonated_by: number;
}
