<mat-form-field [ngClass]="[cssClass || '']" [class.field-spinner]="loader" class="position-relative"
  appearance="outline" [class.drop-down-input-active]="showPanel$ | async" cdkOverlayOrigin
  #originOverlay="cdkOverlayOrigin" #parentFormField>
  <div class="inner-transparent-overlay"></div>
  <mat-label>{{ label }}</mat-label>
  <input type="text" matInput [required]="required" #outerInput [disabled]="disabled" [readonly]="true"
    [value]="displayValue" />

  <div class="f-row suffix-icon" matSuffix>
    <app-chip *ngIf="selection?.selected.length > 1" class="mr-2">{{
      selection?.selected.length > 1
      ? ' +' + (selection?.selected?.length - 1)
      : ''
      }}</app-chip>
    <mat-icon fontSet="mso" class="icon-size-5" [ngStyle]="{ color: 'rgba(0, 0, 0, 0.54)' }">arrow_drop_down</mat-icon>
  </div>
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
</mat-form-field>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
  cdkConnectedOverlayBackdropClass="cdk-overlay-transparent-backdrop" [cdkConnectedOverlayOrigin]="originOverlay"
  [cdkConnectedOverlayWidth]="_overlayWidth" [cdkConnectedOverlayOpen]="showPanel$ | async"
  [cdkConnectedOverlayPositions]="positions">
  <section class="drop-down-search-panel mat-elevation-z4" role="listbox" tabindex="-1">
    <div class="filter-wrapper">
      <div class="f-col p-3">
        <app-text-line weight="strong mb-1" type="base">
          {{ label }}
          {{
          selection?.selected?.length
          ? '(' + selection?.selected?.length + ')'
          : ''
          }}
        </app-text-line>
        <app-search placeholder="Search..." #innerInput [formControl]="searchControl"></app-search>
      </div>
      <cdk-virtual-scroll-viewport [style.height.px]="250" itemSize="48">
        <ng-container *ngIf="selection?.selected?.length">
          <ng-container *ngFor="let option of filteredSelection | async">
            <mat-option [title]="displayFn(option)" (click)="selection?.toggle(option)"
              (keyup.enter)="selection?.toggle(option)">
              <mat-checkbox tabindex="-1" [color]="color" (change)="selection?.toggle(option)" [checked]="true">
                {{ displayFn(option) }}
              </mat-checkbox>
            </mat-option>
          </ng-container>

          <mat-divider class="w-100 mb-2 mt-2"></mat-divider>
        </ng-container>

        <ng-container *ngIf="receivedValues?.length && !disabled">
          <ng-container *ngFor="let option of filteredValues | async">
            <mat-option [title]="displayFn(option)" [ngStyle]="{ 'user-select': 'none' }"
              *ngIf="!selection?.isSelected(option[valueKey])" [disabled]="
                        selectionLimit > 0 &&
                        selection?.selected?.length === selectionLimit
                      " (keyup.enter)="
                        selectionLimit > 0 &&
                        selection?.selected?.length === selectionLimit
                          ? null
                          : selection?.toggle(option[valueKey])
                      " (click)="
                        selectionLimit > 0 &&
                        selection?.selected?.length === selectionLimit
                          ? null
                          : selection?.toggle(option[valueKey])
                      ">
              <mat-checkbox tabindex="-1" [disabled]="
                          selectionLimit > 0 &&
                          selection?.selected?.length === selectionLimit
                        " [color]="color" (change)="selection?.toggle(option[valueKey])"
                (keyup.enter)="selection?.toggle(option[valueKey])">
                {{ displayFn(option) }}
              </mat-checkbox>
            </mat-option>
          </ng-container>
        </ng-container>

        <ng-container *ngIf="
                    searchControl?.value && (filteredValues | async)?.length === 0
                  ">
          <mat-option disabled="true">No results found for <b>{{ searchControl?.value }}</b></mat-option>
        </ng-container>

        <ng-container *ngIf="receivedValues.length === 0">
          <mat-option disabled="true">No data found</mat-option>
        </ng-container>
      </cdk-virtual-scroll-viewport>


      <div *ngIf="addOption" class="new-option">
        <app-filter-option (click)="emitAddNewEvent()">
          <app-text-line textColor="link" type="base" weight="text-center">
            <mat-icon class="fx-icons" fontSet="mso">add</mat-icon>
            Add new
          </app-text-line>
        </app-filter-option>
      </div>
    </div>
  </section>
</ng-template>