import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse, StatusType } from '@interfaces/global';
import { PartnerType } from '@modules/partners/services/partner.service';
import ApiBase from '@utilities/api-base';
import { Observable } from 'rxjs';

export interface IdObject {
  campaign_ids?: string[];
  partner_ids?: string[];
  partner_type?: PartnerType;
  optimization_rule_ids?: number[];
  rule_ids?: number[];
}

@Injectable({
  providedIn: 'root'
})
export class BulkOperationService extends ApiBase {
  constructor(private http: HttpClient) {
    super();
  }

  public changeStatusBulk(
    statusType: StatusType,
    statusValue: string,
    idsObject: IdObject,
    parentPath: string = ''
  ): Observable<ApiResponse<null>> {
    return this.http.patch<ApiResponse<null>>(
      `${this.baseUrl}${parentPath}${statusType}/status/${statusValue}`,
      idsObject
    );
  }
}
