import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class LabelComponent {
  /**
   * Label text
   */
  @Input()
  text: string;

  /**
   * Label position
   */
  @Input()
  position: 'center' | 'left' | 'right';

  /**
   * Label prefix icon
   */
  @Input()
  prefixIcon: string;

  /**
   * Label suffix icon
   */
  @Input()
  suffixIcon: string;

  /**
   * Label appearance: solid | outline
   */
  @Input()
  appearance: 'solid' | 'outline' = 'solid';

  /**
   * Label minimum width
   */
  @Input()
  minWidth: number;

  /**
   * Set the icon font type e.g. solid | light | brand | duotone
   */
  @Input()
  set iconFontType(font: string) {
    if (font && font.trim()) {
      this.fontSet = `cf-icons-${font.toLowerCase()}`;
    }
  }

  /**
   * the curent icon font being used
   */
  fontSet = 'cf-icons';

  /**
   * Label status: info | success | warning | error
   */
  @Input()
  status = '';

  /**
   * return applied classList based on inputs config
   */
  get classList(): string[] {
    const list = ['cf-label'];

    if (this.status) {
      if (this.appearance === 'solid') {
        list.push(`cf-label-${this.status}`);
      } else {
        list.push('cf-hollow');
      }
      if (this.suffixIcon) {
        list.push('cf-clickable');
      }
    }

    return list;
  }
}
