export enum ColumnTypes {
  AVATAR = 'avatar',
  AVATAR_LINK = 'avatar_link',
  STATUS = 'status',
  DATE = 'date',
  TIME = 'time',
  TEXT = 'text',
  TITLE = 'title',
  LINK = 'link',
  HYPERLINK = 'hyperlink',
  HTML = 'html',
  EXTERNALLINK = 'external-link',
  TOGGLE = 'toggle',
  EDITABLE_TOGGLE = 'editable-toggle',
  TABLE = 'table',
  EXPANDABLE_TABLE = 'expandable-table',
  NESTED = 'nested',
  TAGS = 'tags',
  SELECTABLE_PILLS = 'selectable_pills',
  PERMISSIONS = 'permissions',
  USER_TYPE = 'user_type',
  SUM = 'sum',
  AVERAGE = 'average'
}

export const ColumnTypesMap: {
  [key:string]:ColumnTypes
} = {
  email: ColumnTypes.TEXT,
  text: ColumnTypes.TEXT,
  date: ColumnTypes.DATE,
  status: ColumnTypes.STATUS,
  company: ColumnTypes.LINK,
  title: ColumnTypes.LINK,
  description: ColumnTypes.HTML,
  kpi: ColumnTypes.HTML,
  preview_url: ColumnTypes.HYPERLINK,
  target_url: ColumnTypes.HYPERLINK,
  active: ColumnTypes.TOGGLE,
  categories: ColumnTypes.TAGS,
  traffic_channels: ColumnTypes.TAGS
};
