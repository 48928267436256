<mat-form-field appearance="outline" [class.field-spinner]="loader" [ngClass]="[cssClass || '']">
  <mat-label>{{ selectLabel }}</mat-label>
  <mat-select [required]="required" [disabled]="disabled" [(ngModel)]="selectedValue"
    (selectionChange)="onValueChange($event)" #select>
    <div class="search">
      <app-search appClickStopPropagation placeholder="Type to search" [formControl]="searchCtrl"></app-search>
    </div>
    <mat-option *ngFor="let option of clonedValues" [value]="valueKey ? option[valueKey] : option"
      [title]="option[nameKey]" [disabled]="option?.disabled || isOptionDisabled(option)">
      {{ !!displayFn ? displayFn(option) : option[nameKey] }}
    </mat-option>
    <app-null-state size="16" [message]="{ line1: 'No results found' }"
      *ngIf="clonedValues?.length === 0 && searchCtrl?.value">
    </app-null-state>
    <div class="action brt" *ngIf="addOption">
      <app-filter-option (click)="emitAddNewEvent()">
        <app-text-line textColor="link" type="base" weight="text-center">
          <mat-icon class="fx-icons" fontSet="mso">add</mat-icon>
          Add new
        </app-text-line>
      </app-filter-option>
    </div>
  </mat-select>
  <mat-hint *ngIf="!!hint">{{ hint }}</mat-hint>
</mat-form-field>