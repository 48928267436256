import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton',
  templateUrl: './skeleton.component.html',
  styleUrls: ['./skeleton.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SkeletonComponent {
  /**
   * Pass a valid skeleton type
   */
  @Input()
  set type(name: string) {
    this.skeletonType = `skeleton-${name}`;
  }

  /**
   * skeleton class
   * default class is skeleton-line
   */
  skeletonType = 'skeleton-line';
}
