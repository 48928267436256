<div class="f-row justify-content-start position-relative" id="wrapper" [ngClass]="{ 'two-cols': !!showChart }">
  <div class="f-col">
    <mat-chip-set *ngIf="hasInitialValue">
      <mat-chip class="pointer" *ngFor="let option of options" (click)="handleTypeChange(option.value)"
        [highlighted]="selectedType === option.value">{{ option.label }}
      </mat-chip>
    </mat-chip-set>

    <app-list-data-table *ngIf="hasData" class="table-element" [dataSourceConfig]="dataSourceConfig" [paginate]="false"
      [showToolbar]="false" [disableFieldTypes]="true">
    </app-list-data-table>

    <div class="null-state-container" *ngIf="!hasData">
      <app-null-state icon="bar_chart" size="20" [message]="{
          line1: 'There\'s no activity for the selected criteria',
          line2: 'Try modifying the criteria and try again'
        }"></app-null-state>
    </div>
  </div>

  <div class="chart-container" *ngIf="showChart && hasData">
    <app-chart-renderer [config]="chartConfig"></app-chart-renderer>
  </div>
</div>