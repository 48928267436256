import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { ColumnTypes } from '../../../../_utilities/table-data';
import { environment } from 'src/environments/environment';
import { RawDataService } from '@services/raw-data.service';

@Component({
  selector: 'app-custom-cell',
  templateUrl: './custom-cell.component.html',
  styleUrls: ['./custom-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomCellComponent {
  @Input() type: ColumnTypes;
  @Input() value: unknown;
  @Input() extendedView: boolean;
  @Input() format: string;
  @Input() prefixIcon: string;
  @Input() suffixIcon: string;
  @Input() limit: number;
  @Input() localizeNumericValues: boolean;

  @Output() optionClicked: EventEmitter<unknown> = new EventEmitter();
  constructor(private rawDataService: RawDataService) { }

  optionSelected(value: unknown) {
    this.optionClicked.emit(value);
  }

  getLocalizedNumber(value: number) {
    if (!isNaN(value) && value > 0) {
      return this.localizeNumericValues
        ? value?.toLocaleString(environment?.locale || '')
        : value;
    }

    return value;
  }

  resolveCountries(countryCodes: string[] | string): string[] {
    const codes = Array.isArray(countryCodes) ? countryCodes : [countryCodes];

    return codes.map((code) => this.rawDataService.getCountryName(code));
  }
}
