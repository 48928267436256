import { Component, OnDestroy } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { FindAccountResponse } from '@interfaces/auth';
import { ApiResponse } from '@interfaces/global';
import { AuthService } from '@services/auth.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-find-my-account',
  templateUrl: './find-my-account.component.html',
  styleUrls: ['./find-my-account.component.scss']
})
export class FindMyAccountComponent implements OnDestroy {
  subs = new SubSink();
  emailControl: UntypedFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.email
  ]);
  availableAcccounts: Array<FindAccountResponse> = [];
  constructor(private authService: AuthService) { }

  findAccountByEmail() {
    this.subs.sink = this.authService
      .findAccount({ email: this.emailControl.value })
      .subscribe((response: ApiResponse<FindAccountResponse[]>) => {
        this.availableAcccounts = response?.data || [];
      });
  }

  getDomain(domain: string) {
    return `https://${domain}`;
  }

  get hasAvailableAccounts() {
    return this.availableAcccounts?.length;
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }
}
