import { NgModule } from '@angular/core';
import { FactoryModule } from '@modules/factory/factory.module';
import { MessagesComponent } from './messages/messages.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { SendMessageComponent } from './send-message/send-message.component';
import { UpdatesRoutingModule } from './updates-routing.module';

@NgModule({
  declarations: [
    MessagesComponent,
    NotificationsComponent,
    SendMessageComponent
  ],
  imports: [UpdatesRoutingModule, FactoryModule]
})
export class UpdatesModule {}
