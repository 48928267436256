import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomNotificationComponent } from '@modules/factory/custom-notification/custom-notification.component';

export interface CustomNotificationData {
  message: string;
  icon?: string;
  action?: string;
  title?: string;
}

@Injectable({
  providedIn: 'root'
})
export class NotifyService {
  durationInSeconds = 5;
  constructor(private snackBar: MatSnackBar) {}

  openSnackBar(message: string, action: string) {
    if (message) {
      this.snackBar.open(message, action, {
        duration: this.durationInSeconds * 1000
      });
    }
  }

  alert(alertData: CustomNotificationData) {
    if (alertData?.message) {
      this.snackBar.openFromComponent(CustomNotificationComponent, {
        duration: this.durationInSeconds * 1000,
        panelClass: ['notifications-panel'],
        horizontalPosition: 'end',
        verticalPosition: 'top',
        politeness: 'polite',
        data: alertData
      });
    }
  }
}
