import { Injectable } from '@angular/core';
import { WorkerRequest, WorkerResponse } from '@interfaces/worker';
import { BehaviorSubject, filter } from 'rxjs';
import { callmethodByName } from './_worker/worker-functions';

@Injectable({
  providedIn: 'root'
})
export class AppWorkerService {
  private worker: Worker;
  private workerResponseSub$: BehaviorSubject<WorkerResponse> = new BehaviorSubject<WorkerResponse>(null);

  constructor() {
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker(new URL('./app.worker', import.meta.url));

      this.worker.onmessage = ({ data }) => {
        this.workerResponseSub$.next(data);
      };
    }
  }

  public postWork(work: WorkerRequest) {
    if (typeof Worker !== 'undefined') {
      this.worker.postMessage({ ...work });
    } else {
      const response: WorkerResponse = callmethodByName(work?.name, work?.args, work?.uid);

      this.workerResponseSub$.next(response);
    }
  }

  public watchWorkerResponse(uid: string) {
    return this.workerResponseSub$.asObservable().pipe(
      filter((response: WorkerResponse) => response && response.uid === uid)
    );
  }

  public clearWorker() {
    this.workerResponseSub$.next(null);
  }
}
