<div class="row m-0" class="{{ classes }}" [style.minWidth]="_width" *ngIf="type === 'profile'">
  <div class="col-12">
    <app-skeleton type="avatar"></app-skeleton>
    <div class="f-col mt-2">
      <app-skeleton type="line"></app-skeleton>
      <app-skeleton type="line-half"></app-skeleton>
    </div>
    <div class="f-col mt-4">
      <app-skeleton type="line"></app-skeleton>
      <app-skeleton type="line-half"></app-skeleton>
    </div>
  </div>
</div>
<div class="row m-0" class="{{ classes }}" [style.minWidth]="_width" *ngIf="type === 'text'">
  <div class="col-12">
    <div class="f-col mt-2">
      <app-skeleton type="line"></app-skeleton>
      <app-skeleton type="line"></app-skeleton>
      <app-skeleton type="line-half"></app-skeleton>
    </div>
    <div class="f-col mt-4">
      <app-skeleton type="line"></app-skeleton>
      <app-skeleton type="line"></app-skeleton>
      <app-skeleton type="line-half"></app-skeleton>
    </div>
    <div class="f-col mt-2">
      <app-skeleton type="line"></app-skeleton>
      <app-skeleton type="line-half"></app-skeleton>
    </div>
  </div>
</div>
<div class="row mt-4 my-0" class="{{ classes }}" [style.minWidth]="_width" *ngIf="type === 'grid'">
  <div class="col-4">
    <div class="f-col my-2">
      <app-skeleton type="line" class="mb-4"></app-skeleton>
    </div>
    <div class="f-col my-2">
      <app-skeleton type="line" class="mb-4"></app-skeleton>
    </div>
    <div class="f-col my-2">
      <app-skeleton type="line" class="mb-4"></app-skeleton>
    </div>
  </div>
  <div class="col-4">
    <div class="f-col my-2">
      <app-skeleton type="line" class="mb-4"></app-skeleton>
    </div>
    <div class="f-col my-2">
      <app-skeleton type="line" class="mb-4"></app-skeleton>
    </div>
    <div class="f-col my-2">
      <app-skeleton type="line" class="mb-4"></app-skeleton>
    </div>
  </div>
  <div class="col-4">
    <div class="f-col my-2">
      <app-skeleton type="line" class="mb-4"></app-skeleton>
    </div>
    <div class="f-col my-2">
      <app-skeleton type="line" class="mb-4"></app-skeleton>
    </div>
    <div class="f-col my-2">
      <app-skeleton type="line" class="mb-4"></app-skeleton>
    </div>
  </div>
</div>

<div class="row m-0" class="{{ classes }}" [style.minWidth]="_width" *ngIf="type === 'title'">
  <div class="col-12">
    <div class="f-col mt-2">
      <app-skeleton type="line"></app-skeleton>
      <app-skeleton type="line-half"></app-skeleton>
    </div>
  </div>
</div>

<div class="row m-0" class="{{ classes }}" [style.minWidth]="_width" *ngIf="type === 'list'">
  <div class="f-col mt-2 w-100">
    <app-skeleton type="line"></app-skeleton>
    <app-skeleton type="line"></app-skeleton>
    <app-skeleton type="line-half"></app-skeleton>
  </div>
  <div class="f-col mt-2 w-100">
    <app-skeleton type="line"></app-skeleton>
    <app-skeleton type="line"></app-skeleton>
    <app-skeleton type="line-half"></app-skeleton>
  </div>
  <div class="f-col mt-2 w-100">
    <app-skeleton type="line"></app-skeleton>
    <app-skeleton type="line"></app-skeleton>
    <app-skeleton type="line-half"></app-skeleton>
  </div>
</div>

<div class="row m-0" class="{{ classes }}" [style.minWidth]="_width" *ngIf="type === 'form'">
  <div class="col-6 mb-3">
    <app-skeleton type="line"></app-skeleton>
  </div>
  <div class="col-6">
    <app-skeleton type="line"></app-skeleton>
  </div>

  <div class="col-6 mb-3">
    <app-skeleton type="line"></app-skeleton>
  </div>
  <div class="col-6">
    <app-skeleton type="line"></app-skeleton>
  </div>

  <div class="col-6 mb-3">
    <app-skeleton type="line"></app-skeleton>
  </div>
  <div class="col-6">
    <app-skeleton type="line"></app-skeleton>
  </div>
</div>

<div class="row m-0" class="{{ classes }}" [style.minWidth]="_width" *ngIf="type === 'cards'">
  <div class="col-2">
    <li class="grid-item grid-border">
      <app-dashboard-widget>
        <ng-container title>
          <app-skeleton type="line"></app-skeleton>
          <app-skeleton type="line-half"></app-skeleton>
        </ng-container>

        <ng-container content>
          <app-skeleton type="line"></app-skeleton>
          <app-skeleton type="line"></app-skeleton>
          <app-skeleton type="line-half"></app-skeleton>
        </ng-container>
      </app-dashboard-widget>
    </li>
  </div>
  <div class="col-2">
    <li class="grid-item grid-border">
      <app-dashboard-widget>
        <ng-container title>
          <app-skeleton type="line"></app-skeleton>
          <app-skeleton type="line-half"></app-skeleton>
        </ng-container>

        <ng-container content>
          <app-skeleton type="line"></app-skeleton>
          <app-skeleton type="line"></app-skeleton>
          <app-skeleton type="line-half"></app-skeleton>
        </ng-container>
      </app-dashboard-widget>
    </li>
  </div>
  <div class="col-2">
    <li class="grid-item grid-border">
      <app-dashboard-widget>
        <ng-container title>
          <app-skeleton type="line"></app-skeleton>
          <app-skeleton type="line-half"></app-skeleton>
        </ng-container>

        <ng-container content>
          <app-skeleton type="line"></app-skeleton>
          <app-skeleton type="line"></app-skeleton>
          <app-skeleton type="line-half"></app-skeleton>
        </ng-container>
      </app-dashboard-widget>
    </li>
  </div>
  <div class="col-2">
    <li class="grid-item grid-border">
      <app-dashboard-widget>
        <ng-container title>
          <app-skeleton type="line"></app-skeleton>
          <app-skeleton type="line-half"></app-skeleton>
        </ng-container>

        <ng-container content>
          <app-skeleton type="line"></app-skeleton>
          <app-skeleton type="line"></app-skeleton>
          <app-skeleton type="line-half"></app-skeleton>
        </ng-container>
      </app-dashboard-widget>
    </li>
  </div>
</div>

<div fxLayout="column" fxLayoutAlign="start start" class="{{ classes }} px-4" [style.minWidth]="_width"
  *ngIf="type === 'table'">
  <table width="100%">
    <tr style="height: 50px;">
      <td class="px-2">
        <app-skeleton type="line"></app-skeleton>
      </td>
      <td class="px-2">
        <app-skeleton type="line"></app-skeleton>
      </td>
      <td class="px-2">
        <app-skeleton type="line"></app-skeleton>
      </td>
      <td class="px-2">
        <app-skeleton type="line"></app-skeleton>
      </td>
    </tr>
    <tr style="height: 50px;">
      <td class="px-2">
        <app-skeleton type="line"></app-skeleton>
      </td>
      <td class="px-2">
        <app-skeleton type="line"></app-skeleton>
      </td>
      <td class="px-2">
        <app-skeleton type="line"></app-skeleton>
      </td>
      <td class="px-2">
        <app-skeleton type="line"></app-skeleton>
      </td>
    </tr>
    <tr style="height: 50px;">
      <td class="px-2">
        <app-skeleton type="line"></app-skeleton>
      </td>
      <td class="px-2">
        <app-skeleton type="line"></app-skeleton>
      </td>
      <td class="px-2">
        <app-skeleton type="line"></app-skeleton>
      </td>
      <td class="px-2">
        <app-skeleton type="line"></app-skeleton>
      </td>
    </tr>

  </table>
</div>

<!-- <div class="row m-0">
      <div class="col-4 p-0">
        <div class="f-col m-2">
          <app-skeleton type="line" *ngFor="let a of [].constructor(rows)" class="mb-2"></app-skeleton>
        </div>
      </div>
      <div class="col-4 p-0">
        <div class="f-col m-2">
          <app-skeleton type="line" *ngFor="let a of [].constructor(rows)" class="mb-2"></app-skeleton>
        </div>
      </div>
      <div class="col-4 p-0">
        <div class="f-col m-2">
          <app-skeleton type="line" *ngFor="let a of [].constructor(rows)" class="mb-2"></app-skeleton>
        </div>
      </div>
    
    </div> -->