import { Component, Input } from '@angular/core';
import { areAllObjectsNull } from '@enums/globals';
import {
  DataSourceConfig,
  PaginatedResponse,
  TopEntityResponse
} from '@interfaces/global';
import { ChartConfiguration } from 'chart.js';
import { prepareChartConfig } from 'src/app/_utilities/chart-helper';

@Component({
  selector: 'app-chart-table-renderer',
  templateUrl: './chart-table-renderer.component.html',
  styleUrls: ['./chart-table-renderer.component.scss']
})
export class ChartTableRendererComponent {
  selectedType: 'today' | 'yesterday' | 'mtd' | 'all_time' = 'today';
  chartConfig: ChartConfiguration;
  existingValue: TopEntityResponse;
  hasInitialValue: boolean = false;

  dataSourceConfig: DataSourceConfig = {
    dataSource: { data: [] } as PaginatedResponse<any>,
    hiddenColumns: [],
    stickyColumns: [],
    stickyColumnsEnd: [],
    actionMenus: [],
    enableSelection: false
  };

  options = [
    { value: 'today', label: 'Today' },
    { value: 'yesterday', label: 'Yesterday' },
    { value: 'mtd', label: 'MTD' },
    { value: 'all_time', label: 'Lifetime' }
  ];

  @Input() set dataSourceOptions(config: Partial<DataSourceConfig>) {
    if (config?.stickyColumns) {
      this.dataSourceConfig.stickyColumns = config.stickyColumns;
    }
  }
  @Input() showChart: boolean = true;
  @Input() set config(val: TopEntityResponse) {
    if (val) {
      this.existingValue = val;
      this.selectedType = Object.keys(val)?.filter(
        (key) => !areAllObjectsNull(val[key])
      )[0] as any;

      this.chartConfig = prepareChartConfig(val, this.selectedType);
      this.dataSourceConfig.dataSource.data = val[this.selectedType];
      this.hasInitialValue = true;
    } else {
      this.chartConfig = null;
    }
  }

  constructor() {}

  get hasData(): boolean {
    return (
      !!this.dataSourceConfig.dataSource.data?.length || !!this.chartConfig
    );
  }

  handleTypeChange(type: 'today' | 'yesterday' | 'mtd') {
    this.selectedType = type;
    this.chartConfig = prepareChartConfig(
      this.existingValue,
      this.selectedType
    );
    this.dataSourceConfig = {
      ...this.dataSourceConfig,
      dataSource: {
        data: this.existingValue[this.selectedType]
      } as PaginatedResponse<any>
    };
  }
}
