import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { StatusChangeDialogComponent } from '@modules/factory/status-change-dialog/status-change-dialog.component';
import { StatusType } from '@interfaces/global';
import { AssignRolesDialogComponent } from '@modules/factory/assign-roles-dialog/assign-roles-dialog.component';
import { PartnerType } from '@modules/partners/services/partner.service';
import { PaymentDialogComponent } from '@modules/factory/payment-dialog/payment-dialog.component';
import { BillingStatus } from '@enums/globals';
import { PremiumInfoCardComponent } from '@modules/factory/premium-info-card/premium-info-card.component';
import { CustomReportDialogComponent } from '@modules/reports/custom-report/custom-report-dialog/custom-report-dialog.component';
import { CustomReportRequest } from '@modules/reports/custom-report/custom-report.service';

export interface PaymentNotificationConfig {
  billing_status: BillingStatus;
  dismissible: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class GlobalDialogService {
  constructor(private dialog: MatDialog) { }

  openDialog(
    component,
    data: any = null,
    width = '550px',
    panelClass = 'create-master-panel'
  ) {
    return this.dialog.open(component, {
      width,
      data,
      scrollStrategy: new NoopScrollStrategy(),
      panelClass
    });
  }

  public openChangeStatusDialog(
    statusType: StatusType,
    id: string | number,
    partner_type?: PartnerType
  ) {
    return this.dialog.open(StatusChangeDialogComponent, {
      width: '550px',
      data: { statusType, id, partner_type },
      panelClass: 'create-master-panel'
    });
  }

  public openRoleAssignmentDialog(role_id: number, user_id: number) {
    return this.dialog.open(AssignRolesDialogComponent, {
      width: '550px',
      data: { role_id, user_id },
      panelClass: 'create-master-panel'
    });
  }

  public openPaymentDialog(data: PaymentNotificationConfig) {
    return this.dialog.open(PaymentDialogComponent, {
      width: '550px',
      data,
      panelClass: 'create-master-panel',
      backdropClass: 'block-actions-backdrop',
      disableClose: true
    });
  }
  public openPremiumFeatureDialog() {
    return this.dialog.open(PremiumInfoCardComponent, {
      width: '550px',
      data: {},
      panelClass: 'create-master-panel',
      backdropClass: 'ac-theme-background',
      disableClose: true
    });
  }
  public openCustomReortDialog(data: Partial<CustomReportRequest> = null) {
    return this.dialog.open(CustomReportDialogComponent, {
      width: '550px',
      data,
      panelClass: 'create-master-panel',
      disableClose: true
    });
  }
}
