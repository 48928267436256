import { Component, OnInit } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { MemberVerify } from 'src/app/_interfaces';

@Component({
  selector: 'app-member-verified',
  templateUrl: './member-verified.component.html',
  styleUrls: ['./member-verified.component.css']
})
export class MemberVerifiedComponent implements OnInit {
  loader: boolean;
  success: boolean;
  body: MemberVerify = {
    email: '',
    password: '',
    token: ''
  };
  passwordForm: UntypedFormGroup;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService
  ) {
    this.route.queryParams.subscribe((p) => {
      if (p && p.token) {
        this.body = {
          email: p.email,
          token: p.token,
          password: ''
        };
        this.success = true;
      } else {
        this.success = false;
      }
    });
  }

  acceptInvite() {
    this.body.password = this.passwordForm.controls.password.value;
    if (this.body) {
      this.loader = true;
      this.auth.verifyMember(this.body).subscribe((res) => {
        if (res.success) {
          this.success = true;
          this.router.navigate(['/login']);
        } else {
          this.success = false;
        }
        this.loader = false;
      });
    }
  }

  ngOnInit(): void {
    this.passwordForm = new UntypedFormGroup({
      password: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(5)
      ]),
      confirmPassword: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(5)
      ])
    });
  }
}
