import {
  Component,
  EventEmitter,
  Input,
  Output,
  ChangeDetectionStrategy
} from '@angular/core';

@Component({
  selector: 'app-chip',
  templateUrl: './chip.component.html',
  styleUrls: ['./chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChipComponent {
  /**
   * Specify the chip label
   */
  @Input()
  label: string;
  /**
   * Specify if required
   */
  @Input()
  required: boolean;

  /**
   * if the chip should be removable with a close icon
   */
  @Input()
  removable: boolean;

  /**
   * if the chip should be selectable
   */
  @Input()
  selectable: boolean;

  /**
   * specify if the chip is selected
   */
  @Input()
  selected: boolean;

  /**
   * specify if the chip should be disabled
   */
  @Input()
  disabled: boolean;

  /**
   * specify the type of chip e.g. warning | error | info
   */
  @Input()
  type: string;

  /**
   * Specify the suffix icon
   */
  @Input()
  icon: string;

  /**
   * enable tag layout
   */
  @Input()
  tags: boolean;

  /**
   * Specify the suffix icon
   */
  @Input()
  iconPosition: 'before' | 'after' = 'before';

  /**
   * Set the icon font type e.g. solid | light | brand | duotone
   */
  @Input()
  set iconFontType(font: string) {
    if (font && font.trim()) {
      this.fontSet = `${font.toLowerCase()}`;
    }
  }

  /**
   * the curent icon font being used
   */
  fontSet = 'mso';

  /**
   * Output property to emit remove event
   */
  @Output()
  removed: EventEmitter<any> = new EventEmitter();

  constructor() { }

  /**
   * Method to emit chip remove event
   */
  remove(): void {
    this.removed.emit();
  }

  /**
   * Get the current chip type class
   */
  getType(): any[] {
    if (this.disabled) {
      return ['_disabled'];
    }

    if (this.selectable) {
      return this.selected ? ['selected'] : this.type ? [`${this.type}`] : [];
    } else {
      return this.type ? [`${this.type}`] : [];
    }
  }
}
