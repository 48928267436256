import { CdkCopyToClipboard, Clipboard } from '@angular/cdk/clipboard';
import { Directive, ElementRef, Input, NgZone, OnDestroy, OnInit } from '@angular/core';
import { NotifyService } from '@services/notify.service';
import { SubSink } from 'subsink';

@Directive({
  selector: '[appTextCopy]'
})
export class TextCopyDirective extends CdkCopyToClipboard implements OnInit, OnDestroy {
  subs = new SubSink();
  @Input() set appTextCopy(text: string) {
    this.text = text;
  }

  get appTextCopy(): string {
    return this.text;
  }

  constructor(
    private el: ElementRef<HTMLElement>,
    _clipboard: Clipboard,
    _ngZone: NgZone,
    private notify: NotifyService
  ) {
    super(_clipboard, _ngZone);

    this.subs.sink = this.copied.subscribe(() => {
      this.notify.openSnackBar('Text copied to clipboard', 'success');
    });
  }

  override ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }

  ngOnInit(): void {
    if (this.appTextCopy) {
      this.el?.nativeElement.classList.add('text-to-copy');
    }
  }
}
