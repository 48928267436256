import { Injectable } from '@angular/core';
import * as countryList from '../../assets/data/countries.json';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RawDataService {
  private readonly countryList: Array<{
    name: string;
    code: string;
    let3: string;
  }> = [...(countryList as any).default].map((country) => ({
    name: `${country.name} (${country.code})`,
    code: country.code,
    let3: country.let3
  }));
  constructor() {}

  public get countries$() {
    return of(this.countryList);
  }
  public getCountryName(code: string) {
    return this.countryList.find((c) => c.code === code)?.name || 'Unknown';
  }
  public getCountryLet3(code: string) {
    return this.countryList.find((c) => c.code === code)?.let3 || 'Unknown';
  }
}
