import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmDialogOptions, InputConfig } from '@interfaces/confirm';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  note: any = null;
  inputConfig: InputConfig;
  inputControl = new FormControl('');

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: ConfirmDialogOptions
  ) {
    const { data, inputConfig } = this.dialogData || {};
    if (inputConfig) {
      this.inputConfig = inputConfig;
    }

    if (data) {
      const { note, note_icon, note_background, note_color } = data;

      this.note = {
        note,
        note_icon,
        note_background,
        note_color
      };
    }
  }

  ngOnInit(): void {
    if (this.inputConfig?.validators?.length) {
      this.inputControl.setValidators(this.inputConfig.validators);
      this.inputControl.updateValueAndValidity();
    }
  }

  onNoClick(inputValue?: string): void {
    this.dialogRef.close(inputValue);
  }
}
