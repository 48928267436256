import { NavigationMenuItem } from '@interfaces/menu';
import { CRUD, ModuleNames } from '@interfaces/permissions';

export const MenuList: Array<NavigationMenuItem> = [
  {
    label: 'Dashboard',
    icon: 'dashboard',
    url: 'network/dashboard',
    module: ModuleNames.Dashboard,
    permission: CRUD.Read,
    expandable: false
  },
  {
    label: 'Campaigns',
    icon: 'campaign',
    url: 'campaigns',
    expandable: true,
    children: [
      {
        label: 'Create campaign',
        url: 'manage-campaign',
        outlet: {
          side: ['create-campaign', 'new', 'campaign-configuration']
        },
        module: ModuleNames.Campaign,
        permission: CRUD.Create
      },
      {
        label: 'Manage campaign',
        url: 'manage-campaign',
        module: ModuleNames.Campaign,
        permission: CRUD.Read
      },
      {
        label: 'Offer/Campaigns',
        url: 'manage-campaign',
        module: ModuleNames.Offer_Campaign,
        permission: CRUD.Read
      },
      {
        label: 'Coupon codes',
        url: 'coupon-codes',
        module: ModuleNames.CouponCodes,
        permission: CRUD.Read
      },
      {
        label: 'Offer check',
        url: 'offer-check',
        module: ModuleNames.OfferCheck,
        permission: CRUD.Read
      },
      {
        label: 'Smart link',
        url: 'smart-link',
        module: ModuleNames.SmartLink,
        permission: CRUD.Read
      },
      {
        label: 'Access approval',
        url: 'tasks',
        module: ModuleNames.Tasks,
        permission: CRUD.Read,
      },
      {
        label: 'Optimization rules',
        url: 'optimization-rule',
        module: ModuleNames.Campaign,
        permission: CRUD.Update
      },
      {
        label: 'Campaign analyzer',
        url: 'campaign-analyzer',
        module: ModuleNames.Campaign,
        permission: CRUD.Read
      },
    ]
  },
  {
    label: 'Reports',
    icon: 'bar_chart_4_bars',
    url: 'reports',
    module: ModuleNames.Reports,
    permission: CRUD.Read,
    expandable: true,
    children: [
      {
        label: 'Smart report',
        url: 'smart-report',
      },
      {
        label: 'Custom report',
        url: 'custom-report',
      },
      {
        label: 'Recent exports',
        url: 'recent-exports',
      },
      {
        label: 'Scheduled report',
        url: 'scheduled-report',
      }
    ]
  },
  {
    label: 'Partners',
    icon: 'partner_exchange',
    url: 'partners',
    module: ModuleNames.Partner,
    permission: CRUD.Read,
    expandable: true,
    children: [
      {
        label: 'Publishers',
        url: 'publishers',
        module: ModuleNames.Publisher,
        permission: CRUD.Read,
      },
      {
        label: 'Advertisers',
        url: 'advertisers',
        module: ModuleNames.Advertiser,
        permission: CRUD.Read,
      }
    ]
  },
  {
    label: 'Postback',
    icon: 'swap_horiz',
    url: 'postback',
    module: ModuleNames.Publisher,
    permission: CRUD.Read,
    expandable: true,
    children: [
      {
        label: 'Postback / Pixels',
        url: 'postback-pixels',
      },
      {
        label: 'Postback logs',
        url: ['postback-logs', 'tracking', 'postback'],
        defaultDateRangeAsQueryParam: true,
      }
    ]
  },
  {
    label: 'Logs',
    icon: 'overview',
    url: 'logs',
    module: ModuleNames.Logs,
    permission: CRUD.Read,
    expandable: true,
    children: [
      {
        label: 'Activity logs',
        url: 'activity-logs',
      },
      {
        label: 'Api logs',
        url: 'api-logs',
      },
    ]
  },
  {
    label: 'Automation',
    icon: 'robot_2',
    url: 'automation',
    module: ModuleNames.Automations,
    permission: CRUD.Read,
    expandable: true,
    children: [
      {
        label: 'MMP Integration guide',
        url: 'integration-guide',
      },
      {
        label: 'Import data',
        url: 'import',
      },
      {
        label: 'Network integration',
        url: 'network-integration',
      },
      {
        label: 'Smart alerts',
        url: 'smart-alerts',
      }
    ]
  },
  {
    label: 'Fraud detection',
    icon: 'rule',
    url: 'fraud',
    module: ModuleNames.FraudDetection,
    permission: CRUD.Read,
    expandable: true,
    children: [
      {
        label: 'Dashboard',
        url: 'dashboard',
      },
      {
        label: 'Providers',
        url: 'providers',
      },
      {
        label: 'Detection rules',
        url: 'detection-rules',
      },
    ]
  },
  {
    label: 'Customization',
    icon: 'dashboard_customize',
    url: 'customization',
    module: ModuleNames.Customization,
    permission: CRUD.Read,
    expandable: false
  },
];

export const navigationMenuUrls = (menus: NavigationMenuItem[] = []) => {
  let list = [];

  menus.forEach((m) => {
    if (m.children?.length) {
      list = [...list, ...navigationMenuUrls(m.children)];
    }

    list.push(m.url);
  });

  return list;
};
