import { Component, Inject } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StatusType } from '@interfaces/global';
import { DropdownOption, Statuses } from '@modules/campaign/campaign-constants';
import { PartnerType } from '@modules/partners/services/partner.service';
import { TeamService } from '@modules/team/services/team.service';
import { BulkOperationService } from '@services/bulk-operation.service';
import { NotifyService } from '@services/notify.service';
import { Observable, of } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-status-change-dialog',
  templateUrl: './status-change-dialog.component.html',
  styleUrls: ['./status-change-dialog.component.scss']
})
export class StatusChangeDialogComponent {
  statuses: Observable<DropdownOption<any>[]> = of([]);
  changeStatusControl: UntypedFormControl = new UntypedFormControl(null, Validators.required);
  loader = false;

  constructor(
    private dialogRef: MatDialogRef<StatusChangeDialogComponent>,
    private bulkOperationService: BulkOperationService,
    private teamService: TeamService,
    private notify: NotifyService,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      statusType: StatusType;
      id: any;
      partner_type?: PartnerType;
    }
  ) {
    this.getStatusesByType(data?.statusType);
  }

  getStatusesByType(statusType: StatusType) {
    this.statuses = of(Statuses[statusType]);
  }

  changeStatus() {
    const { statusType, id, partner_type } = this.data || {};
    let statusIds = null;

    this.loader = true;
    if (statusType === StatusType.TeamMember) {
      this.teamService
        .changeStatus(id, this.changeStatusControl.value)
        .pipe(
          finalize(() => {
            this.loader = false;
          })
        )
        .subscribe((res) => {
          this.notify.openSnackBar(res?.message, 'Dismiss');
          this.close(true);
        });
    }

    if (statusType === StatusType.Partner) {
      statusIds = {
        partner_ids: [id],
        partner_type
      };
    }
    if (statusType === StatusType.Campaign) {
      statusIds = { campaign_ids: [id] };
    }

    this.bulkOperationService
      .changeStatusBulk(statusType, this.changeStatusControl.value, statusIds)
      .pipe(
        finalize(() => {
          this.loader = false;
        })
      )
      .subscribe((res) => {
        this.notify.openSnackBar(res?.message, 'Dismiss');
        this.close(true);
      });
  }

  close(success = false) {
    this.dialogRef.close(success);
  }
}
