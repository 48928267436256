import { Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ActionEvent, PaginatedResponse } from '@interfaces/global';
import { TableData } from '@modules/factory/list-data-table/table-interface';
import { SharedService } from '@services/shared.service';
import AbstractTableComponent from '@utilities/table-component';
import { UpdateService } from '../update.service';
import { UserType } from '@interfaces/user';
import { ColumnTypes } from '@utilities/table-data';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss']
})
export class MessagesComponent
  extends AbstractTableComponent
  implements OnInit, TableData
{
  override method = this.updateService.getAllmessages.bind(this.updateService);
  closeOuter: any;
  linkModifier(dataSource: PaginatedResponse<any[]>): PaginatedResponse<any[]> {
    if (dataSource?.data) {
      dataSource = {
        ...dataSource,
        data: dataSource?.data.map((row) => ({
          ...row,
          sender: {
            label: row?.sender?.name,
            routerLink: [
              '.',
              {
                outlets: {
                  side: [
                    'member-profile',
                    UserType.TeamMember,
                    row?.sender?._id
                  ]
                }
              }
            ]
          },
          receiver: {
            label: row?.receiver?.name,
            routerLink: [
              '.',
              {
                outlets: {
                  side: [
                    'member-profile',
                    UserType.TeamMember,
                    row?.receiver?._id
                  ]
                }
              }
            ]
          }
        })) as any
      };
    }
    return dataSource;
  }

  constructor(
    shared: SharedService,
    private updateService: UpdateService
  ) {
    super(shared);
    this.columnTypeMap = [
      {
        columnName: 'sender',
        type: ColumnTypes.LINK
      },
      {
        columnName: 'receiver',
        type: ColumnTypes.LINK
      },
      {
        columnName: 'createdAt',
        type: ColumnTypes.DATE
      }
    ];
  }

  ngOnInit(): void {
    this.getTableData(this.pageEvent);
    this.closeOuter = '';
  }

  actionMenuClicked(event: ActionEvent<any>) {}
}
