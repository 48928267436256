<!-- Hidden input for avatar upload -->
<input #uploadInput type="file" style="display: none" accept="image/*" (change)="onAvatarUpload($event)" />
<div class="cf-avatar">
  <mat-card-header>
    <ng-template [ngIf]="imgUrl" [ngIfElse]="noPicture">
      <img [src]="imgUrl" alt="avatar" mat-card-avatar [ngClass]="classList" />
    </ng-template>
    <ng-template #noPicture>
      <div mat-card-avatar [ngClass]="classList" *ngIf="initials; else custom">
        {{ initials }}
      </div>

      <ng-template #custom>
        <div mat-card-avatar [ngClass]="classList">
          <ng-content></ng-content>
        </div>
      </ng-template>
    </ng-template>
  </mat-card-header>
  <span *ngIf="editable" ngClass="cf-avatar-control {{ size }}" (click)="onAvatarClick()"
    (keyup.enter)="onAvatarClick()" tabindex="0">
    <mat-icon fontSet="mso" class="avatar-edit-icon">edit</mat-icon>
  </span>
  <span *ngIf="online" ngClass="cf-avatar-online {{ size }}"> </span>
</div>