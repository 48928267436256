export const COHORT_DAY_VALIDATOR = /^D\d{0,10}$/;
export const CLICK_ID_VALIDATOR = /={click_id}/;
export const CAMPAIGN_ID_VALIDATOR = /={campaign_id}/;
export const POSTBACK_URL_VALIDATOR = /(?=.*\={click_id})(?=.*\={goal})/;
export const EMAIL_VALIDATOR_REGEX: RegExp =
  /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
export const TOKENIZED_URL_VALIDATOR =
  /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,8}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
export const IP_ADDRRESS_VALIDATOR =
  /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/(1[0-9]|2[0-9]|3[0-2])$|^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
export const MACRO_VALUE_VALIDATOR = /^[^{}]*$/;
export const DIGITS_ONLY_VALIDATOR = /^\d+$/;
