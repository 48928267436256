import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MultilineMessage, PrimaryButtonConfig } from 'src/app/_interfaces';

@Component({
  selector: 'app-null-state',
  templateUrl: './null-state.component.html',
  styleUrls: ['./null-state.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NullStateComponent {
  @Input()
  loader: boolean;

  @Input()
  hasData: boolean;

  @Input()
  loaderType: 'text' | 'table' = 'text';

  @Input() message: MultilineMessage = {
    line1: 'No data to display...',
    line2: ''
  };

  @Input()
  classes: string[] = [];

  @Input()
  icon = 'search';

  @Input()
  iconColor = '__secondary';

  @Input()
  set size(val: string) {
    this.iconSize = `icon-size-${val}`;
  }

  iconSize = 'icon-size-13';

  @Input() fontSet = 'mso';

  @Input()
  buttonData: PrimaryButtonConfig = null;

  @Output() buttonTrigger: EventEmitter<unknown> = new EventEmitter();

  addNewLink() {
    this.buttonTrigger.emit();
  }
}
