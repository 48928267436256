import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { ApiResponse, PaginatedResponse } from '@interfaces/global';
import ApiBase from '@utilities/api-base';
import { BehaviorSubject, Observable, of, switchMap, tap } from 'rxjs';

export interface Initiator {
  name: string;
  id: number;
}

export interface Message {
  initiator: Initiator;
  description: string;
  title: string;
  level: string;
  category: string;
}

export interface PushNotification {
  id: string;
  message: Message;
  is_read: boolean;
  created_at: string;
}

@Injectable({
  providedIn: 'root'
})
export class PushNotificationService extends ApiBase {
  private notificationStackSub$: BehaviorSubject<PushNotification[]> =
    new BehaviorSubject([]);
  private notificationCountSub$: BehaviorSubject<number> = new BehaviorSubject(
    0
  );

  private _totalCount = 0;
  private _pageSize = 25;

  public get totalCount() {
    return this._totalCount;
  }

  private set totalCount(count: number) {
    this._totalCount = count;
  }

  public get pageSize() {
    return this._pageSize;
  }

  private set pageSize(size: number) {
    this._pageSize = size;
  }

  get notificationStack$() {
    return this.notificationStackSub$.asObservable();
  }

  get notificationCount$() {
    return this.notificationCountSub$.asObservable();
  }

  set notificationCount(count: number) {
    this.notificationCountSub$.next(count);
  }

  constructor(private http: HttpClient) {
    super();
  }

  updateNotificationStack(pageEvent: PageEvent = this.pageEvent) {
    this.getNotifications(pageEvent)
      .pipe(
        tap((response: ApiResponse<PaginatedResponse<PushNotification[]>>) => {
          this.notificationCount = Number(response.message?.split(' ')[0]);
          this.totalCount = response.data.meta.total;
          this.pageSize = response.data.meta.per_page;
        }),
        switchMap(
          (response: ApiResponse<PaginatedResponse<PushNotification[]>>) =>
            of(response.data.data)
        )
      )
      .subscribe((notifications: PushNotification[]) => {
        this.notificationStackSub$.next(notifications);
      });
  }

  getNotifications(
    pageEvent: PageEvent = this.pageEvent
  ): Observable<ApiResponse<PaginatedResponse<PushNotification[]>>> {
    return this.http.get<ApiResponse<PaginatedResponse<PushNotification[]>>>(
      `${this.baseUrl}notifications?page=${pageEvent?.pageIndex}&per_page=${pageEvent?.pageSize}`
    );
  }

  markAllRead(): Observable<ApiResponse<null>> {
    return this.http.get<ApiResponse<null>>(
      `${this.baseUrl}notifications/mark-read`
    );
  }
}
