import { Component, Input } from '@angular/core';
import { DataSourceConfig, PaginatedResponse } from '@interfaces/global';

@Component({
  selector: 'app-table-field',
  templateUrl: './table-field.component.html',
  styleUrls: ['./table-field.component.scss']
})
export class TableFieldComponent {
  config: DataSourceConfig = {
    dataSource: { data: [] } as PaginatedResponse<any>,
    hiddenColumns: ['_id'],
    stickyColumns: ['name'],
    stickyColumnsEnd: [],
    actionMenus: [],
    enableSelection: false
  };

  @Input() set value(val: any) {
    this.config = {
      ...this.config,
      dataSource: { data: val } as PaginatedResponse<any>
    };
  }

  constructor() {}
}
