<h2 mat-dialog-title>Save report</h2>
<mat-dialog-content class="mat-typography">
    <h3>What would you like to call your report?</h3>

    <form [formGroup]="form">
        <mat-form-field appearance="outline" class="w-100">
            <input matInput placeholder="Report name" formControlName="name">
        </mat-form-field>

        <mat-form-field appearance="outline" class="w-100">
            <textarea matInput placeholder="Description" formControlName="description"></textarea>
        </mat-form-field>
    </form>

</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button [class.spinner]="loader" [disabled]="!form.valid" cdkFocusInitial
        (click)="submitFormData()">Save</button>
</mat-dialog-actions>