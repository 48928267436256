import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import get from 'lodash/get';
import * as moment from 'moment';

import { Ranges } from '../_enums';
import { ApiResponse, DateRange, PresetDateRange } from '../_interfaces';
import { NotifyService } from './notify.service';
import { convertToTokens } from '@enums/tracking-generator';

@Injectable({
  providedIn: 'root'
})
export class HelperService {
  private successCodes: number[] = [200, 201, 204];
  constructor(private notify: NotifyService) {}

  arrayToCsv(array) {
    // Use first element to choose the keys and the order
    const keys = Object.keys(array[0]);
    // Build header
    let result = keys.join(',') + '\n';

    // Add the rows
    array.forEach((obj) => {
      keys.forEach((k, ix) => {
        if (ix) {
          result += ',';
        }
        result += obj[k] ? obj[k] : '';
      });
      result += '\n';
    });

    return result;
  }

  handleSuccessText(data: any) {
    const message = data && data?.message ? data?.message : 'Success!';

    this.notify.openSnackBar(message, 'DISMISS');
  }

  handleSuccess(response: ApiResponse<any>) {
    this.notify.openSnackBar(response?.message, 'Dismiss');
  }

  modifyDateFormat(dateR: DateRange, separator = '/'): DateRange {
    if (!dateR) {
      return null;
    }
    return {
      from: moment(new Date(dateR.from)).format(
        `DD${separator}MM${separator}YYYY`
      ),
      to: moment(new Date(dateR.to)).format(`DD${separator}MM${separator}YYYY`)
    };
  }

  modifyDateForDashboard(dateRange): DateRange {
    const dateR = {
      from: dateRange.from ? this.formatDate(dateRange.from) : '',
      to: dateRange.to ? this.formatDate(dateRange.to) : ''
    };

    return dateR;
  }

  // date formate
  formatDate(value) {
    value = new Date(value);
    return (
      value.getFullYear() +
      '-' +
      (value.getMonth() + 1).toString().padStart(2, 0) +
      '-' +
      value.getDate().toString().padStart(2, 0)
    );
  }

  formatDateTime(value: any) {
    value = new Date(value);
    return (
      value.getFullYear() +
      '-' +
      (value.getMonth() + 1).toString().padStart(2, 0) +
      '-' +
      value.getDate().toString().padStart(2, 0) +
      ' ' +
      value.getHours().toString().padStart(2, 0) +
      ':' +
      value.getMinutes().toString().padStart(2, 0) +
      ':' +
      value.getSeconds().toString().padStart(2, 0)
    );
  }

  getRandColor(brightness = 5) {
    // Six levels of brightness from 0 to 5, 0 being the darkest
    const rgb = [
      Math.random() * 256,
      Math.random() * this.getRandomArbitrary(),
      Math.random() * 256
    ];

    // var rgb = [Math.random() * 256, Math.random() * 256, Math.random() * 256];
    const mix = [brightness * 51, brightness * 51, brightness * 51]; // 51 => 255/5
    const mixedrgb = [rgb[0] + mix[0], rgb[1] + mix[1], rgb[2] + mix[2]].map(
      (x) => Math.round(x / 2.0)
    );

    return 'rgb(' + mixedrgb.join(',') + ')';
  }

  getRandomArbitrary(min = 160, max = 220) {
    return Math.random() * (max - min) + min;
  }

  encodeURL(params) {
    return Object.keys(params)
      .map((key) => {
        return key + '=' + params[key];
      })
      .join('&');
  }

  decodeURL(queryString) {
    if (!queryString) return {};
    const query = {};
    const pairs = (
      queryString[0] === '?' ? queryString.substr(1) : queryString
    ).split('&');
    // eslint-disable-next-line @typescript-eslint/prefer-for-of

    for (let i = 0; i < pairs.length; i++) {
      const pair = pairs[i].split('=');

      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }

    return query;
  }

  splitStringOnce(inputString: string, separator: string): string[] {
    const index = inputString.indexOf(separator);

    if (index !== -1) {
      const firstPart = inputString.substring(0, index);
      const secondPart = inputString.substring(index + separator.length);

      return [firstPart, secondPart];
    } else {
      // If the separator is not found, return the whole string in the first part
      return [inputString];
    }
  }

  getParamsFromUrl(url: string, recursive = false) {
    url = decodeURI(url);
    if (typeof url === 'string') {
      const params = this.splitStringOnce(url, '?');
      const eachParamsArr = params[1]?.split('&');
      let obj = {};

      if (eachParamsArr && eachParamsArr.length) {
        eachParamsArr.map((param) => {
          const keyValuePair = param.split('=');
          const key = keyValuePair[0];
          const value = keyValuePair[1];

          if (!!key && (value !== null || value !== undefined)) {
            if (recursive && value?.includes('http')) {
              obj = {
                ...obj,
                ...this.getParamsFromUrl(value, true)
              };
            } else {
              obj[key] = value;
            }
          }
        });
      }

      return obj;
    } else {
      return {};
    }
  }

  // getParamsFromUrl(url: string, recursive = false) {
  //   url = decodeURI(url);
  //   if (typeof url === 'string') {
  //     const params = this.splitStringOnce(url, '?');
  //     const eachParamsArr = params[1]?.split('&');
  //     let obj = {};

  //     if (eachParamsArr && eachParamsArr.length) {
  //       eachParamsArr.map((param) => {
  //         const keyValuePair = param.split('=');
  //         const key = keyValuePair[0];
  //         const value = keyValuePair[1];

  //         if (!!key && (value !== null || value !== undefined)) {
  //           if (recursive && value?.includes('http')) {
  //             obj = {
  //               ...obj,
  //               ...this.getParamsFromUrl(value, true),
  //             };
  //           } else {
  //             if (value) {
  //               if (this.extractValues(value).length > 1) {
  //                 console.error('Multiple values found in a single key');
  //               } else {
  //                 obj[key] = this.extractValues(value)[0];
  //               }
  //             }
  //           }
  //         }
  //       });
  //     }

  //     return obj;
  //   }
  // }

  extractValues(inputString: string): string[] {
    const regex = /\{([^}]+)\}/g;
    const matches = inputString.match(regex) || [];

    // Extract the captured values from the matches
    const values = matches.map((match) => match.slice(1, -1));

    return convertToTokens(values);
  }

  displayFormErrorText(validationErrors, formInstance: UntypedFormGroup) {
    if (validationErrors) {
      Object.keys(validationErrors).forEach((prop) => {
        const formControl = formInstance.get(prop);

        if (formControl) {
          formControl.setErrors({
            serverError: validationErrors[prop]
          });
        }
      });
    }
  }

  dateRangeExist(dateR: DateRange): boolean {
    return dateR && dateR.from && dateR.to ? true : false;
  }

  getDateRangeApiFormat(dateR: DateRange) {
    return {
      from: moment(new Date(dateR.from)).format('DD/MM/YYYY'),
      to: moment(new Date(dateR.to)).format('DD/MM/YYYY')
    };
  }

  setDateRangeToDisplay(dateR: DateRange) {
    if (this.dateRangeExist(dateR)) {
      const dateRModified: DateRange = {
        from: this.formatDate(new Date(dateR.from)),
        to: this.formatDate(new Date(dateR.to))
      };

      return dateRModified;
    }
    return null;
  }

  validateEmail(email: string) {
    if (
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
        email
      )
    ) {
      return true;
    }
    return false;
  }

  downloadCsv(dateRange: DateRange, appId: string, response: any) {
    dateRange = this.modifyDateFormat(dateRange, '_');
    const filename = `${appId ? appId + '_' : ''}${dateRange.from}_${
      dateRange.to
    }_report.csv`;

    return this.downloadCSVByName(filename, response);
  }

  downloadCSVByName(name: string, data: any) {
    const blob = new Blob([this.arrayToCsv(data)]);
    const navigator = window.navigator as any;

    if (get(navigator, 'msSaveOrOpenBlob')) {
      // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
      navigator.msSaveBlob(blob, name);
    } else {
      const a = window.document.createElement('a');

      a.href = window.URL.createObjectURL(blob);
      a.download = name;
      document.body.appendChild(a);
      // IE: 'Access is denied';
      // see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
      a.click();
      document.body.removeChild(a);
    }
  }

  formatTimeZone($timezones) {
    const temp = [];

    $timezones.map((t) => {
      temp.push({
        zone: t.zone,
        gmt: t.gmt,
        name: t.name,
        label: t.zone + ' - ' + t.name
      });
    });
    return temp;
  }

  get R() {
    return Ranges;
  }

  // find number of days between two dates
  getDaysBetweenDates(from: moment.Moment, to: moment.Moment): number {
    const diffDays = to.diff(from, 'days');

    return diffDays;
  }

  getDefaultDateRange(mode: Ranges = Ranges.T): DateRange {
    if (this.setupPresets()) {
      const obj = this.setupPresets().find(
        (p: { presetLabel: Ranges }) => p.presetLabel === mode
      );

      return obj.range;
    } else {
      return null;
    }
  }

  getRange(mode: Ranges, format = null): DateRange {
    if (this.setupPresets()) {
      const obj = this.setupPresets().find(
        (p: { presetLabel: Ranges }) => p.presetLabel === mode
      );

      if (format) {
        obj.range = this.convertToFormat(obj.range, format);
      }
      return obj.range;
    } else {
      return null;
    }
  }

  convertToFormat(dateRange: DateRange, format: string): DateRange {
    if (dateRange) {
      const from = moment(dateRange.from).format(format);
      const to = moment(dateRange.to).format(format);

      return { from, to };
    }

    return null;
  }

  convertDateFormat(date: Date, format: string) {
    return moment(date).format(format);
  }

  // helper function to create initial presets
  setupPresets(): PresetDateRange[] {
    const backDate = (numOfDays: number) => {
      const $today = new Date();

      return new Date($today.setDate($today.getDate() - numOfDays));
    };

    const today = new Date();
    const yesterday = backDate(1);
    const minus7 = backDate(7);
    const minus30 = backDate(30);
    const currMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
    const currMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
    const lastMonthStart = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      1
    );
    const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);

    return [
      { presetLabel: this.R.T, range: { from: today, to: today } },
      { presetLabel: this.R.Y, range: { from: yesterday, to: yesterday } },
      { presetLabel: this.R.L7, range: { from: minus7, to: today } },
      { presetLabel: this.R.L30, range: { from: minus30, to: today } },
      {
        presetLabel: this.R.CM,
        range: { from: currMonthStart, to: currMonthEnd }
      },
      {
        presetLabel: this.R.LM,
        range: { from: lastMonthStart, to: lastMonthEnd }
      }
    ];
  }

  notifyError(message: string): Error {
    this.notify.openSnackBar(message, 'Dismiss');
    return new Error(message);
  }

  jsonToFormData(
    json: any,
    formData: FormData = new FormData(),
    prefix = ''
  ): FormData {
    for (const key of Object.keys(json)) {
      const value = json[key];
      const name = prefix ? `${prefix}[${key}]` : key;

      if (value instanceof File) {
        formData.append(name, value, value.name);
      } else if (value instanceof FileList) {
        for (let i = 0; i < value.length; i++) {
          formData.append(`${name}[${i}]`, value[i], value[i].name);
        }
      } else if (typeof value === 'object' && value !== null) {
        this.jsonToFormData(value, formData, name);
      } else {
        formData.append(name, value);
      }
    }
    return formData;
  }

  ddmmyyToJsdate(dateString: string) {
    if (!dateString) {
      return null;
    }
    const dateParts = dateString?.split('-');
    const year = parseInt(dateParts[2], 10);
    const month = parseInt(dateParts[1], 10) - 1; // month is zero-indexed
    const day = parseInt(dateParts[0], 10);
    const dateObject = new Date(year, month, day);

    return dateObject;
  }

  sanitizeTokenizedUrl = (
    url: string,
    validator: (params: string) => boolean
  ) => {
    if (!url) return '';
    const currentUrl = url;
    const finalUrl = currentUrl?.split('?')[0];
    const finalParams = {};
    const searchParams = this.decodeURL(currentUrl?.split('?')[1]) || {};

    Object.keys(searchParams)
      .filter((key) => !!key && validator(searchParams[key]))
      .forEach((key) => {
        finalParams[key] = searchParams[key];
      });

    return decodeURIComponent(
      `${finalUrl}?${new URLSearchParams(finalParams).toString()}`
    );
  };
}
