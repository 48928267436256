import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-phone-cell',
  templateUrl: './phone-cell.component.html',
  styleUrls: ['./phone-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PhoneCellComponent {
  @Input() phone: string;
  constructor() { }
}
