<div class="profile-outer-container ac-theme-background">
  <div class="container-fluid px-2 pt-4 pb-2">
    <mat-card class="mx-3" *ngIf="userInfo$ | async as UserDetails">
      <div class="row">

        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 mb-4">
          <!-- Name and email, avatar -->
          <div fxLayout="row" fxLayoutAlign="start center" class="mb-2">
            <div fxLayout="column" fxLayoutAlign="start start" class="mr-2">
              <app-avatar size="medium" [editable]="false" [name]="fullname$ | async"></app-avatar>
            </div>
            <div fxLayout="column" fxLayoutAlign="start start" class="mt-2">
              <app-text-line type="leading" weight="strong" class="text-center">{{
                fullname$ | async
                }}</app-text-line>
              <app-text-line type="base" textColor="theme" class="text-center">
                {{ UserDetails?.email }}
              </app-text-line>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <!-- User details -->
          <div fxLayout="column" class="profile-widget">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="brb mb-2">
              <app-text-line type="leading" textColor="theme">Your personal details</app-text-line>
            </div>
            <app-two-column-table class="w-100" [dataSource]="[
                      {
                        key: 'Phone',
                        value: UserDetails?.phone,
                        type: 'phone'
                      },
                      { key: 'Status', value: 'Active', type: 'status' },
                      {
                        key: 'Country',
                        value: UserDetails?.country_code,
                        type: 'country'
                      }
                    ]"></app-two-column-table>
          </div>
        </div>

        <!-- Password change -->
        <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <div fxLayout="column" class="profile-widget">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="brb mb-2">
              <app-text-line type="leading" textColor="theme">Request password change</app-text-line>
            </div>
            <app-text-line weight="mb-2" [enableWrap]="true">
              Click the <b>send link</b> button to send a password reset
              link to your registered email address.
            </app-text-line>
            <div>
              <button mat-raised-button color="primary" (click)="changePassword()">
                Send link
              </button>
            </div>
          </div>
        </div>

        <!-- Support request -->
        <ng-template appContentPermission="{{ModuleNames.Support}}.{{Crud.Read}}">
          <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
            <div fxLayout="column" class="profile-widget">
              <div fxLayout="row" fxLayoutAlign="space-between center" class="brb">
                <app-text-line type="leading" textColor="theme">Support request</app-text-line>
                <mat-icon fontSet="mso" [matTooltip]="supportText">help</mat-icon>
              </div>


              <ng-container *ngIf="accountManager$ | async as Accountmanager">
                <div fxLayout="row" fxLayoutAlign="start center" class="mb-2">
                  <div fxLayout="column" fxLayoutAlign="start start" class="mr-2">
                    <app-avatar size="medium" [editable]="false" name="{{ Accountmanager?.name }}"></app-avatar>
                  </div>
                  <div fxLayout="column" fxLayoutAlign="start start" class="mt-2">
                    <app-text-line type="leading" weight="strong" class="text-center">{{ Accountmanager?.name
                      }}</app-text-line>
                    <app-custom-cell [value]="Accountmanager?.email" type="email"></app-custom-cell>
                    <app-text-line [appTextCopy]="Accountmanager?.phone || null" [matTooltip]="
                                                                        Accountmanager?.phone ? 'Click to copy' : ''
                                                                      ">
                      {{ Accountmanager?.phone }}
                    </app-text-line>
                  </div>
                </div>
              </ng-container>

              <app-text-line weight="mb-3" type="small" textColor="error" [enableWrap]="true">
                <b>Note:</b> Access granted is only valid for <b>1 hour</b>.
                You can revoke access at any time by clicking the
                <b>Revoke access</b> button.
              </app-text-line>

              <div fxLayout="row wrap" fxLayoutAlign="space-between center">
                <div fxLayout="row" fxLayoutAlign="start center" class="mb-1">
                  <button mat-raised-button color="primary" class="mr-2"
                    [disabled]="accessGrantedStatus || accessCheckLoader" (click)="grantAccess()">
                    Grant access
                  </button>
                  <button mat-raised-button class="mr-2" color="warn"
                    [disabled]="!accessGrantedStatus || accessCheckLoader" (click)="revokeAccess()">
                    Revoke access
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-template>

        <!-- API docs and API key link -->
        <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <div fxLayout="column" class="profile-widget">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="brb mb-2">
              <app-text-line type="leading" textColor="theme">Your API key</app-text-line>
            </div>

            <mat-label>
              <small>API Key</small>
            </mat-label>
            <app-filter-option [selected]="true" [appTextCopy]="apiKey || null"
              [matTooltip]="apiKey ? 'Click to copy' : ''">
              <div class="f-row">
                <div class="f-1">
                  {{ apiKey || '{your_api_key_will_appear_here}' }}
                </div>
              </div>
            </app-filter-option>

            <mat-label class="mt-2">
              <small>API docs link</small>
            </mat-label>

            <app-filter-option [selected]="true" [appTextCopy]="apiKey || null"
              [matTooltip]="apiKey ? 'Click to copy' : ''">
              <div class="f-row">
                <div class="f-1">
                  <a href="https://appcarry.apidog.io/" target="_blank">https://appcarry.apidog.io/</a>
                </div>
              </div>
            </app-filter-option>
          </div>
        </div>

        <!-- 2FA change -->
        <div class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <div fxLayout="column" class="profile-widget">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="brb mb-2">
              <app-text-line type="leading" textColor="theme">Manage Two Factor Authentication</app-text-line>
            </div>
            <app-text-line weight="mb-2" [enableWrap]="true">
              Enable 2FA to add an extra layer of security to your account.
            </app-text-line>
            <div>
              <mat-slide-toggle [formControl]="twoFactorControl">Enable 2FA</mat-slide-toggle>
            </div>
          </div>
        </div>

        <!-- Your teams -->
        <div *ngIf="userTeams?.length>0" class="col-lg-4 col-md-12 col-sm-12 col-xs-12">
          <div fxLayout="column" class="profile-widget">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="brb mb-2">
              <app-text-line type="leading" textColor="theme">Your teams</app-text-line>
            </div>
            <div class="scrollable-area w-100">
              <ng-container *ngFor="let team of userTeams">
                <app-filter-option class="w-100" [selected]="true">{{
                  team | titlecase
                  }}</app-filter-option>
              </ng-container>
            </div>
          </div>
        </div>

      </div>
    </mat-card>
  </div>

  <ng-template appContentPermission="{{ModuleNames.Team}}.{{Crud.Read}}">
    <div class="container-fluid px-2">
      <div class="row m-0 mb-4">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 d-flex">
          <mat-card class="flex-fill">
            <app-dashboard-widget>
              <ng-container title>
                <app-text-line type="leading" textColor="theme">My team members</app-text-line>
              </ng-container>
              <ng-container content>
                <div class="row m-0 mb-2" [style.height.px]="400">
                  <app-list-data-table class="w-100" [isLoading]="loader" [showToolbar]="false"
                    [searchTerm]="searchControl?.value" [dataSourceConfig]="dataSourceConfig"
                    [columnTypeMap]="columnTypesMap" [paginate]="false">
                    <ng-container emptyStateSection>
                      <app-null-state [loader]="loader" loaderType="table" icon="person_add" [size]="20" [message]="{
                          line1: 'You do not have any members in our team',
                          line2: 'You can add team members from settings page'
                        }">
                        <!-- <button mat-raised-button color="primary" [routerLink]="[
                            '..',
                            {
                              outlets: {
                                side: ['settings', 'team-members']
                              }
                            }
                          ]">
                          <mat-icon class="icon-size-5 mr-1">add</mat-icon>
                          Add team member
                        </button> -->
                      </app-null-state>
                    </ng-container>
                  </app-list-data-table>
                </div>
              </ng-container>
            </app-dashboard-widget>
          </mat-card>
        </div>
      </div>
    </div>
  </ng-template>
</div>