import { Component, OnDestroy } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { BrandingResponse } from '@interfaces/global';
import { OutletTitle, SharedService } from '@services/shared.service';
import { StorageService } from '@services/storage.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-abstract-component',
  template: ''
})
export default abstract class AbstractComponent implements OnDestroy {
  openSidebar: boolean;
  openSidebarOuter: boolean;
  titleFromRoute: Observable<OutletTitle>;
  subs = new SubSink();
  loader: boolean;
  pageEvent: PageEvent = {
    length: 0,
    pageIndex: 1,
    pageSize: 25,
    previousPageIndex: 0
  };

  constructor(shared: SharedService) {
    this.titleFromRoute = shared.title;
  }

  prepareTwoColumnDataSource(
    dataSource: any[],
    keyProperty: string,
    valueProperty: string
  ): Array<{ key: string; value: any }> {
    return dataSource?.map((row) => ({
      key: row[keyProperty],
      value: row[valueProperty]
    }));
  }

  prepareTwoColumnDataSourceFromObject(
    data: any,
    properties: string[]
  ): Array<{ key: string; value: any }> {
    const ds = [];

    Object.keys(data)?.forEach((property) => {
      if (
        properties?.some(
          (prop) =>
            prop === property &&
            (data[property] !== undefined || data[property] !== null)
        )
      ) {
        ds.push({
          key: property,
          value: data[property]
        });
      }
    });

    return ds;
  }
  get subdomain() {
    const { origin } = new URL(window.location.origin);
    const branding: BrandingResponse = StorageService.getLocalItem(
      environment.production ? origin : environment.networkOrBrandDomain
    );

    return branding?.domain;
  }

  unsubscribe() {
    this.subs?.unsubscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
