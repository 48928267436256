<div fxFill [ngClass]="['Appcarry-' + (panelTheme$ | async)]">
  <mat-drawer-container class="w-100 h-100 __canvas" autosize [hasBackdrop]="false">
    <mat-drawer mode="over" position="end" [opened]="openContentSidebar" [disableClose]="(isMobile$ | async) === false"
      animationDuration="90">
      <router-outlet name="side" (activate)="openContentSidebar = true" (deactivate)="openContentSidebar = false">
      </router-outlet>
    </mat-drawer>

    <app-top-navigation [background]="(brandColor | async) || 'primary'"></app-top-navigation>

    <mat-drawer-container class="drawer-container" autosize [hasBackdrop]="(isMobile$ | async)">
      <mat-drawer #drawer class="app-sidenav" mode="over" [opened]="openSidebar" animationDuration="90"
        (openedChange)="setSidenavState = $event" [ngClass]="{ 'no-shadow': openSidebar }"
        [disableClose]="(isMobile$ | async) === false" [autoFocus]="false">
        <div class="menu-container-wrapper">
          <div class="menu-container">
            <app-expansion-menu> </app-expansion-menu>
          </div>
        </div>
      </mat-drawer>

      <div class="w-100 h-100"
        [ngClass]="{'main-container': (isLoggedIn$ | async) === true && (isLoggedIn$ | async) !== null && (isLoggedIn$ | async) !== undefined}">
        <app-global-loader></app-global-loader>
        <router-outlet></router-outlet>
      </div>
    </mat-drawer-container>
  </mat-drawer-container>
</div>