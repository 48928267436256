<ng-container [ngSwitch]="type">
  <ng-template ngSwitchCase="metric">
    <h1
      class="metric {{ weight }} {{ '__' + textColor }}"
      [ngClass]="{ 'no-wrap': !enableWrap, 'break-all': breakAll }">
      <ng-container *ngTemplateOutlet="contentArea"></ng-container>
    </h1>
  </ng-template>
  <ng-template ngSwitchCase="display">
    <h1
      class="display {{ weight }} {{ '__' + textColor }}"
      [ngClass]="{ 'no-wrap': !enableWrap, 'break-all': breakAll }">
      <ng-container *ngTemplateOutlet="contentArea"></ng-container>
    </h1>
  </ng-template>
  <ng-template ngSwitchCase="headline">
    <h2
      class="headline {{ weight }} {{ '__' + textColor }}"
      [ngClass]="{ 'no-wrap': !enableWrap, 'break-all': breakAll }">
      <ng-container *ngTemplateOutlet="contentArea"></ng-container>
    </h2>
  </ng-template>
  <ng-template ngSwitchCase="subheading">
    <h3
      class="subheading {{ weight }} {{ '__' + textColor }}"
      [ngClass]="{ 'no-wrap': !enableWrap, 'break-all': breakAll }">
      <ng-container *ngTemplateOutlet="contentArea"></ng-container>
    </h3>
  </ng-template>
  <ng-template ngSwitchCase="leading">
    <h4
      class="leading {{ weight }} {{ '__' + textColor }}"
      [ngClass]="{ 'no-wrap': !enableWrap, 'break-all': breakAll }">
      <ng-container *ngTemplateOutlet="contentArea"></ng-container>
    </h4>
  </ng-template>
  <ng-template ngSwitchCase="base">
    <p
      class="base {{ weight }} {{ '__' + textColor }}"
      [ngClass]="{ 'no-wrap': !enableWrap, 'break-all': breakAll }">
      <ng-container *ngTemplateOutlet="contentArea"></ng-container>
    </p>
  </ng-template>
  <ng-template ngSwitchCase="small">
    <p
      class="small {{ weight }} {{ '__' + textColor }}"
      [ngClass]="{ 'no-wrap': !enableWrap, 'break-all': breakAll }">
      <ng-container *ngTemplateOutlet="contentArea"></ng-container>
    </p>
  </ng-template>
  <ng-template ngSwitchCase="xsmall">
    <p
      class="xsmall {{ weight }} {{ '__' + textColor }}"
      [ngClass]="{ 'no-wrap': !enableWrap, 'break-all': breakAll }">
      <ng-container *ngTemplateOutlet="contentArea"></ng-container>
    </p>
  </ng-template>
  <ng-template #contentArea>
    <ng-content></ng-content>
  </ng-template>
</ng-container>
