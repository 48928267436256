import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-filter-option',
  templateUrl: './filter-option.component.html',
  styleUrls: ['./filter-option.component.scss']
})
export class FilterOptionComponent {
  @Input() disabled: boolean;
  @Input() selected: boolean;

  constructor() {}
}
