import { StorageService } from '@services/storage.service';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';

import { environment } from 'src/environments/environment';
import {
  APPCARRY_WEB_SDK,
  CONVERSION_IFRAME,
  CONVERSION_PIXEL,
  GLOBAL_POSTBACK_URL,
  IMPRESSION_PIXEL,
  JS_TAG,
  POSTBACK_URL,
  TRACKING_URL,
  TrackingTypes
} from './globals';

export interface TrackingParams {
  tokenParams: any;
  commonParams: any;
}

export interface TrackingConfig {
  trackingString: string;
  campaignParams?: any;
  params?: any;
  domain?: string;
}

export const trackingGenerator = (
  trackingType: TrackingTypes,
  primaryParams: any,
  campaignParams: any
) => {
  switch (trackingType) {
    case TrackingTypes.GlobalPostbackUrl:
      return trackingValue({
        trackingString: GLOBAL_POSTBACK_URL.value,
        params: primaryParams
      });

    case TrackingTypes.PostBack_Conversion:
      return trackingValue({
        trackingString: POSTBACK_URL.value,
        params: primaryParams,
        campaignParams
      });

    case TrackingTypes.TrackingUrl:
      return rearrangeQueryParams(
        trackingValue({
          trackingString: TRACKING_URL.value,
          params: primaryParams,
          campaignParams
        })
      );

    case TrackingTypes.Conversion_Iframe:
      return trackingValue({
        trackingString: CONVERSION_IFRAME.value,
        params: { tracking_domain: primaryParams?.tracking_domain }
      });

    case TrackingTypes.Conversion_Pixel:
      return trackingValue({
        trackingString: CONVERSION_PIXEL.value,
        params: { tracking_domain: primaryParams?.tracking_domain }
      });

    case TrackingTypes.Appcarry_Web_SDK:
      return trackingValue({
        trackingString: APPCARRY_WEB_SDK.value,
        params: { tracking_domain: primaryParams?.tracking_domain }
      });

    case TrackingTypes.JS_Tag:
      return trackingValue({
        trackingString: JS_TAG.value,
        params: { tracking_domain: primaryParams?.tracking_domain }
      });

    case TrackingTypes.ImpressionPixel:
      return trackingValue({
        trackingString: IMPRESSION_PIXEL.value,
        params: {
          ...primaryParams,
          impression_id: '{impression_id}'
        }
      });

    default:
      return null;
  }
};

export const trackingValue = ({
  trackingString,
  params,
  campaignParams,
  domain
}: TrackingConfig) => {
  const securityToken = getSecurityToken();
  const stringifiedParam = decodeURIComponent(
    `${new URLSearchParams(
      filteredParams({
        ...campareAndMergeValidParams(params, campaignParams)
      })
    )}`
  );

  domain = domain || params?.tracking_domain;

  trackingString = trackingString
    .replace(`[[QUERYPARAMS]]`, stringifiedParam)
    .replace('[[DOMAIN]]', domain || '{DOMAIN}')
    .replace(
      '[[PARAMS]]',
      JSON.stringify(filteredParams({ security_token: securityToken }))
    )
    .replace('{SECURITY_TOKEN}', securityToken);

  return trackingString;
};

export const getSecurityToken = () => {
  const brandDetails = StorageService.getLocalItem(
    environment.production ? origin : environment.networkOrBrandDomain
  );

  return brandDetails?.security_token || '{SECURITY_TOKEN}';
}

export const rearrangeQueryParams = (url: string): string => {
  if (!url) return url;

  try {
    const urlObject = new URL(url);
    const queryParams = urlObject.searchParams;

    const paramsWithoutBraces: string[] = [];
    const paramsWithBraces: string[] = [];

    // Separate query parameters based on values containing or not containing { and }
    queryParams.forEach((value, key) => {
      if (value.includes('{') && value.includes('}')) {
        paramsWithBraces.push(`${key}=${value}`);
      } else {
        paramsWithoutBraces.push(`${key}=${value}`);
      }
    });

    // Concatenate the arrays to form the rearranged query parameters
    const rearrangedQueryParams = [...paramsWithoutBraces, ...paramsWithBraces];

    // Update the URL with the rearranged query parameters
    urlObject.search = rearrangedQueryParams.join('&');

    return urlObject.toString();
  } catch (error) {
    return url;
  }
};

export const filteredParams = (params: any) => {
  const obj = {};

  Object.keys(cloneDeep(params)).forEach((key) => {
    if (key !== 'tracking_domain' && key !== 'trackingType' && !!params[key]) {
      obj[key] = params[key];
    }
  });

  return obj;
};

export const campareAndMergeValidParams = (
  primaryParams: any,
  campaignParams: any
) => {
  const finalParams = {};
  const keys = Array.from(
    new Set([
      ...Object.keys(primaryParams || {}),
      ...Object.keys(campaignParams || {})
    ])
  );

  keys.forEach((key) => {
    if (get(campaignParams, key) && get(primaryParams, key)) {
      if (
        !campaignParams[key]?.includes('{') &&
        !campaignParams[key]?.includes('}')
      ) {
        finalParams[key] = campaignParams[key];
      } else {
        finalParams[key] = primaryParams[key];
      }
    } else if (!get(campaignParams, key) && get(primaryParams, key)) {
      finalParams[key] = primaryParams[key];
    } else if (get(campaignParams, key)) {
      finalParams[key] = campaignParams[key];
    }
  });

  return finalParams;
};

/**
 * Extracts the unique tokens from the given string
 * tokens are extracted without the curly braces
 * @param inputString string
 * @returns string[]
 */
export const extractUniqueTokensFromUrl = (inputString: string): string[] => {
  if (!inputString) return [];

  const regex = /\{([^}]+)\}/g;
  const matches = inputString.match(regex) || [];

  // Extract the captured values from the matches
  const tokens = matches.map((match) => match.slice(1, -1));

  return Array.from(new Set(tokens)); // remove duplicates
};

export const convertToTokens = (tokens: string[]) => {
  return tokens.map((token) => `{${token}}`);
};

/**
 * Converts the given tokens to macros
 * @param tokes string[]
 * @returns { key: string; value: string }[]
 */
export const convertTokensToMacros = (
  tokes: string[]
): { key: string; value: string }[] => {
  return tokes.map((token) => {
    return {
      key: token,
      value: `{${token}}`
    };
  });
};

/**
 * Returns the tracking params from the given url
 * @param url string
 * @returns { key: string; value: string }[]
 */
export const getTrackingParams = (
  url: string
): { key: string; value: string }[] => {
  return convertTokensToMacros(extractUniqueTokensFromUrl(url));
};
