import { Directive, ElementRef, Renderer2, OnInit, Input } from '@angular/core';
import { PremiumFeatureKeys } from '@interfaces/permissions';
import { AuthService } from '@services/auth.service';

@Directive({
  selector: '[appPremiumFeature]'
})
export class PremiumFeatureDirective implements OnInit {
  @Input() appPremiumFeature: PremiumFeatureKeys;
  @Input() featureIcon: string = 'workspace_premium'; // Default icon name
  @Input() featureIconPosition: 'before' | 'after' = 'before';

  constructor(
    private el: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    private auth: AuthService
  ) { }

  ngOnInit() {
    this.prependIcon();
  }

  private prependIcon() {
    if (
      !this.featureIcon ||
      !this.auth.isPremiumFeature(this.appPremiumFeature)
    ) {
      return;
    }

    const iconContainerElement = this.renderer.createElement('div');

    this.renderer.addClass(iconContainerElement, 'h-100');
    this.renderer.addClass(iconContainerElement, 'pr-1');
    this.renderer.addClass(iconContainerElement, 'd-flex');
    this.renderer.addClass(iconContainerElement, 'align-items-center');
    this.renderer.addClass(iconContainerElement, 'justify-content-center');
    this.renderer.addClass(iconContainerElement, 'premium-fixed-right');

    const iconElement = this.renderer.createElement('mat-icon');

    this.renderer.setAttribute(iconElement, 'title', 'Premium feature');

    this.renderer.addClass(iconElement, '__theme');
    this.renderer.addClass(iconElement, 'icon-size-5');
    this.renderer.addClass(iconElement, 'mso');

    const iconText = this.renderer.createText(this.featureIcon);

    this.renderer.appendChild(iconElement, iconText);
    this.renderer.appendChild(iconContainerElement, iconElement);

    this.el.nativeElement.classList.add('position-relative');
    this.el.nativeElement.appendChild(iconContainerElement);
  }
}
