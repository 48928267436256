<h2 mat-dialog-title align="center">Premium feature</h2>
<mat-dialog-content class="mat-typography" fxLayout="column" fxLayoutAlign="center start">
  <div class="premium-icon-container" fxLayout="row" fxLayoutAlign="center center">
    <mat-icon color="primary" class="icon-size-20" fontSet="mso">admin_panel_settings</mat-icon>
  </div>
  <app-text-line [enableWrap]="true" weight="text-center">{{
    message
    }}</app-text-line>
  <div fxLayout="row" fxLayoutAlign="center center" class="w-100">
    <a href="#" class="text-center">#AppcarryPremium</a>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-button mat-dialog-close>Dismiss</button>
  <button mat-raised-button [mat-dialog-close]="true" color="primary" cdkFocusInitial>
    Get premium
  </button>
</mat-dialog-actions>