import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { URL_DATE_FORMAT } from '@enums/globals';
import { AuthService } from '@services/auth.service';
import { HelperService } from '@services/helper.service';
import { PanelTheme, SharedService } from '@services/shared.service';
import moment from 'moment';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { NavigationMenuItem, UserType } from 'src/app/_interfaces';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-expansion-menu',
  templateUrl: './expansion-menu.component.html',
  styleUrls: ['./expansion-menu.component.scss']
})
export class ExpansionMenuComponent implements OnInit, OnDestroy {
  menuItems: NavigationMenuItem[] = [];
  private showSubMenuDrawer: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private _currentFocusedMenu: BehaviorSubject<NavigationMenuItem> = new BehaviorSubject(null);
  private _currentSelectedMenu: BehaviorSubject<NavigationMenuItem> = new BehaviorSubject(null);

  public showSubMenuDrawer$: Observable<boolean> = this.showSubMenuDrawer.asObservable();
  subs = new SubSink();
  activeMenu: NavigationMenuItem = null;

  @Input() selectedMenuItem: NavigationMenuItem = null;
  @Output() currentMenu = new EventEmitter();
  selectedMenu: string;
  currentRoutePath: string;
  brandColor: Observable<string>;
  dateRange: { start: string, end: string };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
    private shared: SharedService,
    private helper: HelperService
  ) {
    this.subs.sink = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.currentRoutePath = event.url;
      });

    this.brandColor = this.shared.currentBrandColor;
    const { from, to } = this.helper.getDefaultDateRange();

    this.dateRange = {
      start: moment(from).format(URL_DATE_FORMAT),
      end: moment(to).format(URL_DATE_FORMAT)
    };
  }

  ngOnInit(): void {
    this.auth.menuListSub.subscribe((items) => {
      this.menuItems = [...items];
    });

    combineLatest([this._currentFocusedMenu, this._currentSelectedMenu])
      .pipe(debounceTime(100))
      .subscribe(([focused,]) => {
        this.activeMenu = focused;
      })
  }

  isActiveParentOrChild(menu: NavigationMenuItem, isChild?: boolean) {
    if (typeof menu.url !== 'string') {
      return false;
    }

    const parentPathName =
      this.currentRoutePath?.split('/')[isChild ? 2 : 1] || '';

    return parentPathName.indexOf(menu.url) !== -1;
  }

  selectMenu(item: NavigationMenuItem) {
    this.currentMenu.emit(item.url);
  }

  isCurrentSubMenu(item: NavigationMenuItem): boolean {
    return item.url === this.selectedMenu;
  }

  hasSubMenu(item: NavigationMenuItem) {
    return item.children && item.children.length > 0;
  }
  isDisabled(item: NavigationMenuItem) {
    return item.disabled && item.disabled === true;
  }

  flattenUrl(url: string[]) {
    return [].concat(...url);
  }

  public get isOwner() {
    return this.auth.userType === UserType.Owner;
  }

  menuHover(menu: NavigationMenuItem) {
    if (menu?.children?.length) {
      this._currentFocusedMenu.next(menu);
      this.showSubMenuDrawer.next(true);
    } else {
      this._currentFocusedMenu.next(null);
      this.showSubMenuDrawer.next(false);
    }
  }

  menuClick(menu: NavigationMenuItem) {
    this._currentSelectedMenu.next(menu);
  }

  mouseLeave() {
    this.showSubMenuDrawer.next(false);
  }

  subMenuDrawerClosed() {
    this._currentFocusedMenu.next(null);
  }

  /**
   * Get the first child menu url
   * @param item NavigationMenuItem
   * @returns string
   */
  getMenuUrl(item: NavigationMenuItem) {
    if (item?.children?.length > 0) {
      const firstChild = item?.children?.find((i) => !i?.outlet);

      return `${item?.url}/${firstChild?.url}`;
    }

    return item?.url;
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}