import {
  Component,
  OnInit,
  OnDestroy,
  forwardRef,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnChanges
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR
} from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SearchComponent)
    }
  ]
})
export class SearchComponent
  implements ControlValueAccessor, OnInit, OnChanges, OnDestroy {
  constructor() { }

  /**
   * Define an indiviual form control
   */
  control: UntypedFormControl = new UntypedFormControl('');

  private sub: Subscription[] = [];

  /**
   * pass the suffix button Icon
   */
  @Input() suffixIcon: string;

  /**
   * custom class for search
   */
  @Input() classes: string;

  /**
   * the curent icon font being used
   */
  fontSet = 'mso';

  /**
   * Set the icon font type e.g. solid | light | brand | duotone
   */
  @Input()
  set iconFontType(font: string) {
    if (font && font.trim()) {
      this.fontSet = `${font.toLowerCase()}`;
    }
  }

  /**
   * Detect if the form field is active
   */
  isActive: boolean;

  /**
   * Getting placeholder from parent
   */
  @Input() placeholder: string;

  /**
   * To get id for the field
   */
  @Input() id: number;

  /**
   * To emit value change of input to parent
   */
  @Output() valueChange = new EventEmitter<string>();

  /**
   * To emit value change of input to parent
   */
  @Output() afterFocus = new EventEmitter<string>();

  /**
   * To emit value change of input to parent
   */
  @Output() afterBlur = new EventEmitter<string>();

  /**
   * To emit value change of input to parent
   */
  @Output() actionClicked = new EventEmitter<any>();

  /**
   * Input to set the disabled value
   */
  @Input() set disabled(value: boolean) {
    this.setDisabledState(value);
  }

  /**
   * Explicitly set/change the field's disabled state
   * @param isDisabled Pass the current state(boolean)
   */
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }

  /**
   * onChange handler for change event
   * @param value pass the current state(string)
   */
  onChange = (value: string): void => { };

  /**
   * Register touched event
   */
  onTouched = (): void => { };

  /**
   * Call onTouched on click
   */
  registerTouchEvents(value: any): void {
    this.control.markAsTouched();
    this.onTouched();
    this.isActive = value === 'focus';

    if (value === 'focus') {
      this.afterFocus.emit(this.control.value);
    }
    if (value === 'blur') {
      this.afterBlur.emit(this.control.value);
    }
  }

  /**
   * Call onChange on value change
   * @param value pass the current value
   */
  afterChange(value: string): void {
    this.valueChange.emit(value);
    this.onChange(value);
  }

  /**
   * Method gets called when formcontrol value changes
   * inside a formGroup
   * @param val Pass the current value(string)
   */
  writeValue(val: string): void {
    this.control.setValue(val);
  }

  /**
   * Angular method to register the change event
   * to be active only inside a formGroup
   * @param onChange function passed on change
   */
  registerOnChange(onChange: (value: any) => void): void {
    this.onChange = onChange;
  }

  /**
   * Angular method to register the touched event
   * to be active only inside a formGroup
   * @param fn function passed on touched
   */
  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  /**
   * Clear the text inside the search field
   */
  clearSearch(): void {
    this.control.setValue('');
  }

  /**
   * Angular hook for detecting any changes
   * @param changes Interface containing all the input parameters
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.value?.previousValue !== changes?.value?.currentValue) {
      this.control.setValue(changes?.value?.currentValue);
      this.valueChange.emit(changes?.value?.currentValue);
    }
  }

  /**
   * ANGULAR HOOK
   */
  ngOnInit(): void {
    const subscription = this.control.valueChanges.subscribe((data) => {
      this.onChange(data);
      this.valueChange.emit(data);
    });

    this.sub.push(subscription);
  }

  ngOnDestroy(): void {
    this.sub.map((subscription) => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}
