import { Injectable } from '@angular/core';
import {
  ACTIONCOLUMNKEY,
  EXPANSIONCOLUMNKEY,
  SELECTIONCOLUMNKEY
} from '@enums/globals';
import { ColumnsConfig } from '@interfaces/global';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataTableService {
  private currentDisplayedColumns$ = new BehaviorSubject<string[]>([]);

  public currentDisplayedColumnsSub =
    this.currentDisplayedColumns$.asObservable();

  public set currentDisplayedColumns(displayedColumns: string[]) {
    const specialColumns = [
      SELECTIONCOLUMNKEY,
      EXPANSIONCOLUMNKEY,
      ACTIONCOLUMNKEY,
      '_id',
      'id'
    ];

    this.currentDisplayedColumns$.next(
      displayedColumns?.filter((col) => specialColumns?.indexOf(col) === -1)
    );
  }

  private columnsConfig$ = new BehaviorSubject<ColumnsConfig>(null);
  columnsConfigSub = this.columnsConfig$.asObservable();

  set columnsConfig(config: ColumnsConfig) {
    this.columnsConfig$.next(config);
  }

  constructor() {}
}
