import { Component, Input } from '@angular/core';

export interface ToolbarData {
  title: string;
}

@Component({
  selector: 'app-table-toolbar',
  templateUrl: './table-toolbar.component.html',
  styleUrls: ['./table-toolbar.component.scss']
})
export class TableToolbarComponent {
  @Input() toolbarData: ToolbarData;
  @Input() shadowOn: boolean;
  constructor() {}
}
