import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

// @angular material modules
import { A11yModule } from '@angular/cdk/a11y';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';

import { ClipboardModule } from '@angular/cdk/clipboard';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

// Common Components
import { PortalModule } from '@angular/cdk/portal';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { LoaderInterceptor } from '@interceptor/loader-interceptor';
import { FlexContainerComponent } from '@layouts/flex-container/flex-container.component';
import { ReplaceUnderscorePipe } from '@pipes/replaceUnderscore.pipe';
import { SafeHTMLPipe } from '@pipes/safe-html.pipe';
import { GlobalDialogService } from '@services/global-dialog.service';

// Directives
import { CapDirective } from '@directives/cap.directive';
import { ClickStopPropagationDirective } from '@directives/click-stop-propagation.directive';
import { DynamicComponentLoaderDirective } from '@directives/dynamic-component-loader.directive';
import { EllipsisDirective } from '@directives/ellipsis.directive';

import { FluidHeightDirective } from '@directives/fluid-height.directive';
import { CapitalizePipe } from '@pipes/capitalize.pipe';
import { SearchFilterPipe } from '@pipes/searchFilter.pipe';
import { TranslatePipe } from '@pipes/translate.pipe';
import { ContentPermissionDirective } from 'src/app/_content-permission/content-permission.directive';
import { OverlayModule } from '@angular/cdk/overlay';
import { MatSortModule } from '@angular/material/sort';
import { TrimInputDirective } from '@directives/trim-input.directive';
import { PremiumFeatureDirective } from '@directives/premium-feature.directive';
import { TextCopyDirective } from '@directives/text-copy.directive';
import { NumberOnlyDirective } from '@directives/number-only.directive';

import { EmailEditorModule } from 'ng-email-editor';
@NgModule({
  declarations: [
    CapDirective,
    EllipsisDirective,
    SearchFilterPipe,
    TranslatePipe,
    ReplaceUnderscorePipe,
    FlexContainerComponent,
    // Pipes
    SafeHTMLPipe,
    CapitalizePipe,
    // Directives
    DynamicComponentLoaderDirective,
    ClickStopPropagationDirective,
    ContentPermissionDirective,
    FluidHeightDirective,
    TrimInputDirective,
    PremiumFeatureDirective,
    TextCopyDirective,
    NumberOnlyDirective
  ],
  imports: [
    CommonModule,
    ScrollingModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatPaginatorModule,
    MatToolbarModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    MatIconModule,
    MatMenuModule,
    MatRadioModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatSidenavModule,
    MatToolbarModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSelectModule,
    MatSnackBarModule,
    MatChipsModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatDividerModule,
    MatTableModule,
    MatTreeModule,
    MatStepperModule,
    MatDatepickerModule,
    ClipboardModule,
    MatSliderModule,
    MatBadgeModule,
    MatSortModule,
    RouterModule,
    PortalModule,
    MatSlideToggleModule,
    MatListModule,
    MatGridListModule,
    DragDropModule,
    OverlayModule,
    A11yModule,
    EmailEditorModule
  ],
  exports: [
    CommonModule,
    ScrollingModule,
    MatProgressSpinnerModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatPaginatorModule,
    MatBadgeModule,
    MatToolbarModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    MatIconModule,
    MatMenuModule,
    MatRadioModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatSidenavModule,
    MatToolbarModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatCheckboxModule,
    MatSelectModule,
    MatSnackBarModule,
    MatChipsModule,
    MatExpansionModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatTooltipModule,
    MatDividerModule,
    MatTableModule,
    MatTreeModule,
    MatStepperModule,
    MatDatepickerModule,
    ClipboardModule,
    MatSliderModule,
    MatSortModule,
    RouterModule,
    PortalModule,
    MatSlideToggleModule,
    MatListModule,
    A11yModule,
    MatGridListModule,
    DragDropModule,
    OverlayModule,
    EmailEditorModule,
    ReplaceUnderscorePipe,
    FlexContainerComponent,
    // Pipes
    SafeHTMLPipe,
    CapitalizePipe,
    // Directives
    DynamicComponentLoaderDirective,
    ClickStopPropagationDirective,
    ContentPermissionDirective,
    FluidHeightDirective,
    TrimInputDirective,
    PremiumFeatureDirective,
    TextCopyDirective,
    NumberOnlyDirective
  ],
  providers: [
    TranslatePipe,
    GlobalDialogService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoaderInterceptor,
      multi: true
    }
  ]
})
export class SharedModule { }
