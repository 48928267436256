import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MenuList, navigationMenuUrls } from '@enums/menus';
import { PushNotificationService } from '@services/_pusher/push-notification.service';
import { PusherService } from '@services/_pusher/pusher.service';
import { AuthService } from '@services/auth.service';
import { BreakpointService } from '@services/breakpoint.service';
import { PwaService } from '@services/pwa.service';
import {
  PanelTheme,
  SharedService,
  SidenavState
} from '@services/shared.service';
import cloneDeep from 'lodash/cloneDeep';
import { Observable, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { BrandingResolver } from './_resolvers/branding.resolver';
import { NavigationMenuItem } from './_interfaces';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  _isMobile: boolean;
  title = 'adtrack-ui';
  openSidebar = false;
  openContentSidebar = false;
  selectedMenuItem: NavigationMenuItem;
  @ViewChild('drawer') drawer: unknown;
  loginActive: boolean;
  openOuterSidebar: boolean;
  brandColor: Observable<string>;
  favIcon: HTMLLinkElement = document.querySelector('#favicon');
  titleFromRoute: Observable<string>;
  subs = new SubSink();

  constructor(
    private breakpointService: BreakpointService,
    private router: Router,
    private route: ActivatedRoute,
    private shared: SharedService,
    private branding: BrandingResolver,
    private titleService: Title,
    private auth: AuthService,
    private pwa: PwaService,
    private pusher: PusherService,
    private pushNotificationService: PushNotificationService
  ) {

    this.brandColor = this.shared.currentBrandColor;
    this.subs.sink = this.breakpointService.isMobile$.subscribe((isMobile) => {
      this._isMobile = isMobile;
    });

    this.subs.sink = combineLatest([
      this.auth.currentUserObs,
      this.isMobile$,
      this.sidenavState$
    ])
      .pipe(
        map(([currentUser, isMobile, sidenavState]) => {
          this.openSidebar =
            !!currentUser && (!isMobile || sidenavState === 'open');
        })
      )
      .subscribe();

    this.subs.sink = this.auth.currentUserObs
      .pipe(distinctUntilChanged((a, b) => a?._id === b?._id))
      .subscribe((currentUser) => {
        if (currentUser?._id) {
          this.pushNotificationService.updateNotificationStack();
          this.pusher.initiateSubscription();
        } else {
          this.pusher.unsubscribeChannel();
        }
      });
  }

  ngOnInit(): void {
    this.subs.sink = this.shared.brandingTrigger.subscribe((val) => {
      if (val) {
        this.branding.getBrandDetails(true);
      }
    });

    this.handleSidenav(MenuList);
    this.subs.sink = this.pusher.feedItems.subscribe((i) => {
      if (i) {
        this.pushNotificationService.updateNotificationStack();
      }
    });
  }

  public get isLoggedIn$(): Observable<boolean> {
    return this.auth.currentUserObs.pipe(switchMap((user) => of(!!user?._id)));
  }

  public get isMobile$(): Observable<boolean> {
    return this.breakpointService.isMobile$;
  }

  public get sidenavState$(): Observable<SidenavState> {
    return this.shared.sidenavStateSub;
  }

  public set setSidenavState(state: unknown) {
    this.shared.sidenavState = state ? 'open' : 'closed';
  }

  public get panelTheme$(): Observable<PanelTheme> {
    return this.shared.panelThemeSub$;
  }

  handleSidenav(menus: NavigationMenuItem[]): void {
    this.subs.sink = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.loginActive = !!(event?.url === '/register');
        const rt = this.shared.getChild(this.route);
        const whitelisted = [
          ...navigationMenuUrls(cloneDeep(menus)),
          'profile',
          'billing',
          'team',
          'roles-permissions'
        ];

        if (!this._isMobile) {
          this.shared.sidenavState = whitelisted.some((u) =>
            event?.url.includes(`${u}`)
          )
            ? 'open'
            : 'closed';
        }

        this.subs.sink = rt.data.subscribe((data) => {
          this.shared.componentTitle = data;
          this.titleService.setTitle(
            `${this.shared.currentSubdomain}${data.primary ? ' | ' + data.primary : ''
            }`
          );
        });
      });
  }

  ngOnDestroy(): void {
    this.auth.removeLocalData();
    this.pusher.unsubscribeChannel();
    this.subs?.unsubscribe();
  }
}