<app-sidenav-wrapper>
  <ng-container sidenavToolbarLeft>
    <app-text-line type="leading">Send messages</app-text-line>
  </ng-container>

  <ng-container sidenavContent>
    <app-sidesheet-form-wrapper>
      <ng-container form>
        <form [formGroup]="form" class="sidesheet-form">
          <div class="row m-0">
            <div class="col-12">
              <app-user-selector
                [required]="true"
                formControlName="to_user_id"></app-user-selector>
            </div>
            <div class="col-12">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Title</mat-label>
                <input type="text" formControlName="title" matInput required />
              </mat-form-field>
            </div>
            <div class="col-12">
              <mat-form-field class="w-100" appearance="outline">
                <mat-label>Your message</mat-label>
                <textarea
                  type="text"
                  [rows]="4"
                  formControlName="message"
                  matInput
                  required></textarea>
                <mat-hint>
                  <app-text-line
                    [textColor]="
                      form?.controls?.message?.value?.length > 255
                        ? 'error'
                        : 'primary'
                    "
                    type="xsmall"
                    >{{ form?.controls?.message?.value?.length || 0 }}/255
                    Characters left</app-text-line
                  >
                </mat-hint>
              </mat-form-field>
            </div>

            <div class="col-12"></div>
          </div>
        </form>
      </ng-container>

      <ng-container action>
        <div class="row m-0">
          <div class="col-12">
            <button
              class="float-right"
              [class.spinner]="loader"
              mat-raised-button
              color="primary"
              [disabled]="form?.invalid || loader"
              (click)="submitFormData()">
              Send
            </button>
          </div>
        </div>
      </ng-container>
    </app-sidesheet-form-wrapper>
  </ng-container>
</app-sidenav-wrapper>
