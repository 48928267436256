<h2 mat-dialog-title>Setup 2FA</h2>
<mat-dialog-content class="mat-typography">

    <div class="qr-wrapper mb-2" fxLayout="column" fxLayoutAlign="center center">
        <div class="cf-field p-3 w-100" fxLayout="row" fxLayoutAlign="center center">
            @if(loader) {
            <mat-spinner diameter="50"></mat-spinner>
            }
            @else {
            <qrcode [qrdata]="qrData" [width]="150" [errorCorrectionLevel]="'M'"></qrcode>
            }
        </div>
    </div>

    <mat-form-field appearance="outline" class="w-100">
        <mat-label>
            Enter code
        </mat-label>
        <input type="text" matInput [formControl]="qrInputcontrol">

        <mat-hint>Enter the code from your authenticator app</mat-hint>
    </mat-form-field>

</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-raised-button type="button" [disabled]="!qrInputcontrol.valid" color="primary"
        (click)="validateAuthCode()">Submit</button>
</mat-dialog-actions>