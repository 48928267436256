<div class="modal-wrapper">
  <div class="f-row modal-header">
    <app-text-line type="leading" [enableWrap]="true">Assign role to user</app-text-line>
    <button mat-button (click)="close()" class="close-btn">
      <mat-icon fontSet="mso">close</mat-icon>
    </button>
  </div>
  <div class="f-row modal-body">
    <form [formGroup]="form" class="f-col w-100 mt-3">
      <mat-form-field appearance="outline" [hidden]="!!data?.user_id" class="w-100">
        <mat-label>Select user</mat-label>
        <mat-select formControlName="user_id">
          <mat-option *ngFor="let user of users$ | async" [value]="user._id">{{ user.first_name + ' ' + user.last_name
            }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field [hidden]="!!data?.role_id" appearance="outline" class="w-100 mt-3">
        <mat-label>Select role</mat-label>
        <mat-select formControlName="role_permissions_id">
          <mat-option *ngFor="let role of roles$ | async" [value]="role.id">{{ role.role_name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </form>
  </div>
  <div class="f-row modal-footer">
    <button mat-button (click)="close()" type="button">Cancel</button>
    <div class="f-spacer"></div>
    <button [class.spinner]="loader" type="button" mat-raised-button color="primary" [disabled]="!form.valid || loader"
      (click)="submitFormData()">
      Save
    </button>
  </div>
</div>