import { MatTableDataSource } from '@angular/material/table';
import { BehaviorSubject } from 'rxjs';

export class ListDataSource extends MatTableDataSource<any> {
  // loader subject to host data loading state
  private loadingSubject = new BehaviorSubject<boolean>(false);

  // Table data source subject
  private tableDataSourceSubject = new BehaviorSubject<any[]>([]);

  // loading observable for subscription
  public loading$ = this.loadingSubject.asObservable();

  constructor() {
    super();
  }

  public setData(dataSource: any[]) {
    this.data = dataSource;
    this.tableDataSourceSubject.next(dataSource);
  }

  public updateData(dataSource: any[]) {
    this.data = [...this.tableDataSourceSubject.getValue(), ...dataSource];
    this.tableDataSourceSubject.next(this.data);
  }

  public getDisplayedColumns(columns: string[]) {
    return ['select', ...columns, 'action'];
  }

  /**
   * Return length of dataSource length
   */
  public dataCount(): number {
    return this.tableDataSourceSubject.getValue().length;
  }

  /**
   * Disconnect from data source and loading observables
   * call complete
   */
  public override disconnect() {
    this.tableDataSourceSubject.complete();
    this.loadingSubject.complete();
  }

  /**
   * reset data source
   */
  public reset() {
    this.tableDataSourceSubject.next([]);
  }
}
