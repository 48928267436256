import { Component } from '@angular/core';
import { SharedService } from '@services/shared.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-official-links',
  templateUrl: './official-links.component.html',
  styleUrls: ['./official-links.component.scss']
})
export class OfficialLinksComponent {
  tos_url: string = environment.terms_of_use;
  privacy_policy_orl: string = environment.privacy_policy;
  constructor(private shared: SharedService) {
    this.shared.branding.subscribe((brand) => {
      if (brand?.privacy_policy) {
        this.privacy_policy_orl = brand.privacy_policy;
      }
      if (brand?.terms_condition) {
        this.tos_url = brand.terms_condition;
      }
    });
  }
}
