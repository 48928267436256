<div class="toolbox-outer-container">
    <div class="toolbox-inner-container">
        <div class="scrollable-container">
            <ul class="tools-list">
                @for(menuItem of toolboxMenu; track menuItem.index) {
                <li (click)="menuClicked(menuItem)" (keyup.enter)="menuClicked(menuItem)" tabindex="0">
                    <mat-icon fontSet="mso" color="primary" class="icon-size-9">{{menuItem?.icon}}</mat-icon>
                    <app-text-line type="small" weight="strong" [enableWrap]="true" class="text-center">
                        {{menuItem?.title}}
                    </app-text-line>
                </li>
                }
            </ul>
        </div>
    </div>
</div>