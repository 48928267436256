import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton-previews',
  templateUrl: './skeleton-previews.component.html',
  styleUrls: ['./skeleton-previews.component.scss']
})
export class SkeletonPreviewsComponent {
  _width: string;
  @Input() type: 'profile' | 'table' | 'list' | 'text' | 'title' | 'form' | 'grid' | 'cards';
  @Input() classes: string = '';
  @Input() rows: number = 3;
  @Input() set width(val: string | number) {
    if (typeof val === 'string') {
      this._width = val;
    }
    if (typeof val === 'number') {
      this._width = `${val}px`;
    }
  }
  constructor() { }
}
