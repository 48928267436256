import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-category-cell',
  templateUrl: './category-cell.component.html',
  styleUrls: ['./category-cell.component.scss']
})
export class CategoryCellComponent {
  @Input() label: string;
  @Input() color: string;
  @Input() colorClass: string;
  changeDetection: ChangeDetectionStrategy.OnPush

  constructor() { }
}
