import { Component, OnInit, Inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef
} from '@angular/material/snack-bar';
import { CustomNotificationData } from '@services/notify.service';

@Component({
  selector: 'app-custom-notification',
  templateUrl: './custom-notification.component.html',
  styleUrls: ['./custom-notification.component.scss']
})
export class CustomNotificationComponent implements OnInit {
  constructor(
    @Inject(MatSnackBarRef) private _snackBarRef: any,
    @Inject(MAT_SNACK_BAR_DATA) private data: CustomNotificationData
  ) {}

  ngOnInit(): void {}

  get snackBarData(): CustomNotificationData {
    return this.data;
  }
  get snackBarRef() {
    return this._snackBarRef;
  }
}
