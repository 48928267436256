<div class="f-col">
  <div fxLayout="row" fxLayoutAlign="center center" class="center action-links">
    <div class="text-right mr-1">
      <a class="ac-primary" target="_blank" href="{{ tos_url }}"
        >Terms of Services</a
      >
    </div>
    <div class="text-left ml-1">
      <a class="ac-primary" target="_blank" href="{{ privacy_policy_orl }}"
        >Privacy policy</a
      >
    </div>
  </div>
</div>
