import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-status-cell',
  templateUrl: './status-cell.component.html',
  styleUrls: ['./status-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusCellComponent {
  @Input() status: 'pending' | 'active' | 'inactive';
  constructor() { }

  getTextColor(status: string) {
    status = status?.toLowerCase();

    if (status === 'pending' || status === 'private' || status === 'due') {
      return 'warning';
    }
    if (status === 'active' || status === 'approved' || status === 'paid') {
      return 'success';
    }
    if (status === 'public') {
      return 'info';
    }
    if (status === 'disabled' || status === 'inactive') {
      return 'disabled';
    }
    if (status === 'paused') {
      return 'paused';
    }
    if (
      status === 'blocked' ||
      status === 'permission' ||
      status === 'overdue'
    ) {
      return 'error';
    }

    // Default return value
    return '';
  }
}
