import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { AuthService } from '@services/auth.service';
import { StorageService } from '@services/storage.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FindAccountGuard {
  constructor(
    private router: Router,
    private auth: AuthService
  ) { }

  canActivate():
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const { hostname } = new URL(window.location.origin);
    const brandDetails = StorageService.getLocalItem(`https://${hostname}`);

    if (brandDetails) {
      this.router.navigate(['/login']);
      return false;
    }

    return true;
  }
}
