import { Component, OnInit, Input } from '@angular/core';
import { PaginatedResponse } from '@interfaces/global';
import { SharedService } from '@services/shared.service';
import isEqual from 'lodash/isEqual';
import AbstractComponent from 'src/app/_utilities/abstract-component';

@Component({
  selector: 'app-expanded-row',
  templateUrl: './expanded-row.component.html',
  styleUrls: ['./expanded-row.component.scss']
})
export class ExpandedRowComponent extends AbstractComponent implements OnInit {
  linkModifier(dataSource: PaginatedResponse<unknown[]>): PaginatedResponse<unknown[]> {
    return dataSource;
  }

  @Input() element: any;

  detailsDataSource = [];
  hiddenKeys = ['id', 'date'];
  sectionDetails = [];

  constructor(shared: SharedService) {
    super(shared);
  }

  ngOnInit(): void {
    this.initDataSource(this.element?.report);
  }

  initDataSource(data) {
    Object.keys(data || {})?.forEach((key) => {
      if (!this.hiddenKeys.find((hk) => hk === key)) {
        if (typeof data[key] !== 'object') {
          this.detailsDataSource.push({
            key,
            value: data[key]
          });
        } else {
          if (Array.isArray(data[key]) && data[key]?.length) {
            this.sectionDetails.push({
              label: key,
              data: data[key]
            });
          }
          if (!Array.isArray(data[key]) && data[key]) {
            this.sectionDetails.push({
              label: key,
              data: data[key]
            });
          }
        }
      }
    });
  }

  getTwoColDS(data: any) {
    const pickedProps = Object.keys(data)?.filter((key) => key !== 'date');

    return this.prepareTwoColumnDataSourceFromObject(data, pickedProps);
  }
}
