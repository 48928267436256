import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-html-cell',
  templateUrl: './html-cell.component.html',
  styleUrls: ['./html-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HtmlCellComponent {
  @Input() value: string;
  @Input() extendedView: boolean;
  constructor() {}
}
