import {
  ChangeDetectionStrategy,
  Component,
  Input
} from '@angular/core';
import { UserType } from '@interfaces/user';
import { EnumToDropdownOptions } from '@modules/campaign/campaign-constants';

@Component({
  selector: 'app-user-type-cell',
  templateUrl: './user-type-cell.component.html',
  styleUrls: ['./user-type-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserTypeCellComponent {
  _userType: { type: string; color: string; label: string; icon: string };
  userTypes = EnumToDropdownOptions(UserType);
  @Input() set type(type: UserType) {
    this._userType = this.getUserTypeDetails(type);
  }

  getUserTypeDetails(option: UserType) {
    switch (option) {
      case UserType.Owner:
        return {
          type: option,
          label: 'Owner',
          color: 'accent',
          icon: 'admin_panel_settings'
        };
        break;
      case UserType.TeamMember:
        return { type: option, label: 'Team member', color: '', icon: 'person' };
        break;
      case UserType.Advertiser:
        return {
          type: option,
          label: 'Advertiser',
          color: '',
          icon: 'tv_signin'
        };
        break;
      case UserType.Publisher:
        return {
          type: option,
          label: 'Publisher',
          color: '',
          icon: 'tv_signin'
        };
        break;
      default:
        return { type: option, label: option, color: '', icon: '' };
        break;
    }
  }
}
