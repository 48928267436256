import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { LoginGuard } from '@services/login.guard';

// import { EmailVerifiedComponent } from './email-verified/email-verified.component';
// import { LoginComponent } from './login/login.component';
// import { MemberVerifiedComponent } from './member-verified/member-verified.component';
// import { NotFoundComponent } from './not-found/not-found.component';
// import { PasswordResetComponent } from './password-reset/password-reset.component';
// import { RegisterComponent } from './register/register.component';
// import { RegistrationSuccessComponent } from './registration-success/registration-success.component';

const routes: Routes = [
  // { path: '', component: LoginComponent, canActivate: [LoginGuard], data: { primary: 'Login', id: 'login' } },
  // { path: 'register', component: RegisterComponent, data: { primary: 'Registration', id: 'register' } },
  // { path: 'registration_success', component: RegistrationSuccessComponent, data: { primary: 'Registration success' } },
  // { path: 'email/confirm', component: EmailVerifiedComponent, data: { primary: 'Confirm email' } },
  // { path: 'member/confirm', component: MemberVerifiedComponent, data: { primary: 'Confirm member' } },
  // { path: 'reset_password', component: PasswordResetComponent, data: { primary: 'Reset password' } },
  // { path: 'page-not-found', component: NotFoundComponent, data: { primary: 'Not found' } },
  // { path: '**', redirectTo: 'page-not-found', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRegisterRoutingModule {}
