import {
  Directive,
  Input,
  OnInit,
  OnChanges,
  TemplateRef,
  SimpleChanges,
  ViewContainerRef
} from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@services/auth.service';

@Directive({
  selector: '[appContentPermission]'
})
export class ContentPermissionDirective implements OnInit, OnChanges {
  @Input() appContentPermission: string;

  constructor(
    private auth: AuthService,
    private templateRef: TemplateRef<any>,
    private container: ViewContainerRef,
    private router: Router
  ) {}

  ngOnInit() {
    this.checkAccess();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.appContentPermission?.currentValue) {
      this.checkAccess();
    }
  }

  checkAccess() {
    this.container.clear();

    if (this.appContentPermission) {
      const hasAccess = this.auth.hasPermissionInline(
        this.appContentPermission
      );

      hasAccess
        ? this.container.createEmbeddedView(this.templateRef)
        : this.container.clear();
    } else {
      this.container.createEmbeddedView(this.templateRef);
    }
  }
}
