import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';

@Component({
  selector: 'app-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent {
  /**
   * Avatar size: small | medium | large
   */
  @Input()
  set size(value: string) {
    if (['small', 'medium', 'large'].includes(value)) {
      this.avatarSize = value;
    }
  }

  get size(): string {
    return this.avatarSize;
  }

  private avatarSize = 'small';

  /**
   * Avatar initials
   */
  @Input()
  set name(value: string) {
    const splitted = value.split(' ')?.filter((str) => !!str);

    this.initials =
      splitted.length > 1
        ? `${[...splitted[0]][0]}${[...splitted[1]][0]}`
        : `${[...splitted[0]][0]}${[...splitted[0]][1]}`;
  }

  initials = '';

  /**
   * Initial Avatar image
   */
  @Input()
  srcPicture: string;

  /**
   * set Supported formats
   */
  @Input()
  set supportedFormats(format: string[]) {
    this.formats.push(`image/${format}`);
  }

  /**
   * Hold the received formats
   */
  formats: string[] = [];

  /**
   * Enable avatar image change
   */
  @Input()
  editable = false;

  /**
   * Display a green online badge
   */
  @Input()
  online = false;

  /**
   * Emit the newly uploaded image as a base64 string
   */
  @Output()
  avatarChange: EventEmitter<any> = new EventEmitter();

  /**
   * Input file used for avatar image change
   */
  @ViewChild('uploadInput') uploadInput: ElementRef;

  /**
   * Hold latest uploaded image
   */
  uploadedPicture: string;

  constructor() {}
  /**
   * open file browser only if avatar edit is enabled
   */
  onAvatarClick(): void {
    if (this.editable) {
      this.uploadInput.nativeElement.click();
    }
  }

  /**
   * Read and show a preview of uploaded image
   * @param event file change event
   */
  onAvatarUpload(event): void {
    const resp = {
      status: false,
      file: null,
      message: ''
    };

    if (event.target.files && event.target.files[0]) {
      const isImage =
        this.formats.length > 0
          ? this.formats.includes(event.target.files[0].type)
          : event.target.files[0].type.includes('image');

      if (!isImage) {
        // throw new Error('Invalid image format');
        resp.status = false;
        resp.message = 'Invalid image format';
      } else {
        const reader = new FileReader();

        reader.readAsDataURL(event.target.files[0]);

        reader.onload = (res) => {
          this.uploadedPicture = res.target.result.toString();
          resp.status = true;
          resp.message = 'Success';
          resp.file = this.uploadedPicture;
        };
      }

      this.avatarChange.emit(resp);
    }
  }

  /**
   * get uploaded/initial image to display
   */
  get imgUrl(): string {
    return this.uploadedPicture || this.srcPicture;
  }

  /**
   * get applied classes list
   */
  get classList(): string[] {
    return [`cf-${this.size}`];
  }
}
