import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  public httpOptions;

  private baseUrl = environment.base_url;
  constructor(private http: HttpClient) {}

  setHeader(contentType = 'application/json') {
    let headers = new HttpHeaders();

    headers = headers.append('Content-Type', contentType);
    this.httpOptions = { headers };
  }

  // GET data
  getData(url: string): Observable<any> {
    this.setHeader();
    return this.http.get<any>(this.baseUrl + url);
  }

  getDataObject(url: string) {
    return fetch(url);
  }

  // GET data
  get(url: string): Observable<any> {
    this.setHeader();
    return this.http.get<any>(url);
  }

  // POST data
  postData(url: string, body: any, required = true) {
    if ((body === null || body === undefined) && required) {
      throw new Error('Required parameter body was null or undefined');
    }
    this.setHeader();
    return this.http.post(this.baseUrl + url, body, this.httpOptions);
  }

  // PATCH data
  patchData(url: string, body: any) {
    if (body === null || body === undefined) {
      throw new Error('Required parameter body was null or undefined.');
    }
    this.setHeader();
    return this.http.patch(this.baseUrl + url, body, this.httpOptions);
  }

  // DELETE data
  deleteData(url: string) {
    return this.http.delete(this.baseUrl + url);
  }
}
