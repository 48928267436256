import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EmailVerify } from 'src/app/_interfaces';
import { AuthService } from '@services/auth.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-email-verified',
  templateUrl: './email-verified.component.html',
  styleUrls: ['./email-verified.component.css']
})
export class EmailVerifiedComponent {
  loader: boolean;
  success: boolean;
  token: string;
  constructor(
    private route: ActivatedRoute,
    private auth: AuthService
  ) {
    this.route.queryParams.subscribe((p) => {
      if (p && p.token) {
        this.token = p?.token;
        this.verifyToken(p.token);
      } else {
        this.success = false;
      }
    });
  }

  verifyToken(token: string) {
    const body: EmailVerify = {
      token
    };

    this.loader = true;
    this.auth
      .verifyEmail(body)
      .pipe(
        finalize(() => {
          this.loader = false;
        })
      )
      .subscribe((res) => {
        if (res.success) {
          this.success = true;
        }
      });
  }
}
