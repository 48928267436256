<app-sidesheet-form-wrapper>
  <ng-container form>
    <div class="sidesheet-form">
      <div class="f-col align-items-center w-100">
        <ng-container *ngIf="!loader && !!profileData; else showEmptyState">
          <div class="grid-status-item auto m-0 md">
            <div class="cf-section">
              <div class="cf-card f-col align-items-center justify-content-center text-center">
                <app-avatar size="large" [name]="fullName"></app-avatar>
              </div>
            </div>
          </div>

          <div class="grid-status-item auto m-0 md">
            <div class="cf-section">
              <div class="cf-card f-col">
                <div class="cf-text">
                  <div class="f-col text-center">
                    <app-text-line type="display" weight="">{{
                      fullName | capitalize
                      }}</app-text-line>
                    <div class="f-row mt-3">
                      <app-text-line type="leading" weight="mr-1">Email:</app-text-line>
                      <app-text-line type="leading" textColor="secondary">{{
                        profileData?.email || 'NA'
                        }}</app-text-line>
                    </div>
                    <div class="f-row mt-2">
                      <app-text-line type="leading" weight="mr-1">Campany:</app-text-line>
                      <app-text-line type="leading" textColor="secondary">{{
                        profileData?.company_name || 'NA'
                        }}</app-text-line>
                    </div>
                    <div class="f-row mt-2">
                      <app-text-line type="leading" weight="mr-1">Phone:</app-text-line>
                      <app-text-line type="leading" textColor="secondary">{{
                        profileData?.phone || 'NA'
                        }}</app-text-line>
                    </div>
                    <div class="f-row mt-2">
                      <app-text-line type="leading" weight="mr-1">Website:</app-text-line>
                      <a href="{{ profileData?.website || '' }}" class="nodecor" target="_blank">
                        <app-text-line type="leading" [textColor]="profileData?.website ? 'link' : ''">{{
                          profileData?.website || 'NA' }}</app-text-line>
                      </a>
                    </div>
                    <div class="f-row mt-2">
                      <app-text-line type="leading" weight="mr-1">Status:</app-text-line>
                      <app-status-cell [status]="
                          profileData?.status || 'NA'
                        "></app-status-cell>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <ng-template #showEmptyState>
          <app-skeleton-previews *ngIf="loader; else empty" type="text" class="w-100"></app-skeleton-previews>
          <ng-template #empty>
            <app-null-state icon="user" [size]="40" [message]="{
                line1: 'Data unavailabe',
                line2:
                  'Details for this profile isn\'t available, please try again after sometime'
              }"></app-null-state>
          </ng-template>
        </ng-template>
      </div>
    </div>
  </ng-container>
</app-sidesheet-form-wrapper>