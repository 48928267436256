<h2 mat-dialog-title>Payment {{ data.billing_status }}</h2>
<mat-dialog-content class="mat-typography">
  <h3>{{ notifyText }}</h3>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button
    mat-button
    class="mr-1"
    [color]="data?.dismissible ? '' : 'warn'"
    [mat-dialog-close]="data?.dismissible"
    (click)="logout()">
    {{ data?.dismissible ? 'Dismiss' : 'Logout' }}
  </button>
  <button
    mat-raised-button
    color="primary"
    [mat-dialog-close]="data?.dismissible"
    (click)="paymentRoute()"
    cdkFocusInitial>
    Pay now
  </button>
</mat-dialog-actions>
