import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { HttpErrorInterceptor } from '@interceptor/http-error-interceptor';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { BillingStatus, STORAGE_KEY } from '@enums/globals';
import { UserType } from '@interfaces/user';
import { StorageService } from './storage.service';
import { GlobalDialogService } from './global-dialog.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private authService: AuthService,
    private router: Router,
    private errIntercept: HttpErrorInterceptor,
    private globalDialogService: GlobalDialogService
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.isAllowed(next, state);
  }

  /**
   * Method to check if the token is being refreshed right now
   * if it is, then wait for the refresh to complete
   * and check if the user is logged in
   * @returns Observable<boolean>
   */
  isAllowed(
    next: ActivatedRouteSnapshot,
    routeState: RouterStateSnapshot
  ): Observable<boolean> {
    return new Observable((observer) => {
      this.errIntercept.tokenRefreshState$.subscribe((state: boolean) => {
        if (!state) {
          if (
            this.authService.currentUserValue !== null &&
            this.authService.currentUserValue !== undefined
          ) {
            const user = this.authService.currentUserValue;

            if (user?.billing_status !== BillingStatus.None) {
              if (
                this.authService.userType !== UserType.Advertiser &&
                this.authService.userType !== UserType.Publisher
              ) {
                if (routeState?.url !== '/billing/billing-info') {
                  if (user?.billing_status === BillingStatus.Overdue) {
                    this.globalDialogService.openPaymentDialog({
                      billing_status: user.billing_status,
                      dismissible: false
                    });
                  } else {
                    if (
                      !StorageService.getLocalItem(
                        STORAGE_KEY.PAYMENT_NOTIFICATION
                      )
                    ) {
                      StorageService.setLocalItem(
                        STORAGE_KEY.PAYMENT_NOTIFICATION,
                        user?._id
                      );
                      this.globalDialogService.openPaymentDialog({
                        billing_status: user.billing_status,
                        dismissible: true
                      });
                    }
                  }
                }
              }
            }

            observer.next(true);
            observer.complete();
          } else {
            this.router.navigate(['/login']);
            observer.next(false);
            observer.complete();
          }
        }
      });
    });
  }
}
