import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { Router } from '@angular/router';
import {
  ActionButton,
  ActionDropdownMenu,
  ActionEvent,
  ActionMenuConfig,
  CellStatus,
  ColumnCalculations,
  ColumnIconsMap,
  ColumnTypeMap,
  ColumnsConfig,
  DataSourceConfig,
  PaginatedResponse
} from '@interfaces/global';
import { SharedService } from '@services/shared.service';
import AbstractComponent from '@utilities/abstract-component';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { take } from 'rxjs/operators';

import { ColumnTypes } from '../../../_utilities/table-data';
import { ListDataSource } from './data-source/data-source';
// import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';

import { moveItemInArray } from '@angular/cdk/drag-drop';
import {
  ACTIONCOLUMNKEY,
  EXPANSIONCOLUMNKEY,
  SELECTIONCOLUMNKEY
} from '@enums/globals';
import { AuthService } from '@services/auth.service';
import { AppWorkerService } from 'src/app/app-worker.service';
import { DataTableService } from './data-table.service';

@Component({
  selector: 'app-list-data-table',
  templateUrl: './list-data-table.component.html',
  styleUrls: ['./list-data-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state(
        'collapsed',
        style({
          height: '0px',
          minHeight: '0',
          overflow: 'hidden'
        })
      ),
      state('expanded', style({ height: '*' })),
      transition(
        'expanded <=> collapsed',
        animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')
      )
    ])
  ]
})
export class ListDataTableComponent
  extends AbstractComponent
  implements AfterViewInit, OnDestroy {
  _showToolbar$ = new BehaviorSubject<boolean>(true);
  _paginate$ = new BehaviorSubject<boolean>(true);
  _heightType$ = new BehaviorSubject<'max' | ''>('');

  fluidHeightConfig: { value: number, type: string } = {
    value: 113,
    type: ''
  };
  dataSource = new ListDataSource();
  selection = new SelectionModel<any>(true, []);
  lookupColunms$: BehaviorSubject<string[]> = new BehaviorSubject([]);
  displayedColumns = [];

  selectionColumnWidth = 100;
  minColumnWidth = 150;
  actionColumnWidth = 50;
  stickyColumns = [];
  stickyEndColumns = [];
  totalCount = 0;
  pageSize = 25;
  selectionEnabled: boolean;
  overrideColumnTypes: any = {};
  columnDataformat: any = {};
  private _actionMenus: ActionMenuConfig[] = [];
  set actionMenus(value: Array<ActionMenuConfig>) {
    value = this.auth.sanitizeMenuList(value);
    value = value?.map((menu) => {

      if (menu?.buttons?.length) {
        menu.buttons = this.auth.sanitizeMenuList(menu.buttons);

        menu.buttons = menu.buttons.map((btn) => {
          if (btn?.menu?.length) {
            btn.menu = this.auth.sanitizeMenuList(btn.menu as ActionDropdownMenu[]);
          }
          return btn;
        });
      }

      return menu;
    })

    this._actionMenus = value;
  }

  get actionMenus() {
    return this._actionMenus;
  }

  expandedElement: any;
  columnValueMap: Record<string, any> = {};

  @Input() set showToolbar(value: boolean) {
    setTimeout(() => {
      this._showToolbar$.next(value);
      this.cdref.detectChanges();
    }, 10)
  }
  @Input() set paginate(value: boolean) {
    setTimeout(() => {
      this._paginate$.next(value);
      this.cdref.detectChanges();
    }, 10);
  }
  @Input() set heightType(value: 'max' | '') {
    setTimeout(() => {
      this._heightType$.next(value);
      this.cdref.detectChanges();
    }, 10);
  }

  @Output() selectionChange: EventEmitter<Array<any>> = new EventEmitter();
  @Output() actionClicked: EventEmitter<ActionEvent<any>> = new EventEmitter(
    null
  );
  @Output() paginateEvent: EventEmitter<PageEvent> = new EventEmitter();
  // @Output() scrolledToEnd: EventEmitter<any> = new EventEmitter();

  @ViewChild('table') table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('tableContainer') tableContainer: ElementRef<HTMLElement>;
  @ViewChild('actionColumnRef') actionColumnRef: ElementRef<HTMLTableCellElement>;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updateTableColumnSize();
    this.updateColumnWidth();
    this.cdref.detectChanges();
  }
  @Input() isExpandable: boolean;
  @Input() isCustomizable: boolean = true;
  @Input() disableFieldTypes: boolean;
  @Input() selectionKey: string | number = 'id';
  @Input() enableCustomization: boolean = false;
  @Input() hideHeaderRow = false;
  @Input() localizeNumericValues = true;
  @Input() columnPrefixIcon: Array<ColumnIconsMap> = [];
  @Input() set columnTypeMap(columnTypesMap: Array<ColumnTypeMap>) {
    columnTypesMap.forEach((data) => {
      const { columnName, type, format, limit } = data || {};

      this.overrideColumnTypes[columnName] = {
        columnName,
        type,
        format,
        limit
      };
      if (format) {
        this.columnDataformat[columnName] = format;
      }
    });
  }
  @Input() set isLoading(state: boolean) {
    this.loader = state;
    this.cdref.detectChanges();
  }

  /**
   * Handles the native filteration option for Mat table data source
   */
  @Input() set lookupColumns(lookupColumns: string[]) {
    this.lookupColunms$.next(lookupColumns);
    setTimeout(() => {
      this.setFilterPredicate();
      this.cdref.detectChanges();
    }, 400);
  }

  /**
   * Handles the native filteration option for Mat table data source
   */
  @Input() set searchTerm(searchText: string) {
    searchText = searchText?.trim(); // Remove whitespace
    searchText = searchText?.toLowerCase() || ''; // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = searchText;
  }

  _cellStatus: CellStatus;
  private _dataSourceConfig: DataSourceConfig;

  @Input() set dataSourceConfig(data: DataSourceConfig) {
    if (!data?.dataSource) {
      return;
    }

    this._dataSourceConfig = cloneDeep(data);
    this._cellStatus = data?.cellStatus;
    this.dataTableService.currentDisplayedColumns = Object.keys(
      data.dataSource?.data[0] || {}
    );
    this.renderTableData(data);
  }

  get dataSourceConfig(): DataSourceConfig {
    return this._dataSourceConfig;
  }

  get _selectionColumnKey() {
    return SELECTIONCOLUMNKEY;
  }

  get _expansionColumnKey() {
    return EXPANSIONCOLUMNKEY;
  }

  get _actionColumnKey() {
    return ACTIONCOLUMNKEY;
  }

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private cdref: ChangeDetectorRef,
    private shared: SharedService,
    private dataTableService: DataTableService,
    private auth: AuthService,
    private worker: AppWorkerService
  ) {
    super(shared);

    this.subs.sink = this.worker.watchWorkerResponse('setColumnCalculations').subscribe((response) => {
      this.columnValueMap = response?.result || {};
      this.cdref.detectChanges();
    });

    this.subs.sink = combineLatest([this._showToolbar$, this._paginate$, this._heightType$])
      .subscribe(([showToolbar, paginate, heightType]) => {
        this.fluidHeightConfig = {
          value: showToolbar ? 113 : paginate ? 57 : 0,
          type: heightType
        }
      });
  }

  // onScroll(event: number) {
  //   this.scrolledToEnd.emit(event);
  // }

  emitAction(action: ActionEvent<any>) {
    this.actionClicked.emit(action);
  }

  emitSelectedOption(event, row: any) {
    if (event) {
      this.emitAction({ emitValue: event, row });
    }
  }

  getColumnActionButtons(column: string): Array<ActionButton> {
    return this.actionMenus
      ?.find((menu) => menu.columnName === column)?.buttons;
  }

  getCellStatusClass(row: any) {
    if (Array.isArray(this._cellStatus?.statusKey)) {
      if (this._cellStatus?.inverse) {
        return this._cellStatus.statusKey.some((key) => row[key]) ? 'inactive-cell' : '';
      }

      return this._cellStatus.statusKey.some((key) => row[key]) ? '' : 'inactive-cell';

    } else {
      if (this._cellStatus?.statusKey in row) {
        if (this._cellStatus?.inverse) {
          return row[this._cellStatus.statusKey] ? 'inactive-cell' : '';
        }

        return row[this._cellStatus.statusKey] ? '' : 'inactive-cell';
      }
    }

    return '';
  }

  isEnabled(btn: ActionButton, row: any) {
    return 'accessKey' in btn
      ? !get(row, btn.accessKey, false)
      : true;
  }

  shouldDisableButton(btn: ActionButton, row: any) {
    if (!btn?.disableFn) {
      return false;
    }

    return btn?.disableFn(row);
  }

  renderTableData(data: DataSourceConfig) {
    this.prepareDataSource(data).then((resolvedData) => {
      this.cdref.markForCheck();
      this.dataSource = new ListDataSource();
      const { reOrderedDataSource, displayedColumns, selected } = resolvedData;

      this.displayedColumns = [...displayedColumns].filter(
        (column) => !column.startsWith('_')
      );

      this.dataSource.setData(reOrderedDataSource);
      this.setFilterPredicate();

      if (selected?.length) {
        selected?.map((selectedId) => this.selection.select(selectedId));
      } else {
        this.selection.clear();
      }

      setTimeout(() => {
        this.updateTableColumnSize();
        this.updateColumnWidth();
        this.cdref.detectChanges();
      }, 200);
    });
  }

  /**
   * Set the columns that should appear sticky
   * @param startColumns column names as string array
   */
  public setStickyColumns(startColumns: string[], endColumns: string[]) {
    this.stickyColumns = startColumns;
    this.stickyEndColumns = endColumns;
  }

  isColSticky(column: string) {
    return !!this.stickyColumns?.find((col) => col === column);
  }

  isStickyEnd(column: string) {
    return !!this.stickyEndColumns?.find((col) => col === column);
  }

  dropCol(event: any) {
    const filteredColumns = cloneDeep(this.displayedColumns);

    moveItemInArray(
      filteredColumns,
      event.previousIndex + (this.selectionEnabled ? 1 : 0),
      event.currentIndex + (this.selectionEnabled ? 1 : 0)
    );

    this.displayedColumns = cloneDeep(filteredColumns);
  }

  sortData(sort: Sort) {
    const data = this.dataSource.data.slice();

    if (!sort.active || sort.direction === '') {
      this.dataSource.data = data;
      return;
    }

    this.dataSource.data = [...data].sort((a, b) => {
      return this.compareFn(
        this.getCompareValue(a[sort.active], sort.active),
        this.getCompareValue(b[sort.active], sort.active),
        sort.direction == 'asc'
      );
    });
  }

  getCompareValue(tableElement: any, column: string) {
    const currentColumnType = this.overrideColumnTypes[column];

    switch (currentColumnType?.type) {
      case ColumnTypes.LINK:
        return tableElement?.label;
      case ColumnTypes.STATUS:
        return tableElement;
      case ColumnTypes.TAGS:
        return tableElement?.length;
      case ColumnTypes.DATE:
        return new Date(tableElement).getTime();
      default:
        if (
          typeof tableElement === 'object' &&
          Object.keys(tableElement || {})?.length
        ) {
          return tableElement?.label;
        }

        return !isNaN(Number(tableElement))
          ? Number(tableElement)
          : tableElement;
    }
  }

  getFieldType(column: string): ColumnTypes {
    if (this.disableFieldTypes) {
      return ColumnTypes.TEXT;
    }

    return this.overrideColumnTypes[column]?.type || ColumnTypes.TEXT;
  }

  getPrefixIcon(column: string): string {
    return (
      this.columnPrefixIcon?.find((config) => config?.columnName === column)
        ?.prefixIcon || null
    );
  }

  getSuffixIcon(column: string): string {
    return (
      this.columnPrefixIcon?.find((config) => config?.columnName === column)
        ?.suffixIcon || null
    );
  }

  getFormat(column: string): string {
    return this.columnDataformat[column] || '';
  }

  getLimit(column: string): number {
    return this.overrideColumnTypes[column]?.limit || 0;
  }

  ngAfterViewInit(): void {
    this.subs.sink = this.selection.changed.subscribe(() => {
      this.selectionChange.emit(this.getSelectedRows(this.selection.selected));
    });

    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.subs.sink = this.dataSource.connect().subscribe(() => {
      this.totalCount = this.dataSource.dataCount();
      this.updateTableColumnSize();
    });

    // Scroll sub
    // this.subs.sink = this.scrollRef
    //   ?.elementScrolled()
    //   ?.pipe(
    //     map(() => this.scrollRef.measureScrollOffset('bottom')),
    //     pairwise(),
    //     filter(([y1, y2]) => y2 < y1 && y2 < 96),
    //     throttleTime(200)
    //   )
    //   .subscribe(() => {
    //     this.ngZone.run(() => {
    //       this.onScroll(null);
    //     });
    //   });

    this.subs.sink = this.dataTableService.columnsConfigSub.subscribe(
      (config: ColumnsConfig) => {
        if (this.enableCustomization) {
          const dsc = cloneDeep(this.dataSourceConfig);

          if (config?.hidden?.length) {
            dsc.hiddenColumns = Array.from(
              new Set([...(config?.hidden ?? []), ...dsc.hiddenColumns])
            );
          }
          if (config?.displayed?.length) {
            dsc.visibleColumns = config.displayed;
          }

          this.renderTableData(dsc);
        }
      }
    );
  }

  getSelectedRows(
    selected: Array<unknown>
  ): Array<unknown> {
    return this.dataSource.data.filter(
      (data) => selected.indexOf(data[this.selectionKey]) > -1
    );
  }

  private get lookupColumnsValue() {
    return this.lookupColunms$.getValue();
  }

  setFilterPredicate() {
    // Custom filter function implementation
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      const lookupColumns = this.lookupColumnsValue?.length ? this.lookupColumnsValue : Object.keys(data);

      return lookupColumns.some(columnName => {
        const fieldType = this.getFieldType(columnName);
        const columnValue = data[columnName];

        switch (fieldType) {
          case ColumnTypes.TEXT:
          case ColumnTypes.HYPERLINK:
          case ColumnTypes.STATUS:
            return this.compareWithIncludes(columnValue, filter);
          case ColumnTypes.LINK:
            return this.compareWithIncludes(columnValue?.label, filter);
          case ColumnTypes.AVATAR:
            return this.compareWithIncludes(columnValue?.email, filter) ||
              this.compareWithIncludes(columnValue?.label, filter);
          case ColumnTypes.AVATAR_LINK:
            return this.compareWithIncludes(columnValue?.name, filter) ||
              this.compareWithIncludes(columnValue?.label, filter) ||
              this.compareWithIncludes(columnValue?.email, filter);
          default:
            return false;
        }
      });
    };
  }

  compareWithIncludes(compareIn: string, compareWhat: string): boolean {
    return `${compareIn}`
      ?.toLowerCase()
      ?.includes(`${compareWhat?.toLowerCase()}`);
  }

  prepareDisplayedColumns(dataSource, stickyColumns, stickyColumnsEnd) {
    return [
      ...(this.isExpandable ? [EXPANSIONCOLUMNKEY] : []),
      ...(stickyColumns || []),
      ...this.getDynamicColumns(
        dataSource,
        stickyColumns || [],
        stickyColumnsEnd || []
      ),
      ...(stickyColumnsEnd || [])
    ];
  }

  prepareDataSource({
    stickyColumns,
    stickyColumnsEnd,
    selected,
    hiddenColumns,
    visibleColumns,
    dataSource,
    enableSelection,
    actionMenus,
    columnCalculation
  }: DataSourceConfig): Promise<{
    reOrderedDataSource: any;
    displayedColumns: string[];
    selected: Array<any>;
  }> {
    this.actionMenus = actionMenus;

    return new Promise((resolve) => {
      let tableData = dataSource?.data;
      let displayedColumns = this.prepareDisplayedColumns(
        dataSource,
        stickyColumns,
        stickyColumnsEnd
      );

      displayedColumns =
        tableData?.length && visibleColumns?.length
          ? visibleColumns
          : displayedColumns;

      this.selectionEnabled = enableSelection;

      if (enableSelection) {
        tableData = tableData.map((row) => ({
          [SELECTIONCOLUMNKEY]: null,
          ...row
        }));

        displayedColumns.unshift(SELECTIONCOLUMNKEY);
      }

      if (
        actionMenus?.length &&
        actionMenus.find((menu) => menu.columnName === ACTIONCOLUMNKEY) &&
        tableData?.length
      ) {
        tableData = tableData.map((row) => ({
          ...row,
          [ACTIONCOLUMNKEY]: null
        }));
        displayedColumns.push(ACTIONCOLUMNKEY);
      }

      !!columnCalculation &&
        this.setColumnCalculations(
          columnCalculation,
          tableData,
          displayedColumns
        );
      this.setStickyColumns(stickyColumns, stickyColumnsEnd);
      this.handlePagination(dataSource);
      displayedColumns = tableData?.length
        ? this.removeHiddenColumns(displayedColumns, hiddenColumns)
        : [];

      resolve({
        reOrderedDataSource: tableData,
        displayedColumns: [...new Set(displayedColumns)],
        selected
      });
    });
  }


  setColumnCalculations(
    columnCalculation: ColumnCalculations,
    tableData: any[],
    displayedColumns
  ) {
    this.worker.postWork({
      name: 'setColumnCalculations',
      uid: 'setColumnCalculations',
      args: [columnCalculation, tableData, displayedColumns, this.columnValueMap]
    });
  }

  hasMapping(column: string) {
    return Array.from(new Set());
  }

  handlePagination(dataSource: PaginatedResponse<any>) {
    const { meta } = dataSource || {};

    if (!meta) {
      return;
    }
    const { total, per_page } = meta || {};

    this.totalCount = total;
    this.pageSize = per_page;
  }

  paginatorAction(event: PageEvent) {
    this.paginateEvent.emit(event);
  }

  get showActionColumn() {
    return !!this.displayedColumns.find((col) => col === ACTIONCOLUMNKEY);
  }

  removeHiddenColumns(columns: string[], hiddenColumns: string[]): string[] {
    if (!hiddenColumns?.length) {
      return columns;
    }
    return columns.filter((name) => hiddenColumns?.indexOf(name) === -1);
  }

  getDynamicColumns(dataSource: any, start: string[], end: string[]): string[] {
    const { data } = dataSource || {};
    if (!data || data.length === 0) {
      return [];
    }

    const firstObject = data[0];
    const filteredKeys = new Set([...start, ...end]);

    return Object.keys(firstObject).filter(key => !filteredKeys.has(key));
  }

  reOrderProperties(tableData: any[], columns: string[]): any[] {
    return tableData.map(row => {
      const reorderedRow: any = {};
      columns.forEach(key => {
        if (Object.prototype.hasOwnProperty.call(row, key)) {
          reorderedRow[key] = row[key];
        }
      });
      return reorderedRow;
    });
  }

  selectAll(event: MatCheckboxChange) {
    if (event.checked) {
      this.dataSource.data.map((d) =>
        this.selection.select(d[this.selectionKey])
      );
    } else {
      this.selection.clear();
    }
  }

  /**
   * table width based on displayed columns width
   */
  updateColumnWidth() {
    const specialColumnsSet = new Set([SELECTIONCOLUMNKEY, ACTIONCOLUMNKEY]);
    const containerWidth = this.tableContainer?.nativeElement?.clientWidth || 0;

    let specialColumnsWidth = 0;
    let totalColumns = 0;

    this.displayedColumns.forEach(col => {
      if (!specialColumnsSet.has(col)) {
        totalColumns++;
      } else {
        if (col === SELECTIONCOLUMNKEY) {
          specialColumnsWidth += 100;
        } else if (col === ACTIONCOLUMNKEY && this.actionColumnRef?.nativeElement) {
          specialColumnsWidth += this.actionColumnRef.nativeElement.clientWidth || 150;
        }
      }
    });

    const availableWidth = containerWidth - specialColumnsWidth;
    const perColumnWidth = totalColumns > 0 ? availableWidth / totalColumns : 0;

    this.minColumnWidth = perColumnWidth || 200; // Default width
  }

  /**
   * use this method to update the UI after dynamic columns are displayed
   */
  updateTableColumnSize() {
    this.subs.sink = this.ngZone.onMicrotaskEmpty.pipe(take(3)).subscribe(() => {
      this.updateColumnWidth();
      this.table?.updateStickyColumnStyles();
      this.table?.updateStickyFooterRowStyles();
      this.table?.updateStickyHeaderRowStyles();
      this.cdref.detectChanges();
    });
  }

  compareFn = (a: number | string, b: number | string, isAsc: boolean) => {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  };

  override ngOnDestroy(): void {
    this.worker.clearWorker();
    this.dataSource.disconnect();
    this.subs?.unsubscribe();
  }
}
