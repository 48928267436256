import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { LinkObject } from '../link-cell/link-cell.component';

export interface AvatarLinkObject extends LinkObject {
  name: string;
  image?: string;
  email?: string;
}

@Component({
  selector: 'app-avatar-link-cell',
  templateUrl: './avatar-link-cell.component.html',
  styleUrls: ['./avatar-link-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarLinkCellComponent {
  @Input() value: AvatarLinkObject;
  @Input() external: boolean;
  constructor() { }
}
