import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

import { STORAGE_KEY } from '@enums/globals';
import { LoginResponse } from '../_interfaces';

export const COOKIES_CONFIG = {
  COOKIES_LIVETIME: 365
};

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  constructor(private cookieService: CookieService) { }

  static setLocalItem(
    key: string,
    model: any,
    ttl: number = environment.storageExpiry
  ) {
    if (ttl > 0) {
      this.setWithExpiry(key, model, ttl);
    } else {
      localStorage.setItem(key, JSON.stringify(model));
    }
  }

  static getLocalItem(key: string): any {
    let data = {};

    try {
      data = this.getWithExpiry(key);
    } catch (error) {
      data = null;
    }

    return data;
  }

  static removeLocalItem(key: string): any {
    if (typeof key === 'string' && key.length > 0) {
      return localStorage.removeItem(key);
    }

    return null;
  }

  static clearSessionStorage() {
    sessionStorage.clear();
  }

  static getDomain(): string {
    let domain: string = window.location.hostname;

    domain = domain.replace(/^www\./, '');

    const parts: string[] = domain.split('.');

    if (parts.length > 3) {
      return domain;
    }

    parts.shift();

    return parts.join('.');
  }

  private removeCookie(name: string) {
    const domain: string = StorageService.getDomain();

    this.cookieService.delete(name, '/', domain);
  }

  setUserData(userData: LoginResponse) {
    StorageService.setLocalItem(STORAGE_KEY.USER_DATA, userData);
  }

  getUserData(): LoginResponse {
    return StorageService.getLocalItem(STORAGE_KEY.USER_DATA);
  }

  removeUserData() {
    this.removeCookie(STORAGE_KEY.USER_DATA);
  }

  setCookie(
    name: string,
    value: any,
    livetime: number = COOKIES_CONFIG.COOKIES_LIVETIME
  ) {
    const domain: string = StorageService.getDomain();

    this.cookieService.set(
      name,
      JSON.stringify(value),
      livetime,
      '/',
      domain,
      false,
      `Lax`
    );
  }

  getCookie<T>(name: string): T | null {
    const cookieValue: string = this.cookieService.get(name);

    return cookieValue ? JSON.parse(cookieValue) : null;
  }

  static setWithExpiry(key: string, value: any, ttl: number) {
    const now = new Date();

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value: value,
      expiry: now.getTime() + ttl
    };

    localStorage.setItem(key, JSON.stringify(item));
  }

  static getWithExpiry(key: string) {
    const itemStr = localStorage.getItem(key);

    // if the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();

    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry || !item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  }
}
