import { FocusMonitor } from '@angular/cdk/a11y';
import {
  CdkConnectedOverlay,
  ConnectedPosition,
  ScrollStrategy,
  ViewportRuler
} from '@angular/cdk/overlay';
import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  ViewChild,
  forwardRef
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatFormField } from '@angular/material/form-field';
import { MAT_SELECT_CONFIG, MatSelectConfig } from '@angular/material/select';
import { MultiselectSearchBaseComponent } from '@utilities/multiselect-search.base';
import get from 'lodash/get';
import { merge as $merge, EMPTY, Observable, Subject, iif } from 'rxjs';
import { delay, filter, mapTo, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-multiselect-search',
  templateUrl: './multiselect-search.component.html',
  styleUrls: ['./multiselect-search.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => MultiselectSearchComponent)
    }
  ]
})
export class MultiselectSearchComponent
  extends MultiselectSearchBaseComponent
  implements OnChanges, OnInit, OnDestroy
{
  showPanel$: Observable<boolean>;
  private isDisabled: boolean;

  scrollStrategy: ScrollStrategy;

  @ViewChild('outerInput', { read: ElementRef, static: true })
  private outerInput: ElementRef;

  @ViewChild(CdkConnectedOverlay, { static: true })
  private connectedOverlay: CdkConnectedOverlay;

  private isPanelVisible$: Observable<boolean>;
  private isPanelHidden$: Observable<boolean>;
  private isOverlayDetached$: Observable<void>;

  /** Whether or not the overlay panel is open. */
  private _panelOpen = false;

  /** Whether or not the overlay panel is open. */
  get panelOpen(): boolean {
    return this._panelOpen;
  }

  /** Width of the overlay panel. */
  _overlayWidth: string | number;

  positions: ConnectedPosition[] = [
    {
      originX: 'start',
      originY: 'bottom',
      overlayX: 'start',
      overlayY: 'top',
      offsetY: -21
    },
    {
      originX: 'end',
      originY: 'bottom',
      overlayX: 'end',
      overlayY: 'top'
    },
    {
      originX: 'start',
      originY: 'top',
      overlayX: 'start',
      overlayY: 'bottom',
      panelClass: 'mat-mdc-select-panel-above',
      offsetY: 190
    },
    {
      originX: 'end',
      originY: 'top',
      overlayX: 'end',
      overlayY: 'bottom',
      panelClass: 'mat-mdc-select-panel-above'
    }
  ];

  @Input() label = '';
  @Input() override hint: string;
  @Input() cssClass: string;
  @Input() override receivedValues: Array<object>;
  @Input() default = 'All';
  @Input() required: boolean;
  @Input() isLoading: boolean;
  @Input() addOption: boolean;
  @Input() color: 'primary' | 'accent' | 'warn' | '' = 'primary';
  @Input() selectionLimit = 0;
  @Input() override loader = false;
  @Input() set disabled(val: boolean) {
    this.isDisabled = val;
  }
  get disabled() {
    return this.isDisabled;
  }

  @Output() addNew: EventEmitter<any> = new EventEmitter();
  @ViewChild('parentFormField') parentFormField: MatFormField;

  allSelected = false;

  /** Emits whenever the component is destroyed. */
  protected readonly _destroy = new Subject<void>();

  /**
   * Show the display value for a selected option
   * @param value value to display
   * @returns string
   */
  @Input() override displayFn = (value: any): string => {
    if (value) {
      if (typeof value === 'object') {
        return value[this.nameKey];
      } else {
        const found = this.receivedValues?.find(
          (val) => val[this.valueKey] === value
        );

        return get(found, this.nameKey, '');
      }
    }

    return '';
  };

  constructor(
    public dialog: MatDialog,
    private focusMonitor: FocusMonitor,
    protected _viewportRuler: ViewportRuler,
    @Optional()
    @Inject(MAT_SELECT_CONFIG)
    protected _defaultOptions?: MatSelectConfig
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.focusMonitor.stopMonitoring(this.outerInput);
  }

  override ngOnInit(): void {
    this.scrollStrategy = new ConfirmScrollStrategy(this.outerInput);

    this.isPanelVisible$ = this.focusMonitor.monitor(this.outerInput).pipe(
      filter((focused) => !!focused),
      mapTo(true)
    );

    this.isOverlayDetached$ = this.isPanelVisible$.pipe(
      delay(0),
      switchMap(() =>
        iif(
          () => !!this.connectedOverlay.overlayRef,
          this.connectedOverlay.overlayRef.detachments(),
          EMPTY
        )
      )
    );

    this.isPanelHidden$ = $merge(
      this.isOverlayDetached$,
      this.connectedOverlay.backdropClick
    ).pipe(mapTo(false));
    this.showPanel$ = $merge(this.isPanelHidden$, this.isPanelVisible$);

    this.showPanel$.subscribe((isPanelVisible) => {
      this._panelOpen = isPanelVisible;
      if (this._panelOpen) {
        this._overlayWidth = this._getOverlayWidth();
      }
    });
  }

  emitAddNewEvent() {
    this.addNew.emit();
  }

  /** Gets how wide the overlay panel should be. */
  private _getOverlayWidth(): string | number {
    return (
      this.parentFormField?._elementRef?.nativeElement?.clientWidth || '100%'
    );
  }

  /** Closes the overlay panel and focuses the host element. */
  close(): void {
    if (this._panelOpen) {
      this._panelOpen = false;
    }
    this.connectedOverlay.detach.next();
  }
}

class ConfirmScrollStrategy implements ScrollStrategy {
  _overlay: any;

  constructor(private inputRef: ElementRef) {}

  attach(overlayRef: any) {
    this._overlay = overlayRef;
  }

  enable() {
    document.addEventListener('scroll', this.scrollListener);
  }

  disable() {
    document.removeEventListener('scroll', this.scrollListener);
  }

  private scrollListener = () => {
    // if (confirm('The overlay will be closed. Procced?')) {
    //   this._overlay.detach();
    //   this.inputRef.nativeElement.blur();
    //   return;
    // }
    this._overlay.updatePosition();
  };
}
