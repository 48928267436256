<app-table-toolbar
  *ngIf="(_showToolbar$ | async) !== null && (_showToolbar$ | async) !== undefined && (_showToolbar$ | async) === true">
  <ng-container toolbarLeft>
    <ng-content select="[tableToolbarLeft]"></ng-content>
  </ng-container>
  <ng-container toolbarRight>
    <ng-content select="[tableToolbarRight]"></ng-content>
  </ng-container>
</app-table-toolbar>
<div (window:resize)="onResize()" #tableContainer class="table-container" [fluidHeight]="fluidHeightConfig">
  <ng-container *ngIf="!dataSource?.data?.length">
    <div class="table-load-container" [ngClass]="{ 'is-loading': loader }">
      <ng-content select="[emptyStateSection]"></ng-content>
    </div>
  </ng-container>

  <ng-container *ngIf="selection.hasValue() && selectionEnabled">
    <div class="table-fixed-header">
      <div class="f-row action-buttons-container">
        <mat-checkbox color="primary" class="mb-0 mr-3" (change)="selectAll($event)"
          [checked]="selection.selected.length === dataSource?.data?.length" [indeterminate]="
            selection.hasValue() &&
            selection.selected.length !== dataSource?.data?.length
          "></mat-checkbox>

        <ng-container *ngIf="selection.hasValue()">
          <app-button-group>
            <ng-container *ngFor="let btn of getColumnActionButtons(_selectionColumnKey)">
              <ng-container *ngIf="!btn?.menu?.length; else actionWithDropdown">
                <button class="cf-button cf-minor" mat-button (click)="
                                      emitAction({ emitValue: btn.emitValue, row: null })
                                    " [color]="btn?.color || ''">
                  <mat-icon class="icon-size-6" *ngIf="!!btn?.icon" [fontSet]="btn?.fontSet || 'mso'">{{ btn.icon }}
                  </mat-icon>
                  {{ btn.label | titlecase }}
                </button>
              </ng-container>

              <ng-template #actionWithDropdown>
                <button class="cf-button cf-minor" mat-button [matMenuTriggerFor]="optionMenu"
                  [color]="btn?.color || ''">
                  <mat-icon class="icon-size-6" *ngIf="!!btn?.icon" [fontSet]="btn?.fontSet || 'mso'">{{ btn.icon }}
                  </mat-icon>
                  {{ btn.label | titlecase }}
                </button>

                <mat-menu #optionMenu="matMenu">
                  <app-filter-option *ngFor="let opt of btn?.menu" (click)="
                                        emitAction({ emitValue: opt.emitValue, row: null })
                                      ">
                    {{ opt.label }}
                  </app-filter-option>
                </mat-menu>
              </ng-template>
            </ng-container>
          </app-button-group>
        </ng-container>
      </div>
    </div>
  </ng-container>

  <cdk-virtual-scroll-viewport *ngIf="dataSource?.data?.length" [itemSize]="48" [style.height]="'100%'"
    maxBufferPx="400" minBufferPx="200" tabindex="0">
    <table #table mat-table matSort [dataSource]="dataSource" (matSortChange)="sortData($event)" cdkDropList
      cdkDropListOrientation="horizontal" [cdkDropListData]="displayedColumns" (cdkDropListDropped)="dropCol($event)"
      class="cf-table position-relative" multiTemplateDataRows [ngStyle]="{
        width: !dataSource?.data?.length ? '100%' : '',
        height: !dataSource?.data?.length ? '0px' : 'height: calc(100% - 111px)'
      }">
      <!-- Dynamic columns -->
      <ng-container *ngFor="let column of displayedColumns; let i = index">
        <!-- Adding condition for Selection column -->
        <ng-container *ngIf="column === _selectionColumnKey">
          <ng-container [matColumnDef]="_selectionColumnKey" [sticky]="true">
            <th mat-header-cell *matHeaderCellDef class="select-column" [cdkDragDisabled]="true">
              <div class="f-row action-buttons-container" *ngIf="!selection.hasValue()">
                <mat-checkbox color="primary" class="mb-0 mr-3" (change)="selectAll($event)" [checked]="
                    selection.selected.length === dataSource?.data?.length
                  " [indeterminate]="
                    selection.hasValue() &&
                    selection.selected.length !== dataSource?.data?.length
                  "></mat-checkbox>
              </div>
            </th>
            <td mat-cell *matCellDef="let element" class="select-column"
              [ngStyle]="{ 'min-width.px': 100, 'max-width.px': 100 }">
              <mat-checkbox class="mb-0" color="primary" (change)="selection.toggle(element[selectionKey])"
                [checked]="selection.isSelected(element[selectionKey])">
              </mat-checkbox>
            </td>
          </ng-container>
        </ng-container>

        <!-- Adding condition for Expansion column -->
        <ng-container *ngIf="column === _expansionColumnKey && isExpandable">
          <ng-container [matColumnDef]="_expansionColumnKey" sticky>
            <th mat-header-cell *matHeaderCellDef class="expansion-column"></th>
            <td mat-cell *matCellDef="let element">
              <button mat-button [matTooltip]="
                  element?.id === expandedElement
                    ? 'Click to collapse'
                    : 'Click to expand'
                " [disabled]="!element?.report" (click)="
                  expandedElement =
                    expandedElement === element?.id ? null : element?.id
                ">
                <mat-icon fontSet="mso" class="icon-size-5">{{
                  element?.id === expandedElement
                  ? 'chevron-up'
                  : 'chevron-down'
                  }}
                </mat-icon>
              </button>
            </td>
          </ng-container>
        </ng-container>

        <!-- place for other columns other than Expansion, Selection and Action -->
        <ng-container *ngIf="
            column !== _selectionColumnKey &&
            column !== _actionColumnKey &&
            column !== _expansionColumnKey
          ">
          <ng-container [matColumnDef]="column" [sticky]="isColSticky(column)" [stickyEnd]="isStickyEnd(column)">
            <th cdkDrag cdkDragLockAxis="x" mat-sort-header="{{ column }}" mat-header-cell *matHeaderCellDef
              [ngStyle]="{ 'min-width.px': minColumnWidth }" [style.display]="selection.hasValue() ? 'none' : ''">
              <app-text-line weight="strong" type="base">{{
                column | titlecase | replaceUnderscore
                }}</app-text-line>

              <div fxLayout="row" *ngIf="columnValueMap[column] !== undefined">
                :
                <app-text-line weight="mx-1" textColor="highlight" type="base">{{ columnValueMap[column]
                  }}</app-text-line>
              </div>

              <div *cdkDragPreview class="column-drag-preview">
                <app-text-line weight="strong" type="base">{{
                  column | titlecase | replaceUnderscore
                  }}</app-text-line>
              </div>

              <div *cdkDragPlaceholder class="column-drag-preview placeholder"></div>
            </th>
            <td mat-cell *matCellDef="let element" [ngStyle]="{ 'min-width.px': minColumnWidth }"
              class="{{ getCellStatusClass(element) }}">
              <app-custom-cell (optionClicked)="emitSelectedOption($event, element)"
                [prefixIcon]="getPrefixIcon(column)" [suffixIcon]="getSuffixIcon(column)" [type]="getFieldType(column)"
                [value]="element[column]" [format]="getFormat(column)" [limit]="getLimit(column)"
                [localizeNumericValues]="localizeNumericValues" [extendedView]="false">
              </app-custom-cell>
            </td>
          </ng-container>
        </ng-container>

        <!-- Adding condition for Action column -->
        <ng-container *ngIf="column === _actionColumnKey && showActionColumn">
          <ng-container [matColumnDef]="_actionColumnKey" stickyEnd>
            <th mat-header-cell *matHeaderCellDef class="select-column" [cdkDragDisabled]="true" #actionColumnRef
              [ngStyle]="{ 'min-width': 'fit-content' }">
              <div fxLayout="row" fxLayoutAlign="end center" *ngIf="!selection.hasValue()">
                <span>Action</span>
              </div>
            </th>
            <td mat-cell *matCellDef="let element" class="select-column" [ngStyle]="{ 'min-width': 'fit-content' }">
              <div fxLayout="row" fxLayoutAlign="end center">
                <app-button-group>
                  <ng-container *ngFor="
                      let btn of getColumnActionButtons(_actionColumnKey)
                    ">
                    <ng-container *ngIf="!btn?.menu?.length; else actionWithDropdown">
                      <button *ngIf="isEnabled(btn, element)" [disabled]="shouldDisableButton(btn, element)"
                        [matTooltip]="btn?.tooltip" class="cf-button cf-minor" mat-button (click)="
                                                  emitAction({
                                                    emitValue: btn.emitValue,
                                                    row: element
                                                  })
                                                " [color]="btn?.color || ''">
                        <mat-icon class="icon-size-5" *ngIf="!!btn?.icon" [fontSet]="btn?.fontSet || 'mso'">{{
                          btn.icon }}
                        </mat-icon>
                        {{ btn.label | titlecase }}
                      </button>
                    </ng-container>

                    <ng-template #actionWithDropdown>
                      <button class="cf-button cf-minor" mat-button [matMenuTriggerFor]="optionMenu"
                        [color]="btn?.color || ''">
                        <mat-icon class="icon-size-5" *ngIf="!!btn?.icon" [fontSet]="btn?.fontSet || 'mso'">{{
                          btn.icon }}
                        </mat-icon>
                        {{ btn.label | titlecase }}
                      </button>

                      <mat-menu #optionMenu="matMenu">
                        <ng-container *ngFor="let opt of btn?.menu">
                          <app-filter-option [disabled]="shouldDisableButton(btn, element)"
                            *ngIf="isEnabled(btn, element)" (click)="
                                                                                  emitAction({
                                                                                    emitValue: opt.emitValue,
                                                                                    row: element
                                                                                  })
                                                                                ">
                            {{ opt.label }}
                          </app-filter-option>
                        </ng-container>
                      </mat-menu>
                    </ng-template>
                  </ng-container>
                </app-button-group>
              </div>
            </td>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div class="element-detail" [@detailExpand]="
              element?.id === expandedElement ? 'expanded' : 'collapsed'
            ">
            <app-expanded-row [element]="element"></app-expanded-row>
          </div>
        </td>
      </ng-container>

      <ng-container *ngIf="!hideHeaderRow">
        <tr [hidden]="!dataSource?.data?.length" mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      </ng-container>

      <tr mat-row *matRowDef="let row; columns: displayedColumns"
        [class.expanded-row]="expandedElement === row?.id && isExpandable"></tr>

      <ng-container *ngIf="isExpandable">
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>
      </ng-container>
    </table>
  </cdk-virtual-scroll-viewport>
</div>

<mat-paginator [hidden]="!dataSource?.data?.length"
  *ngIf="(_paginate$ | async) !== null && (_paginate$ | async) !== undefined && (_paginate$ | async) === true"
  [length]="totalCount" [pageSize]="pageSize" [pageSizeOptions]="[25, 50, 100]" color="primary"
  (page)="paginatorAction($event)">
</mat-paginator>