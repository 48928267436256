import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationExtras,
  Router
} from '@angular/router';
import { BrandDetails, TextColors } from '@interfaces/global';
import { NavigationMenuItem } from '@interfaces/menu';
import { CRUD, ModuleNames } from '@interfaces/permissions';
import { LoginResponse, UserType } from '@interfaces/user';
import { PushNotificationService } from '@services/_pusher/push-notification.service';
import { AuthService } from '@services/auth.service';
import { BreakpointService } from '@services/breakpoint.service';
import { PanelTheme, SharedService } from '@services/shared.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-top-navigation',
  templateUrl: './top-navigation.component.html',
  styleUrls: ['./top-navigation.component.scss'],
})
export class TopNavigationComponent implements OnInit, OnDestroy {
  @Input() set background(color: string) {
    if (['primary', 'accent', 'warn'].indexOf(color) > -1) {
      this.toolbarColor = color;
    } else {
      if (this.shared.currentPanelTheme === PanelTheme.Dark) {
        this.toolbarCustomColor = '#212e3d';
      } else {
        this.toolbarCustomColor = color;
      }
    }
  }

  currentThemeIcon: string = 'wb_sunny';
  toolbarColor: string = 'primary';
  toolbarCustomColor: string;
  textColor: TextColors = TextColors.white;
  userInfo$: Observable<LoginResponse>;
  userMenuItems$: Observable<NavigationMenuItem[]> = new BehaviorSubject([...LOGGED_IN_MENU]);
  menuId: string;

  subdomain: Observable<string>;
  branding: Observable<BrandDetails>;
  subs = new SubSink();

  constructor(
    private shared: SharedService,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private breakPointService: BreakpointService,
    private pushNotificationService: PushNotificationService
  ) {
    this.subdomain = this.shared.subdomain;
    this.branding = this.shared.branding;
    this.userInfo$ = this.auth.currentUserObs;

    this.subs.sink = this.shared.panelThemeSub$.subscribe((theme) => {
      this.currentThemeIcon = theme === PanelTheme.Dark ? 'wb_sunny' : 'dark_mode';
      if (this.shared.currentPanelTheme === PanelTheme.Dark) {
        this.toolbarCustomColor = '#212e3d';
      } else {
        this.toolbarCustomColor = this.shared.brandColor;
      }
    });
  }

  get isMobile() {
    return this.breakPointService.isMobile$;
  }

  get isLoggedIn() {
    return !!this.auth.currentUserValue?.authorization?.token;
  }

  toggleSideNavState() {
    this.shared.toggleSidenavState();
  }

  menuClicked(option: NavigationMenuItem) {
    if (option.url === 'logout') {
      this.auth.logout().subscribe();
      return;
    }

    if (option?.outlet) {
      this.router.navigate([
        '.',
        { outlets: { [option?.outlet as string]: option.url } } as NavigationExtras
      ], { queryParamsHandling: 'merge' });
    } else {
      this.router.navigate([option.url]);
    }
  }

  public get isPartner() {
    return (
      this.auth.userType === UserType.Publisher ||
      this.auth.userType === UserType.Advertiser
    );
  }


  toggleTheme() {
    this.shared.toggleTheme();
  }

  ngOnInit(): void {
    this.subs.sink = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const rt = this.shared.getChild(this.route);

        rt.data.subscribe((data) => {
          this.menuId = data?.id || 'register';
        });
      });

    this.subs.sink = this.auth.currentUserObs.subscribe(() => {
      this.userMenuItems$ = new BehaviorSubject([]);

      // Todo: Change this after real time Change detection for directive is fixed
      setTimeout(() => {
        this.userMenuItems$ = new BehaviorSubject([...LOGGED_IN_MENU]);
      }, 0);
    });
  }

  get notificationCounter$() {
    return this.pushNotificationService.notificationCount$;
  }

  get notificationsMenu() {
    return NOTIFICATIONS_MENU;
  }

  get writeMessageMenu() {
    return MESSAGE_MENU;
  }

  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }
}

export const NOTIFICATIONS_MENU: NavigationMenuItem = {
  label: 'Notifications',
  icon: 'notifications',
  url: 'notifications',
  outlet: 'side',
  permission: null
};

export const MESSAGE_MENU: NavigationMenuItem = {
  label: 'Write message',
  icon: 'comment-alt-edit',
  url: 'write-message',
  outlet: 'side',
  module: ModuleNames.Message,
  permission: CRUD.Create
};

export const LOGGED_IN_MENU: NavigationMenuItem[] = [
  {
    label: 'Profile',
    icon: 'manage_accounts',
    url: 'profile',
    module: ModuleNames.Profile,
    permission: CRUD.Read
  },
  {
    label: 'Billing',
    icon: 'account_balance_wallet',
    url: 'billing',
    permission: CRUD.Read,
    module: ModuleNames.Billing
  },
  {
    separator: true
  },
  {
    label: 'Settings',
    icon: 'settings',
    url: 'settings',
    outlet: 'side',
    permission: CRUD.Read,
    module: ModuleNames.Settings
  },
  {
    separator: true
  },
  {
    label: 'Logout',
    icon: 'logout',
    url: 'logout',
    color: {
      icon: 'warn',
      text: 'error'
    }
  }
];
