<mat-toolbar class="table-toolbar" [ngClass]="{ shadow: !!shadowOn }">
  <div class="w-100" fxLayout="row" fxLayoutAlign="space-between center">
    <div class="f-1" fxLayout="row" fxLayoutAlign="start center">
      <app-text-line
        *ngIf="toolbarData?.title"
        type="leading"
        textColor="primary"
        class="cp"
        >{{ toolbarData?.title || '' }}</app-text-line
      >
      <ng-content select="[toolbarLeft]"></ng-content>
    </div>
    <div fxLayout="row" fxLayoutAlign="end center">
      <ng-content select="[toolbarRight]"></ng-content>
    </div>
  </div>
</mat-toolbar>
