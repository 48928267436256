import { ChangeDetectorRef, Directive, inject } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import {
  ApiResponse,
  CRUD,
  ColumnTypeMap,
  DataSourceConfig,
  ModuleNames,
  PaginatedResponse
} from '@interfaces/index';
import { TableData } from '@modules/factory/list-data-table/table-interface';
import { OutletTitle, SharedService } from '@services/shared.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SubSink } from 'subsink';

import { environment } from 'src/environments/environment';
import { ColumnTypes } from './table-data';

@Directive()
export default abstract class AbstractTableComponent implements TableData {
  private cdr = inject(ChangeDetectorRef);
  private _outerSidebar: boolean;
  private _mainSidebar: boolean;

  set openOuterSidebar(value: boolean) {
    this._outerSidebar = value;
    this.cdr.detectChanges();
  }
  get openOuterSidebar() {
    return this._outerSidebar;
  }

  set openSidebar(value: boolean) {
    this._mainSidebar = value;
    this.cdr.detectChanges();
  }
  get openSidebar() {
    return this._mainSidebar;
  }

  titleFromRoute: Observable<OutletTitle>;
  subs = new SubSink();
  loader: boolean;
  refreshObs = new BehaviorSubject([]);
  searchControl = new UntypedFormControl('');
  selectionKey = 'id';

  selectedRows = [];
  rowsToRemove = [];

  method: (
    pageEvent?: PageEvent
  ) => Observable<ApiResponse<PaginatedResponse<any>>>;
  pageEvent: PageEvent = {
    length: 0,
    pageIndex: 1,
    pageSize: 25,
    previousPageIndex: 0
  };
  columnTypeMap: ColumnTypeMap[] = [
    {
      columnName: 'campaign',
      type: ColumnTypes.LINK
    },
    {
      columnName: 'publisher',
      type: ColumnTypes.LINK
    },
    {
      columnName: 'advertiser',
      type: ColumnTypes.LINK
    },
    {
      columnName: 'file_name',
      type: ColumnTypes.HYPERLINK
    },
    {
      columnName: 'is_active',
      type: ColumnTypes.TOGGLE
    },
    {
      columnName: 'postback_url',
      type: ColumnTypes.HYPERLINK
    },
    {
      columnName: 'privacy_postback_url',
      type: ColumnTypes.HYPERLINK
    },
    {
      columnName: 'event',
      type: ColumnTypes.STATUS
    },
    {
      columnName: 'import_type',
      type: ColumnTypes.STATUS
    },
    {
      columnName: 'start_date',
      type: ColumnTypes.DATE
    },
    {
      columnName: 'end_date',
      type: ColumnTypes.DATE
    },
    {
      columnName: 'coupon_type',
      type: ColumnTypes.STATUS
    },
    {
      columnName: 'user_type',
      type: ColumnTypes.USER_TYPE
    },
    {
      columnName: 'categories',
      type: ColumnTypes.TAGS,
      limit: 3
    },
    {
      columnName: 'promotion_methods',
      type: ColumnTypes.TAGS,
      limit: 3
    },
    {
      columnName: 'traffic_channels',
      type: ColumnTypes.TAGS,
      limit: 3
    },
    {
      columnName: 'status',
      type: ColumnTypes.STATUS,
      limit: 3
    }
  ];

  dataSourceConfig: DataSourceConfig = {
    dataSource: { data: [] } as PaginatedResponse<any>,
    stickyColumns: [],
    stickyColumnsEnd: [],
    hiddenColumns: ['_id'],
    actionMenus: [],
    selected: []
  };

  abstract linkModifier(
    dataSource: PaginatedResponse<Array<any>>
  ): PaginatedResponse<Array<any>>;

  constructor(shared: SharedService) {
    this.titleFromRoute = shared.title;
  }

  selectRows(event: any[]) {
    this.selectedRows = event;
  }

  deselectRows(event: any[]) {
    this.rowsToRemove = event;
  }

  resetPageEvent() {
    this.pageEvent = {
      length: 0,
      pageIndex: 1,
      pageSize: 25,
      previousPageIndex: 0
    };
  }

  resetDataSource(enableSelection = false) {
    this.dataSourceConfig = {
      dataSource: { data: [] } as PaginatedResponse<any>,
      stickyColumns: [],
      stickyColumnsEnd: [],
      hiddenColumns: ['_id'],
      actionMenus: [],
      selected: [],
      enableSelection
    };
  }

  refreshTableData() {
    this.dataSourceConfig = {
      ...this.dataSourceConfig
    };
  }

  canModifyKey<T>(key: string, value: unknown, row: T) {
    if (row[key] !== null && row[key] !== undefined) {
      return {
        [key]: value
      };
    }

    return {};
  }

  getTableData(pageEvent: PageEvent) {
    if (!this.method) {
      console.error('Method not implemented');
      return;
    }
    this.loader = true;
    this.subs.sink = this?.method(pageEvent)
      .pipe(
        finalize(() => {
          this.loader = false;
        })
      )
      .subscribe((data) => {
        this.dataSourceConfig = {
          ...this.dataSourceConfig,
          dataSource: this.linkModifier(data?.data)
        };
      });
  }
  get Crud() {
    return CRUD;
  }
  get ModuleNames() {
    return ModuleNames;
  }
  get isTableEmpty(): boolean {
    return !this.dataSourceConfig?.dataSource?.data?.length;
  }
  get currencySymbol() {
    return environment.currencySymbol;
  }

  removeNullValues(obj: any) {
    for (const key of Object.keys(obj)) {
      if (
        obj[key] === null ||
        (Array.isArray(obj[key]) && obj[key].length === 0)
      ) {
        delete obj[key];
      }
    }
    return obj;
  }

  convertToPaginatedResponse(
    response: ApiResponse<any>
  ): ApiResponse<PaginatedResponse<any>> {
    return {
      data: {
        data: response?.data,
        links: {
          first: '',
          last: '',
          next: null,
          prev: null
        },
        meta: {
          per_page: 1000,
          total: 1000,
          current_page: 1,
          from: 1,
          last_page: 1,
          links: [],
          path: '',
          to: 1
        }
      },
      message: response?.message,
      success: response?.success
    };
  }

  handlePagination(event: PageEvent) {
    this.pageEvent = { ...event, pageIndex: event?.pageIndex + 1 };
    this.getTableData(this.pageEvent);
  }
}
