<app-text-line [enableWrap]="true" [innerHtml]="value" *ngIf="extendedView; else clickToView"></app-text-line>

<ng-template #clickToView>
  <button mat-button [matMenuTriggerFor]="htmlPreview">
    <mat-icon class="icon-size-6 mr-2" fontSet="mso">visibility</mat-icon>
    Preview
  </button>

  <mat-menu #htmlPreview="matMenu">
    <div class="html-preview-section">
      <app-text-line [enableWrap]="true" [innerHtml]="value"></app-text-line>
    </div>
  </mat-menu>
</ng-template>