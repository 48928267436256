import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-sidesheet-form-wrapper',
  templateUrl: './sidesheet-form-wrapper.component.html',
  styleUrls: ['./sidesheet-form-wrapper.component.scss']
})
export class SidesheetFormWrapperComponent implements OnInit {
  constructor() {}
  @Input() classes: string = '';
  @Input() actionSection: boolean = true;
  @Input() fullHeight: boolean = false;
  ngOnInit(): void {}
}
