export interface CRUDPermission {
  create: boolean;
  read: boolean;
  update: boolean;
  delete: boolean;
}

export enum CRUD {
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Delete = 'delete'
}

export enum PremiumFeatureKeys {
  AdvanceReport = 'advanced-report',
  SmartLink = 'smart-link',
  SmartAlert = 'smart-alerts',
  SamplingCutback = 'sampling',
  OfferCheck = 'offer-check',
  IPWhitelisting = 'ip-whitelisting',
  LandingPages = 'landing-pages',
}

export interface ModulePermissions {
  [key: string]: Array<CRUD>;
}

export enum ModuleNames {
  Campaign = 'campaign',
  Advertiser = 'advertiser',
  Publisher = 'publisher',
  RoleManagement = 'role-management',
  Billing = 'billing',
  Team = 'team',
  Category = 'category',
  PromotionMethods = 'promotion-methods',
  Customization = 'customization',
  Reports = 'reports',
  Automations = 'automations',
  Sampling = 'sampling',
  Settings = 'settings',
  Tasks = 'tasks',
  Messages = 'messages',

  // Other than the back-end modules
  Dashboard = 'dashboard',
  Offer_Campaign = 'offer_campaign',
  CouponCodes = 'coupon_codes',
  OfferCheck = 'offer_check',
  SmartLink = 'smart_link',
  Logs = 'logs',
  Partner = 'partner',
  Message = 'message',
  Profile = 'profile',
  PartnerProfile = 'partner_profile',
  OptimizationRule = 'optimization_rule',
  ApiKey = 'api_key',
  Support = 'support',
  SmartAlerts = 'smart_alerts',
  Invoices = 'invoices',
  FraudDetection = 'fraud_detection',
}

export enum AccountTypeModules {
  AdvertiserAccess = 'advertiser_access',
}

export interface AllowedAction {
  action: CRUD;
  value: boolean;
}

export interface IPermissionDataSource {
  id?: number;
  allowed_actions: AllowedAction[];
  privilege: ModuleNames;
}
