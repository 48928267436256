import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, APP_INITIALIZER, isDevMode, ErrorHandler } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HttpErrorInterceptor } from '@interceptor/http-error-interceptor';
import { JwtInterceptor } from '@interceptor/jwt.interceptor';
import { FactoryModule } from '@modules/factory/factory.module';
import { LoginRegisterModule } from '@modules/login-register/login-register.module';
import { ApiService } from '@services/api.service';
import { AuthService } from '@services/auth.service';
import { BreakpointService } from '@services/breakpoint.service';
import { HelperService } from '@services/helper.service';
import { LoaderService } from '@services/loader.service';
import { CookieService } from 'ngx-cookie-service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FindMyAccountComponent } from './_components/find-my-account/find-my-account.component';
import { HighlightDirective } from './_directives/highlight.directive';
import { BrandingResolver } from './_resolvers/branding.resolver';
import { UpdatesModule } from '@modules/updates/updates.module';
import * as Sentry from "@sentry/angular-ivy";

// App initializer function
export const initializeApp = (brandingResolver: BrandingResolver) => {
  return (): Promise<boolean> => {
    return brandingResolver.load();
  };
};

@NgModule({
  declarations: [AppComponent, FindMyAccountComponent, HighlightDirective],
  imports: [
    BrowserModule,
    RouterModule,
    BrowserAnimationsModule,
    FactoryModule,
    HttpClientModule,
    LoginRegisterModule,
    UpdatesModule,
    // material UI modules here
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    AppRoutingModule
  ],
  providers: [
    ApiService,
    AuthService,
    CookieService,
    HelperService,
    BreakpointService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    LoaderService,
    Title,
    BrandingResolver,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [BrandingResolver, Sentry.TraceService],
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
