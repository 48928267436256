import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-two-column-table',
  templateUrl: './two-column-table.component.html',
  styleUrls: ['./two-column-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TwoColumnTableComponent {
  @Input() dataSource: unknown[] = [];
  @Input() displayedColumns: string[] = ['key', 'value'];
  @Input() layout: 'profile' | 'table' = 'table';
  @Input() tableClass: string = 'mb-2';
  constructor() { }
}
