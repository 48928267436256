<div fxFill fxLayout="column" fxLayoutAlign="center center">
  <app-null-state [icon]="hasAvailableAccounts ? 'how_to_reg' : 'person'" size="20" [message]="{
      line1: hasAvailableAccounts
        ? 'Accounts found'
        : 'Could not find the requested address',
      line2: hasAvailableAccounts
        ? 'Choose an account to continue'
        : 'Need help finding your account? try using your email'
    }">
    <div fxLayout="column" fxLayoutAlign="center center" *ngIf="!hasAvailableAccounts; else showAccounts">
      <mat-form-field appearance="outline" class="wide mb-4">
        <mat-label>Enter your email</mat-label>
        <input type="text" matInput [formControl]="emailControl" />
        <mat-hint style="text-align: center;">If an account exists we'll get it for you</mat-hint>
      </mat-form-field>

      <button mat-raised-button color="primary" (click)="findAccountByEmail()" [disabled]="!emailControl.valid">
        Find your account
      </button>
    </div>

    <ng-template #showAccounts>
      <div class="available-accounts-grid">
        <div class="account-card f-col" *ngFor="let account of availableAcccounts">
          <app-text-line type="base" textColor="info" [enableWrap]="true" weight="strong" class="mb-1">
            {{ account.company_name }}
          </app-text-line>
          <a type="button" class="nodecor" href="{{ getDomain(account?.domain) }}" mat-button>Go</a>
        </div>
      </div>
    </ng-template>
  </app-null-state>
</div>