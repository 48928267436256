import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from '@services/auth.service';
import { StorageService } from '@services/storage.service';
import { STORAGE_KEY } from '@enums/globals';
import { environment } from 'src/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  private masterToken: string;
  constructor(private authService: AuthService) {
    this.masterToken = StorageService.getLocalItem(STORAGE_KEY.MASTER_TOKEN);
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // add authorization header with jwt token if available
    const token = this.authService.currentUserToken;

    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: this.getToken(request, token)
        }
      });
    }

    if (request.url.includes('/fapi')) {
      request = request.clone({
        method: 'GET'
      });
    }

    return next.handle(request);
  }

  getToken(request: HttpRequest<any>, token: string) {
    this.masterToken = StorageService.getLocalItem(STORAGE_KEY.MASTER_TOKEN);
    if (
      request.url.includes('/app/search') ||
      request.url.includes('/master/login')
    ) {
      return `Bearer ${this.masterToken || token}`;
    } else {
      return `Bearer ${token}`;
    }
  }
}
