import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router, UrlTree } from '@angular/router';
import { ApiResponse, BrandingResponse } from '@interfaces/index';
import { NotifyService } from '@services/notify.service';
import { SharedService } from '@services/shared.service';
import { StorageService } from '@services/storage.service';
import get from 'lodash/get';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { SubSink } from 'subsink';

export interface IFormData<T> {
  method: (x: T) => Observable<ApiResponse<any>>;
  constructor: (
    shared: SharedService,
    router: Router,
    route: ActivatedRoute
  ) => void;
}

@Component({
  selector: 'app-form-data-component',
  template: ''
})
export default abstract class FormDataComponent<T> implements OnDestroy {
  abstract form: UntypedFormGroup;
  outlet: any = 'side';
  loader: boolean;
  patchId: any;
  method: (x: T, patchId?: any) => Observable<ApiResponse<any>>;
  patchRequest: (patchId: any) => Observable<ApiResponse<any>>;
  private sharedService: SharedService;
  private routerInstance: Router;
  private routeInstance: ActivatedRoute;
  private notifyInstance: NotifyService;

  formDataId: any;
  subs = new SubSink();
  autoclose = true;
  notifyUser = true;
  resetFormAfterSave = true;
  resetFormOnError = false;
  formStateUpdate = true;
  emitDataAfterSave = true;

  @Output() formDataSaved: EventEmitter<ApiResponse<any>> = new EventEmitter(
    null
  );

  constructor(
    shared: SharedService,
    router: Router,
    route: ActivatedRoute,
    notify: NotifyService
  ) {
    this.sharedService = shared;
    this.routerInstance = router;
    this.routeInstance = route;
    if (notify) {
      this.notifyInstance = notify;
    }
  }

  extractPatchId(data: any, key: string = '_id') {
    this.patchId = get(data, key, null);
    return data;
  }

  patchExistingData<T, K>(id: any, transformer?: (x: K) => T): void {
    if (!id) return null;

    this.loader = true;
    this.subs.sink = this.patchRequest(id)
      .pipe(
        finalize(() => {
          this.loader = false;
        })
      )
      .subscribe({
        complete: () => {
          this.loader = false;
        },
        next: (resp: ApiResponse<K>) => {
          const patchData = transformer ? transformer(resp?.data) : resp?.data;

          this.form.patchValue(patchData);
        },
        error: (error) => {
          this.loader = false;
          this.notifyInstance.openSnackBar(error?.message, 'Dismiss');
        }
      });
  }

  submitFormData() {
    this.loader = true;
    this.subs.sink = this.method(this.form.value, this.patchId).subscribe({
      complete: () => {
        this.loader = false;
        this.closeSidesheet();
        if (this.resetFormAfterSave) {
          this.form?.reset();
          this.form?.markAsPristine();
        }
      },
      next: (data: any) => {
        if (this.formStateUpdate) this.sharedService.formDataSavedState = true;
        this.emitDataAfterSave && this.formDataSaved.emit(data);
        this.handleNotification(data);
      },
      error: (error) => {
        this.loader = false;
        this.notifyInstance.openSnackBar(error?.message, 'Dismiss');
      }
    });
  }

  handleNotification(data: ApiResponse<any>) {
    if (this.notifyUser) {
      if (!this.notifyInstance) {
        console.warn('Could not find Notify service instance');
        return;
      }
      this.notifyInstance.openSnackBar(data?.message, 'Dismiss');
    }
  }

  closeSidesheet() {
    if (this.autoclose) {
      if (this.outlet === 'side') {
        this.routerInstance.navigate([{ outlets: { [this.outlet]: null } }], {
          relativeTo: this.routeInstance.parent
        });
      } else {
        this.routerInstance.navigateByUrl(this.outlet);
      }
    }
  }

  removeNullValues(obj: any) {
    for (const key of Object.keys(obj)) {
      if (
        obj[key] === null ||
        (Array.isArray(obj[key]) && obj[key].length === 0)
      ) {
        delete obj[key];

        if (Array.isArray(obj[key])) {
          // iterate over the array of objects and remove null values
        }
      }
    }
    return obj;
  }

  /**
   * Find and remove null values recursively
   * within an object or an array of objects
   * at any level
   * @param obj any
   */
  removeNullValuesRecursively(obj: any) {
    for (const key of Object.keys(obj)) {
      if (
        obj[key] === null ||
        (Array.isArray(obj[key]) && obj[key].length === 0)
      ) {
        delete obj[key];
      } else if (typeof obj[key] === 'object') {
        this.removeNullValuesRecursively(obj[key]);
      } else if (Array.isArray(obj[key]) && obj[key].length) {
        obj[key].forEach((item: any) => {
          this.removeNullValuesRecursively(item);
        });
      }
    }
    return obj;
  }

  /**
   * Update the url tree for the current activated route
   */
  routeAfterSidesheetClosed() {
    const urlTree: UrlTree = this.routerInstance.parseUrl(
      this.routerInstance.url
    );

    delete urlTree.root.children?.side?.children.outer;
    return urlTree;
  }

  get subdomain() {
    const { origin } = new URL(window.location.origin);
    const branding: BrandingResponse = StorageService.getLocalItem(
      environment.production ? origin : environment.networkOrBrandDomain
    );

    return branding?.domain;
  }

  unsubscribe() {
    this.subs?.unsubscribe();
  }

  ngOnDestroy(): void {
    this.unsubscribe();
  }
}
