import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-premium-info-card',
  templateUrl: './premium-info-card.component.html',
  styleUrls: ['./premium-info-card.component.scss']
})
export class PremiumInfoCardComponent implements OnInit {
  message: string = `Unlock the full potential of your campaigns with our Premium features! 🚀 Supercharge your performance marketing efforts, gain in-depth insights, and elevate your ROI. Upgrade today for data-driven success!`;
  constructor(
    private dialogRef: MatDialogRef<PremiumInfoCardComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any
  ) {}

  ngOnInit(): void {}

  navigateBack() {
    this.dialogRef.close();
  }
}
