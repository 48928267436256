import {
  InternalNavigationMenu,
  ReportNavigationMenu,
  ReportType,
  ReportViewType,
  TrackingType
} from '@interfaces/global';
import { CRUD, ModuleNames } from '@interfaces/permissions';
import every from 'lodash/every';
import isNull from 'lodash/isNull';

export const REGEXPATTERNS = {
  URL: /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
  TIME: /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/,
  DAY: /^(sunday|monday|tuesday|wednesday|thursday|friday|saturday)$/i,
  INVALID_DOMAINS: /^(?!.*\b(appcarry\.com|appcarry\.live)\b).*$/
};

export const MACRO_DEFINITIONS_LINK = `https://help.appcarry.com/frequently-asked-questions/what-are-appcarry-tracking-macros-and-parameters`;

export const NOLIMIT = {
  length: 0,
  pageIndex: 1,
  pageSize: 1000,
  previousPageIndex: 0
};

export const addIndex = (
  menu: InternalNavigationMenu[]
): InternalNavigationMenu[] => {
  let index = 0;

  return menu.map((item) => {
    if (!item?.separator) {
      item.index = index;
      index++;
    }

    return item;
  });
};

export enum PARTNERINFO_TYPES {
  INTEGRATION = 'integration',
  ATTRIBUTION = 'attribution',
  AD_REVENUE = 'ad_revenue',
  COST = 'cost',
  PERMISSIONS = 'permissions'
}

export const STORAGE_KEY = {
  ACCEPT_COOKIE: 'acceptCookie',
  USER_DATA: 'currentUser',
  CURRENTUSER: 'currentUser',
  PREMIUM: 'premium',
  MASTER_TOKEN: 'master_token',
  COHORT_OPTIONS: 'cohort_options',
  BRANDCOLOR: 'brandColor',
  FILTERS: 'filters',
  TEAM_MEMBERS: 'teamMembers',
  ADVANCED_REPORT_CONFIG: 'advancedReportConfig',
  APP_THEME: 'app_theme',
  RESENDEMAIL: 'resendEmail',
  TABLE_COLUMN_STATE: 'tableColumnState',
  PUSHER_CHANNEL: 'pusher_channel',
  PAYMENT_NOTIFICATION: 'payment_notification',
  TITLE_STATE: 'title_state',
};

export const COOKIES_CONFIG = {
  COOKIES_LIVETIME: 365
};

export class API {
  public static FACEBOOK_REQUESTS_URL = {
    BASIC_INFO: '/me?fields=id,name,email,birthday,hometown'
  };

  public static FACEBOOK_API = {
    PREFIX: 'https://graph.facebook.com/',
    PICTURE_SUFFIX: '/picture?width=200&height=200'
  };
}

export const LTV_VALUES = {
  DAU: 'dau',
  MAU: 'mau',
  NON_ORGANIC_INSTALLS: 'non_organic_installs',
  NON_ORGANIC_REVENUE: 'non_organic_revenue',
  ORGANIC_INSTALLS: 'organic_installs',
  ORGANIC_REVENUE: 'organic_revenue',
  COST: 'cost',
  SESSIONS: 'sessions',
  LOYAL_USERS: 'loyal_users'
};

export const COLLECTION = {
  UAT: 'UAT',
  BMS: 'BMS',
  GROUPDATA: 'groupData',
  PERFORMANCE: 'performance',
  GEOINSTALLS: 'geoInstalls',
  AI: 'AI',
  UID: 'uid',
  DATAID: 'dataId'
};

export enum SharingType {
  SELF = 'self',
  ALL = 'all'
}

export enum SharedData {
  NVNR = 'NVNR',
  VNR = 'VNR',
  VR = 'VR'
}

export const COHORT_TYPE = [
  {
    name: 'user_acquisition',
    label: 'User Acquisition'
  },
  {
    name: 'retargeting',
    label: 'Retargeting'
  },
  {
    name: 'unified',
    label: 'Unified'
  }
];

export const TREND_TYPE = [
  {
    name: 'life_time',
    label: 'User lifetime performance'
  },
  {
    name: 'attribution_time',
    label: 'KPI by attribution Time'
  }
];

export const getIconByName = (name: string) => {
  switch (name) {
    case 'campaign':
      return 'campaign';
      break;
    case 'publisher':
      return 'newspaper';
      break;
    case 'advertiser':
      return 'ad';
      break;
    case 'reports':
      return 'bar_chart';
      break;

    default:
      return 'folder';
      break;
  }
};

export const orderObjectProperties = (
  inputObject: any,
  orderArray: string[]
): any => {
  const orderedObject: any = {};

  // Add properties from the orderArray to the orderedObject
  orderArray.forEach((property) => {
    if (inputObject.hasOwnProperty(property)) {
      orderedObject[property] = inputObject[property];
    }
  });

  // Add remaining properties to the orderedObject
  for (const property in inputObject) {
    if (!orderedObject.hasOwnProperty(property)) {
      orderedObject[property] = inputObject[property];
    }
  }

  return orderedObject;
};

export const FILTER_BY_OPTIONS = [
  { name: 'Media Source', value: 'media_source' },
  { name: 'Channel', value: 'channel' },
  { name: 'Agency', value: 'agency' },
  { name: 'Campaign', value: 'campaign' },
  { name: 'Campaign ID', value: 'campaign_id' },
  { name: 'Ad', value: 'ad' },
  { name: 'Ad ID', value: 'ad_id' },
  { name: 'AdSet', value: 'ad_set' },
  { name: 'AdSet ID', value: 'ad_set_id' },
  { name: 'Country', value: 'country' },
  { name: 'Site ID', value: 'site_id' },
  { name: 'Attribution Touch Type', value: 'attribution_type' },
  { name: 'Conversion Type', value: 'conversion_type' },
  { name: 'Revenue Type', value: 'revenue_type' }
];

export const GeneralReportsMenu: ReportNavigationMenu[] = [
  {
    title: 'Campaigns report',
    subtitle: 'View detailed report on campaigns performance',
    url: ReportType.Campaign,
    icon: 'computer',
    reportViewType: 'advanced',
    permission: `${ModuleNames.Campaign}.${CRUD.Read}`
  },
  {
    title: 'Publishers report',
    subtitle: 'View detailed report on publishers performance',
    url: ReportType.Publisher,
    icon: 'settings',
    reportViewType: 'advanced',
    permission: `${ModuleNames.Publisher}.${CRUD.Read}`
  },
  {
    title: 'Advertisers report',
    subtitle: 'View detailed report on advertisers performance',
    url: ReportType.Advertiser,
    icon: 'manage_accounts',
    reportViewType: 'advanced',
    permission: `${ModuleNames.Advertiser}.${CRUD.Read}`
  },
  {
    title: 'Goals report',
    subtitle: 'View detailed report on goals achievement',
    url: ReportType.Goal,
    icon: 'ad',
    reportViewType: 'advanced',
  }
];
export const AdditionalReportsMenu: ReportNavigationMenu[] = [
  {
    title: 'CAP report',
    subtitle: 'View detailed report on CAP',
    icon: 'tune',
    url: ReportType.CAP,
    reportViewType: 'advanced',
  },
  {
    title: 'Sampling report',
    subtitle: 'View detailed report on sampling data',
    icon: 'price_change',
    url: ReportType.Sampling,
    reportViewType: 'advanced',
    permission: `${ModuleNames.Sampling}.${CRUD.Read}`
  },
  {
    title: 'Offline report',
    subtitle: 'View uploaded offline data report',
    icon: 'wifi_off',
    url: ReportType.Offline,
    reportViewType: 'advanced',
  },
  {
    title: 'Cohort report',
    subtitle: 'View detailed report on Cohort data',
    icon: 'scatter_plot',
    url: ReportType.Cohort,
    reportViewType: 'general'
  },
  // {
  //   title: 'Conversion funnel',
  //   subtitle: 'View detailed report on conversion funneling',
  //   icon: 'filter_alt',
  //   url: ReportType.ConversionFunnel,
  //   reportViewType: 'general'
  // },
];
export const TrackingReportsMenu: ReportNavigationMenu[] = [
  {
    title: 'Click report',
    subtitle: 'View detailed report on clicks data',
    icon: 'ads_click',
    url: ReportType.Click,
    reportViewType: 'general'
  },
  {
    title: 'Conversion report',
    subtitle: 'View detailed report on conversion data',
    icon: 'conversion_path',
    url: ReportType.Conversion,
    reportViewType: 'general'
  },
  {
    title: 'Impression report',
    subtitle: 'View detailed report on impression data',
    icon: 'web_traffic',
    url: ReportType.Impression,
    reportViewType: 'general'
  },
  {
    title: 'Failed Conversions',
    subtitle: 'View conversions logs which are not considered',
    icon: 'sync_problem',
    url: ReportType.FailedConversion,
    reportViewType: 'general'
  }
];
export const PublisherReports: ReportNavigationMenu[] = [
  {
    title: 'Offers/Campaigns report',
    subtitle: 'View detailed report on offers/campaigns',
    icon: 'campaign',
    url: ReportType.Campaign,
    reportViewType: 'advanced',
    permission: `${ModuleNames.Offer_Campaign}.${CRUD.Read}`
  },
  {
    title: 'Daily report',
    subtitle: 'View detailed report on daily data',
    icon: 'event',
    url: ReportType.Daily,
    reportViewType: 'advanced'
  },
  {
    title: 'Sub ID report',
    subtitle: 'View detailed report on Sub IDs',
    icon: 'pie_chart',
    url: ReportType.SubID,
    reportViewType: 'advanced'
  },
  // {
  //   title: 'Geo-Wise report',
  //   subtitle: 'View detailed report on Geo-Wise data',
  //   icon: 'globe-stand',
  //   url: ReportType.GeoWise,
  // },
  {
    title: 'Goal-Wise report',
    subtitle: 'View detailed report on Goal-Wise data',
    icon: 'public',
    url: ReportType.Goal,
    reportViewType: 'advanced'
  }
];
export const AdvertiserReports: ReportNavigationMenu[] = [
  {
    title: 'Campaigns report',
    subtitle: 'View detailed report on campaigns performance',
    icon: 'campaign',
    url: ReportType.Campaign,
    reportViewType: 'advanced',
    permission: `${ModuleNames.Campaign}.${CRUD.Read}`
  },
  {
    title: 'Goals report',
    subtitle: 'View detailed report on goals achievement',
    url: ReportType.Goal,
    icon: 'sports_score',
    reportViewType: 'advanced'
  }
];

export const getReportViewTypeByUrl = (url: string): ReportViewType => {
  const report = AllReportMenus.find((menu) => menu.url === url);
  return report?.reportViewType;
};

export const AllReportMenus = [
  ...GeneralReportsMenu,
  ...TrackingReportsMenu,
  ...AdditionalReportsMenu
];

export enum TrackingTypes {
  Conversion_Pixel = 'conversion_pixel',
  Conversion_Iframe = 'conversion_iframe',
  JS_Tag = 'js_tag',
  Appcarry_Web_SDK = 'appcarry_web_sdk',
  PostBack_Conversion = 'postBack_conversion',
  TrackingUrl = 'tracking_url',
  GlobalPostbackUrl = 'global_postback_url',
  ImpressionPixel = 'impression_pixel'
}

export const STANDARD_DATE_FORMAT = 'YYYY-MM-DD';
export const DISPLAY_DATE_FORMAT = 'MMM d, y';
export const URL_DATE_FORMAT = 'DD-MM-YYYY';

export const POSTBACK_URL: TrackingType = {
  label: TrackingTypes.PostBack_Conversion,
  value: `[[DOMAIN]]/acquisition?[[QUERYPARAMS]]`
};

export const TRACKING_URL: TrackingType = {
  label: TrackingTypes.TrackingUrl,
  value: `[[DOMAIN]]?[[QUERYPARAMS]]`
};

export const GLOBAL_POSTBACK_URL: TrackingType = {
  label: TrackingTypes.GlobalPostbackUrl,
  value: `[[DOMAIN]]/acquisition?security_token={SECURITY_TOKEN}&click_id={click_id}`
};

export const CONVERSION_PIXEL = {
  label: TrackingTypes.Conversion_Pixel,
  value: `<script src="[[DOMAIN]]/pixel?security_token={SECURITY_TOKEN}&[[QUERYPARAMS]]">`
};

export const CONVERSION_IFRAME = {
  label: TrackingTypes.Conversion_Iframe,
  value: `<iframe src="[[DOMAIN]]/pixel?security_token={SECURITY_TOKEN}&[[QUERYPARAMS]]" scrolling="no" frameborder="0" width="1" height="1"></iframe>`
};

export const JS_TAG = {
  label: TrackingTypes.JS_Tag,
  value: `<script type="text/javascript">(function(){var a=document.createElement("iframe");a.setAttribute("src","[[DOMAIN]]/pixel?security_token={SECURITY_TOKEN}&[[QUERYPARAMS]]");a.style.width="1";a.style.height="1";document.body.appendChild(a)})();</script>`
};

export const APPCARRY_WEB_SDK = {
  label: TrackingTypes.Appcarry_Web_SDK,
  value: `<script src='https://static-cdn.appcarry.com/js/appcarry-web-sdk.js'></script>
            <script>
            window.AppcarrySDK.trackConversion('[[DOMAIN]]', [[PARAMS]]);
            </script>`
};

export const IMPRESSION_PIXEL = {
  label: TrackingTypes.ImpressionPixel,
  value: `<img src="[[DOMAIN]]/impression?[[QUERYPARAMS]]">`
};

type AnyObject = Record<string, any>;

export const deleteProperties = <T extends AnyObject>(
  obj: T,
  propertiesToDelete: string[]
): T => {
  const newObj = { ...obj };

  propertiesToDelete.forEach((property) => {
    if (property in newObj) {
      delete newObj[property];
    }
  });

  return newObj;
};

export const areAllPropertiesNull = (obj: AnyObject): boolean => {
  return every(obj, isNull);
};

/**
 * Check if all objects in an array have null values in their properties
 * @param arr Array
 * @returns boolean
 */
export const areAllObjectsNull = (arr: AnyObject[]): boolean => {
  return !arr?.length || arr.every((obj) => areAllPropertiesNull(obj));
};

export const EXPANSIONCOLUMNKEY = '$expansion';
export const SELECTIONCOLUMNKEY = '$select';
export const ACTIONCOLUMNKEY = '$action';

export enum BillingStatus {
  None = 'none',
  Overdue = 'overdue',
  Due = 'due'
}
