import { WorkerResponse } from "@interfaces/worker";

export const callmethodByName = (name: string, args: unknown[], uid): WorkerResponse => {
    let result = null;
    let error = '';
    try {
        result = workerFunctions[name](...args);
    } catch (e) {
        error = e.message;
    }
    return { result, error, uid };
}

export const workerFunctions = {
    setColumnCalculations: (columnCalculation: any,
        tableData: any[],
        displayedColumns, columnValueMap: unknown) => {
        displayedColumns.forEach((column) => {
            if (columnCalculation?.sumAllExcept?.indexOf(column) === -1) {
                const sum = tableData.reduce((a, b) => a + (Number(b[column]) || 0), 0);

                columnValueMap[column] = sum;
            }
            if (columnCalculation?.averageColumns?.indexOf(column) > -1) {
                const sum = tableData.reduce((a, b) => a + (Number(b[column]) || 0), 0);

                columnValueMap[column] = `${sum / tableData.length}`;
            }
            if (columnCalculation?.sumColumns?.indexOf(column) > -1) {
                const sum = tableData.reduce((a, b) => a + (Number(b[column]) || 0), 0);

                columnValueMap[column] = sum;
            }
        });

        displayedColumns.forEach((column: string) => {
            if (columnCalculation?.rateColumns?.indexOf(column) > -1) {
                const rateKey = columnCalculation?.rateCalculationParamsMap[column];

                if (!rateKey) {
                    console.error('Rate calculation args missing');
                    columnValueMap[column] = ``;
                }

                const { total, divisor } = rateKey;

                if (columnValueMap[divisor] === 0) {
                    columnValueMap[column] = `0 %`;
                } else {
                    columnValueMap[column] = `${(
                        (columnValueMap[total] / columnValueMap[divisor]) *
                        100
                    )?.toFixed(2)} %`;
                }
            }
        });

        return columnValueMap;
    },
    filterNodeAndAncestors: (dataNodes: any[], nodeName: string): unknown => {
        if (!nodeName) {
            return dataNodes.map((node) => {
                node.visible = true;

                return node;
            });
        }
        const nodes: any[] = [];
        const parent: any[] = [];
        const grandParent: any[] = [];

        dataNodes.forEach((currentNode) => {
            if (currentNode.item.label.toLowerCase()?.includes(nodeName)) {
                if (currentNode.level === 0) {
                    nodes.push(currentNode);
                } else if (currentNode.level === 1) {
                    parent.push(currentNode);

                    const _parentNode = dataNodes.find(n => n.id === currentNode.item.parentId);

                    if (parent.findIndex(p => p.id === _parentNode?.id) === -1 && _parentNode) {
                        parent.push(_parentNode);
                    }

                } else if (currentNode.level === 2) {
                    grandParent.push(currentNode);

                    const _parentNode = dataNodes.find(n => n.id === currentNode.item.parentId);

                    if (parent.findIndex(p => p.id === _parentNode?.id) === -1 && _parentNode) {
                        parent.push(_parentNode);

                        const _grandParentNode = dataNodes.find(n => n.id === _parentNode.item.parentId);

                        if (grandParent.findIndex(p => p.id === _grandParentNode?.id) === -1 && _grandParentNode) {
                            grandParent.push(_grandParentNode);
                        }
                    }
                }
            }
        });

        return [...nodes, ...parent, ...grandParent];
    },
    flattenObjectArray: (array: any[], childrenKey: string): any[] => {
        const flatArray = [];

        array.forEach(item => {
            flatArray.push(item);
            if (item[childrenKey] && item[childrenKey].length > 0) {
                const children = workerFunctions.flattenObjectArray(item[childrenKey], childrenKey);
                flatArray.push(...children);
            }
        });

        return flatArray;
    }
}

export const compareFn = (a: number | string, b: number | string, isAsc: boolean) => {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
};

export const getLighterColor = (color: string, factor: number): string => {
    // Function to lighten RGB color
    const lightenRGB = (r: number, g: number, b: number, a: number | undefined): string => {
        r = Math.min(Math.floor(r * (1 + factor)), 255);
        g = Math.min(Math.floor(g * (1 + factor)), 255);
        b = Math.min(Math.floor(b * (1 + factor)), 255);
        const alpha = a !== undefined ? `, ${a}` : '';
        return `rgba(${r}, ${g}, ${b}${alpha})`;
    }

    // Function to lighten hex color
    const lightenHex = (hex: string): string => {
        hex = hex.replace('#', '');
        const r = parseInt(hex.substring(0, 2), 16);
        const g = parseInt(hex.substring(2, 4), 16);
        const b = parseInt(hex.substring(4, 6), 16);
        return lightenRGB(r, g, b, undefined);
    }

    // Detect color format and call appropriate function
    if (color.startsWith('#')) {
        return lightenHex(color);
    } else if (color.startsWith('rgb')) {
        const values = color.match(/\d+/g);
        if (!values || (values.length !== 3 && values.length !== 4)) {
            throw new Error('Invalid color format. Please provide a valid RGB or RGBA color.');
        }
        const r = parseInt(values[0], 10);
        const g = parseInt(values[1], 10);
        const b = parseInt(values[2], 10);
        const a = values.length === 4 ? parseFloat(values[3]) : undefined;
        return lightenRGB(r, g, b, a);
    } else {
        throw new Error('Unsupported color format. Please provide a valid hex, RGB, or RGBA color.');
    }
}