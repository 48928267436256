<div fxLayout="column" fxLayoutAlign="start start" class="h-100 w-100 center mat-app-background position-relative">
  <!-- <app-common-toolbar class="w-100" [loginActive]="false"></app-common-toolbar> -->
  <div fxLayout="column" fxLayoutAlign="center center" class="h-100 w-100">
    <mat-card class="l-card ht4 transparent-bg">
      <div fxLayout="row" fxLayoutAlign="start start" class="h-100 w-100">
        <div class="col-md-6 h-100 desktop">
          <div class="w-100 h-100" fxLayout="row" fxLayoutAlign="center center">
            <span class="appCarryLogin desktop" [ngStyle]="{
                'background-image': 'url(' + backgroundImage + ')'
              }">
            </span>
          </div>
        </div>
        <div class="h-100 pt-5 pb-5 desktop">
          <div class="h-100 pt-4 pb-4">
            <div class="hr"></div>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 h-100 p-3 position-relative" fxLayout="column" fxLayoutAlign="center center">
          <div class="w350p" *ngIf="!forgetPassword">
            <div class="top-blue-hr"></div>
            <h2 class="text-normal">Login</h2>

            @if(!!current2FAtoken()) {
            <form class="w-100" [formGroup]="twoFactorAuthForm" (ngSubmit)="onLogin()">
              <div fxLayout="column" fxLayoutAlign="center center" class="w-100">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Enter OTP from authenticator app</mat-label>
                  <input type="text" matInput placeholder="OTP" formControlName="otp" required />
                  <mat-error *ngIf="twoFactorAuthForm.get('otp').errors?.serverError">
                    {{ twoFactorAuthForm.get('otp').errors?.serverError }}
                  </mat-error>
                </mat-form-field>

                <span *ngIf="errorText" class="danger">{{ errorText }}</span>
                <button mat-raised-button [disabled]="loading" class="w-100" [class.spinner]="loading" color="primary">
                  Login
                </button>
              </div>
            </form>
            }
            @else {
            <form class="w-100" [formGroup]="loginForm" (ngSubmit)="onLogin()">
              <div fxLayout="column" fxLayoutAlign="center center" class="w-100">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Enter email</mat-label>
                  <input type="email" matInput placeholder="E-mail" formControlName="email" required />
                  <mat-error *ngIf="loginForm.get('email').errors?.serverError">
                    {{ loginForm.get('email').errors?.serverError }}
                  </mat-error>
                </mat-form-field>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Enter password</mat-label>
                  <input [attr.type]="passwordVisible ? 'text' : 'password'" matInput placeholder="Enter password"
                    formControlName="password" required />
                  <mat-icon fontSet="mso" matSuffix class="icon-size-5 __secondary link" *ngIf="passwordVisible"
                    (click)="passwordVisible = false">visibility_off</mat-icon>
                  <mat-icon fontSet="mso" matSuffix class="icon-size-5 __secondary link" *ngIf="!passwordVisible"
                    (click)="passwordVisible = true">visibility</mat-icon>

                  <mat-error *ngIf="loginForm.get('password').errors?.serverError">
                    {{ loginForm.get('password').errors?.serverError }}
                  </mat-error>
                </mat-form-field>

                <section class="mb-2 w-100 text-left">
                  <mat-checkbox color="primary" formControlName="rememberMe">
                    Remember Me
                  </mat-checkbox>
                </section>
                <span *ngIf="errorText" class="danger">{{ errorText }}</span>
                <button mat-raised-button [disabled]="loading" class="w-100" color="primary">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  Login
                </button>
                <div class="w-100 center p-2">
                  <span class="ac-primary link" (click)="togglePasswordForm()" (keyup.enter)="togglePasswordForm()"
                    tabindex="0">Forgot your password?</span>
                </div>
              </div>
            </form>
            }
          </div>

          <div class="w350p" *ngIf="forgetPassword">
            <div class="top-blue-hr"></div>
            <h2 class="text-normal">Reset password</h2>
            <form class="w-100" [formGroup]="loginForm" (ngSubmit)="onLogin()">
              <div fxLayout="column" fxLayoutAlign="center center" class="w-100">
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Enter email</mat-label>
                  <input type="email" matInput placeholder="E-mail" formControlName="email" required />
                  <mat-error *ngIf="loginForm.get('email').errors?.serverError">
                    {{ loginForm.get('email').errors?.serverError }}
                  </mat-error>
                </mat-form-field>
                <span *ngIf="errorText" class="danger">{{ errorText }}</span>
                <button mat-raised-button [disabled]="loading" class="w-100" color="primary">
                  <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                  Send password reset link
                </button>
                <div class="w-100 center p-2">
                  <span class="ac-primary link" (click)="togglePasswordForm()" (keyup.enter)="togglePasswordForm()"
                    tabindex="-1">Back to login</span>
                </div>
              </div>
            </form>
          </div>

          <app-official-links class="bottom"></app-official-links>
        </div>
      </div>
    </mat-card>
  </div>
</div>