import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { orderObjectProperties } from '@enums/globals';
import {
  BrandingResponse,
  ColumnTypeMap,
  PaginatedResponse,
  TeamMember
} from '@interfaces/global';
import { CRUD, ModuleNames } from '@interfaces/permissions';
import { LoginResponse, UserType } from '@interfaces/user';
import { PostbackService } from '@modules/postback/services/postback.service';
import { TeamService } from '@modules/team/services/team.service';
import { AccessState, AuthService } from '@services/auth.service';
import { ConfirmationService } from '@services/confirmation.service';
import { NotifyService } from '@services/notify.service';
import { SharedService } from '@services/shared.service';
import { StorageService } from '@services/storage.service';
import AbstractTableComponent from '@utilities/table-component';
import { ColumnTypes } from '@utilities/table-data';
import { Observable, of } from 'rxjs';
import { distinctUntilChanged, switchMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent extends AbstractTableComponent implements OnInit {
  twoFactorControl = this.fb.control(false);
  supportText: string = `Use Grant access to raise a support request
                  to our team. Once initiated your account manager will
                  be notified to help you with your query.`;
  override method = this.team.getTeamMembers.bind(this.team);
  accountManager$ = this.auth
    .getAccountManager()
    .pipe(switchMap((res) => of(res?.data)));
  accessGrantedStatus: boolean;
  linkModifier(dataSource: PaginatedResponse<TeamMember[]>): PaginatedResponse<TeamMember[]> {
    dataSource.data = dataSource.data.map((item) => {
      return orderObjectProperties(
        {
          ...item,
          name: {
            label: `${item?.first_name} ${item?.last_name}`,
            email: item?.email,
            routerLink: [
              '../',
              {
                outlets: {
                  outer: ['member-profile', UserType.TeamMember, item._id]
                }
              }
            ]
          },
          roles: item?.roles?.map((role) => role?.name) as any,
          teams: item?.teams?.map((t) => t.name) as any
        },
        ['name']
      );
    });

    return dataSource;
  }
  userInfo$: Observable<LoginResponse>;
  apiKey = '';
  brandDetails: BrandingResponse;
  override loader = false;
  postbackUrlLoader = false;
  postbackUrl: string;
  accessCheckLoader = false;
  columnTypesMap: ColumnTypeMap[] = [
    {
      columnName: 'name',
      type: ColumnTypes.AVATAR_LINK
    },
    {
      columnName: 'roles',
      type: ColumnTypes.TAGS
    },
    {
      columnName: 'status',
      type: ColumnTypes.STATUS
    },
    {
      columnName: 'user_type',
      type: ColumnTypes.USER_TYPE
    },
    {
      columnName: 'teams',
      type: ColumnTypes.TAGS
    }
  ];
  constructor(
    private auth: AuthService,
    private notify: NotifyService,
    private fb: UntypedFormBuilder,
    private postbackService: PostbackService,
    private team: TeamService,
    private confirm: ConfirmationService,
    shared: SharedService,
    private cdRef: ChangeDetectorRef
  ) {
    super(shared);
    this.userInfo$ = this.auth?.currentUserObs;
    const { origin } = new URL(window.location.origin);

    this.apiKey = this.auth.apiKey;
    this.brandDetails = StorageService.getLocalItem(
      environment.production ? origin : environment.networkOrBrandDomain
    );

    this.dataSourceConfig.hiddenColumns = ['first_name', 'last_name', 'email'];
  }

  notifyCopy(label: string) {
    this.notify.openSnackBar(`${label} copied to clipboard`, 'Dismiss');
  }

  // get postbackUrl() {
  //   return `http://${this.subdomain}appcarry.live/acquisition?security_token=${
  //     this.securityToken || 'TOKEN'
  //   }&click_id={CLICK_ID}&goal={GOAL_VALUE}`;
  // }

  get subdomain(): string {
    return this.brandDetails?.domain?.replace('appcarry.com', '') || '';
  }

  get securityToken(): string {
    return this.brandDetails?.security_token;
  }

  get fullname$(): Observable<string> {
    return this.userInfo$.pipe(
      switchMap((res) => of(`${res?.first_name} ${res?.last_name}`))
    );
  }

  ngOnInit(): void {
    this.auth.hasPermissionInline(`${ModuleNames.Team}.${CRUD.Read}`) &&
      this.getTableData(this.pageEvent);
    this.manageAccess(AccessState.Check);
    this.twoFactorControl.valueChanges
      .subscribe((res) => {
        res ? this.enableTwoFactor() : this.disableTwoFactor();
      })
    this.auth.currentUserObs.subscribe((res) => {
      this.twoFactorControl.setValue(res?.is_2f_enabled, { emitEvent: false });
    });
  }

  enableTwoFactor() {
    this.auth.openTwoFactorAuthDialog(null)
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.notify.openSnackBar(res.message, 'Dismiss');
        } else {
          this.twoFactorControl.setValue(false, { emitEvent: false });
        }
      });
  }

  disableTwoFactor() {
    const confirm = this.confirm.openDialog({
      cancelLabel: 'Cancel',
      successLabel: 'Disable 2FA',
      message: 'Are you sure you want to disable Two-factor authentication?'
    })
    confirm.afterClosed().subscribe((isConfirmed) => {
      if (isConfirmed) {
        this.auth.disableTwoFactorAuth().subscribe((res) => {
          this.notify.openSnackBar(res.message, 'Dismiss');
        });
      } else {
        this.twoFactorControl.setValue(true, { emitEvent: false });
      }
    });
  }

  grantAccess() {
    this.confirm
      .openDialog({
        cancelLabel: 'Cancel',
        successLabel: 'Confirm',
        message:
          'You are about to grant access to your account. Click confirm to proceed.'
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.manageAccess(AccessState.Grant);
        }
      });
  }

  manageAccess(state: AccessState) {
    this.accessCheckLoader = true;
    this.auth.manageAccess(state).subscribe((res) => {
      this.accessCheckLoader = false;
      this.accessGrantedStatus = !!res?.data?.has_access;

      if (state !== AccessState.Check) {
        this.notify.openSnackBar(res?.message, 'Dismiss');
      }
    });
  }

  revokeAccess() {
    this.manageAccess(AccessState.Revoke);
  }

  shareData(password: string) {
    const data: ShareData = { text: password };

    navigator.share(data);
  }

  changePassword() {
    this.confirm
      .openDialog({
        cancelLabel: 'Cancel',
        successLabel: 'Send',
        message:
          'Sure to generate and send reset password link to your registered email?'
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.auth
            .forgotPassword({
              email: this.auth.currentUserValue.email,
              account_id: null
            })
            .subscribe((res) => {
              this.notify.openSnackBar(
                'Password reset link sent successfully on your registered email',
                'Dismiss'
              );
            });
        }
      });
  }

  public get userTeams(): string[] {
    return this.auth?.currentUserValue?.teams?.map((t) => t?.name) || [];
  }
}
