import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { TrackingProviders } from '@enums/providers';
import {
  AddPostbackRequest,
  ApiResponse,
  DebugResponse,
  PaginatedResponse,
  Postback
} from '@interfaces/global';
import { BehaviorSubject, Observable } from 'rxjs';
import ApiBase from 'src/app/_utilities/api-base';

export interface PostbackUrlrequest {
  platform: keyof TrackingProviders;
}
export interface PostbackUrlResponse {
  url: string;
}

@Injectable({
  providedIn: 'root'
})
export class PostbackService extends ApiBase {
  private addPostbackTrigger: BehaviorSubject<boolean> = new BehaviorSubject(
    null
  );

  public postbackTriggerObs = this.addPostbackTrigger.asObservable();
  constructor(private http: HttpClient) {
    super();
  }

  public set postbackTriggerValue(val: boolean) {
    this.addPostbackTrigger.next(val);
  }

  debugPostback(id: number): Observable<ApiResponse<DebugResponse>> {
    return this.http.get<ApiResponse<DebugResponse>>(
      `${this.baseUrl}postback/debug/${id}`
    );
  }

  changePostbackStatus(
    id: number,
    action: 'enable' | 'disable'
  ): Observable<ApiResponse<DebugResponse>> {
    return this.http.patch<ApiResponse<DebugResponse>>(
      `${this.baseUrl}postback/${id}/${action}`,
      {}
    );
  }

  deletePostback(id: number): Observable<ApiResponse<null>> {
    return this.http.delete<ApiResponse<null>>(`${this.baseUrl}postback/${id}`);
  }

  addPostback(
    body: AddPostbackRequest,
    id?: any
  ): Observable<ApiResponse<null>> {
    body = this.removeNullValues(body);
    return this.http.post<ApiResponse<null>>(
      `${this.baseUrl}postback/create/${id || ''}`,
      body
    );
  }

  getPostbacks(
    pageEvent: PageEvent = this.pageEvent
  ): Observable<ApiResponse<PaginatedResponse<Postback[]>>> {
    return this.http.get<ApiResponse<PaginatedResponse<Postback[]>>>(
      `${this.baseUrl}postback?page=${pageEvent?.pageIndex}&per_page=${pageEvent?.pageSize}`
    );
  }

  getPostbackDetails(postbackId: number): Observable<ApiResponse<any>> {
    return this.http.get<ApiResponse<any>>(
      `${this.baseUrl}postback/${postbackId}`
    );
  }

  getPostbackUrl(
    body: PostbackUrlrequest
  ): Observable<ApiResponse<PostbackUrlResponse>> {
    return this.http.post<ApiResponse<any>>(
      `${this.baseUrl}advertiser/postback`,
      body
    );
  }
}
