<h2 mat-dialog-title>Confirmation</h2>
<div mat-dialog-content class="p-3">
  <h3 class="mat-h3 text-center">{{ dialogData.message }}</h3>

  <div *ngIf="note && note.note !== null">
    <div [style.background]="
        note && note.note_background ? note.note_background : '#ffffff'
      ">
      <div class="m-3 p-2 text-center">
        <span [style.color]="note && note.note_color ? note.note_color : ''">
          <mat-icon fontSet="mso" color="warn" class="icon-sm">{{
            note.note_icon
            }}</mat-icon>
          {{ note.note }}
        </span>
      </div>
    </div>
  </div>

  <div class="w-100 mt-3" *ngIf="inputConfig">
    <mat-form-field class="w-100" appearance="outline">
      <mat-label>{{inputConfig?.label}}</mat-label>
      @if(inputConfig?.inputType === 'input') {
      <input matInput placeholder="{{ inputConfig?.placeholder }}" [formControl]="inputControl" />
      }
      @if(inputConfig?.inputType === 'textarea') {
      <textarea matInput placeholder="{{ inputConfig?.placeholder }}" [formControl]="inputControl"></textarea>
      }
    </mat-form-field>
  </div>

  <div mat-dialog-actions fxLayout="row" fxLayoutAlign="center center">
    <button class="mr-2" mat-button (click)="onNoClick()">
      {{ dialogData.cancelLabel }}
    </button>
    <button mat-raised-button color="primary" [disabled]="(!!inputConfig && !inputControl.valid)"
      (click)="onNoClick(inputControl?.value || true)">
      {{ dialogData.successLabel }}
    </button>
  </div>
</div>