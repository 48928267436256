import { Component, OnInit } from '@angular/core';
import {
  UntypedFormGroup,
  Validators,
  UntypedFormControl
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ResetPasswordBody } from '@interfaces/global';
import { AuthService } from '@services/auth.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  resetForm: UntypedFormGroup;
  loader = false;
  success: boolean;
  errorText =
    "The link you'\re trying to access is either expired or not valid";
  token: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService
  ) {
    this.route.queryParams.subscribe((p) => {
      if (p && p.token) {
        this.token = p.token;
        this.success = true;
      } else {
        this.success = false;
      }
    });
  }

  ngOnInit(): void {
    this.resetForm = new UntypedFormGroup({
      password: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(6)
      ]),
      confirmPassword: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(6)
      ])
    });
  }

  onSubmit() {
    if (this.resetForm.valid) {
      const body: ResetPasswordBody = {
        password: this.resetForm.get('password').value,
        token: this.token
      };

      this.resetForm.disable();
      this.loader = true;
      this.auth
        .resetPassword(body)
        .pipe(
          finalize(() => {
            this.loader = false;
          })
        )
        .subscribe((res) => {
          this.resetForm.reset();
          this.success = res?.success;
          this.router.navigateByUrl('/login');
        });
    }
  }
}
