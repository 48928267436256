import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-selectable-pills-cell',
  templateUrl: './selectable-pills-cell.component.html',
  styleUrls: ['./selectable-pills-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectablePillsCellComponent {
  @Input() value: Array<{ action: string; value: boolean }>;
  @Output() optionClicked: EventEmitter<string> = new EventEmitter();
  constructor() { }

  emitOption(option: string): void {
    this.optionClicked.emit(option);
  }
}
