<div class="progress-bar-track">
  <span
    class="progress-bar"
    style.width.%="{{ config?.value || 0 }}"
    [style.backgroundColor]="config?.color"></span>
</div>
<app-text-line
  *ngIf="label"
  weight="mt-1"
  type="xsmall"
  textColor="secondary"
  >{{ label }}</app-text-line
>
