<div class="sidenav-wrapper" fxLayout="column" fxLayoutAlign="start start" [ngClass]="{ 'full-height': fullHeight }">
  <div class="sidenav-toolbar brb" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign="start center">
      <button *ngIf="fragment === 'navigateBack'; else closeButton" mat-button class="close-btn mr-2"
        (click)="navigateBack()">
        <mat-icon fontSet="mso" class="icon-size-6">chevron_left</mat-icon>
      </button>

      <ng-template #closeButton>
        <button mat-button class="close-btn mr-2" (click)="closeSidesheet()">
          <mat-icon fontSet="mso" class="icon-size-6">close</mat-icon>
        </button>
      </ng-template>
      <ng-content select="[sidenavToolbarLeft]"></ng-content>
    </div>

    <div fxLayout="row" fxLayoutAlign="end center">
      <ng-content select="[sidenavToolbarRight]"></ng-content>
    </div>
  </div>

  <div class="sidenav-content" [ngClass]="{ 'full-height': fullHeight }" fxLayout="column" fxLayoutAlign="start start">
    <ng-content select="[sidenavContent]"></ng-content>
  </div>
</div>