import {
  AllCampaignsResponse,
  ChartColors,
  ChartWidget,
  DataSourceConfig,
  LTVResponse,
  MetricData,
  PaginatedResponse,
  ReportData,
  StatusWidgetData,
  TextColors,
  TopEntityResponse,
  TopMetrics,
  WidgetMetadata,
  WidgetTypes
} from '@interfaces/global';
import { ChartConfiguration, ChartOptions, Legend, Tooltip } from 'chart.js';
import omit from 'lodash/omit';

import * as countryList from '../../assets/data/countries.json';
import { BasePermission } from '@interfaces/menu';
import { UserType } from '@interfaces/user';

const FONTSTACK = 'Roboto Flex, Roboto, Helvetica, Arial, sans-serif';

export const configureChartOptions = ({
  type,
  data,
  options,
  plugins
}: ChartConfiguration): ChartConfiguration => {
  options = getMergedOptions(type, options);

  plugins = [Tooltip, Legend];

  data = { ...data };

  return { type, data, options, plugins };
};

export const getMergedOptions = (type, options: any): any => {
  if (type === 'doughnut' || type === 'pie') {
    options = options as ChartOptions<'doughnut'>;

    options = {
      ...options,
      maintainAspectRatio: false,
      plugins: {
        legend: { display: true },
        datalabels: {
          backgroundColor: 'rgb(255 255 255 / 50%)',
          borderRadius: 3
        }
      }
    } as ChartOptions<'doughnut'>;
  } else {
    options = {
      ...options,
      maintainAspectRatio: false
    };
  }

  return options;
};

export const handleDashboardWidgetData = (
  data:
    | LTVResponse
    | AllCampaignsResponse
    | MetricData
    | TopEntityResponse
    | ReportData[],
  type: WidgetTypes,
  metadata: Array<WidgetMetadata>
): Array<
  ChartWidget<
    ChartConfiguration | DataSourceConfig | ReportData[] | StatusWidgetData
  >
> => {
  if (type === WidgetTypes.CHART) {
    return handleChartGrid(data as LTVResponse, metadata[0]);
  }
  if (type === WidgetTypes.CHART_REPORT) {
    return [handleReportChartGrid(data as ReportData[], metadata[0])];
  }
  if (type === WidgetTypes.STATUS_CHART) {
    return handleStatusChartGrid(data as AllCampaignsResponse, metadata[0]);
  }
  if (type === WidgetTypes.TABLE) {
    return Object.keys(data).map((key) =>
      handleTableGrid(data[key], {
        title: key,
        info: DashboardHelpers[key]?.desciption,
        id: key
      })
    );
  }
  if (type === WidgetTypes.CHART_TABLE) {
    return [handleChartAndTableData(data as TopEntityResponse, metadata[0])];
  }

  return null;
};

export const handleChartAndTableData = (
  data: TopEntityResponse,
  metadata: WidgetMetadata
): ChartWidget<any> => {
  return {
    gridType: WidgetTypes.CHART_TABLE,
    info: metadata?.info,
    title: metadata?.title,
    id: metadata?.id,
    config: {
      stats: data,
      chart: null
    }
  };
};

export const handleTableGrid = (
  data: MetricData,
  metadata: WidgetMetadata
): ChartWidget<DataSourceConfig | any> => {
  if (!data) {
    return null;
  }

  const dataSource: PaginatedResponse<any> = {
    data: [],
    links: null,
    meta: null
  };

  if (metadata?.title === 'stats') {
    Object?.keys(data).forEach((rowName) => {
      const row = { type: rowName };

      Object.keys(data[rowName]).forEach((col) => {
        row[col] = data[rowName][col];
      });

      dataSource.data.push(row);
    });

    return {
      gridType: WidgetTypes.TABLE,
      info: metadata?.info,
      title: metadata?.title,
      id: metadata?.id,
      config: {
        dataSource,
        hiddenColumns: [],
        stickyColumns: ['type'],
        stickyColumnsEnd: [],
        actionMenus: [],
        enableSelection: false
      }
    };
  } else if (metadata?.title === 'metrics') {
    return {
      gridType: WidgetTypes.TABLE,
      info: metadata?.info,
      title: metadata?.title,
      id: metadata?.id,
      config: {
        stats: data as any
      }
    };
  } else {
    return null;
  }
};

export const handleStatusChartGrid = (
  data: AllCampaignsResponse,
  metadata: WidgetMetadata
): Array<ChartWidget<StatusWidgetData>> => {
  if (Object.keys(data).every((key) => !data[key])) {
    return [
      {
        info: metadata?.info,
        title: metadata?.title,
        id: metadata?.id,
        gridType: WidgetTypes.STATUS_CHART,
        config: null
      }
    ];
  }

  const $countryList: any = (countryList as any).default;
  const countByStatus = [];
  const countryNames = [];
  const values = [];
  const datasetColors = [];
  const colors = [];

  Object.keys(data.count).forEach((key) => {
    colors.push(getTextColor(key));
    countByStatus.push({ key, val: data.count[key] });
  });

  Object.keys(data?.active).forEach((key) => {
    countryNames.push(
      $countryList.find((country) => country.code === key)?.name
    );
    values.push(data.active[key]);
    datasetColors.push(getRandomColor());
  });

  return [
    {
      info: metadata?.info,
      title: metadata?.title,
      id: metadata?.id || 'map',
      gridType: WidgetTypes.WORLDMAP,
      config: {
        map: data?.active,
        chart: null,
        statuses: null
      }
    },
    {
      info: metadata?.info,
      title: metadata?.title,
      id: metadata?.id || 'statuses',
      gridType: WidgetTypes.STATUS_CHART,
      config: {
        statuses: countByStatus,
        chart: null
      }
    }
  ];
};

export const prepareChartConfig = (
  config: TopEntityResponse,
  selectedType: 'today' | 'yesterday' | 'mtd' | 'all_time'
): ChartConfiguration => {
  if (!config) {
    return null;
  }

  const dataByType = (config[selectedType] as TopMetrics[]) || [];
  const labels = Object.keys(dataByType[0] || {}).filter(
    (k) => k !== 'name' && k !== 'id'
  );
  const datasets = [];
  const borderColors = generateDistinctColors(labels.length);

  dataByType.forEach((row, i) => {
    datasets.push(
      {
        type: 'line',
        tension: 0.2,
        fill: false,
        label: row.name,
        data: labels.map((label) => row[label]),
        borderColor: borderColors[i],
        backgroundColor: borderColors[i],
        borderWidth: 1.5,
        order: 1
      },
      {
        type: 'bar',
        fill: true,
        label: 'hidden',
        data: labels.map((label) => row[label]),
        backgroundColor: ChartColors.hover,
        borderWidth: 0,
        pointHitRadius: 0,
        order: 2
      }
    );
  });

  if (!dataByType?.length) {
    return null;
  }

  return configureChartOptions({
    type: 'scatter',
    data: {
      labels,
      datasets
    },
    options: {
      plugins: {
        legend: {
          labels: {
            filter: (item, chart) => {
              return !item.text.includes('hidden');
            }
          }
        },
        tooltip: {
          intersect: false,
          enabled: true,
          mode: 'index',
          axis: 'xy',
          boxPadding: 12,
          position: 'nearest',
          backgroundColor: TextColors.white,
          bodyColor: TextColors.primary,
          titleFont: {
            size: 14,
            family: FONTSTACK,
            weight: 'bold'
          },
          bodyFont: {
            size: 14,
            family: FONTSTACK
          },
          borderColor: ChartColors.secondary,
          borderWidth: 1,
          filter: (tooltipItem: any) => {
            return tooltipItem?.dataset?.type !== 'bar';
          }
        }
      },
      scales: {
        x: {
          grid: {
            offset: false,
            display: false
          }
        },
        y: {
          ticks: {
            display: false
          },
          grid: {
            offset: false,
            display: false
          },
          display: false
        }
      }
    }
  });
};

export const getTextColor = (status: string) => {
  switch (status) {
    case 'active':
      return 'info';
      break;
    case 'paused':
      return 'warning';
      break;
    case 'pending':
      return 'error';
      break;
    case 'disabled':
      return 'secondary';
      break;
    default:
      return '';
      break;
  }
};

export const handleReportChartGrid = (
  data: ReportData[],
  metadata: WidgetMetadata
): ChartWidget<any> => {
  if (!data?.length) {
    return {
      info: metadata?.info,
      title: metadata?.title,
      gridType: WidgetTypes.CHART_REPORT
    } as any;
  }

  const dataAsObj = {};

  data?.forEach((item) => {
    dataAsObj[item?.report_date] = omit(item, ['report_date']);
  });

  const datasets = [];
  const dateAskeyLabels = Object.keys(dataAsObj);
  const labels = Object.keys(dataAsObj[dateAskeyLabels[0]]);
  const borderColors = generateDistinctColors(labels.length)

  labels.forEach((label, i) => {
    const dataArr = dateAskeyLabels.map((date) => dataAsObj[date][label]);

    datasets.push(
      {
        type: 'line',
        tension: 0.2,
        fill: false,
        label,
        data: dataArr,
        borderColor: borderColors[i],
        backgroundColor: borderColors[i],
        borderWidth: 1.5,
        order: 1
      },
      {
        type: 'bar',
        fill: true,
        label: 'hidden',
        data: dataArr,
        backgroundColor: ChartColors.hover,
        borderWidth: 0,
        pointHitRadius: 0,
        order: 2
      }
    );
  });

  return {
    info: metadata?.info,
    title: metadata?.title,
    id: metadata?.id,
    gridType: WidgetTypes.CHART_REPORT,
    config: configureChartOptions({
      type: 'scatter',
      data: {
        labels: dateAskeyLabels,
        datasets
      },
      options: {
        plugins: {
          legend: {
            labels: {
              filter: (item, chart) => {
                return !item.text.includes('hidden');
              }
            }
          },
          tooltip: {
            intersect: false,
            enabled: true,
            mode: 'index',
            axis: 'xy',
            boxPadding: 12,
            position: 'nearest',
            backgroundColor: TextColors.white,
            bodyColor: TextColors.primary,
            titleFont: {
              size: 14,
              family: FONTSTACK,
              weight: 'bold'
            },
            bodyFont: {
              size: 14,
              family: FONTSTACK
            },
            borderColor: ChartColors.secondary,
            borderWidth: 1,
            filter: (tooltipItem: any) => {
              return tooltipItem?.dataset?.type !== 'bar';
            }
          }
        },
        scales: {
          x: {
            grid: {
              offset: false,
              display: false
            }
          },
          y: {
            ticks: {
              display: false
            },
            grid: {
              offset: false,
              display: false
            },
            display: false
          }
        }
      }
    })
  };
};

export const handleChartGrid = (
  data: LTVResponse,
  metadata: WidgetMetadata
): Array<ChartWidget<ChartConfiguration>> => {
  const keyLabels = Object.keys(data);

  return keyLabels.map((key) => {
    const labels = [...Object.keys(data[key])];
    const values: any[] = [...Object.values(data[key])];

    return {
      info: metadata?.info,
      title: metadata?.title,
      id: metadata?.id,
      gridType: WidgetTypes.CHART,
      config: configureChartOptions({
        type: 'doughnut',
        data: {
          labels,
          datasets: [
            {
              data: values,
              backgroundColor: [
                ChartColors.green,
                ChartColors.orange,
                ChartColors.info
              ],
              hoverOffset: 4
            }
          ]
        }
      })
    };
  });
};

export const getRandomColor = () => {
  const randomColor = Math.floor(Math.random() * 16777215).toString(16);

  document.body.style.backgroundColor = '#' + randomColor;
  return '#' + randomColor;
};

export interface DashboardWidgetMetadata extends Partial<BasePermission> {
  title: string;
  desciption: string;
  height: number;
  width: number;
  css?: string;
}

export const DashboardHelpers: Record<string, DashboardWidgetMetadata> = {
  metrics: {
    title: `Metrics`,
    desciption: `These metrics provides an overview of the overall business performance.`,
    height: 250,
    width: 50,
    css: 'span-4'
  },
  stats: {
    title: `Stats`,
    desciption: `This section gives the status the total number of publishers, advertisers and the status if they are active or pending for approval.`,
    height: 250,
    width: 50,
    css: 'span-4'
  },
  campaigns: {
    title: `Top 5 campaigns`,
    desciption: `Here you can see top performing campaigns with some metrics like clicks, conversions, impressions, payout and revenue.`,
    height: 300,
    width: 100
  },
  advertisers: {
    title: `Top 5 advertisers`,
    desciption: `Here you can see top advertisers with some metrics like clicks, conversions, impressions, payout.`,
    height: 300,
    width: 100,
    restrictedUserType: [UserType.Advertiser, UserType.Publisher]
  },
  publishers: {
    title: `Top 5 publishers`,
    desciption: `Here you can see top publishers with some metrics like clicks, conversions, impressions, payout.`,
    height: 300,
    width: 100,
    restrictedUserType: [UserType.Advertiser, UserType.Publisher]
  },
  performance: {
    title: `Performance`,
    desciption: `Here you can see the performance trend of your business over the period of time.`,
    height: 350,
    width: 100
  },
  geo: {
    title: `Campaign stats`,
    desciption: `Here are the campaign status where you can view the number of active, paused, pending, and disabled campaigns.`,
    height: 250,
    width: 50,
    restrictedUserType: [UserType.Advertiser, UserType.Publisher]
  },
  map: {
    title: `Top 5 click sources`,
    desciption: `Here you can see the top 5 click sources on the map.`,
    height: 300,
    width: 50,
    css: 'span-7'
  },
  statuses: {
    title: `Campaign stats`,
    desciption: `Here are the campaign status where you can view the number of active, paused, pending, and disabled campaigns.`,
    height: 200,
    width: 50,
    css: 'span-1',
    restrictedUserType: [UserType.Advertiser, UserType.Publisher]
  }
};


export const generateDistinctColors = (count: number): string[] => {
  const colors: string[] = [];
  const goldenRatioConjugate = 0.618033988749895;

  for (let i = 0; i < count; i++) {
    const hue = (i * goldenRatioConjugate) % 1; // Generate hue using the golden ratio
    const saturation = 0.5; // Fixed saturation
    const lightness = 0.6; // Fixed lightness
    const rgbColor = hslToRgb(hue, saturation, lightness);
    const hexColor = rgbToHex(rgbColor);
    colors.push(hexColor);
  }

  return colors;
}

const hslToRgb = (h: number, s: number, l: number): [number, number, number] => {
  let r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p: number, q: number, t: number) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

const componentToHex = (c: number): string => {
  const hex = c.toString(16);
  return hex.length == 1 ? '0' + hex : hex;
}

const rgbToHex = (rgb: [number, number, number]): string => {
  return '#' + componentToHex(rgb[0]) + componentToHex(rgb[1]) + componentToHex(rgb[2]);
}
