import { LoginResponse } from '@interfaces/user';
import { BasePermissionsByUserType, ModulePermissionMap } from './static-permission-definition';
import { ModuleNames } from '@interfaces/permissions';

export class ModuleStoreClass {

  constructor(private loginResponse: LoginResponse) { }

  public get modulePermissions() {
    const modulePermissions = this.loginResponse?._permissions;

    if (!modulePermissions) {
      return BasePermissionsByUserType[this.loginResponse?.user_type];
    } else {
      // Merging the base permissions with the permissions from the login response
      BasePermissionsByUserType[this.loginResponse?.user_type].forEach((modulePermission) => {
        modulePermissions[modulePermission.module] = modulePermission.permissions;
      })
    }

    return Object.keys(modulePermissions).map((key) => {
      return { module: key, permissions: modulePermissions[key] };
    }) as ModulePermissionMap<ModuleNames>[];
  }
}
