<mat-drawer-container class="w-100 h-100" autosize (mouseleave)="mouseLeave()">
  <mat-drawer #drawer class="h-100 submenu-drawer" mode="side" position="end" animationDuration="90"
    [opened]="(showSubMenuDrawer$ | async) === true" (closed)="subMenuDrawerClosed()">
    <ng-container *ngFor="let subItem of activeMenu?.children">
      <ng-container *ngIf="subItem?.outlet; else withoutOutlet">
        <div class="dropdown-option animate-fade-in" [appPremiumFeature]="subItem.url" featureIconPosition="after"
          [ngClass]="{
                                                    disabled: isDisabled(subItem)
                                                  }" [routerLink]="[
                                                    activeMenu.url,
                                                    subItem.url,
                                                    { outlets: subItem?.outlet || '' }
                                                  ]" [routerLinkActive]="['active', 'active-menu-link']">
          <span>{{ subItem.label }}</span>
        </div>
      </ng-container>

      <ng-template #withoutOutlet>
        <div class="dropdown-option animate-fade-in" [appPremiumFeature]="subItem?.url" featureIconPosition="after"
          [ngClass]="{
                                                    disabled: isDisabled(subItem)
                                                  }" [routerLink]="flattenUrl([activeMenu.url, subItem.url])"
          [routerLinkActive]="['active', 'active-menu-link']" [queryParams]="
                                                    subItem?.defaultDateRangeAsQueryParam ? dateRange : {}
                                                  ">
          <span>{{ subItem.label }}</span>
        </div>
      </ng-template>
    </ng-container>
  </mat-drawer>

  <div fxFill>
    <div class="m3-sidenav-container">
      <div class="m3-scrollables">
        <ul class="m3-nav-list">
          @for(item of menuItems; track item.url) {
          <li class="m3-nav-list-item mb-2 link" (mouseover)="menuHover(item)" (focus)="menuHover(item)"
            (click)="menuClick(item)" (keyup.enter)="menuClick(item)" tabindex="0" [routerLink]="[getMenuUrl(item)]"
            routerLinkActive="active">
            <a class="m3-nav-link nodecor">
              <span class="m3-icon-container">
                <mat-icon class="m3-nav-icon icon-size-6" fontSet="mso">{{ item.icon }}</mat-icon>
              </span>

              <app-text-line type="small" weight="strong text-center">
                {{ item.label | titlecase }}
              </app-text-line>
            </a>
          </li>
          }
        </ul>
      </div>

      <div class="fixed-bottom-section">
        <div class="branding f-col align-items-center">
          <app-text-line type="xsmall" textColor="secondary">Powered by</app-text-line>
          <a href="https://appcarry.com" target="_blank">
            <img src="/assets/applogos/appcarry-logo.svg" width="80px" alt="Appcarry Logo" />
          </a>
        </div>
      </div>
    </div>
  </div>
</mat-drawer-container>