import { ActionEvent, ApiResponse, StatusType } from "@interfaces/global";
import { BulkOperationService } from "@services/bulk-operation.service";
import { ConfirmationService } from "@services/confirmation.service";

export const isColorDark = (hexOrRgba: string): boolean => {
  // Function to convert hex to RGB
  const hexToRgb = (hex: string): number[] => {
    const bigint = parseInt(hex.slice(1), 16);
    return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
  };

  // Function to calculate brightness from RGB values
  const calculateBrightness = (r: number, g: number, b: number): number => {
    return (0.299 * r + 0.587 * g + 0.114 * b) / 255;
  };

  let r, g, b;

  // Check if hex or rgba is provided
  if (hexOrRgba.startsWith('#')) {
    // Hex color
    [r, g, b] = hexToRgb(hexOrRgba);
  } else if (hexOrRgba.startsWith('rgba')) {
    // RGBA color
    const rgbaMatch = hexOrRgba.match(
      /rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d+(\.\d+)?)\)/
    );
    if (rgbaMatch) {
      r = parseInt(rgbaMatch[1]);
      g = parseInt(rgbaMatch[2]);
      b = parseInt(rgbaMatch[3]);
    } else {
      throw new Error('Invalid RGBA format');
    }
  } else {
    throw new Error('Invalid color format');
  }

  // Calculate brightness and determine if it's dark or light
  const brightness = calculateBrightness(r, g, b);
  return brightness < 0.5; // Adjust this threshold based on your preference
};

export const calculateContrastRatio = (
  color1: string,
  color2: string
): number => {
  if (!color1 || !color2) {
    return null;
  }
  // Function to convert hex to RGB
  const hexToRgb = (hex: string): number[] => {
    const bigint = parseInt(hex.slice(1), 16);
    return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
  };

  // Function to calculate relative luminance from RGB values
  const calculateRelativeLuminance = (
    r: number,
    g: number,
    b: number
  ): number => {
    const linearizeColorComponent = (c: number): number => {
      const srgbValue = c / 255;
      return srgbValue <= 0.04045
        ? srgbValue / 12.92
        : Math.pow((srgbValue + 0.055) / 1.055, 2.4);
    };
    const linearR = linearizeColorComponent(r);
    const linearG = linearizeColorComponent(g);
    const linearB = linearizeColorComponent(b);
    return 0.2126 * linearR + 0.7152 * linearG + 0.0722 * linearB;
  };

  let color1Rgb, color2Rgb;

  // Check if hex or rgba is provided
  if (color1?.startsWith('#')) {
    // Hex color
    color1Rgb = hexToRgb(color1);
  } else if (color1?.includes('rgb')) {
    color1Rgb = rgbaToRGB(color1);
  } else {
    throw new Error('Invalid color format for color1');
  }

  if (color2?.startsWith('#')) {
    // Hex color
    color2Rgb = hexToRgb(color2);
  } else if (color1.includes('rgb')) {
    color2Rgb = rgbaToRGB(color2);
  } else {
    throw new Error('Invalid color format for color2');
  }

  // Calculate relative luminance for both colors
  const luminance1 = calculateRelativeLuminance(
    color1Rgb[0],
    color1Rgb[1],
    color1Rgb[2]
  );
  const luminance2 = calculateRelativeLuminance(
    color2Rgb[0],
    color2Rgb[1],
    color2Rgb[2]
  );

  // Calculate contrast ratio
  const contrastRatio =
    (Math.max(luminance1, luminance2) + 0.05) /
    (Math.min(luminance1, luminance2) + 0.05);

  return contrastRatio;
};

export const rgbaToRGB = (rgba: string): number[] => {
  const rgbaMatch = rgba.match(
    /rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d+(\.\d+)?)\)/
  );
  if (rgbaMatch) {
    const r = parseInt(rgbaMatch[1]);
    const g = parseInt(rgbaMatch[2]);
    const b = parseInt(rgbaMatch[3]);

    return [r, g, b];
  } else {
    throw new Error('Invalid RGBA format');
  }
};

export const isValidArray = (arr: string[]) => {
  return Array.isArray(arr) && arr.length > 0;
};

export const handleBulkStatusChange = <T>(event: ActionEvent<T>,
  confirm: ConfirmationService,
  bulkOperationService: BulkOperationService,
  selectedIds: unknown[],
  type: StatusType,
  key: string,
  subscribe: (res: ApiResponse<null>) => void,
  parentPath?: string,
  statusValue?: string
) => {
  const isMultiple = selectedIds?.length > 1;
  const dialogSub = confirm.openDialog({
    cancelLabel: 'No',
    successLabel: 'Yes',
    message: `Are you sure you want to change the status of ${isMultiple ? selectedIds?.length : 'this'
      } ${type}${isMultiple ? 's' : ''}?`
  });

  dialogSub.afterClosed().subscribe((isConfirmed) => {
    if (isConfirmed) {
      bulkOperationService
        .changeStatusBulk(type, statusValue ?? event.emitValue, {
          [key]: selectedIds
        }, parentPath)
        .subscribe(subscribe);
    }
  });
}
